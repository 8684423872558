import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { removeDevice, removeGroup } from '@/api/clients/devicesGroups';
import { setSelectedTreeItem } from '@/redux/appOptions';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import ConfirmationModal from '@/components/ConfirmationModal';


export interface RemovalConfirmationModalProps {
  open: boolean,
  item: DevicesGroupItem | DeviceItem,
  handleClose: () => void,
  variant: 'group' | 'device',
}

const RemovalConfirmationModal = ({
  open, item, handleClose, variant,
}: RemovalConfirmationModalProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (item) {
      if (variant.toLowerCase() === 'group') {
        removeGroup(item.id);
      } else {
        removeDevice(item.id);
      }
      dispatch(setSelectedTreeItem(item.parentId));
    }
    handleClose();
  };

  return (
    <ConfirmationModal
      open={open}
      content={(
        <Typography>
          <Trans i18nKey="common:removeDataInfo" />
        </Typography>
      )}
      handleClose={handleClose}
      handleConfirm={onSubmit}
      confirmButtonText={t('common:common.remove')}
    />
  );
};

export default RemovalConfirmationModal;

