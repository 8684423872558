import React from 'react';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';


const ButtonsContainer = styled.div<{$center: boolean}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
`;

const StyledCreateIcon = styled(CreateIcon)`
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

interface ActionIconsProps {
  onEdit: () => void,
  onRemove: () => void,
  center?: boolean,
}

const ActionIcons = ({ onEdit, onRemove, center = false }: ActionIconsProps) => (
  <ButtonsContainer $center={center}>
    <IconButton
      onClick={() => onEdit()}
      size="small"
    >
      <StyledCreateIcon fontSize="small" />
    </IconButton>
    <IconButton
      onClick={() => onRemove()}
      size="small"
    >
      <StyledDeleteIcon fontSize="small" />
    </IconButton>
  </ButtonsContainer>
);

export default ActionIcons;
