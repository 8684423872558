/* eslint-disable no-underscore-dangle */
import { DeviceTreeItemBase, TreeItemType } from './DeviceTreeItemBase';


export class DisplayItem extends DeviceTreeItemBase {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  readonly type: TreeItemType.Display = TreeItemType.Display;
  public agentId: string;
  public displayNo: number;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(agentId: string, displayNo: number) {
    super();
    this._id = `${agentId}-${displayNo.toString()}`;
    this.agentId = agentId;
    this.displayNo = displayNo;
  }

  /** Zwraca dzieci i wnuki które są typu DisplayItem */
  public getAllDisplays(): Array<DisplayItem> {
    return [this];
  }
}
