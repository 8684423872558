import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cell, Column } from 'react-table';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ConfigurationTabContent from '@/components/configuration/ConfigurationTabContent';
import { UrlCategoryWithStatus } from '@/redux/collections/urlCategoriesCollection';
import ConfigTableLayout from '@/components/ConfigTableLayout';
import useConfigTable from '@/components/ConfigTableLayout/useConfigTable';
import { systemCategoriesWithStatusSelector } from '@/redux/collections/systemCategoriesCollection';
import CategoryStatusSelect from '@/components/ConfigTableLayout/CategoryStatusSelect';
import TableCellAccordion from '@/components/TableCellAccordion';
import UserSpecificStatus from '@/components/configuration/UserSpecificStatus';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';


const StyledName = styled(Typography)`
  margin-left: 30px;
  font-size: 1em;
`;

const SystemCategories = () => {
  const { t } = useTranslation(['common']);
  const categories = useSelector(systemCategoriesWithStatusSelector);
  const data: UrlCategoryWithStatus[] = useMemo(() => categories, [categories]);

  const columns: Array<Column<UrlCategoryWithStatus>> = React.useMemo(
    () => [
      {
        Header: t('common:configuration.categoryName'),
        id: 'name',
        Cell: ({ row }: Cell<UrlCategoryWithStatus>) => (
          <>
            {(row.original.status === ControlState.DEPENDS_ON_USER) ? (
              <TableCellAccordion
                row={row}
                content={(
                  <UserSpecificStatus itemId={row.original.id} status={row.original.status} />
                )}
              >
                {row.original.name}
              </TableCellAccordion>
            ) : (
              <StyledName>
                {row.original.name}
              </StyledName>
            )}
          </>
        ),
        width: 40,
      },
      {
        Header: t('common:common.description'),
        id: 'description',
        accessor: 'description',
        width: 40,
      },
      {
        Header: t('common:common.status'),
        id: 'status',
        width: 20,
        Cell: ({ row }: Cell<UrlCategoryWithStatus>) => (
          <CategoryStatusSelect status={row.original.status} categoryId={row.original.id} />
        ),
      },
    ],
    [t]
  );

  const tableInstance = useConfigTable<UrlCategoryWithStatus>({
    columns,
    data,
  });

  return (
    <ConfigurationTabContent
      title={t('common:systemCategories')}
    >
      <ConfigTableLayout<UrlCategoryWithStatus>
        tableInstance={tableInstance}
      />
    </ConfigurationTabContent>
  );
};


export default SystemCategories;
