import React from 'react';


const SvgUser = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <defs>
      <path id="user_svg__a" d="M0 0h16v16H0z" />
      <path
        id="user_svg__c"
        fillRule="evenodd"
        d="M2 13.562c0-3.097 2.115-4.803 5.955-4.803s5.955 1.706 5.955 4.803c0 .242-.209.438-.466.438H2.466C2.21 14 2 13.804 2 13.562zm5.955-3.927c-3.14 0-4.822 1.173-5.005 3.49h10.01c-.183-2.317-1.865-3.49-5.005-3.49z"
      />
      <path
        id="user_svg__d"
        fillRule="evenodd"
        d="M4.86 4.963C4.86 3.274 6.192 2 7.956 2c1.764 0 3.094 1.274 3.094 2.964 0 1.738-1.388 3.153-3.094 3.153S4.861 6.702 4.861 4.963zm.934 0c0 1.256.97 2.278 2.161 2.278 1.192 0 2.161-1.022 2.161-2.277 0-1.19-.929-2.088-2.161-2.088-1.252 0-2.161.878-2.161 2.088z"
      />
      <mask
        id="user_svg__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16H0z" />
        <use fill="#fff" xlinkHref="#user_svg__a" />
      </mask>
    </defs>
    <use fill="none" xlinkHref="#user_svg__a" />
    <g mask="url(#user_svg__b)">
      <use fill="#2C3E50" xlinkHref="#user_svg__c" />
      <use
        fillOpacity={0}
        stroke="#2C3E50"
        strokeWidth={0.2}
        xlinkHref="#user_svg__c"
      />
      <g>
        <use fill="#2C3E50" xlinkHref="#user_svg__d" />
        <use
          fillOpacity={0}
          stroke="#2C3E50"
          strokeWidth={0.2}
          xlinkHref="#user_svg__d"
        />
      </g>
    </g>
  </svg>
);

export default SvgUser;
