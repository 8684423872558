/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PermissionDescription } from '@/api/owpb/pbFiles/roles_service_pb';


const adapter = createEntityAdapter<PermissionDescription.AsObject>({});

export type DescriptionsCollectionState = ReturnType<typeof adapter.getInitialState>;

const descriptionsCollection = createSlice({
  name: 'DESCRIPTIONS_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const descriptionsCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.descriptionsCollection
);

export const descriptionsCollectionHolderFunctions = {
  ...descriptionsCollection.actions,
  items: descriptionsCollectionSelectors.selectAll,
};


export default descriptionsCollection.reducer;
