import React from 'react';


const SvgComputer = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <g fill="none">
      <path d="M0 0h16v16H0V0z" />
      <path
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3h10a1 1 0 011 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1zM4.712 13.667h6.576M10 11v2.667M6 11v2.667"
      />
    </g>
  </svg>
);

export default SvgComputer;
