import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import treeService from '@/TreeService';
import { deviceStatusFilterSelector } from '@/redux/appOptions';


export function useDevicesTree(
): { tree: Array<DevicesGroupItem | DeviceItem>, version: number } {
  const filter = useSelector(deviceStatusFilterSelector);
  const [tree, setTree] = useState<Array<DevicesGroupItem | DeviceItem>>([]);
  /* Ponieważ obiekt tree jest instancją złożonej klasy,
  * to useState nie jest wstanie odróżnić starszej wersji od nowszej.
  * To sprawia że nie odpala się rerender komponentu.
  * Wersjonowanie drzewa za pomocą zmiennej `version` naprawia ten problem. */
  const [version, setVersion] = useState<number>(0);

  useEffect(() => {
    if (!treeService.devicesTree) {
      throw new Error('useDevicesTree used before tree initialization');
    }
    const observable = treeService.devicesTree.devicesTreeSubject.asObservable();
    const subscription = observable.subscribe((value) => {
      if (treeService.devicesTree) {
        setVersion(value);
        setTree(treeService.devicesTree.getRootItems());
      }
    });
    return () => subscription.unsubscribe();
  }, [filter]);
  return { tree, version };
}
