import React from 'react';
import { HeaderGroup } from 'react-table';
import styled, { css } from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ResizeHandle from '@/components/ResizeHandle';
import StyledTableCell from './StyledTableCell';


const StyledTableHead = styled(TableHead)<{ $isPlaceholder?: boolean }>`
  ${({ $isPlaceholder }) => ($isPlaceholder
    ? css`
      position: relative;
      visibility: hidden;
    `
    : css`
      position: absolute;
      top: 0;
      width: calc(100% - 7px);
    `)}
`;

const StyledHeaderCell = styled(StyledTableCell)`
  background-color: ${({ theme }) => theme.table.headerBackground};
  overflow: initial;
  cursor: initial !important;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  width: 100%;
`;


export interface HeaderProps<T extends Record<string, unknown>> {
  headerGroups: Array<HeaderGroup<T>>,
  isPlaceholder?: boolean,
}

const Header = <T extends Record<string, unknown>>({
  headerGroups,
  isPlaceholder = false,
}: HeaderProps<T>) => { // eslint-disable-line arrow-body-style
  return (
    <StyledTableHead
      // @ts-ignore
      component="div"
      $isPlaceholder={isPlaceholder}
    >
      {headerGroups.map(headerGroup => (
        <TableRow
          component="div"
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => {
            const {
              onClick,
              ...otherProps
            } = column.getHeaderProps(column.getSortByToggleProps());
            return (
              <StyledHeaderCell
                component="div"
                {...otherProps}
              >
                <StyledTableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                  onClick={onClick}
                >
                  {column.render('Header')}
                </StyledTableSortLabel>
                <ResizeHandle
                  {...column.getResizerProps()}
                  $isResizing={column.isResizing}
                />
              </StyledHeaderCell>
            );
          })}
        </TableRow>
      ))}
    </StyledTableHead>
  );
};


export default Header;
