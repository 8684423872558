import log from 'loglevel';
import { logout } from '@/api';
import { ErrorWithStatus, GrpcErrorOrStatus } from '@/api/ErrorWithStatus';
import { showNoty } from '@/utils/helpers';
import { Code } from './google/pbFiles/code_pb';


export function handleError(
  errorObject: GrpcErrorOrStatus,
  handler?: (err: ErrorWithStatus) => void
) {
  if (errorObject instanceof Error) {
    log.error('handleError() got errorObject in form of Error instance, this is not implemented. Maybe it should be');
  }

  const errorWithStatus = new ErrorWithStatus(errorObject);
  log.error(errorWithStatus);
  // Code.UNAUTHENTICATED (16) indicates issues with authentication, specifically wrong token
  if (errorWithStatus.code === Code.UNAUTHENTICATED) {
    logout();
  }
  if (!handler) {
    showNoty('error', errorWithStatus.message);
  } else {
    handler(errorWithStatus);
  }
}
