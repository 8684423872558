import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';
import { updateSettingsURLCategoryState, updateSettingsURLCategoryStateForRole } from '@/api/clients/settingsFunctions';
import { useGetColorForControlState } from '@/hooks/useGetColorForControlState';


const StyledTextField = styled(TextField)`
  width: 220px;
`;

const DotContainer = styled.span<{ $color: string }>`
  margin-right: 0.5rem;
  font-size: 0.75em;
  color: ${({ $color }) => $color}
`;

export interface CategoryStatusSelectProps {
  status: ControlState,
  categoryId: string,
  roleId?: string,
}

const CategoryStatusSelect = ({
  status,
  categoryId,
  roleId,
}: CategoryStatusSelectProps) => {
  const { t } = useTranslation(['common']);
  const getColorForControlState = useGetColorForControlState();
  return (
    <StyledTextField
      value={status}
      onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (!roleId) {
          updateSettingsURLCategoryState(categoryId, Number(target.value));
        } else {
          updateSettingsURLCategoryStateForRole(roleId, categoryId, Number(target.value));
        }
      }}
      variant="standard"
      select
    >
      <MenuItem value={ControlState.ALLOW}>
        <DotContainer $color={getColorForControlState(ControlState.ALLOW)}>
          {'\u2B24'}
        </DotContainer>
        {t('common:configuration.status.allow')}
      </MenuItem>
      <MenuItem value={ControlState.CHECK}>
        <DotContainer $color={getColorForControlState(ControlState.CHECK)}>
          {'\u2B24'}
        </DotContainer>
        {t('common:configuration.status.check')}
      </MenuItem>
      <MenuItem value={ControlState.BLOCK}>
        <DotContainer $color={getColorForControlState(ControlState.BLOCK)}>
          {'\u2B24'}
        </DotContainer>
        {t('common:configuration.status.block')}
      </MenuItem>
      {!roleId && (
        <MenuItem value={ControlState.DEPENDS_ON_USER}>
          <DotContainer $color={getColorForControlState(ControlState.DEPENDS_ON_USER)}>
            {'\u2B24'}
          </DotContainer>
          {t('common:configuration.status.dependsOnUser')}
        </MenuItem>
      )}
    </StyledTextField>
  );
};


export default CategoryStatusSelect;
