/* eslint-disable no-underscore-dangle */
import { WindowsUserInfo } from '@/api/owpb/pbFiles/basic_pb';
import { DeviceConnected } from '@/api/owpb/pbFiles/pushsub_events_pb';
import { DeviceTreeItemBase, TreeItemType } from './DeviceTreeItemBase';
import { DisplayItem } from './DisplayItem';


export class AgentItem extends DeviceTreeItemBase {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  readonly type: TreeItemType.Agent = TreeItemType.Agent;
  public WindowsUser: WindowsUserInfo | undefined;
  public children: Array<DisplayItem>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(agentId: string, deviceConnected: DeviceConnected) {
    super();
    this._id = agentId;
    this.children = new Array<DisplayItem>();
    this.updateItem(deviceConnected);
  }

  get displaysCount(): number {
    return this.WindowsUser ? this.WindowsUser.getDisplaysCount() : 0;
  }

  get windowsUserSeesionIsActive(): boolean {
    return this.WindowsUser ? this.WindowsUser.getSessionState() === 0 : false;
  }

  updateItem(obj: DeviceConnected): void {
    this.WindowsUser = obj.getWindowsUser();
  }
}
