import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { handleError } from '@/api/errorHandler';
import {
  CreateURLCategoryRequest,
  UpdateURLCategoryRequest,
  DeleteURLCategoryRequest,
  CreateContentFilterRequest,
  UpdateContentFilterRequest,
  DeleteContentFilterRequest,
} from '../owpb/pbFiles/filter_service_pb';
import { FilterServiceClient } from '../owpb/pbFiles/Filter_serviceServiceClientPb';


const client = new FilterServiceClient(apiUrl);

export const getFilterType = (filterType?: number) => {
  switch (filterType) {
    case 0:
      return i18n.t('common:configuration.websiteAddress');
    case 1:
      return i18n.t('common:configuration.word');
    case 3:
      return i18n.t('common:configuration.regularExpresion');
    default:
      return '';
  }
};

export function createURLCategory(name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new CreateURLCategoryRequest();
  request.setName(name);

  client.createURLCategory(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.createURLCategorySuccess')))
    .catch(handleError);
}

export function updateURLCategory(id: string, name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateURLCategoryRequest();
  request.setId(id);
  request.setName(name);

  client.updateURLCategory(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.updateURLCategorySuccess')))
    .catch(handleError);
}

export function removeURLCategory(id: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteURLCategoryRequest();
  request.setId(id);

  client.deleteURLCategory(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.deleteURLCategorySuccess')))
    .catch(handleError);
}

export function createURLCategoryFilter(
  filterType: number, categoryId: string[], data: string
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new CreateContentFilterRequest();
  request.setFilterType(filterType);
  request.setCategoriesIdList(categoryId);
  request.setData(data);

  client.createContentFilter(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.createURLCategoryFilterSuccess', { filterType: getFilterType(filterType).toLowerCase() })))
    .catch(handleError);
}

export function updateURLCategoryFilter(
  filterType: number, id: string, categoryId: string[], name: string
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateContentFilterRequest();
  request.setId(id);
  request.setCategoriesIdList(categoryId);
  request.setData(name);

  client.updateContentFilter(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.updateURLCategoryFilterSuccess', { filterType: getFilterType(filterType).toLowerCase() })))
    .catch(handleError);
}

export function removeURLCategoryFilter(id: string, categoryId: string[], filterType?: number) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteContentFilterRequest();
  request.setId(id);
  request.setCategoriesIdList(categoryId);

  client.deleteContentFilter(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.removeURLCategoryFilter', { filterType: getFilterType(filterType).toLowerCase() })))
    .catch(handleError);
}
