import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import FullAccess from '@/components/fromSvg/internetAccessIcons/FullAccess';
import ContentControl from '@/components/fromSvg/internetAccessIcons/ContentControl';
import InternetBlockage from '@/components/fromSvg/internetAccessIcons/InternetBlockage';
import Restrictive from '@/components/fromSvg/internetAccessIcons/Restrictive';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { InetControlState } from '@/api/owpb/pbFiles/basic_pb';
import { ApiConsts } from '@/api/ApiConsts';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import AccessControlContextMenu from '@/components/AccessControlContextMenu';
import { accessControlsMobileWidth } from '@/constants/common';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import InetAccessControlSubmenu from '@/components/AccessControlContextMenu/InetAccessControlSubmenu';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import { deviceIdFromAgentId } from '@/utils/helpers';
import AccessControlElement from './AccessControlElement';
import AccessControlMenuCategory from './AccessControlMenuCategory';


const ContextMenuButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  svg {
    font-size: 1.4em;
    margin-right: 15px;
  }
`;

interface InternetAccessControlMenuProps {
  currentSetting?: InetControlState,
}

const InternetAccessControlMenu = ({ currentSetting }: InternetAccessControlMenuProps) => {
  const { t } = useTranslation(['common']);
  const { selectedTreeItem } = useSelectedTreeItem();
  const { width } = useWindowDimensions();
  const isMobile = width < accessControlsMobileWidth;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const isActiveVisible = selectedTreeItem && !selectedTreeItem.isGroup;

  const ids = useIdsOfAllSelectedDevices();
  const onlyDeviceIds = selectedTreeItem.type === TreeItemType.Agent
    ? [deviceIdFromAgentId(selectedTreeItem.id)]
    : ids;

  const handleAccessChange = (value: number) => {
    if (selectedTreeItem.id) {
      updateSettingsDeviceAccess(
        ApiConsts.SettingsDeviceInetAccess,
        value.toString(),
        onlyDeviceIds
      );
    }
  };

  return (
    <AccessControlMenuCategory title={t('common:app.internetAccessControl')}>
      <AccessControlElement
        onClick={() => handleAccessChange(InetControlState.INET_ALLOW)}
        isActive={isActiveVisible && currentSetting === InetControlState.INET_ALLOW}
        startIcon={<FullAccess />}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.fullAccess')}
      </AccessControlElement>

      <AccessControlElement
        onClick={() => handleAccessChange(InetControlState.INET_CHECK)}
        isActive={isActiveVisible && currentSetting === InetControlState.INET_CHECK}
        startIcon={<ContentControl />}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.contentControl')}
      </AccessControlElement>

      <AccessControlElement
        onClick={() => handleAccessChange(InetControlState.INET_BLOCK)}
        isActive={isActiveVisible && currentSetting === InetControlState.INET_BLOCK}
        startIcon={<InternetBlockage />}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.blockedInternet')}
      </AccessControlElement>

      <AccessControlElement
        onClick={() => handleAccessChange(InetControlState.INET_STRICT)}
        isActive={isActiveVisible && currentSetting === InetControlState.INET_STRICT}
        startIcon={<Restrictive />}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.restrictive')}
      </AccessControlElement>
      {isMobile && (
        <ContextMenuButton
          onClick={
            (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setAnchorEl(event.currentTarget);
            }
          }
        >
          <FullAccess />
          <ContentControl />
          <InternetBlockage />
          <Restrictive />
          <ExpandMoreIcon />
        </ContextMenuButton>
      )}
      {anchorEl && (
        <AccessControlContextMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        >
          <InetAccessControlSubmenu
            handleClose={() => setAnchorEl(null)}
            deviceId={onlyDeviceIds}
          />
        </AccessControlContextMenu>
      )}
    </AccessControlMenuCategory>
  );
};

export default InternetAccessControlMenu;
