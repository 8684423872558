import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { CancelablePromise } from './owpb/pbFiles/AgentServiceRpcClient';


class CancelablePromiseManager {
  private promises = new Map<string, CancelablePromise<unknown>>();


  public addPromise(promise: CancelablePromise<unknown>) {
    const id = uuidv4();
    this.promises.set(id, promise);
    promise.finally(() => {
      this.promises.delete(id);
    }).catch(() => {
      // konieczne, ponieważ promise.finally zwraca promise, który bez tego będzie
      // miał nieobsłużony wyjątek.
    });
  }

  public cancelAll() {
    this.promises.forEach((promise) => {
      try {
        promise.cancel();
      } catch (error) {
        log.warn(error);
      }
    });

    this.promises.clear();
  }
}

export default new CancelablePromiseManager();
