import { useSelector } from 'react-redux';
import { SelectableTreeItem } from '@/TreeService/types';
import { selectedTreeItemSelector } from '@/redux/appOptions';
import { useDevicesTree } from '@/hooks/useDevicesTree';
import { useTreeItem } from './useTreeItem';


interface UseSelectedTreeItemReturn {
  selectedTreeItem: SelectableTreeItem,
  version: number,
}

export function useSelectedTreeItem(): UseSelectedTreeItemReturn {
  const selectedItemId = useSelector(selectedTreeItemSelector);
  const { tree } = useDevicesTree();

  const { treeItem, version } = useTreeItem(selectedItemId);

  return {
    selectedTreeItem: treeItem as SelectableTreeItem ?? tree[0],
    version,
  };
}
