import React from 'react';
import hasRequiredPermission from '@/utils/securityAndPermissions';


interface PermissionsControlProps {
  /** list of permissions */
  allowedPermissions: string[],
  /** fallback is a component which is displayed, when the user doesn't have required permissions */
  fallback?: React.ReactElement,
  children: React.ReactNode,
}

const PermissionsControl: React.FC<PermissionsControlProps> = ({
  allowedPermissions,
  children,
  fallback,
}: PermissionsControlProps) => {
  const check = hasRequiredPermission(allowedPermissions);

  if (check) {
    return (
      <>
        {children}
      </>
    );
  }

  return fallback ?? null;
};


export default PermissionsControl;
