import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useField } from 'react-final-form';
import { FFBaseProps } from './types';


const StyledFormHelperText = styled(FormHelperText)`
  margin-top: -5px;
`;


interface FFCheckboxWithLabelProps extends
  Omit<CheckboxProps, 'value' | 'checked' | 'name' | 'onChange'>,
  FFBaseProps<string | null> {
  label?: React.ReactNode,
}

const FFCheckboxWithLabel = ({
  name,
  label,
  config,
  ...otherProps
}: FFCheckboxWithLabelProps) => {
  const {
    input: {
      checked, onChange, disabled, value,
    },
    meta: { error, touched },
  } = useField(name, config);


  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            disabled={disabled}
            name={name}
            onChange={onChange}
            checked={checked}
            value={value}
            {...otherProps}
          />

        )}
        label={label}
      />
      <StyledFormHelperText error={!!error}>
        {(error && touched) ? error : ' '}
      </StyledFormHelperText>
    </>
  );
};

export default FFCheckboxWithLabel;
