import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';


const StyledBox = styled(Box)<{ $columnSetting: number, $gridGap: number }>`
  ${({ $columnSetting }) => `--columns-template: repeat(${$columnSetting}, 1fr);`}
  display: grid;
  grid-template-columns: var(--columns-template);
  grid-gap: ${({ $gridGap }) => $gridGap}px;
  padding: 0;
`;

interface ScreenPreviewsGridProps {
  columnSetting: number,
  gridGap: number,
  children: React.ReactNode,
}

const ScreenPreviewsGrid = forwardRef(({
  children,
  gridGap,
  columnSetting,
}: ScreenPreviewsGridProps, ref) => (
  <StyledBox
    p={3}
    $columnSetting={columnSetting}
    $gridGap={gridGap}
    // @ts-ignore MUI have missing type for Box ref
    ref={ref}
  >
    {children}
  </StyledBox>
));

export default ScreenPreviewsGrid;
