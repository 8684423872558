/* eslint-disable @typescript-eslint/unbound-method */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { RoleUser } from '@/api/owpb/pbFiles/roles_service_pb';


const adapter = createEntityAdapter<RoleUser.AsObject>({
});

export type RoleUsersCollectionState = ReturnType<typeof adapter.getInitialState>;

const roleUsersCollection = createSlice({
  name: 'ROLE_USERS_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const roleUsersCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.roleUsersCollection
);

export const roleUsersCollectionHolderFunctions = {
  ...roleUsersCollection.actions,
  items: roleUsersCollectionSelectors.selectAll,
};


export default roleUsersCollection.reducer;
