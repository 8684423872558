import {
  AppBar, CssBaseline, Toolbar,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import SvgLogo from '@/components/fromSvg/Logo';
import NotyContainer from '@/components/NotyContainer';
import { appHeaderHeight } from '@/constants/common';


const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: ${appHeaderHeight}px;
`;

const StyledToolbar = styled(Toolbar)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const AdminifyLogo = styled(SvgLogo)`
  width: 200px;
  height: auto;
`;

export interface NoLoginLayoutProps {
  children: React.ReactNode,
}

const NoLoginLayout = ({
  children,
}: NoLoginLayoutProps) => (
  <div>
    <NotyContainer />
    <CssBaseline />
    <StyledAppBar position="relative" elevation={0}>
      <StyledToolbar>
        <AdminifyLogo />
      </StyledToolbar>
    </StyledAppBar>
    {children}
  </div>
);


export default NoLoginLayout;
