import React from 'react';


const SvgBlockKeyboard = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.333 7a1.5 1.5 0 00-1.5 1.5v4a1.5 1.5 0 001.5 1.5h9.333a1.5 1.5 0 001.5-1.5v-4a1.5 1.5 0 00-1.5-1.5H5.333zm-2.5 1.5c0-1.38 1.12-2.5 2.5-2.5h9.333a2.5 2.5 0 012.5 2.5v4a2.5 2.5 0 01-2.5 2.5H5.333a2.5 2.5 0 01-2.5-2.5v-4z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.327a.5.5 0 01.5.5v.013a.5.5 0 01-1 0v-.013a.5.5 0 01.5-.5zM6 8.66a.5.5 0 01.5.5v.013a.5.5 0 01-1 0V9.16a.5.5 0 01.5-.5zM8.666 8.66a.5.5 0 01.5.5v.013a.5.5 0 01-1 0V9.16a.5.5 0 01.5-.5zM11.333 8.66a.5.5 0 01.5.5v.013a.5.5 0 11-1 0V9.16a.5.5 0 01.5-.5zM14 8.66a.5.5 0 01.5.5v.013a.5.5 0 01-1 0V9.16a.5.5 0 01.5-.5zM14 11.333a.5.5 0 01.5.5v.007a.5.5 0 11-1 0v-.007a.5.5 0 01.5-.5zM7.3 11.833a.5.5 0 01.5-.5h2.99a.5.5 0 110 1H7.8a.5.5 0 01-.5-.5z"
      fill="#2C3E50"
    />
  </svg>
);

export default SvgBlockKeyboard;
