import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { urlStructure } from '@/constants/urlStructure';
import { defaultMainContainerWidth } from '@/constants/common';
import AppLayout from '@/components/layout/AppLayout';
import NavigationTabs from '@/components/NavigationTabs';
import { ApiConsts } from '@/api/ApiConsts';


const Container = styled.section`
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ theme }) => `calc(100vh - ${theme.constants.navbarHeight})`};
`;

const StyledPaper = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  max-width: ${defaultMainContainerWidth}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  a[role=tab] {
    &:not(:last-child) {
      border-right: ${({ theme }) => theme.border};
    }

    & > span {
      text-transform: none;
      font-size: 0.95em;
    }
  }
`;

const tabLinks = [{
  translationKey: 'common:users',
  link: urlStructure.configurationUsers,
  permission: ApiConsts.PermissionAPIAdmin,
}, {
  translationKey: 'common:userCategories',
  link: urlStructure.configurationUserCategories,
  permission: ApiConsts.PermissionAPIFilters,
}, {
  translationKey: 'common:systemCategories',
  link: urlStructure.configurationSystemCategories,
  permission: ApiConsts.PermissionAPIAdmin,
}];

interface ConfigurationMainContainerProps {
  children: React.ReactNode,
}


const ConfigurationMainContainer = ({ children }: ConfigurationMainContainerProps) => (
  <AppLayout>
    <Container>
      <StyledPaper elevation={0}>
        <NavigationTabs
          tabLinks={tabLinks}
          variant="fullWidth"
        />
        {children}
      </StyledPaper>
    </Container>
  </AppLayout>
);

export default ConfigurationMainContainer;
