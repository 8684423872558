import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Refresh from '@/components/fromSvg/Refresh';
import Adjust from '@/components/fromSvg/Adjust';
import SvgSettings from '@/components/fromSvg/Settings';
import PreviewNameSettings from '@/components/screensPreviewPageComponents/PreviewNameSettings';
import SettingsButton from '../SettingsButton';
import ViewOptions from './ViewOptions';


interface SettingsProps {
  refreshPreviews: () => void,
}

const Settings = ({ refreshPreviews }: SettingsProps) => {
  const { t } = useTranslation(['common']);
  const [isViewOptionsModalOpen, setIsViewOptionsModalOpen] = useState(false);
  const [isPreviewSettingsModalOpen, setIsPreviewSettingsModalOpen] = useState(false);

  return (
    <>
      <SettingsButton
        onClick={refreshPreviews}
        startIcon={<Refresh />}
      >
        {t('common:app.refresh')}
      </SettingsButton>
      <SettingsButton
        onClick={() => setIsViewOptionsModalOpen(true)}
        startIcon={<Adjust />}
      >
        {t('common:app.viewOptions')}
      </SettingsButton>
      <SettingsButton
        onClick={() => setIsPreviewSettingsModalOpen(true)}
        startIcon={<SvgSettings />}
      >
        {t('common:app.settings')}
      </SettingsButton>

      <ViewOptions
        isOpen={isViewOptionsModalOpen}
        onClose={() => setIsViewOptionsModalOpen(false)}
      />
      <PreviewNameSettings
        isOpen={isPreviewSettingsModalOpen}
        onClose={() => setIsPreviewSettingsModalOpen(false)}
      />
    </>
  );
};

export default Settings;
