import { PermissionValue } from '@/api/owpb/pbFiles/roles_service_pb';
import { store } from '@/redux/store';


function hasRequiredPermission(allowedPermissions: string[]) {
  const userPermissions = store.getState().userPermissions.permissionList;
  return userPermissions.some(
    (permission: PermissionValue.AsObject) => allowedPermissions.includes(permission.id)
  );
}


export default hasRequiredPermission;
