import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';


const StyledSection = styled.section`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const ElementTitle = styled(Typography)`
  margin-bottom: 15px;
`;

interface CheckoutElementProps {
  title: string,
  sectionNumber: number,
}

const CheckoutSectionWrapper = ({
  title,
  sectionNumber,
  children,
}: React.PropsWithChildren<CheckoutElementProps>) => (
  <StyledSection>
    <ElementTitle variant="h6">
      {`${sectionNumber}. ${title}`}
    </ElementTitle>
    {children}
  </StyledSection>
);

export default CheckoutSectionWrapper;
