import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  padding-top: 5px;
  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.thickBorder};
  }
`;

const SubmenuCategory = styled.div`
  margin: 0 10px 6px;
`;

interface AccessControlContextSubMenuProps {
  title: string,
  children: React.ReactNode,
}

const AccessControlContextSubMenu = ({ title, children }: AccessControlContextSubMenuProps) => (
  <Container>
    <SubmenuCategory>
      <Typography variant="caption">
        {title}
      </Typography>
    </SubmenuCategory>
    {children}
  </Container>
);


export default AccessControlContextSubMenu;
