import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonFooter from '@/components/GenericOptionsModal/ButtonFooter';
import { GenericOptionsModal } from '../GenericOptionsModal';


const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export interface SelectColumnsModalProps<T extends Record<string, unknown>> {
  isOpen: boolean,
  handleClose: () => void,
  allColumns: Array<ColumnInstance<T>>,
}

const SelectColumnsModal = <T extends Record<string, unknown>>({
  isOpen,
  handleClose,
  allColumns,
}: SelectColumnsModalProps<T>) => {
  const { t } = useTranslation(['common']);
  const areAllColumnsHidden = allColumns.filter(column => column.isVisible).length === 0;
  return (
    <GenericOptionsModal
      title={t('common:app.customizeTable')}
      isOpen={isOpen}
      onClose={handleClose}
      disableBackdropClick
    >
      {allColumns.map(column => (
        <div key={column.id}>
          <FormControlLabel
            label={column.Header}
            control={(
              <Checkbox
                color="primary"
                {...column.getToggleHiddenProps()}
              />
            )}
          />
        </div>
      ))}
      {(
        areAllColumnsHidden && (
          <ErrorText variant="caption">
            {t('common:app.chooseAtLeastOneColumn')}
          </ErrorText>
        )
      )}
      <ButtonFooter
        justConfirm
        onConfirm={handleClose}
        confirmButtonText={t('common:common.close')}
        disabled={areAllColumnsHidden}
      />
    </GenericOptionsModal>
  );
};


export default SelectColumnsModal;
