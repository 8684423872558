import {
  createAsyncThunk, createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import log from 'loglevel';
import { DefaultRootState } from 'react-redux';
import { sessionGetItem } from '@/utils/helpers';
import { sessionStoreKey } from '@/constants/common';
import i18n from '@/utils/i18n';
import { Subscription } from '@/api/owpb/pbFiles/subscription_service_pb';


export const restoreUser = createAsyncThunk<UserState | undefined>(
  'USER/restoreUser',
  async () => {
    try {
      const data = await sessionGetItem(sessionStoreKey.user);
      if (data) {
        const user: UserState = JSON.parse(data);
        return user;
      }
    } catch (error) {
      log.warn('Failed to restore user store');
      log.warn(error);
    }
    return undefined;
  }
);

/* ===========SLICE============= */


export interface UserState {
  authData: adminify.AuthData | null,
  login: string | null,
  // Id grupy urządzeń wybranej przez użytkownika podczas logowania lub '*'
  selectedDevicesGroupId: string,
  subscription: Subscription.AsObject | null,
}

const initialState = {
  authData: null,
  login: null,
  selectedDevicesGroupId: '*',
  subscription: null,
} as UserState;

const user = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    clearUser() {
      return { ...initialState };
    },

    clearLicense(state: UserState) {
      return {
        ...state,
        authData: {
          ...(state.authData as adminify.AuthData),
          license: undefined,
        },
      };
    },

    setAuthData(state: UserState, action: PayloadAction<adminify.AuthData>) {
      return {
        ...state,
        authData: action.payload,
      };
    },

    setLicense(state: UserState, action: PayloadAction<adminify.License>) {
      return {
        ...state,
        authData: {
          ...(state.authData as adminify.AuthData),
          license: action.payload,
        },
      };
    },

    setLogin(state: UserState, action: PayloadAction<string>) {
      return {
        ...state,
        login: action.payload,
      };
    },

    setSelectedDevicesGroupId(state: UserState, action: PayloadAction<string>) {
      return {
        ...state,
        selectedDevicesGroupId: action.payload,
      };
    },

    setSubscription(state: UserState, action: PayloadAction<Subscription.AsObject>) {
      return {
        ...state,
        subscription: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(restoreUser.fulfilled, (state, { payload }) => ({
      ...state,
      payload,
    }));
  },
});

export const {
  clearUser,
  clearLicense,
  setAuthData,
  setLicense,
  setLogin,
  setSelectedDevicesGroupId,
  setSubscription,
} = user.actions;


export default user.reducer;


/* ===========Selectors============= */
export const authDataSelector = (state: DefaultRootState) => state.user.authData;
export const loginSelector = (state: DefaultRootState) => state.user.login;
export const licenseSelector = (state: DefaultRootState) => state.user.authData?.license;
export const subscriptionSelector = (state: DefaultRootState) => state.user.subscription;

export const authMetaDataSelector = createSelector(
  authDataSelector,
  (authData: UserState['authData']) => ({
    auth: authData?.token ?? '',
    lng: i18n.language,
  })
);

export const isAuthenticatedSelector = createSelector(
  authDataSelector,
  (authData: UserState['authData']) => (authData !== null)
);

