import React from 'react';


const SvgMultipleAccessSettings = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <defs>
      <path id="other_svg__a" d="M0 0h16v16H0z" />
      <mask
        id="other_svg__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16H0z" />
        <use fill="#fff" xlinkHref="#other_svg__a" />
      </mask>
    </defs>
    <use fill="none" xlinkHref="#other_svg__a" />
    <g mask="url(#other_svg__b)">
      <path fill="none" d="M0 0h16v16H0V0z" />
      <path
        fill="none"
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 2a8 8 0 005.667 2A8 8 0 018 14 8 8 0 012.333 4 8 8 0 008 2"
      />
      <g fill="#2C3E50">
        <circle cx={6} cy={8.533} r={0.5} />
        <circle cx={8} cy={8.533} r={0.5} />
        <circle cx={10} cy={8.533} r={0.5} />
      </g>
    </g>
  </svg>
);

export default SvgMultipleAccessSettings;
