/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { URLCategory } from '@/api/owpb/pbFiles/filter_service_pb';
import { settingsCollectionSelectors } from '@/redux/collections/settingsCollection';
import { Settings } from '@/api/Settings';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';


export interface UrlCategoryWithStatus extends URLCategory.AsObject, Record<string, unknown> {
  status: ControlState,
}

const adapter = createEntityAdapter<URLCategory.AsObject>({});

export type UrlCategoriesCollectionState = ReturnType<typeof adapter.getInitialState>;

const urlCategoriesCollection = createSlice({
  name: 'URL_CATEGORIES_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const urlCategoriesCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.urlCategoriesCollection
);

export const urlCategoriesWithStatusSelector = createSelector(
  urlCategoriesCollectionSelectors.selectAll,
  settingsCollectionSelectors.selectAll,
  (urlCategories, settingsCollection): UrlCategoryWithStatus[] => {
    const settings = new Settings(settingsCollection);
    return urlCategories.map((category: URLCategory.AsObject) => ({
      ...category,
      status: settings.getURLCategoryState(category.id),
    }));
  }
);

export const urlCategoriesCollectionHolderFunctions = {
  ...urlCategoriesCollection.actions,
  items: urlCategoriesCollectionSelectors.selectAll,
};


export default urlCategoriesCollection.reducer;
