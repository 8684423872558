import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MenuItem } from '@material-ui/core';
import FFCheckbox from '@/components/finalForm/FFCheckbox';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import {
  screenPreviewViewOptionsSelector,
  setScreenPreviewViewOptions,
  ViewOptions as ViewOptionsFormFields,
} from '@/redux/appOptions';
import FFTextInput from '@/components/finalForm/FFTextInput';
import FFSlider from '../finalForm/FFSlider';


const FieldsContainer = styled.div`
  > * + * {
    margin-top: 1.5rem;
  }
`;

const RefreshTimeSelect = styled(FFTextInput)`
  width: 50%
`;

const maxColumn = 8;
const minColumn = 1;

const marks = [...Array(maxColumn + 1).keys()].map((number) => ({
  value: number,
  label: String(number),
}));


export interface ViewOptionsProps {
  isOpen: boolean,
  onClose: () => void,
}

function ViewOptions({
  isOpen,
  onClose,
}: ViewOptionsProps) {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const viewOptions = useSelector(screenPreviewViewOptionsSelector);


  return (
    <FFGenericOptionsModal<ViewOptionsFormFields>
      title={t('common:app.viewOptions')}
      isOpen={isOpen}
      onSubmit={(values) => {
        dispatch(setScreenPreviewViewOptions(values));
        onClose();
      }}
      onClose={onClose}
      formProps={{
        initialValues: viewOptions,
      }}
    >
      {({ values }) => (
        <FieldsContainer>
          <div>
            <FFSlider
              name="numberOfColumns"
              label={t('common:app.numberOfColumns')}
              valueLabelDisplay="auto"
              marks={marks}
              step={1}
              min={minColumn}
              max={maxColumn}
              disabled={values.autoTileSize}
            />
            <FormControlLabel
              control={(
                <FFCheckbox
                  color="primary"
                  name="autoTileSize"
                  config={{
                    type: 'checkbox',
                  }}
                />
              )}
              label={t('common:app.automaticScaling')}
            />
          </div>
          <FFSlider
            name="previewQuality"
            label={t('common:app.previewQuality')}
            valueLabelDisplay="auto"
            step={1}
            min={10}
            max={100}
            endsLabels={[
              t('common:common.quality.low'),
              t('common:common.quality.high'),
            ]}
          />
          {/* @ts-ignore */}
          <RefreshTimeSelect
            select
            name="previewRefreshFrequency"
            label={t('common:app.refreshTime')}
          >
            <MenuItem value={-1}>
              {t('common:app.refreshTimeOff')}
            </MenuItem>
            <MenuItem value={5}>
              {t('common:common.seconds_numberOf', { seconds: 5 })}
            </MenuItem>
            <MenuItem value={10}>
              {t('common:common.seconds_numberOf', { seconds: 10 })}
            </MenuItem>
            <MenuItem value={15}>
              {t('common:common.seconds_numberOf', { seconds: 15 })}
            </MenuItem>
            <MenuItem value={30}>
              {t('common:common.seconds_numberOf', { seconds: 30 })}
            </MenuItem>
            <MenuItem value={45}>
              {t('common:common.seconds_numberOf', { seconds: 45 })}
            </MenuItem>
            <MenuItem value={60}>
              {t('common:common.seconds_numberOf', { seconds: 60 })}
            </MenuItem>
            <MenuItem value={90}>
              {t('common:common.seconds_numberOf', { seconds: 90 })}
            </MenuItem>
          </RefreshTimeSelect>
        </FieldsContainer>
      )}
    </FFGenericOptionsModal>
  );
}


export default ViewOptions;
