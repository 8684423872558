/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { SettingsGroup } from '@/api/owpb/pbFiles/settings_service_pb';


const adapter = createEntityAdapter<SettingsGroup.AsObject>({});

export type SettingsCollectionState = ReturnType<typeof adapter.getInitialState>;

const settingsCollection = createSlice({
  name: 'SETTINGS_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const settingsCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.settingsCollection
);

export const settingsCollectionHolderFunctions = {
  ...settingsCollection.actions,
  items: settingsCollectionSelectors.selectAll,
};


export default settingsCollection.reducer;
