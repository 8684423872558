import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { urlStructure } from '@/constants/urlStructure';
import Checkout from '@/pages/app/license/Checkout';
import PlanConfiguration from '@/pages/app/license/PlanConfiguration';


const License = () => (
  <Switch>
    <Route path={urlStructure.planConfiguration}>
      <PlanConfiguration />
    </Route>
    <Route path={urlStructure.licenseCheckout}>
      <Checkout />
    </Route>
  </Switch>
);


export default License;
