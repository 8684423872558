import { Typography } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import CustomTreeView from '@/components/CustomTreeView';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import { scrollbarStyles } from '@/utils/styles';
import MiniTreeNode from './MiniTreeNode';


const StyledLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 0.9em;
`;

const Container = styled.div`
  max-height: 300px;
  overflow: auto;
  ${scrollbarStyles};
`;

interface MiniTreeProps {
  onSelect: (event: ChangeEvent<{}>, value: string) => void,
  onToggle: (event: ChangeEvent<{}>, nodeIds: string[]) => void,
  selectedItem: string | null,
  label: string,
  items: Array<DevicesGroupItem | DeviceItem>,
  expanded: string[],
}

const MiniTree = ({
  onSelect, onToggle, selectedItem, label, items, expanded,
}: MiniTreeProps) => (
  <>
    <StyledLabel>
      {label}
    </StyledLabel>
    <Container>
      <CustomTreeView
        onNodeToggle={onToggle}
        selected={selectedItem ?? ''}
        expanded={expanded}
      >
        {items.map((treeItem) => (
          <MiniTreeNode
            key={treeItem.id}
            node={treeItem}
            selectedItem={selectedItem}
            onSelect={onSelect}
          />
        ))}
      </CustomTreeView>
    </Container>
  </>
);

export default MiniTree;
