import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateDevice } from '@/api/clients/devicesGroups';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import FFMiniTreeSelect from '@/components/finalForm/FFMiniTreeSelect';
import FFTextInput from './finalForm/FFTextInput';


interface FormFields {
  name: string,
  parentId: string,
}

interface ManageDeviceDialogProps {
  isOpen: boolean,
  handleClose: () => void,
  initialValues: FormFields,
  id: string,
}

const ManageDeviceDialog = ({
  isOpen, handleClose, initialValues, id,
}: ManageDeviceDialogProps) => {
  const { t } = useTranslation(['common']);

  const onSubmit = ({ name, parentId }: FormFields) => {
    updateDevice(id, parentId, name);
    handleClose();
  };

  return (
    <FFGenericOptionsModal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('common:app.editDevice')}
      onSubmit={onSubmit}
      formProps={{
        initialValues: { name: initialValues?.name, parentId: initialValues?.parentId },
      }}
    >
      {() => (
        <>
          <FFTextInput
            name="name"
            label={t('common:app.deviceName')}
            variant="standard"
            type="text"
          />
          <FFMiniTreeSelect
            name="parentId"
            label={t('common:app.belongsTo')}
          />
        </>
      )}
    </FFGenericOptionsModal>
  );
};

export default ManageDeviceDialog;
