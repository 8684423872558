import React from 'react';
import {
  Switch, Redirect, useLocation,
} from 'react-router-dom';
import AccessControlMainContainer from '@/components/AccessControlMainContainer';
import { urlStructure } from '@/constants/urlStructure';
import DetailedHistory from '@/pages/app/accessControl/DetailedHistory';
import DomainsStats from '@/pages/app/accessControl/DomainsStats';
import AccessControlFilters from '@/components/AccessControlFilters';
import SecuredRoute from '@/components/SecuredRoute';
import { ApiConsts } from '@/api/ApiConsts';
import ScreensPreview from './ScreensPreview';
import AppStats from './AppStats';
import BrowsingHistory from './BrowsingHistory';


const AccessControl = () => {
  const location = useLocation();

  return (
    <AccessControlMainContainer>
      {(
        location.pathname !== urlStructure.accessControl
        && location.pathname !== urlStructure.accessControlPreview
      ) && (
        <AccessControlFilters />
      )}
      <Switch>
        <Redirect
          exact
          from={urlStructure.accessControl}
          to={urlStructure.accessControlPreview}
        />
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionScreenshot,
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.accessControlPreview}
        >
          <ScreensPreview />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIWebHistory,
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.accessControlHistory}
        >
          <BrowsingHistory />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAppHistory,
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.accessControlAppStats}
        >
          <AppStats />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAppHistory,
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.accessControlWebStats}
        >
          <DomainsStats />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAppHistory,
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.accessControlDetailedHistory}
        >
          <DetailedHistory />
        </SecuredRoute>
      </Switch>
    </AccessControlMainContainer>
  );
};


export default AccessControl;
