import React from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import SvgSettings from '@/components/fromSvg/Settings';
import SvgRemoveIcon from '@/components/fromSvg/RemoveIcon';
import { TreeItem } from '@/TreeService/types';
import { appAccessToIcon, deviceLockToIcon, inetControlStateToIcon } from '@/utils/display';
import TreeItemLabelIcon from '@/components/Tree/TreeItemLabelIcon';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { ApiConsts } from '@/api/ApiConsts';
import PermissionsControl from '@/components/PermissionsControl';


const Wrapper = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : theme.palette.primary.dark)};
  text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  cursor: default;
  overflow: hidden;
  white-space: nowrap;

  & > p {
    margin: 0 5px;
  }

  & > svg {
    cursor: pointer;
  }
`;

interface LabelProps {
  label?: string,
  type: 'group' | 'device' | 'agent',
  handleSettings?: () => void,
  handleRemove?: () => void,
  item: TreeItem,
}

const Label = React.memo(({
  label,
  type,
  handleSettings,
  handleRemove,
  item,
}: LabelProps) => {
  const theme = useTheme();
  const { selectedTreeItem } = useSelectedTreeItem();
  const isItemActive = selectedTreeItem?.id === item.id;

  const accessIcons = type !== 'agent' && (
    <>
      {inetControlStateToIcon(item.inetAccess)}
      {appAccessToIcon(item.appAccess)}
      {deviceLockToIcon(item.deviceLock)}
    </>
  );


  return (
    <Wrapper $isActive={isItemActive}>
      <TreeItemLabelIcon isActive={item.isConnected} itemType={type} />
      {accessIcons}
      <Typography>
        {label ?? item.name}
      </Typography>
      {(isItemActive && handleSettings) && (
        <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIDevices]}>
          <SvgSettings
            onClick={handleSettings}
            stroke={theme.palette.primary.main}
          />
        </PermissionsControl>
      )}
      {(isItemActive && handleRemove) && (
        <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIDevices]}>
          <SvgRemoveIcon
            onClick={handleRemove}
            stroke={theme.palette.primary.main}
          />
        </PermissionsControl>
      )}
    </Wrapper>
  );
});

export default Label;
