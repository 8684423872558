import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
import { loginSelector } from '@/redux/user';
import NoLoginLayout from '@/components/layout/NoLoginLayout';
import { urlStructure } from '@/constants/urlStructure';
import { PaymentType } from '@/api/owpb/pbFiles/subscription_service_pb';
import { useSearchParams } from '@/hooks/useSearchParams';


const StyledPaper = styled(Paper)`
  width: 60vw;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;

  & > * + * {
    margin-top: 20px;
  }
`;

const Title = styled(Typography)`
  align-self: unset;
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  text-transform: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white}
`;


interface LocationState {
  paymentMethod: PaymentType,
  isDowngrade: boolean | undefined,
}

const OrderStatus = () => {
  const { t } = useTranslation(['common']);
  const login = useSelector(loginSelector);
  const history = useHistory();
  const searchParams = useSearchParams();
  // after p24 redirects with search params including success info, check if succeeded
  const isSuccess = searchParams.get('redirect_status')?.search('succeeded') !== -1;
  const devices = searchParams.get('devices');
  const priceId = searchParams.get('priceId');
  const currency = searchParams.get('currency');

  const { state } = history.location;
  const stateInfo: LocationState | undefined = state ? (state as LocationState[])[0] : undefined;

  const getContent = () => {
    if (stateInfo && stateInfo.isDowngrade) {
      switch (stateInfo.paymentMethod) {
        case PaymentType.PAYMENT_BY_CARD: {
          return t('common:checkout.cardInfoUpdate');
        }
        case PaymentType.PAYMENT_BY_P24:
        case PaymentType.PAYMENT_BY_PRO_FORMA_INVOICE:
        case undefined: {
          return t('common:checkout.subscriptionUpdated');
        }
        default: {
          return null;
        }
      }
    } else {
      switch (stateInfo && stateInfo.paymentMethod) {
        case PaymentType.PAYMENT_BY_P24:
        case PaymentType.PAYMENT_BY_CARD: {
          return t('common:checkout.successPaymentInfo');
        }
        case PaymentType.PAYMENT_BY_PRO_FORMA_INVOICE: {
          return t('common:checkout.successInvoicePaymentInfo');
        }
        // because of p24 redirect, if paymentMethod is unknown, p24 is assumed
        case undefined: {
          return t('common:checkout.successPaymentInfo');
        }
        default: {
          return null;
        }
      }
    }
  };

  return (
    <NoLoginLayout>
      <StyledPaper>
        <Title variant="h5">
          {isSuccess ? t('common:checkout.orderPlaced') : t('common:checkout.orderFailed')}
        </Title>
        <Typography>
          {isSuccess ? getContent() : t('common:checkout.failedInfo')}
        </Typography>
        <StyledButton color="primary" variant="contained">
          {login ? (
            <StyledLink to={urlStructure.userprofile}>
              {t('common:checkout.goToProfile')}
            </StyledLink>
          ) : (
            <StyledLink to={urlStructure.login}>
              {t('common:auth.login.logIn')}
            </StyledLink>
          )}
        </StyledButton>
        {(!isSuccess && login) && (
          <StyledButton color="primary" variant="contained">
            <StyledLink
              to={{
                pathname: urlStructure.licenseCheckout,
                state: { devices, priceId, currency },
              }}
            >
              {t('common:checkout.goBackToCheckout')}
            </StyledLink>
          </StyledButton>
        )}
      </StyledPaper>
    </NoLoginLayout>
  );
};

export default OrderStatus;
