import React, { PropsWithChildren } from 'react';
import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';


const StyledPaper = styled(Paper)`
  margin: 10px;
  padding: 30px;
  height: fit-content;
`;

interface UserProfilePaperProps {
  title: string,
}

const UserProfilePaper = ({ title, children }: PropsWithChildren<UserProfilePaperProps>) => (
  <StyledPaper>
    <Typography variant="h6">
      {title}
    </Typography>
    {children}
  </StyledPaper>
);

export default UserProfilePaper;
