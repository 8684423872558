import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import AccessControlHistoryPage from '@/components/AccessControlHistoryPage';
import useHistoryTable from '@/components/HistoryTable/useHistoryTable';
import HistoryTableLayout from '@/components/HistoryTable/HistoryTableLayout';
import { getBrowsingHistory } from '@/api/clients/history';
import { handleError } from '@/api/errorHandler';
import { WebHistoryItem } from '@/api/owpb/pbFiles/history_service_pb';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import { useBrowsingHistoryColumns } from '@/components/BrowsingHistoryPageComponents/useBrowsingHistoryColumns';
import BrowsingHistorySettings from '@/components/BrowsingHistoryPageComponents/BrowsingHistorySettings';
import {
  AccessControlFilters as BrowsingHistoryFiltersState,
  accessControlFiltersSelector,
} from '@/redux/layout';
import Loader from '@/components/Loader';


const fetchData = async (
  ids: string[],
  filters: BrowsingHistoryFiltersState | null,
  sortingAndPagination?: adminify.TableSortingAndPagination
) => {
  const response = await getBrowsingHistory(ids, filters, sortingAndPagination);
  if (response != null) {
    return response;
  }
  throw new Error('No response');
};

const BrowsingHistory = () => {
  const [allData, setAllData] = useState<Array<WebHistoryItem.AsObject>>([]);
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);

  const ids = useIdsOfAllSelectedDevices();
  const filters = useSelector(accessControlFiltersSelector);

  const fetchMoreData = (sortingAndPagination: adminify.TableSortingAndPagination) => {
    fetchData(ids, filters, sortingAndPagination)
      .then(({ itemsList, hasMore }) => {
        setHasMoreData(hasMore);
        setAllData(allData.concat(itemsList));
      })
      .catch((error) => handleError(error));
  };

  const initialState = {
    hiddenColumns: [
      'urlDomain',
      'categoryName',
      'domainName',
      'fullName',
    ],
  };
  const data = React.useMemo(() => allData, [allData]);
  const columns = useBrowsingHistoryColumns();

  const {
    tableProps,
    openColumnSelectionDialog,
  } = useHistoryTable<WebHistoryItem.AsObject>(
    { data, columns, initialState },
    fetchMoreData,
    hasMoreData,
  );

  const sortingAndPagination = useMemo(() => ({
    sortedBy: tableProps.tableInstance.state.sortBy.length > 0
      ? tableProps.tableInstance.state.sortBy[0]
      : undefined,
  }), [tableProps.tableInstance.state.sortBy]);

  const {
    data: browsingHistoryDataSet,
    isLoading,
  } = useQuery(
    ['getBrowsingHistory', filters, sortingAndPagination],
    () => getBrowsingHistory(ids, filters, sortingAndPagination),
    { staleTime: 60000 }
  );

  useEffect(() => {
    if (ids.length && browsingHistoryDataSet) {
      setAllData(browsingHistoryDataSet.itemsList);
      setHasMoreData(browsingHistoryDataSet.hasMore);
    } else {
      setAllData([]);
      setHasMoreData(false);
    }
  }, [browsingHistoryDataSet, ids]);

  if (isLoading) {
    return (
      <Loader.LoaderContainer>
        <Loader />
      </Loader.LoaderContainer>
    );
  }


  return (
    <AccessControlHistoryPage
      settingsComponent={(
        <BrowsingHistorySettings
          openColumnSelectionDialog={openColumnSelectionDialog}
          isEmpty={data.length === 0}
        />
      )}
    >
      <HistoryTableLayout<WebHistoryItem.AsObject>
        {...tableProps}
      />
    </AccessControlHistoryPage>
  );
};


export default BrowsingHistory;
