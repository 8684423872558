import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { handleError } from '@/api/errorHandler';
import { RolesServiceClient } from '../owpb/pbFiles/Roles_serviceServiceClientPb';
import { UpdateRoleUserRequest, CreateRoleUserRequest, DeleteRoleUserRequest } from '../owpb/pbFiles/roles_service_pb';


const client = new RolesServiceClient(apiUrl);

export function createRoleUser(roleId: string, name: string, email: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new CreateRoleUserRequest();
  request.setRoleId(roleId);
  request.setName(name);
  request.setIdenty(email);

  client.createRoleUser(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.inviteNewUserSuccess')))
    .catch(handleError);
}

export function updateRoleUser(id: string, email: string, roleId: string, name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateRoleUserRequest();
  request.setId(id);
  request.setIdenty(email);
  request.setRoleId(roleId);
  request.setName(name);

  client.updateRoleUser(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.editUserSuccess')))
    .catch(handleError);
}

export function removeRoleUser(id: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteRoleUserRequest();
  request.setId(id);

  client.deleteRoleUser(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.removeUserSuccess')))
    .catch(handleError);
}
