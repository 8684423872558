import React from 'react';


const SvgIconMore = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16.031"
    {...otherProps}
  >
    <defs>
      <path id="iconMore_svg__a" d="M0 0h16v16.031H0z" />
      <mask
        id="iconMore_svg__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16.031H0z" />
        <use fill="#fff" xlinkHref="#iconMore_svg__a" />
      </mask>
    </defs>
    <use fill="none" xlinkHref="#iconMore_svg__a" />
    <g mask="url(#iconMore_svg__b)">
      <path fill="none" d="M0 0h16v17.281H0V0z" />
      <path
        fill="#2C3E50"
        fillRule="evenodd"
        d="M4 6.667c-.733 0-1.333.6-1.333 1.333S3.267 9.333 4 9.333 5.333 8.733 5.333 8 4.733 6.667 4 6.667zM6.667 8c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333S8.733 9.333 8 9.333 6.667 8.733 6.667 8zM12 6.667c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333z"
      />
    </g>
  </svg>
);

export default SvgIconMore;
