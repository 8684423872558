import styled from 'styled-components';
import ButtonWithLoader from '@/components/ButtonWithLoader';


const AuthButton = styled(ButtonWithLoader)`
  padding: 10px;
  margin-top: 6px;
  text-transform: unset !important;
`;

export default AuthButton;
