import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  IconButton, MenuItem, TextField,
} from '@material-ui/core';
import { useField, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import { useQuery } from 'react-query';
import { getAppHistoryUsersRequest } from '@/api/clients/history';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import Loader from '@/components/Loader';


const UserSelect = styled(TextField)<{ $moveArrow: boolean }>`
  width: 200px;
  margin-left: 10px;
  margin-top: -15px;

  .MuiSelect-icon {
    right: ${({ $moveArrow }) => ($moveArrow ? '20px' : '0')}
  }
`;

const StyledLoader = styled(Loader)`
  height: 10px;
  margin: 0 auto;

  & > div {
    top: 0;
  }
`;

const ClearButton = styled(IconButton)`
  margin-right: 0;
  padding: 0;
`;

interface UserSelectAccessControlFiltersProps {
  name: string,
  applyUserFilter: () => void,
  onClear: () => void,
}

const UserSelectAccessControlFilters = ({
  name,
  applyUserFilter,
  onClear,
}: UserSelectAccessControlFiltersProps) => {
  const { t } = useTranslation(['common']);
  const { values } = useFormState();
  const { input, meta } = useField(name);
  const { value = 'all' } = input;

  // values.dates can't be used directly because it's a object,
  // hook dependencies don't work with objects
  const dateRange = useMemo(() => ({
    dateFrom: values.dates.dateFrom,
    dateTo: values.dates.dateTo,
  }), [values.dates.dateFrom, values.dates.dateTo]);

  const ids = useIdsOfAllSelectedDevices();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // queryParameters are required in order to trigger parameters change only on select click
  const [queryParameters, setQueryParameters] = useState<{
    ids: string[],
    dateRange: adminify.DateRange,
  }>();
  const { data: users, isLoading } = useQuery(
    ['users', queryParameters],
    () => getAppHistoryUsersRequest(ids, dateRange),
    { keepPreviousData: true, staleTime: 60000, enabled: !!queryParameters },
  );

  return (
    <UserSelect
      select
      label={t('common:common.user')}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={(e) => {
        input.onChange(e);
        applyUserFilter();
      }}
      $moveArrow={(value && value !== 'all')}
      error={meta.touched && meta.error !== undefined}
      value={value}
      name={name}
      SelectProps={{
        MenuProps: {
          onEnter: () => {
            setQueryParameters({ ids, dateRange });
            setIsMenuOpen(true);
          },
          onExited: () => setIsMenuOpen(false),
        },
      }}
      InputProps={{
        endAdornment: (value && value !== 'all') ? (
          <ClearButton
            onClick={(e) => {
              input.onChange(e);
              onClear();
              applyUserFilter();
            }}
            disabled={!value}
          >
            <ClearIcon color="disabled" fontSize="small" />
          </ClearButton>
        ) : null,
      }}
    >
      {Array.isArray(users) && users.map((user) => (
        <MenuItem
          value={JSON.stringify(user)}
          key={`${user.domainName}/${user.userName}`}
        >
          {`${user.domainName}/${user.userName}`}
        </MenuItem>
      ))}
      {/* add selected value, so when the component is remounted
       or new values are loaded but not selected
       the select will not display an empty value */}
      {value !== 'all'
        && !isLoading
        && !isMenuOpen
        && (users === undefined || (Array.isArray(users) && users.length === 0))
        && (
          <MenuItem
            value={value}
            key={`${JSON.parse(value).domainName}/${JSON.parse(value).userName}`}
          >
            {`${JSON.parse(value).domainName}/${JSON.parse(value).userName}`}
          </MenuItem>
        )}
      <MenuItem value="all" disabled={Array.isArray(users) && users.length === 0 && value === 'all'}>
        {isLoading ? <StyledLoader /> : t('common:allUsers')}
      </MenuItem>
    </UserSelect>
  );
};


export default UserSelectAccessControlFilters;
