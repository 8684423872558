import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Welcome from '@/components/fromSvg/Welcome';
import FilesForDownloadContent from './FilesForDownloadContent';


const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

const InfoContainer = styled.div`
  width: 30em;
`;

const Title = styled(Typography)`
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: -40px;
`;


const GoToApp = styled(Button)`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

interface WelcomeScreenProps {
  isOpen: boolean,
  onClose: () => void,
}

const WelcomeScreen = ({ isOpen, onClose }: WelcomeScreenProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog open={isOpen} fullScreen>
      <DialogContent>
        <Welcome />
        <InfoContainer>
          <Title variant="h4">
            {t('common:auth.hello')}
          </Title>
          <FilesForDownloadContent />
          <GoToApp fullWidth onClick={onClose}>
            {t('common:goToApp')}
          </GoToApp>
        </InfoContainer>
      </DialogContent>
    </Dialog>
  );
};
export default WelcomeScreen;
