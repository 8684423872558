import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';


const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  flex-grow: 1;
  overflow: auto;
`;

const ConfigurationTabTitle = styled(Typography)`
  margin: 0 0 20px;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: 200;
`;

interface ConfigurationTabContentProps {
  title: string,
  actionButtons?: React.ReactNode,
}

const ConfigurationTabContent = ({
  title,
  actionButtons,
  children,
}: PropsWithChildren<ConfigurationTabContentProps>) => (
  <Container>
    <ConfigurationTabTitle variant="h6">
      {title}
    </ConfigurationTabTitle>
    {actionButtons}
    {children}
  </Container>
);

export default ConfigurationTabContent;
