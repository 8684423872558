import { DefaultRootState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';


interface SetAccessControlContentAndTileSizePayload {
  tileWidth: number,
  contentWidth: number,
  contentHeight: number,
}

interface SetAccessControlContentSizePayload {
  width: number,
  height: number,
}

export interface DateRange {
  dateFrom: number,
  dateTo: number,
}

export interface AccessControlFilters extends DateRange {
  user: string | null,
}

/* ===========SLICE============= */

export interface LayoutState {
  previewTileWidth: number,
  accessControlContentSize: {
    width: number,
    height: number,
  },
  treeSearchText: string,
  statsDateRange: DateRange | null,
  accessControlFilters: AccessControlFilters,
  shownWelcomeScreen: boolean,
}

const initialState = {
  previewTileWidth: 0,
  accessControlContentSize: {
    width: 0,
    height: 0,
  },
  treeSearchText: '',
  statsDateRange: null,
  accessControlFilters: {
    dateFrom: dayjs().subtract(7, 'days').hour(0).unix(),
    dateTo: dayjs().hour(23).minute(59).unix(),
    user: 'all',
  },
  shownWelcomeScreen: false,
} as LayoutState;

const layout = createSlice({
  name: 'LAYOUT',
  initialState,
  reducers: {
    setAccessControlContentAndTileSize(
      state: LayoutState,
      action: PayloadAction<SetAccessControlContentAndTileSizePayload>
    ) {
      return {
        ...state,
        previewTileWidth: action.payload.tileWidth,
        accessControlContentSize: {
          width: action.payload.contentWidth,
          height: action.payload.contentHeight,
        },
      };
    },
    setAccessControlContentSize(
      state: LayoutState,
      action: PayloadAction<SetAccessControlContentSizePayload>
    ) {
      return {
        ...state,
        accessControlContentSize: {
          width: action.payload.width,
          height: action.payload.height,
        },
      };
    },
    setTreeSearchText(state: LayoutState, action: PayloadAction<string>) {
      return {
        ...state,
        treeSearchText: action.payload,
      };
    },
    setStatsDateRange(state: LayoutState, action: PayloadAction<DateRange>) {
      return {
        ...state,
        statsDateRange: action.payload,
      };
    },
    setAccessControlFilters(state: LayoutState, action: PayloadAction<AccessControlFilters>) {
      return {
        ...state,
        accessControlFilters: action.payload,
      };
    },
    setShownWelcomeScreen(state: LayoutState, action: PayloadAction<boolean>) {
      return {
        ...state,
        shownWelcomeScreen: action.payload,
      };
    },
  },
});

export const {
  setAccessControlContentAndTileSize,
  setAccessControlContentSize,
  setTreeSearchText,
  setStatsDateRange,
  setAccessControlFilters,
  setShownWelcomeScreen,
} = layout.actions;

export default layout.reducer;


/* ===========Selectors============= */
export const previewTileWidthSelector = (state: DefaultRootState) => (
  state.layout.previewTileWidth
);

export const treeSearchTextSelector = (state: DefaultRootState) => (
  state.layout.treeSearchText
);

export const statsDateRangeSelector = (state: DefaultRootState) => (
  state.layout.statsDateRange
);

export const accessControlFiltersSelector = (state: DefaultRootState) => (
  state.layout.accessControlFilters
);

export const accessControlContentSizeSelector = (state: DefaultRootState) => (
  state.layout.accessControlContentSize
);

export const shownWelcomeScreenSelector = (state: DefaultRootState) => (
  state.layout.shownWelcomeScreen
);
