import React from 'react';
import styled from 'styled-components';
import { TableInstance } from 'react-table';
import { TableSortLabel, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableCell from '@material-ui/core/TableCell';
import { scrollbarStyles } from '@/utils/styles';


const Container = styled.div`
  border: ${({ theme }) => theme.border};
  margin: 10px 0 0;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: auto;
  flex-grow: 1;
  width: 100%;
  ${scrollbarStyles};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const StyledTableSortLabel = styled(TableSortLabel)<{ hideSortIcon: boolean, $isFirst: boolean }>`
  width: 100%;
  padding: 10px 16px;
  ${({ $isFirst }) => ($isFirst && 'padding-left: 45px;')}
  ${({ hideSortIcon }) => (hideSortIcon && 'cursor: default;')}
`;

const StyledTableCell = styled(TableCell)<{ $statusCell: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: ${({ theme }) => theme.palette.divider};
  border-right-style: solid;
  border-right-width: 1px;

  padding-top: ${({ $statusCell }) => ($statusCell && '8px')};
  padding-bottom: ${({ $statusCell }) => ($statusCell && '8px')};

  &:last-child {
    border-right-width: 0;
  }
`;

export interface ConfigTableLayoutProps<T extends Record<string, unknown>> {
  tableInstance: TableInstance<T>,
}

const ConfigTableLayout = <T extends Record<string, unknown>>({
  tableInstance,
}: ConfigTableLayoutProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  return (
    <Container {...getTableProps()}>
      <Title>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <StyledTableSortLabel
                IconComponent={ArrowDropDownIcon}
                active={column.isSorted}
                direction={column.isSortedDesc ? 'desc' : 'asc'}
                hideSortIcon={!column.canSort}
                $isFirst={i === 0}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Typography>
                  {column.render('Header')}
                </Typography>
              </StyledTableSortLabel>
            ))}
          </div>
        ))}
      </Title>
      <div {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <div {...row.getRowProps()}>
              {row.cells.map(cell => (
                <StyledTableCell
                  component="div"
                  $statusCell={cell.column.id === 'status'}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </StyledTableCell>
              ))}
            </div>
          );
        })}
      </div>
    </Container>
  );
};


export default ConfigTableLayout;
