import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';


interface SvgDownloadProps {
  fill?: string,
  onClick?: () => void,
}

const SvgDownload = ({ fill, onClick, ...otherProps }: SvgDownloadProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      onClick={onClick}
      {...otherProps}
    >
      <path fill="none" d="M0 0h16v16H0V0z" />
      <path
        fill={fill ?? theme.palette.primary.dark}
        fillRule="evenodd"
        d="M7.333 2h1.334v6.447l1.726-1.72.94.94L8 11 4.667 7.667l.94-.94 1.726 1.72V2zm5.334 10.667V8H14v4.667C14 13.4 13.4 14 12.667 14H3.333C2.6 14 2 13.4 2 12.667V8h1.333v4.667h9.334z"
      />
    </svg>
  );
};

export default SvgDownload;
