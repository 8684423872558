import React from 'react';


const SvgOpenInNew = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8 3a.6.6 0 00-.6.6v6.6a.6.6 0 00.6.6h6.6a.6.6 0 00.6-.6V6.6a.6.6 0 011.2 0v3.6A1.8 1.8 0 018.4 12H1.8A1.8 1.8 0 010 10.2V3.6a1.8 1.8 0 011.8-1.8h3.6a.6.6 0 010 1.2H1.8zM7.2.6a.6.6 0 01.6-.6h3.6a.6.6 0 01.6.6v3.6a.6.6 0 11-1.2 0v-3h-3a.6.6 0 01-.6-.6z"
      fill="#3579F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.824.176a.6.6 0 010 .848l-6.6 6.6a.6.6 0 11-.849-.848l6.6-6.6a.6.6 0 01.849 0z"
      fill="#3579F5"
    />
  </svg>
);

export default SvgOpenInNew;
