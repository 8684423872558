import React from 'react';


const SvgAppAccessMultipleSettings = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M5 10.625L3.125 16.25h12.5l1.25-5.625H5z"
      stroke="#2C3E50"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 5.125H3.125a.5.5 0 00-.5.5V16.25h1V6.125H6.25v-1zm7.5 2.25h2v3.25h1v-3.75a.5.5 0 00-.5-.5h-2.5v1z"
      fill="#2C3E50"
    />
    <path
      d="M5.5 3.625h8.375v7.125H5.5V3.625z"
      stroke="#2C3E50"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgAppAccessMultipleSettings;
