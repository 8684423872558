import { ApiConsts } from '@/api/ApiConsts';
import { store } from '@/redux/store';
import { authMetaDataSelector } from '@/redux/user';
import { controlStateToString } from '@/utils/converters';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { handleError } from '../errorHandler';
import { ControlState } from '../owpb/pbFiles/basic_pb';
import { UpdateSettingsRequest } from '../owpb/pbFiles/settings_service_pb';
import settingsClient from './settingsClient';


export type UpdateSettingsVariants = typeof ApiConsts.SettingsDeviceInetAccess
  | typeof ApiConsts.SettingsDeviceAppAccess
  | typeof ApiConsts.SettingsDeviceLock;


function updateSettings(id: string, value: string, items: Array<string>, successMessage: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateSettingsRequest();
  request.setId(id);
  request.setValue(value);
  request.setItemsList(items);

  settingsClient.updateSettings(request, authMetaData)
    .then(() => {
      if (successMessage.length > 0) {
        showNoty('success', successMessage);
      }
    })
    .catch(handleError);
}

export function updateSettingsDeviceAccess(
  variant: UpdateSettingsVariants, value: string, deviceIds: string[]
) {
  updateSettings(variant, value, deviceIds, i18n.t('common:notifications.updateSettingsSuccess'));
}

export function updateSettingsURLCategoryState(categoryId: string, state: ControlState) {
  updateSettings(
    ApiConsts.SettingsWebCategoryState,
    controlStateToString(state),
    [categoryId],
    i18n.t('common:notifications.updateURLCategoryStateSuccess')
  );
}

export function updateSettingsURLCategoryStateForRole(
  roleId: string, categoryId: string, state: ControlState
) {
  updateSettings(
    `${ApiConsts.SettingsWebCategoryStateForRole}[${categoryId}]`,
    controlStateToString(state),
    [roleId],
    i18n.t('common:notifications.updateURLCategoryStateSuccess')
  );
}
