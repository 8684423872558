import Typography from '@material-ui/core/Typography';
import TreeItem from '@material-ui/lab/TreeItem';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { WindowsUserInfo } from '@/api/owpb/pbFiles/basic_pb';
import Label from '@/components/Tree/TreeItemLabel';
import { selectedTreeColumnsSelector, setSelectedTreeItem } from '@/redux/appOptions';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { appAccessToIcon, inetControlStateToIcon } from '@/utils/display';


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: var(--grid);
`;

const Column = styled.div<{ isHidden?: boolean }>`
  padding: 0 var(--cell-side-padding);
  display: ${({ isHidden }) => (isHidden ? 'none' : 'initial')};

  &.columnName {
    padding-left: 0;
  }
`;

const Row = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;


export interface DeviceProps {
  node: DeviceItem,
  label: React.ReactNode,
}

const Device = ({
  node,
  label,
}: DeviceProps) => {
  const dispatch = useDispatch();
  const selectedColumns = useSelector(selectedTreeColumnsSelector);

  const visibleAgents = node.children.filter((agent) => agent.visible && !agent.isFiltered);

  // Jeśli na urządzeniu jest tylko jedna sesja agenta to agent ten jest ukryty
  // Zapamiętujemy dane użytkownika takiego urządzenia by wyświetlic je w linii z nazwą urządzenia
  let deviceWindowsUser: WindowsUserInfo.AsObject | undefined;
  if (visibleAgents.length === 0 && node.children.length === 1) {
    deviceWindowsUser = node.children[0].WindowsUser?.toObject();
  }

  return (
    <TreeItem
      nodeId={node.id}
      key={node.id}
      label={(
        <Wrapper>
          <Column className="columnName">
            {label}
          </Column>
          <Column isHidden={!selectedColumns.description}>
            <Row>
              {node.description}
            </Row>
          </Column>
          <Column isHidden={!selectedColumns.domainName}>
            <Row>
              {deviceWindowsUser?.domainName}
            </Row>
          </Column>
          <Column isHidden={!selectedColumns.username}>
            <Row>
              {deviceWindowsUser?.userName}
            </Row>
          </Column>
        </Wrapper>
      )}
      onClick={() => dispatch(setSelectedTreeItem(node.id))}
      onLabelClick={(event) => { event.preventDefault() }}
    >
      {visibleAgents.map((agent) => {
        if (agent.WindowsUser === undefined) {
          return null;
        }

        const windowsUser = agent.WindowsUser?.toObject();
        const windowUserSettings = windowsUser.userSettings;
        const name = `${windowsUser.domainName}/${windowsUser.userName}`;

        return (
          <TreeItem
            nodeId={agent.id}
            key={agent.id}
            onClick={() => dispatch(setSelectedTreeItem(agent.id))}
            label={(
              <Wrapper>
                <Column className="columnName">
                  <Label
                    label={!selectedColumns.username ? name : undefined}
                    type="agent"
                    item={agent}
                  />
                </Column>
                <Column isHidden={!selectedColumns.description}>
                  <Row>
                    {node.description}
                  </Row>
                </Column>
                <Column isHidden={!selectedColumns.domainName}>
                  <Row>
                    {windowsUser.domainName}
                  </Row>
                </Column>
                <Column isHidden={!selectedColumns.username}>
                  <Row>
                    {(windowUserSettings && !windowUserSettings.isDeviceAppAccess) && (
                      appAccessToIcon(windowUserSettings.appAccess)
                    )}
                    {(windowUserSettings && !windowUserSettings.isDeviceInetAccess) && (
                      inetControlStateToIcon(windowUserSettings.inetAccess)
                    )}
                    {windowsUser.userName}
                  </Row>
                </Column>
              </Wrapper>
            )}
          />
        );
      })}
    </TreeItem>
  );
};


export default Device;
