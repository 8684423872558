import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import AccessControlStatisticsPage from '@/components/AccessControlStatisticsPage';
import { getDomainStats } from '@/api/clients/history';
import StatsElement from '@/components/Statistics/StatsElement';
import { getFullTimeFromSeconds } from '@/utils/helpers';
import { accessControlFiltersSelector } from '@/redux/layout';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import StatsSettings from '@/components/StatsSettings';
import NoDataScreen from '@/components/NoDataScreen';
import Loader from '@/components/Loader';


const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

const DomainsStats = () => {
  const [totalTime, setTotalTime] = useState<number>();
  const filters = useSelector(accessControlFiltersSelector);
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const ids = useIdsOfAllSelectedDevices();
  const { data: domainStats, isLoading } = useQuery(
    ['getDomainStats', ids, filters],
    () => getDomainStats(ids, filters),
    { staleTime: 60000 }
  );

  useEffect(() => {
    if (domainStats) {
      setTotalTime(domainStats.reduce((a, b) => a + b.duration, 0));
    } else {
      setTotalTime(0);
    }
  }, [domainStats]);

  if (isLoading) {
    return (
      <Loader.LoaderContainer>
        <Loader />
      </Loader.LoaderContainer>
    );
  }

  return (
    <AccessControlStatisticsPage
      settingsComponent={(
        <StatsSettings
          isEmpty={!!(domainStats && domainStats.length === 0)}
        />
      )}
    >
      {(domainStats && domainStats.length === 0) ? (
        <NoDataScreen
          message={ids.length ? t('common:common.noDataInfo') : t('common:common.noDevices')}
        />
      ) : (
        <>
          <Typography>
            {t('common:app.domainsViewedLongest')}
          </Typography>
          <Subtitle variant="subtitle2">
            {t('common:app.totalDomainsViewingTime', { value: totalTime ? getFullTimeFromSeconds(totalTime) : '-' })}
          </Subtitle>
          {(domainStats && totalTime) && domainStats.map((stat, i) => (
            <StatsElement
              key={stat.urlDomain}
              name={stat.urlDomain}
              value={stat.duration}
              topValue={domainStats[0].duration}
              total={totalTime}
              color={theme.barChartColors[i % theme.barChartColors.length]}
            />
          ))}
        </>
      )}
    </AccessControlStatisticsPage>
  );
};

export default DomainsStats;
