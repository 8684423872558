import { Typography } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import FFTextInput from '@/components/finalForm/FFTextInput';
import AuthButton from '@/components/AuthButton';
import { handleError } from '@/api/errorHandler';
import { confirmUserInvitation } from '@/api/clients/invitation';
import { resetPassword } from '@/api/clients/auth';
import { validatePassword } from '@/utils/validators';
import AccountInfo from './AccountInfo';


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledSwipeableViews = styled(SwipeableViews)`
  width: 100%;
`;

interface RegisterFormFields {
  password: string,
  passwordRepeat: string,
}

interface PasswordFormProps {
  login: string,
  invitationId: string | null,
  resetToken: string | null,
}


const PasswordForm = ({ login, invitationId, resetToken }: PasswordFormProps) => {
  const { t } = useTranslation(['common']);
  const [pageIndex, setPageIndex] = useState<number>(0);


  const validate = (values: RegisterFormFields) => {
    const errors: Partial<Record<keyof RegisterFormFields, string>> = {};

    if (values.password) {
      errors.password = validatePassword(values.password);
    }

    if (!values.password) {
      errors.password = t('errors:fieldCanNotBeEmpty');
    }
    if (!values.passwordRepeat) {
      errors.passwordRepeat = t('errors:fieldCanNotBeEmpty');
    }

    if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = t('errors:passwordsDoNotMatch');
    }
    return errors;
  };

  const onSubmit = async ({ password }: RegisterFormFields) => {
    try {
      if (invitationId) {
        await confirmUserInvitation(invitationId, password);
      } else if (resetToken) {
        await resetPassword(login, resetToken, password);
      }
      setPageIndex(1);
      return {};
    } catch (error) {
      handleError(error);
      return { [FORM_ERROR]: 'Login Failed' };
    }
  };


  return (
    <StyledSwipeableViews index={pageIndex} disabled>
      <Form<RegisterFormFields>
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Typography variant="h5">
              {t('common:auth.setPassword.createPassword')}
            </Typography>
            <FFTextInput
              name="password"
              label={t('common:auth.setPassword.newPassword')}
              type="password"
              reserveSpaceForHelperText
            />
            <FFTextInput
              name="passwordRepeat"
              label={t('common:auth.setPassword.repeatPassword')}
              type="password"
              reserveSpaceForHelperText
            />
            <AuthButton
              fullWidth
              variant="contained"
              type="submit"
              label={t('common:common.create')}
              showLoader={submitting}
            />
          </StyledForm>
        )}
      </Form>
      <AccountInfo
        title={t('common:auth.setPassword.passwordCreated.title')}
        text={t('common:auth.setPassword.passwordCreated.text')}
        linkToLogin
      />
    </StyledSwipeableViews>
  );
};


export default PasswordForm;
