import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import {
  AppBar, Button,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { urlStructure } from '@/constants/urlStructure';
import Tree from '@/components/Tree';
import AppLayout from '@/components/layout/AppLayout';
import InternetAccessControlMenu from '@/components/accessControlMenu/InternetAccessControlMenu';
import AppAccessControlMenu from '@/components/accessControlMenu/AppAccessControlMenu';
import DeviceAccessControlMenu from '@/components/accessControlMenu/DeviceAccessControlMenu';
import { selectedTreeItemSelector } from '@/redux/appOptions';
import { deviceIdFromAgentId } from '@/utils/helpers';
import { useItemAccessStatus } from '@/hooks/useItemAccesStatus';
import ResizeHandle from '@/components/ResizeHandle';
import { scrollbarStyles } from '@/utils/styles';
import { accessControlMainContainerMinWidth } from '@/constants/common';
import { ApiConsts } from '@/api/ApiConsts';
import hasRequiredPermission from '@/utils/securityAndPermissions';
import PermissionsControl from '@/components/PermissionsControl';
import NavigationTabs from './NavigationTabs';


const Grid = styled.div<{ $isTreeVisible: boolean, $treeColumnWidth: number, $accessControlBarHidden: boolean }>`
  --access-controls-menu-height: ${({ $accessControlBarHidden }) => (!$accessControlBarHidden ? '80px' : '5px')};

  height: ${({ theme }) => (`calc(100vh - ${theme.constants.navbarHeight})`)};
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $isTreeVisible, $treeColumnWidth }) => (
    $isTreeVisible
      ? `${$treeColumnWidth}px calc(100% - ${$treeColumnWidth}px)`
      : '0 100%'
  )};
  grid-template-rows: fit-content(var(--access-controls-menu-height)) calc(100% - var(--access-controls-menu-height));
  grid-template-areas:
    "header header"
    "tree page-content";
`;

const AccessControlMenu = styled.div`
  grid-area: header;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => theme.border};
  z-index: 99;
  box-shadow: ${({ theme }) => `0 5px 16px ${theme.palette.secondary.main}`};
  display: flex;
  height: fit-content;
`;

const TreeColumn = styled.div`
  grid-area: tree;
  position: relative;
`;

const ResizableContainer = styled(ResizableBox)`
  position: absolute;
  right: 0;
  height: 100%;
`;

const HideTreeButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  width: 45px;
  min-width: unset;
  padding: 10px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  z-index: 2;

  & svg {
    font-size: 1.2em;
    margin-top: 2px;
  }
`;

const Container = styled.section`
  display: flex;
  overflow-x: auto;
  ${scrollbarStyles}
`;

const MinWidthContainer = styled.div`
  padding: 30px 24px;
  min-width: ${accessControlMainContainerMinWidth}px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border: ${({ theme }) => theme.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: 15px;

  @media (min-width: 1440px) {
    width: fit-content;
  }

  a[role=tab] {
    &:not(:last-child) {
      border-right: ${({ theme }) => theme.border};
    }

    & > span {
      text-transform: none;
      font-size: 0.95em;
    }
  }
`;

const tabLinks = [
  { translationKey: 'preview', link: urlStructure.accessControlPreview, permission: ApiConsts.PermissionScreenshot },
  { translationKey: 'history', link: urlStructure.accessControlHistory, permission: ApiConsts.PermissionAPIWebHistory },
  {
    translationKey: 'appStats',
    link: urlStructure.accessControlAppStats,
    permission: ApiConsts.PermissionAPIAppHistory,
  },
  {
    translationKey: 'websitesStats',
    link: urlStructure.accessControlWebStats,
    permission: ApiConsts.PermissionAPIAppHistory,
  },
  {
    translationKey: 'detailedHistory',
    link: urlStructure.accessControlDetailedHistory,
    permission: ApiConsts.PermissionAPIAppHistory,
  },
];

interface AccessControlMainContainerProps {
  children: React.ReactNode,
}


const AccessControlMainContainer = ({ children }: AccessControlMainContainerProps) => {
  const [isTreeVisible, setIsTreeVisible] = useState(true);
  const [treeColumnWidth, setTreeColumnWidth] = useState(500);
  const selectedTreeItemId = useSelector(selectedTreeItemSelector);

  const deviceId = selectedTreeItemId && deviceIdFromAgentId(selectedTreeItemId);
  const { appAccess, inetAccess, deviceAccess } = useItemAccessStatus(deviceId);

  // setting tree height
  const refTreeColumn = useRef<HTMLDivElement>();
  const [treeColumnHeight, setTreeColumn] = useState(0);
  useEffect(() => {
    if (refTreeColumn?.current) {
      setTreeColumn(refTreeColumn.current.offsetHeight ?? 0);
    }

    const handleResize = () => {
      setTreeColumn(refTreeColumn?.current?.offsetHeight ?? 0);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [refTreeColumn]);

  return (
    <AppLayout>
      <Grid
        $isTreeVisible={isTreeVisible}
        $treeColumnWidth={treeColumnWidth}
        $accessControlBarHidden={!hasRequiredPermission([
          ApiConsts.PermissionDeviceInetAccess,
          ApiConsts.PermissionDeviceAppAccess,
          ApiConsts.PermissionDeviceLock,
          ApiConsts.PermissionAPIAdmin,
        ])}
      >
        {selectedTreeItemId && (
          <AccessControlMenu>
            <PermissionsControl
              allowedPermissions={[
                ApiConsts.PermissionDeviceInetAccess,
                ApiConsts.PermissionAPIAdmin,
              ]}
            >
              <InternetAccessControlMenu currentSetting={inetAccess} />
            </PermissionsControl>
            <PermissionsControl
              allowedPermissions={[
                ApiConsts.PermissionDeviceAppAccess,
                ApiConsts.PermissionAPIAdmin,
              ]}
            >
              <AppAccessControlMenu currentSetting={appAccess} />
            </PermissionsControl>
            <PermissionsControl
              allowedPermissions={[
                ApiConsts.PermissionDeviceLock,
                ApiConsts.PermissionAPIAdmin,
              ]}
            >
              <DeviceAccessControlMenu currentSetting={deviceAccess} />
            </PermissionsControl>
          </AccessControlMenu>
        )}
        <TreeColumn ref={refTreeColumn as React.RefObject<HTMLDivElement>}>
          {treeColumnHeight !== 0 && (
            <ResizableContainer
              height={treeColumnHeight}
              width={treeColumnWidth}
              axis="x"
              handle={<ResizeHandle />}
              minConstraints={[300, 0]}
              onResize={(event, { size }) => {
                setTreeColumnWidth(size.width);
              }}
            >
              <Tree />
            </ResizableContainer>
          )}
          <HideTreeButton
            variant="contained"
            color="primary"
            onClick={() => setIsTreeVisible(!isTreeVisible)}
          >
            {isTreeVisible ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </HideTreeButton>
        </TreeColumn>
        <Container>
          <MinWidthContainer>
            <StyledAppBar position="static" elevation={0}>
              <NavigationTabs
                tabLinks={tabLinks}
              />
            </StyledAppBar>
            {selectedTreeItemId && children}
          </MinWidthContainer>
        </Container>
      </Grid>
    </AppLayout>
  );
};

export default AccessControlMainContainer;
