import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Typography, Link } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import Download from '@/components/fromSvg/Download';
import { agentLink, consoleLink } from '@/constants/common';
import { showNoty } from '@/utils/helpers';


const Info = styled(Typography)`
  margin-bottom: 20px;
`;

const SpecificInfo = styled.div`
  margin-top: 10px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 100;
    color: ${({ theme }) => theme.palette.primary.main};

    svg {
      margin-right: 5px;
    }
  }
`;

const CopyIcon = styled(FileCopyIcon)`
  width: 0.6em;
  height: 0.6em;
`;

const FilesForDownloadContent = ({ ...otherProps }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  const copyURI = (evt: React.MouseEvent<HTMLElement>) => {
    if (evt) {
      evt.preventDefault();
      navigator.clipboard
        .writeText((evt.target as HTMLElement).getAttribute('href') as string)
        .then(() => {
          // noty is not showing when this component is used in mui dialog in <WelcomeScreen />
          // this could be caused by z-index
          showNoty('success', t('common:notifications.copyLinkSuccess'));
        }).catch(() => {
          showNoty('error', t('common:notifications.copyLinkFailure'));
        });
    }
  };

  return (
    <div {...otherProps}>
      <Info variant="body2">
        {t('common:welcomeInfo')}
      </Info>
      <SpecificInfo>
        <Typography variant="subtitle2">
          {t('common:agent')}
        </Typography>
        <Typography variant="caption">
          {t('common:agentInfo')}
        </Typography>
        <Icons>
          <Link href={agentLink} rel="noreferrer noopener nofollow" download>
            <Download fill={theme.palette.primary.main} />
            {t('common:downloadAgent')}
          </Link>
          <Link href={agentLink} onClick={(e: React.MouseEvent<HTMLElement>) => copyURI(e)}>
            <CopyIcon fill={theme.palette.primary.main} />
            {t('common:copyAgentLink')}
          </Link>
        </Icons>
      </SpecificInfo>
      <SpecificInfo>
        <Typography variant="subtitle2">
          {t('common:console')}
        </Typography>
        <Typography variant="caption">
          {t('common:consoleInfo')}
        </Typography>
        <Icons>
          <Link href={consoleLink} rel="noreferrer noopener nofollow" download>
            <Download fill={theme.palette.primary.main} />
            {t('common:downloadConsole')}
          </Link>
          <Link href={consoleLink} onClick={(e: React.MouseEvent<HTMLElement>) => copyURI(e)}>
            <CopyIcon fill={theme.palette.primary.main} />
            {t('common:copyConsoleLink')}
          </Link>
        </Icons>
      </SpecificInfo>
    </div>
  );
};

export default FilesForDownloadContent;
