import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccessControlScreenPreviewPage from '@/components/AccessControlScreenPreviewPage';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import Settings from '@/components/screensPreviewPageComponents/Settings';
import DevicePreviews from '@/components/screensPreviewPageComponents/DevicePreviews';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import treeService from '@/TreeService';
import { deviceIdFromAgentId } from '@/utils/helpers';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import useInterval from '@/hooks/useInterval';
import { screenPreviewViewOptionsSelector, selectedTreeSortationSelector } from '@/redux/appOptions';
import {
  fetchScreen,
  removeScreensData,
  visibleScreensSelector,
  screenImagesSelector,
} from '@/redux/screensPreview';


const ScreensPreview = () => {
  const dispatch = useDispatch();
  const { selectedTreeItem } = useSelectedTreeItem();
  const { previewRefreshFrequency } = useSelector(screenPreviewViewOptionsSelector);
  const visibleScreens = useSelector(visibleScreensSelector);
  const screenImages = useSelector(screenImagesSelector);
  const treeSortation = useSelector(selectedTreeSortationSelector);

  const devices = selectedTreeItem.type !== TreeItemType.Agent
    ? selectedTreeItem.getAllDevicesSorted(treeSortation[0], treeSortation[1])
    : [treeService.devicesTree?.getDeviceById(
      deviceIdFromAgentId(selectedTreeItem.id)
    ) as DeviceItem];

  const fetchAllScreens = useCallback(() => {
    const imagesToRemove = Object.keys(screenImages)
      .filter(item => visibleScreens.indexOf(item) === -1);
    if (imagesToRemove.length > 0) {
      dispatch(removeScreensData(imagesToRemove));
    }

    visibleScreens.forEach((treeItemIdAndScreenNo: string) => {
      dispatch(fetchScreen(treeItemIdAndScreenNo));
    });
  }, [screenImages, dispatch, visibleScreens]);


  useInterval(() => {
    fetchAllScreens();
  }, previewRefreshFrequency * 1000);

  const refreshPreviews = () => {
    fetchAllScreens();
    // todo reset interval
  };

  return (
    <AccessControlScreenPreviewPage
      settingsComponent={(
        <Settings refreshPreviews={refreshPreviews} />
      )}
    >
      {
        devices.map((device) => (
          <DevicePreviews
            key={device.id}
            device={device}
          />
        ))
      }
    </AccessControlScreenPreviewPage>
  );
};

export default ScreensPreview;
