import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';
import { getRoleUserStatus } from '@/utils/helpers';
import { roleUsersCollectionSelectors } from '@/redux/collections/roleUsersCollection';
import ConfigUserModal from '@/components/ConfigUserModal';
import { RoleUser } from '@/api/owpb/pbFiles/roles_service_pb';
import ConfirmationModal from '@/components/ConfirmationModal';
import { removeRoleUser } from '@/api/clients/roles';
import ConfigSubTableLayout from '../ConfigTableLayout/ConfigSubTableLayout';
import ActionIcons from './ConfigTableActionButtons';


interface UsersSubTableProps {
  itemId: string,
}

const UsersSubTable = ({ itemId }: UsersSubTableProps) => {
  const { t } = useTranslation(['common']);
  const allUsers = useSelector(roleUsersCollectionSelectors.selectAll);
  const data = useMemo(
    () => allUsers.filter(user => user.roleId === itemId),
    [allUsers, itemId]
  );
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<RoleUser.AsObject | undefined>(undefined);

  const columns: Array<Column<RoleUser.AsObject>> = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        id: 'identy',
        accessor: 'identy',
      },
      {
        Header: 'Status',
        accessor: ({ status }: { status: number }) => t(getRoleUserStatus(status)),
      },
      {
        Header: 'Action Icons',
        accessor: 'id',
        Cell: ({ row: { original } }: never) => (
          <ActionIcons
            onEdit={() => {
              setUserToEdit(original);
              setIsEditUserModalOpen(true);
            }}
            onRemove={() => {
              setUserToEdit(original);
              setIsRemoveUserModalOpen(true);
            }}
          />
        ),
      },
    ],
    [t]
  );


  const tableInstance = useTable<RoleUser.AsObject>({ columns, data });

  return (
    <>
      <ConfigSubTableLayout
        tableInstance={tableInstance}
      />
      <ConfigUserModal
        open={isEditUserModalOpen}
        handleClose={() => setIsEditUserModalOpen(false)}
        variant="edit"
        initialData={userToEdit}
      />
      <ConfirmationModal
        open={isRemoveUserModalOpen}
        content={(
          <Trans
            i18nKey="common:userRemovalInfo"
            values={{ username: userToEdit?.identy ?? '' }}
          />
        )}
        handleClose={() => setIsRemoveUserModalOpen(false)}
        handleConfirm={() => {
          removeRoleUser(userToEdit?.id ?? '');
          setIsRemoveUserModalOpen(false);
        }}
      />
    </>
  );
};

export default UsersSubTable;
