/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { Role, RoleType } from '@/api/owpb/pbFiles/roles_service_pb';
import { settingsCollectionSelectors } from '@/redux/collections/settingsCollection';
import { Settings } from '@/api/Settings';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';


export interface RoleWithStatus extends Role.AsObject, Record<string, unknown> {
  status: ControlState,
}

const adapter = createEntityAdapter<Role.AsObject>({});

export type RolesCollectionState = ReturnType<typeof adapter.getInitialState>;

const rolesCollection = createSlice({
  name: 'ROLES_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const rolesCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.rolesCollection
);

export const apiUsersRolesSelector = createSelector(
  rolesCollectionSelectors.selectAll,
  (roles: Role.AsObject[]) => (roles.filter(x => x.roleType === RoleType.API_USERS))
);

export const windowsUsersRolesSelector = createSelector(
  rolesCollectionSelectors.selectAll,
  (roles: Role.AsObject[]) => (roles.filter(x => x.roleType === RoleType.WINDOWS_USERS))
);

export const windowsUsersRolesWithStatusSelector = (categoryId: string) => createSelector(
  rolesCollectionSelectors.selectAll,
  settingsCollectionSelectors.selectAll,
  (roles, settingsCollection): RoleWithStatus[] => {
    const settings = new Settings(settingsCollection);
    return roles.filter(x => x.roleType === RoleType.WINDOWS_USERS).map((role: Role.AsObject) => ({
      ...role,
      status: settings.getURLCategoryStateForRole(role.id, categoryId),
    }));
  }
);


export const rolesCollectionHolderFunctions = {
  ...rolesCollection.actions,
  items: rolesCollectionSelectors.selectAll,
};

export default rolesCollection.reducer;
