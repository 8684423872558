import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { handleError } from '@/api/errorHandler';
import i18n from '@/utils/i18n';
import { showNoty } from '@/utils/helpers';
import { CustomerServiceClient } from '@/api/owpb/pbFiles/Customer_serviceServiceClientPb';
import { GetCustomerRequest, UpdateCustomerKeyDescriptionRequest, UpdateCustomerLanguageRequest } from '@/api/owpb/pbFiles/customer_service_pb';


const client = new CustomerServiceClient(apiUrl);

export function getCustomer() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetCustomerRequest();

  return client.getCustomer(request, authMetaData)
    .then((res) => res.toObject())
    .catch(handleError);
}

export function updateCustomerDescription(desc: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateCustomerKeyDescriptionRequest();
  request.setKeyDescription(desc);

  client.updateCustomerKeyDescription(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.updateCustomerDescriptionSuccess')))
    .catch(handleError);
}

export function updateCustomerLanguage(language: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateCustomerLanguageRequest();
  request.setLanguage(language);

  client.updateCustomerLanguage(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.updateCustomerLanguageSuccess')))
    .catch(handleError);
}
