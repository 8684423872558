import React from 'react';
import styled from 'styled-components';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView, { SingleSelectTreeViewProps } from '@material-ui/lab/TreeView';


const StyledTreeView = styled(TreeView)`
  padding-left: var(--cell-side-padding);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5MCIgaGVpZ2h0PSI2MCI+CiAgPHJlY3Qgd2lkdGg9IjkwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjRjhGOEY4Ij48L3JlY3Q+CiAgPHJlY3Qgd2lkdGg9IjkwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjRkZGIj48L3JlY3Q+Cjwvc3ZnPg==");

  li {
    background: transparent;
  }

  .MuiTreeItem-content {
    height: 30px;
  }

  .MuiTreeItem-label:hover,
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }
`;

const CustomTreeView = (
  props: Omit<SingleSelectTreeViewProps, 'defaultCollapseIcon' | 'defaultExpandIcon'>,
) => (
  <StyledTreeView
    {...props}
    defaultCollapseIcon={<ExpandLessIcon />}
    defaultExpandIcon={<ExpandMoreIcon />}
  />
);

export default CustomTreeView;
