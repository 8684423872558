import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Screen from './Screen';
import ControlIcons from './ControlIcons';
import { ComputerIcon, Name, StyledPaper } from './styledComponents';
import { useScreenPreviewImage } from './useScreenPreviewImage';


const DeviceInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ScreenshotPopup = styled(Dialog)`
  .MuiDialog-paper {
    max-width: unset;
    overflow: hidden;
    height: fit-content;
    width: 80%;
  }
`;

const TopPopupNavigation = styled.div`
  display: flex;
  height: var(--header-and-footer-height);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: ${({ theme }) => theme.border};
`;

const CloseButtonIcon = styled(CloseIcon)`
  cursor: pointer;
`;


interface ScreenPreviewModalProps {
  name: string,
  agentOrDeviceId: string,
  closeModal: () => void,
  displayNo: number,
}

const ScreenPreviewModal = ({
  name,
  agentOrDeviceId,
  closeModal,
  displayNo,
}: ScreenPreviewModalProps) => {
  const [
    image,
    isLoadingImage,
    errorMessage,
    resetModalImageLoading,
  ] = useScreenPreviewImage(
    agentOrDeviceId,
    displayNo,
    { quality: 100 },
  );


  return (
    <ScreenshotPopup
      onClose={closeModal}
      open
    >
      <StyledPaper $isPopup square>
        <TopPopupNavigation>
          <DeviceInfo>
            <ComputerIcon />
            <Name>
              {name}
            </Name>
          </DeviceInfo>
          <CloseButtonIcon
            onClick={() => {
              resetModalImageLoading();
              closeModal();
            }}
          />
        </TopPopupNavigation>
        <Screen
          isModal
          isLoadingImage={isLoadingImage}
          errorMessage={errorMessage}
          image={image}
        />
        <ControlIcons
          agentOrDeviceId={agentOrDeviceId}
          displayNo={displayNo}
          isModal
        />
      </StyledPaper>
    </ScreenshotPopup>
  );
};

export default ScreenPreviewModal;
