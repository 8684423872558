import { stringToAppControlState, stringToControlState, stringToInetControlState } from '@/utils/converters';
import { ApiConsts } from './ApiConsts';
import { AppControlState, ControlState, InetControlState } from './owpb/pbFiles/basic_pb';
import { SettingsGroup } from './owpb/pbFiles/settings_service_pb';


export class Settings {
  private settingsGroups: Array<SettingsGroup.AsObject>;

  constructor(settingsGroups: Array<SettingsGroup.AsObject>) {
    this.settingsGroups = settingsGroups;
  }

  public getDeviceAppAccess(deviceId: string): AppControlState {
    const v = this.getValueForItem(ApiConsts.SettingsDeviceAppAccess, deviceId, true);
    return stringToAppControlState(v);
  }

  public getDeviceAppAccessDesktopOrCategoryId(deviceId: string): string {
    const v = this.getValueForItem(ApiConsts.SettingsDeviceAppAccess, deviceId, false);
    const { v2 } = this.splitSettingValue(v);
    return v2;
  }

  public getDeviceInetAccess(deviceId: string): InetControlState {
    const v = this.getValueForItem(ApiConsts.SettingsDeviceInetAccess, deviceId, false);
    return stringToInetControlState(v);
  }

  public getDeviceInetStrictCategoryId(deviceId: string) {
    return this.getValueForItem(ApiConsts.SettingsDeviceInetStrictCategory, deviceId, false);
  }

  public getDeviceLock(deviceId: string): number {
    const v = this.getValueForItem(ApiConsts.SettingsDeviceLock, deviceId, false);
    return Number(v);
  }

  public getURLCategoryState(categoryId: string): ControlState {
    return stringToControlState(this.getValueForItem(ApiConsts.SettingsWebCategoryState,
      categoryId));
  }

  public getURLCategoryStateForRole(roleId: string, categoryId: string): ControlState {
    return stringToControlState(this.getValueForItem(`${ApiConsts.SettingsWebCategoryStateForRole}[${categoryId}]`,
      roleId));
  }

  public getValueForItem(settingsId: string, itemId: string, splitValue: boolean = false): string {
    const groups = this.getGroupsStartsWith(settingsId);
    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];
      if (group.itemsList.indexOf(itemId) > -1) {
        return this.splitSettingsValueIfNeed(group.value, splitValue);
      }
    }

    const defaultGroup = this.getDefaultGroup(settingsId);
    if (defaultGroup) {
      return this.splitSettingsValueIfNeed(defaultGroup.value, splitValue);
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  public splitSettingValue(value: string): {v1: string, v2: string} {
    if (value.length === 0) {
      return { v1: '', v2: '' };
    }
    const idx = value.indexOf(':');
    if (idx > -1) {
      return { v1: value.substr(0, idx), v2: value.substr(idx + 1) };
    }
    return { v1: value, v2: '' };
  }

  private getDefaultGroup(settingsId: string): SettingsGroup.AsObject | null {
    let defaultGroupName = ApiConsts.SettingsGroupDefault + settingsId;
    if (settingsId.startsWith(ApiConsts.SettingsWebCategoryStateForRole)) {
      // SettingsWebCategoryStateForRole używa wartości domyślnej z SettingsWebCategoryState
      defaultGroupName = ApiConsts.SettingsGroupDefault + ApiConsts.SettingsWebCategoryState;
    }
    for (let i = 0; i < this.settingsGroups.length; i += 1) {
      const group = this.settingsGroups[i];
      if (group.id === defaultGroupName) {
        return group;
      }
    }
    return null;
  }

  private getGroupsStartsWith(prefix: string): Array<SettingsGroup.AsObject> {
    const result = new Array<SettingsGroup.AsObject>();
    this.settingsGroups.forEach((group: SettingsGroup.AsObject) => {
      if (group.id.startsWith(prefix)) {
        result.push(group);
      }
    });
    return result;
  }

  private splitSettingsValueIfNeed(value: string, splitValue: boolean): string {
    if (splitValue) {
      const { v1 } = this.splitSettingValue(value);
      return v1;
    }
    return value;
  }
}
