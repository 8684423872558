import React from 'react';
import styled from 'styled-components';
import AppLayout from '@/components/layout/AppLayout';
import FilesForDownloadContent from '@/components/FilesForDownloadContent';


const StyledFilesForDownloadContent = styled(FilesForDownloadContent)`
  width: 40vw;
  margin: 50px auto 0;
`;

const FilesForDownload = () => (
  <AppLayout>
    <StyledFilesForDownloadContent />
  </AppLayout>
);

export default FilesForDownload;
