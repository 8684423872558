import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStripe, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import CheckoutPaymentMethod from '@/components/CheckoutPaymentMethod';
import { PaymentType } from '@/api/owpb/pbFiles/subscription_service_pb';
import { updatePaymentMethod } from '@/api/clients/subscription';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { getPaymentType } from '@/utils/display';
import { FFGenericOptionsModal } from './GenericOptionsModal';


const ManagePaymentMethodInfo = styled(Typography)`
  margin-bottom: 20px;
`;

interface ManagePaymentMethodModalProps {
  isOpen: boolean,
  onClose: () => void,
  currentPaymentMethod: PaymentType,
  currency?: string,
}

const ManagePaymentMethodModal = ({
  isOpen,
  onClose,
  currentPaymentMethod,
  currency,
}: ManagePaymentMethodModalProps) => {
  const { t } = useTranslation(['common']);
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (values: { paymentMethod: string }) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const paymentType = parseInt(values.paymentMethod, 10);

    if (paymentType === PaymentType.PAYMENT_BY_CARD && cardElement) {
      const res = await updatePaymentMethod(
        paymentType,
      );
      const stripeRes = await stripe.confirmCardSetup(res.clientSecret, {
        payment_method: {
          card: cardElement,
          // billing_details: values,
        },
      });
      if (stripeRes.error) {
        throw new Error(stripeRes.error.message);
      } else {
        showNoty('success', i18n.t('common:notifications.updatePaymentMethodSuccess'));
        onClose();
      }
    } else {
      await updatePaymentMethod(
        paymentType,
      );
      showNoty('success', i18n.t('common:notifications.updatePaymentMethodSuccess'));
      onClose();
    }
  };

  return (
    <FFGenericOptionsModal<{ paymentMethod: string }>
      title={t('common:userProfile.managePaymentMethod')}
      confirmButtonText={t('common:common.save')}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      fixedWidth
      noMargins
      disabledSubmitButtonWhenNoValue
    >
      {() => (
        <>
          <ManagePaymentMethodInfo>
            <Trans
              i18nKey="common:userProfile.managePaymentMethodInfo"
              values={{ paymentType: getPaymentType(currentPaymentMethod) }}
            />
          </ManagePaymentMethodInfo>
          <CheckoutPaymentMethod selectedCurrency={currency} />
        </>
      )}
    </FFGenericOptionsModal>
  );
};

export default ManagePaymentMethodModal;
