import Noty from 'noty';
import duration from 'dayjs/plugin/duration';
import dayjs, { Dayjs } from 'dayjs';
import { TreeItem } from '@/TreeService/types';


dayjs.extend(duration);


/**
 * Konwertuje id urządzenia usuwając z niej część dotyczączą sesji (o ile jest takowa)
 */
export function deviceIdFromAgentId(id: string): string {
  const result: string = id;
  const i = result.indexOf('-');
  if (i > 0) {
    return result.substr(0, i);
  }
  return result;
}

export function showNoty(type: Noty.Type, text: string) {
  new Noty({
    type,
    text,
  }).show();
}


export const getWindowWidth = () => Math.max(
  document.documentElement.clientWidth,
  window.innerWidth || 0
);

export const getWindowHeight = () => Math.max(
  document.documentElement.clientHeight,
  window.innerHeight || 0
);


export function sessionSetItem(key: string, value: string): Promise<void> {
  return new Promise((resolve => {
    sessionStorage.setItem(key, value);
    resolve();
  }));
}

export function sessionGetItem(key: string): Promise<string | null> {
  return new Promise((resolve => {
    resolve(sessionStorage.getItem(key));
  }));
}

export function sessionClear() {
  return sessionStorage.clear();
}

export function getFullTimeFromSeconds(seconds: number) {
  const hours = (dayjs.duration({ seconds }).asHours());
  const minutes = ((hours % 1) * 60);
  return (`${hours.toFixed(0)}g ${Math.round(minutes)}min`);
}

export function getRoleUserStatus(status: number) {
  switch (status) {
    default:
    case 0:
      return 'unknown';
    case 1:
      return 'awaitingActivation';
    case 2:
      return 'active';
  }
}

export function setDayEndHour(date: Dayjs) {
  return date.hour(23).minute(59);
}

export function setDayStartHour(date: Dayjs) {
  return date.hour(0);
}

export function sortBy(
  array: TreeItem[],
  parameterName?: adminify.SortColumn,
  direction?: adminify.SortDirection,
) {
  if (parameterName && direction) {
    if (direction === 'asc') {
      return [...array].sort(
        // @ts-ignore
        (a, b) => a[parameterName]?.toUpperCase().localeCompare(b[parameterName]?.toUpperCase())
      );
    }
    if (direction === 'desc') {
      return [...array].sort(
        // @ts-ignore
        (a, b) => b[parameterName]?.toUpperCase().localeCompare(a[parameterName]?.toUpperCase())
      );
    }
  }
  return array;
}

export function formatPrice(price: number) {
  return (price / 100).toFixed(2);
}
