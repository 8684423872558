import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useQuery } from 'react-query';
import { CustomerInvoiceInfo, InvoiceRecipient } from '@/api/owpb/pbFiles/customer_service_pb';
import UneditableTextField from '@/components/UneditableTextField';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import FFColumnLayout from '@/components/finalForm/FFColumnLayout';
import CheckoutPaymentAndInvoiceData, { CheckoutPaymentAndInvoiceDataFormFields } from '@/components/CheckoutPaymentAndInvoiceData';
import { updateCustomerInvoiceInfo } from '@/api/clients/customerService';
import { getSystemInfo } from '@/api/clients/systemInfo';
import { Country } from '@/api/owpb/pbFiles/basic_pb';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import PermissionsControl from '@/components/PermissionsControl';
import { ApiConsts } from '@/api/ApiConsts';


interface InvoiceInfoProps {
  invoiceData: InvoiceRecipient.AsObject | undefined,
  countries: Country.AsObject[],
}

const InvoiceInfo = ({ invoiceData, countries }: InvoiceInfoProps) => {
  const { t } = useTranslation(['common']);

  // find selected country from countries array
  const countryName = useMemo(() => {
    const foundCountry = countries.find((country) => (
      invoiceData && country.code === invoiceData.country));
    return foundCountry ? foundCountry.name : '';
  }, [countries, invoiceData]);

  return (
    <FFColumnLayout columns={2}>
      <UneditableTextField
        label={t('common:paymentData.companyName')}
        value={invoiceData?.name}
      />
      <UneditableTextField
        label={t('common:paymentData.taxId')}
        value={invoiceData?.taxId}
      />
      <UneditableTextField
        label={t('common:paymentData.address')}
        value={invoiceData?.addressLine1}
      />
      <UneditableTextField
        label={t('common:paymentData.addressContinued')}
        value={invoiceData?.addressLine2}
      />
      <UneditableTextField
        label={t('common:paymentData.postalCode')}
        value={invoiceData?.postalCode}
      />
      <UneditableTextField
        label={t('common:paymentData.city')}
        value={invoiceData?.city}
      />
      <UneditableTextField
        label={t('common:paymentData.country')}
        value={countryName}
      />
    </FFColumnLayout>
  );
};

interface PaymentAndInvoiceDataCardProps {
  customerInvoiceInfo: CustomerInvoiceInfo.AsObject,
  refetchData: () => void,
}

const PaymentAndInvoiceDataCard = ({ customerInvoiceInfo, refetchData }:
PaymentAndInvoiceDataCardProps) => {
  const { t } = useTranslation(['common']);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data: systemInfo } = useQuery('getSystemInfo', getSystemInfo);

  const onSubmit = async (values: CheckoutPaymentAndInvoiceDataFormFields) => {
    try {
      await updateCustomerInvoiceInfo(
        !values.buyerAndPayerDataIsTheSame,
        values.buyer,
        values.payer
      );
    } catch (error) {
      return;
    }
    refetchData();
    setIsEditModalOpen(false);
    showNoty('success', i18n.t('common:notifications.updateCustomerInvoiceInfoSuccess'));
  };

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            disabled
            color="primary"
            checked={!customerInvoiceInfo.hasPayer}
          />
        )}
        label={t('common:paymentData.paymentAndInvoiceDataIsTheSame')}
      />
      <InvoiceInfo
        invoiceData={customerInvoiceInfo.buyer}
        countries={systemInfo ? systemInfo.countriesList : []}
      />
      {customerInvoiceInfo.hasPayer && (
        <>
          <Typography variant="body1">
            {t('common:paymentData.invoiceData')}
          </Typography>
          <InvoiceInfo
            invoiceData={customerInvoiceInfo.payer}
            countries={systemInfo ? systemInfo.countriesList : []}
          />
        </>
      )}
      <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsEditModalOpen(true)}
          fullWidth
        >
          {t('common:paymentData.editPaymentData')}
        </Button>
      </PermissionsControl>
      <FFGenericOptionsModal<CheckoutPaymentAndInvoiceDataFormFields>
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title={t('common:paymentData.paymentData')}
        onSubmit={onSubmit}
        confirmButtonText={t('common:common.save')}
        formProps={{
          initialValues: {
            buyerAndPayerDataIsTheSame: !customerInvoiceInfo?.hasPayer,
            buyer: customerInvoiceInfo?.buyer,
            payer: customerInvoiceInfo?.payer,
          },
        }}
      >
        {({ values }) => (
          <CheckoutPaymentAndInvoiceData
            buyerAndPayerDataIsTheSame={values.buyerAndPayerDataIsTheSame}
          />
        )}
      </FFGenericOptionsModal>
    </>
  );
};

export default PaymentAndInvoiceDataCard;
