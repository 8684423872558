import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PermissionsControl from '@/components/PermissionsControl';
import { defaultFallbackPageForInsufficientPermissions } from '@/constants/common';


interface SecuredRouteProps {
  allowedPermissions: string[],
  exact?: boolean,
  path: string,
}

const SecuredRoute = ({
  allowedPermissions,
  exact,
  path,
  children,
  ...otherProps
}: React.PropsWithChildren<SecuredRouteProps>) => (
  <PermissionsControl
    allowedPermissions={allowedPermissions}
    fallback={<Redirect to={defaultFallbackPageForInsufficientPermissions} />}
  >
    <Route exact={exact} path={path} {...otherProps}>
      {children}
    </Route>
  </PermissionsControl>
);


export default SecuredRoute;
