import React from 'react';
import styled from 'styled-components';
import MaterialTooltip, { TooltipProps as MaterialTooltipProps } from '@material-ui/core/Tooltip';


const PopperAdapter = ({ className, ...otherProps }: MaterialTooltipProps) => (
  <MaterialTooltip
    classes={{ popper: className, tooltipArrow: 'tooltipArrow', arrow: 'arrow' }}
    {...otherProps}
  />
);


const StyledTooltip = styled(PopperAdapter)`
  & .tooltipArrow {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.common.white};
    padding: 10px;
    font-size: 0.9em;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    max-width: none;
  }

  & .arrow {
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 1.5em;
  }
`;


export interface TooltipProps extends MaterialTooltipProps {
  interactive?: boolean,
}

const Tooltip = ({
  interactive = true,
  ...otherProps
}: TooltipProps) => (
  <StyledTooltip
    interactive={interactive}
    {...otherProps}
  />
);

export default Tooltip;
