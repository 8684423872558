import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { appAccessToIcon, inetControlStateToIcon, deviceLockToIcon } from '@/utils/display';
import Download from '@/components/fromSvg/Download';
import agentRpcClients from '@/api/agentRpcClients';
import { deviceIdFromAgentId } from '@/utils/helpers';
import IconMore from '@/components/fromSvg/IconMore';
import { useItemAccessStatus } from '@/hooks/useItemAccesStatus';
import { handleError } from '@/api/errorHandler';
import AccessControlContextMenu from '@/components/AccessControlContextMenu';
import InetAccessControlSubmenu from '@/components/AccessControlContextMenu/InetAccessControlSubmenu';
import AppAccessControlSubmenu from '@/components/AccessControlContextMenu/AppAccessControlSubmenu';
import DeviceAccessControlSubmenu from '@/components/AccessControlContextMenu/DeviceAccessControlSubmenu';
import { authMetaDataSelector } from '@/redux/user';
import { ApiConsts } from '@/api/ApiConsts';
import PermissionsControl from '@/components/PermissionsControl';


const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const AccessIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 3px;
  }
`;

const DownloadPreview = styled(Download)<{ $isActive?: boolean }>`
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};
  width: 1.2em;
  height: 1.2em;
  margin-right: 5px;
  opacity: ${({ $isActive, theme }) => ($isActive ? 1 : theme.disabledOpacity)};
`;

const AccessContextMenu = styled(IconMore)`
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
`;

const ActionsIcons = styled.div`
  display: flex;
`;

interface ControlIconsProps {
  agentOrDeviceId?: string,
  displayNo?: number,
  isModal?: boolean,
}

const ControlIcons = ({ agentOrDeviceId, displayNo, isModal = false }: ControlIconsProps) => {
  const deviceId = agentOrDeviceId ? deviceIdFromAgentId(agentOrDeviceId) : null;

  const { appAccess, inetAccess, deviceAccess } = useItemAccessStatus(deviceId);

  const inetAccessIcon = useMemo(() => inetControlStateToIcon(inetAccess), [inetAccess]);
  const appAccessIcon = useMemo(() => appAccessToIcon(appAccess), [appAccess]);
  const computerAccessIcon = useMemo(() => deviceLockToIcon(deviceAccess), [deviceAccess]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const authMetaData = useSelector(authMetaDataSelector);
  const getImage = () => {
    if (displayNo !== undefined && agentOrDeviceId) {
      agentRpcClients.getScreenshotDisplay(
        agentOrDeviceId,
        displayNo,
        authMetaData,
        { quality: 100 }
      )
        .then((image) => {
          const link = document.createElement('a');
          link.href = image;
          link.setAttribute('download', 'preview.png');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  return (
    <IconsContainer>
      <AccessIcons>
        {inetAccessIcon}
        {appAccessIcon}
        {computerAccessIcon}
      </AccessIcons>
      <ActionsIcons>
        <DownloadPreview onClick={() => getImage()} $isActive={displayNo !== undefined} />
        {!isModal && (
          <AccessContextMenu
            onClick={
              (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
            }
          />
        )}
      </ActionsIcons>

      {anchorEl && (
        <AccessControlContextMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        >
          <PermissionsControl
            allowedPermissions={[
              ApiConsts.PermissionDeviceInetAccess,
              ApiConsts.PermissionAPIAdmin,
            ]}
          >
            <InetAccessControlSubmenu
              handleClose={() => setAnchorEl(null)}
              deviceId={agentOrDeviceId ? deviceIdFromAgentId(agentOrDeviceId) : ''}
            />
          </PermissionsControl>
          <PermissionsControl
            allowedPermissions={[
              ApiConsts.PermissionDeviceAppAccess,
              ApiConsts.PermissionAPIAdmin,
            ]}
          >
            <AppAccessControlSubmenu
              handleClose={() => setAnchorEl(null)}
              deviceId={agentOrDeviceId ? deviceIdFromAgentId(agentOrDeviceId) : ''}
            />
          </PermissionsControl>
          <PermissionsControl
            allowedPermissions={[
              ApiConsts.PermissionDeviceLock,
              ApiConsts.PermissionAPIAdmin,
            ]}
          >
            <DeviceAccessControlSubmenu
              handleClose={() => setAnchorEl(null)}
              deviceId={agentOrDeviceId ? deviceIdFromAgentId(agentOrDeviceId) : ''}
            />
          </PermissionsControl>
        </AccessControlContextMenu>
      )}
    </IconsContainer>
  );
};
export default ControlIcons;
