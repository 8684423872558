import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useQueryClient } from 'react-query';
import { appDateFormat } from '@/constants/common';
import { accessControlFiltersSelector, statsDateRangeSelector } from '@/redux/layout';
import ConfirmationModal from '@/components/ConfirmationModal';
import { deleteAppHistory, deleteBrowsingHistory } from '@/api/clients/history';
import { handleError } from '@/api/errorHandler';


const StyledDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    max-width: 400px;
    padding: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;
`;

const RemoveButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.border};
  margin-right: 10px;
`;

interface RemoveHistoryModalProps {
  infoMsg: React.ReactNode,
  infoTitle: React.ReactNode,
  isOpen: boolean,
  onClose: () => void,
  variant: 'webHistory' | 'appHistory',
}

const RemoveHistoryModal = ({
  infoMsg, infoTitle, isOpen, onClose, variant,
}: RemoveHistoryModalProps) => {
  const { t } = useTranslation(['common']);
  const historyDate = useSelector(accessControlFiltersSelector);
  const appStateDate = useSelector(statsDateRangeSelector);
  const date = variant === 'webHistory' ? historyDate : appStateDate;
  const [selectedDate, handleDateChange] = useState(date ? dayjs.unix(date.dateFrom) : dayjs());
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleRemove = async () => {
    let delResponse;
    if (variant === 'webHistory') {
      delResponse = await deleteBrowsingHistory(selectedDate.unix());
      if (delResponse) {
        queryClient.invalidateQueries('getBrowsingHistory').then().catch(handleError);
      }
    } else {
      delResponse = await deleteAppHistory(selectedDate.unix());
      if (delResponse) {
        queryClient.invalidateQueries('getAppHistory').then().catch(handleError);
        queryClient.invalidateQueries('getAppStats').then().catch(handleError);
        queryClient.invalidateQueries('getDomainStats').then().catch(handleError);
      }
    }
  };

  useEffect(() => {
    if (date) {
      handleDateChange(dayjs.unix(date.dateFrom));
    }
  }, [date]);

  return (
    <>
      <StyledDialog open={isOpen}>
        <DialogTitle>
          {infoTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {infoMsg}
          </DialogContentText>
          <KeyboardDatePicker
            value={selectedDate}
            onChange={value => {
              // @ts-ignore
              handleDateChange(value);
            }}
            format={appDateFormat}
            disableFuture
            okLabel={t('common:common.ok')}
            cancelLabel={t('common:common.cancel')}
          />
        </DialogContent>
        <Buttons>
          <CancelButton onClick={onClose} fullWidth>
            {t('common:common.cancel')}
          </CancelButton>
          <RemoveButton
            onClick={() => {
              setIsConfirmationModalOpen(true);
              onClose();
            }}
            fullWidth
          >
            {t('common:common.remove')}
          </RemoveButton>
        </Buttons>
      </StyledDialog>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        content={(
          <Typography>
            <Trans
              i18nKey="common:deleteHistoryConfirmation"
              values={{ date: dayjs(selectedDate).format(appDateFormat) }}
              components={{ bold: <strong /> }}
            />
            <Trans i18nKey="common:removeDataInfo" />
          </Typography>
        )}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={async () => {
          await handleRemove();
          setIsConfirmationModalOpen(false);
        }}
        confirmButtonText={t('common:common.remove')}
      />
    </>
  );
};

export default RemoveHistoryModal;
