import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { screenPreviewSettingsSelector } from '@/redux/appOptions';
import { AgentItem } from '@/TreeService/TreeItems/AgentItem';
import ScreenPreviewTile from './ScreenPreviewTile';
import ScreenPreviewModal from './ScreenPreviewModal';


export interface DisplayPreviewProps {
  agent?: AgentItem,
  deviceName: string,
  displayNo?: number,
  agentOrDeviceId?: string,
}

const DisplayPreview = ({
  agent,
  displayNo,
  agentOrDeviceId,
  deviceName,
}: DisplayPreviewProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [displayName, setDisplayName] = useState('');

  const nameSettings = useSelector(screenPreviewSettingsSelector);

  useEffect(() => {
    const res = [deviceName];
    const winUser = agent?.WindowsUser?.toObject();

    if (winUser) {
      if (nameSettings.domain) {
        res.push(winUser.domainName);
      }
      if (nameSettings.login) {
        res.push(winUser.userName);
      }
      if (nameSettings.nameAndSurname) {
        res.push(winUser.fullName);
      }
    }
    setDisplayName(res.join(' - '));
  }, [agent, deviceName, nameSettings]);


  return (
    <>
      <ScreenPreviewTile
        agentOrDeviceId={agentOrDeviceId}
        name={displayName !== '' ? displayName : deviceName}
        openModal={() => {
          setIsModalOpen(true);
        }}
        displayNo={displayNo}
      />
      {isModalOpen && (
        <ScreenPreviewModal
          agentOrDeviceId={agentOrDeviceId as string}
          name={displayName !== '' ? displayName : deviceName}
          closeModal={() => {
            setIsModalOpen(false);
          }}
          displayNo={displayNo as number}
        />
      )}
    </>
  );
};


export default DisplayPreview;
