/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as auth_service_pb from './auth_service_pb';


export class AuthServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoChangeUserPassword = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.ChangeUserPasswordResponse,
    (request: auth_service_pb.ChangeUserPasswordRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.ChangeUserPasswordResponse.deserializeBinary
  );

  changeUserPassword(
    request: auth_service_pb.ChangeUserPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.ChangeUserPasswordResponse>;

  changeUserPassword(
    request: auth_service_pb.ChangeUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.ChangeUserPasswordResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.ChangeUserPasswordResponse>;

  changeUserPassword(
    request: auth_service_pb.ChangeUserPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.ChangeUserPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/ChangeUserPassword',
        request,
        metadata || {},
        this.methodInfoChangeUserPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/ChangeUserPassword',
    request,
    metadata || {},
    this.methodInfoChangeUserPassword);
  }

  methodInfoCreateAppAuthToken = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.CreateAppAuthTokenResponse,
    (request: auth_service_pb.CreateAppAuthTokenRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.CreateAppAuthTokenResponse.deserializeBinary
  );

  createAppAuthToken(
    request: auth_service_pb.CreateAppAuthTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.CreateAppAuthTokenResponse>;

  createAppAuthToken(
    request: auth_service_pb.CreateAppAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.CreateAppAuthTokenResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.CreateAppAuthTokenResponse>;

  createAppAuthToken(
    request: auth_service_pb.CreateAppAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.CreateAppAuthTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/CreateAppAuthToken',
        request,
        metadata || {},
        this.methodInfoCreateAppAuthToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/CreateAppAuthToken',
    request,
    metadata || {},
    this.methodInfoCreateAppAuthToken);
  }

  methodInfoGetUserAuthToken = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.UserAuthTokenResponse,
    (request: auth_service_pb.GetUserAuthTokenRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.UserAuthTokenResponse.deserializeBinary
  );

  getUserAuthToken(
    request: auth_service_pb.GetUserAuthTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.UserAuthTokenResponse>;

  getUserAuthToken(
    request: auth_service_pb.GetUserAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.UserAuthTokenResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.UserAuthTokenResponse>;

  getUserAuthToken(
    request: auth_service_pb.GetUserAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.UserAuthTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/GetUserAuthToken',
        request,
        metadata || {},
        this.methodInfoGetUserAuthToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/GetUserAuthToken',
    request,
    metadata || {},
    this.methodInfoGetUserAuthToken);
  }

  methodInfoGetAgentAuthToken = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.AgentAuthTokenResponse,
    (request: auth_service_pb.GetAgentAuthTokenRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.AgentAuthTokenResponse.deserializeBinary
  );

  getAgentAuthToken(
    request: auth_service_pb.GetAgentAuthTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.AgentAuthTokenResponse>;

  getAgentAuthToken(
    request: auth_service_pb.GetAgentAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.AgentAuthTokenResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.AgentAuthTokenResponse>;

  getAgentAuthToken(
    request: auth_service_pb.GetAgentAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.AgentAuthTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/GetAgentAuthToken',
        request,
        metadata || {},
        this.methodInfoGetAgentAuthToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/GetAgentAuthToken',
    request,
    metadata || {},
    this.methodInfoGetAgentAuthToken);
  }

  methodInfoGetToolAuthToken = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.ToolAuthTokenResponse,
    (request: auth_service_pb.GetToolAuthTokenRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.ToolAuthTokenResponse.deserializeBinary
  );

  getToolAuthToken(
    request: auth_service_pb.GetToolAuthTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.ToolAuthTokenResponse>;

  getToolAuthToken(
    request: auth_service_pb.GetToolAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.ToolAuthTokenResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.ToolAuthTokenResponse>;

  getToolAuthToken(
    request: auth_service_pb.GetToolAuthTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.ToolAuthTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/GetToolAuthToken',
        request,
        metadata || {},
        this.methodInfoGetToolAuthToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/GetToolAuthToken',
    request,
    metadata || {},
    this.methodInfoGetToolAuthToken);
  }

  methodInfoSendForgotenPassword = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.ForgotenPasswordResponse,
    (request: auth_service_pb.ForgotenPasswordRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.ForgotenPasswordResponse.deserializeBinary
  );

  sendForgotenPassword(
    request: auth_service_pb.ForgotenPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.ForgotenPasswordResponse>;

  sendForgotenPassword(
    request: auth_service_pb.ForgotenPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.ForgotenPasswordResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.ForgotenPasswordResponse>;

  sendForgotenPassword(
    request: auth_service_pb.ForgotenPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.ForgotenPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/SendForgotenPassword',
        request,
        metadata || {},
        this.methodInfoSendForgotenPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/SendForgotenPassword',
    request,
    metadata || {},
    this.methodInfoSendForgotenPassword);
  }

  methodInfoResetPassword = new grpcWeb.AbstractClientBase.MethodInfo(
    auth_service_pb.ResetPasswordResponse,
    (request: auth_service_pb.ResetPasswordRequest) => {
      return request.serializeBinary();
    },
    auth_service_pb.ResetPasswordResponse.deserializeBinary
  );

  resetPassword(
    request: auth_service_pb.ResetPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_service_pb.ResetPasswordResponse>;

  resetPassword(
    request: auth_service_pb.ResetPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: auth_service_pb.ResetPasswordResponse) => void): grpcWeb.ClientReadableStream<auth_service_pb.ResetPasswordResponse>;

  resetPassword(
    request: auth_service_pb.ResetPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: auth_service_pb.ResetPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.AuthService/ResetPassword',
        request,
        metadata || {},
        this.methodInfoResetPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.AuthService/ResetPassword',
    request,
    metadata || {},
    this.methodInfoResetPassword);
  }

}

