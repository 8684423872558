import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from '@/components/Tooltip';
import { previewTileWidthSelector } from '@/redux/layout';
import { screenProportions } from '@/constants/common';
import { removeFromVisibleScreens, screenImagesSelector, setScreenVisible } from '@/redux/screensPreview';
import { ComputerIcon, Name, StyledPaper } from './styledComponents';
import Screen from './Screen';
import ControlIcons from './ControlIcons';


export const IconsContainer = styled.div<{ $width?: number }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border};
  padding: 0 5px;
  width: ${({ $width }) => `${$width}px`};

  svg {
    margin-right: 3px;
  }
`;

export interface ScreenPreviewTileProps {
  name: string,
  agentOrDeviceId?: string,
  openModal: () => void,
  displayNo?: number,
}


const ScreenPreviewTile = ({
  name,
  agentOrDeviceId,
  openModal,
  displayNo,
}: ScreenPreviewTileProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const width = useSelector(previewTileWidthSelector);
  const imageHeight = Math.round((1 / screenProportions) * width);
  const { ref, inView } = useInView({
    delay: 250, // ms
  });

  const isOnline = displayNo !== undefined;
  const treeItemIdAndScreenNo = `${agentOrDeviceId};${displayNo}`;

  // visibility change
  useEffect(() => {
    if (isOnline) {
      if (inView) {
        dispatch(setScreenVisible(treeItemIdAndScreenNo));
      } else {
        dispatch(removeFromVisibleScreens(treeItemIdAndScreenNo));
      }
    }
  }, [treeItemIdAndScreenNo, dispatch, inView, isOnline]);

  // removeFromVisibleScreens on disconnect
  useEffect(() => {
    if (isOnline) {
      return () => dispatch(removeFromVisibleScreens(treeItemIdAndScreenNo));
    }
    return () => {};
  }, [dispatch, isOnline, treeItemIdAndScreenNo]);

  const screenImages = useSelector(screenImagesSelector);
  const { imageUrl, isLoading = false, error } = useMemo(
    () => screenImages[treeItemIdAndScreenNo] ?? {},
    [screenImages, treeItemIdAndScreenNo]
  );

  let errorMessage: string | undefined = error ?? undefined;
  if (errorMessage === undefined) {
    if (!isOnline) {
      errorMessage = t('common:app.disconnected');
    } else if (!isLoading && !imageUrl) {
      errorMessage = t('common:app.previewUnavailable');
    }
  }
  return (
    <StyledPaper
      ref={ref}
      $width={width}
      $imageHeight={imageHeight}
    >
      <IconsContainer $width={width}>
        <ComputerIcon />
        <Tooltip title={name} arrow placement="top">
          <Name>
            {name}
          </Name>
        </Tooltip>
      </IconsContainer>
      <Screen
        isLoadingImage={isLoading && (!imageUrl && !error)}
        image={imageUrl ?? undefined}
        errorMessage={errorMessage}
        onScreenClick={() => {
          openModal();
        }}
      />
      <ControlIcons
        agentOrDeviceId={agentOrDeviceId}
        displayNo={displayNo}
      />
    </StyledPaper>
  );
};

export default ScreenPreviewTile;
