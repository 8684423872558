import React from 'react';


const SvgAddGroup = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 22 16"
    {...otherProps}
  >
    <defs>
      <path
        id="addGroup_svg__a"
        fillRule="evenodd"
        d="M5 4.571h8.286a1 1 0 011 1v4.858a1 1 0 01-1 1H5a1 1 0 01-1-1V5.57a1 1 0 011-1z"
      />
    </defs>
    <g fill="#2C3E50">
      <path d="M18.5 5h1v6h-1z" />
      <path d="M22 7.5v1h-6v-1z" />
    </g>
    <g fill="none">
      <path d="M0 0h13.714v13.714H0V0z" />
      <path
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.714 2.286H11a1 1 0 011 1v4.857a1 1 0 01-1 1H2.714a1 1 0 01-1-1V3.286a1 1 0 011-1zM5.143 9.143v2.286M8.571 9.143v2.286"
      />
    </g>
    <path fill="none" d="M2.286 2.286H16V16H2.286V2.286z" />
    <use fill="#FFF" xlinkHref="#addGroup_svg__a" />
    <use
      fillOpacity={0}
      stroke="#2C3E50"
      strokeDasharray="0 0 0 0"
      strokeLinecap="round"
      strokeLinejoin="round"
      xlinkHref="#addGroup_svg__a"
    />
    <path
      fill="none"
      stroke="#2C3E50"
      strokeDasharray="0 0 0 0"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.286 13.714h5.27M7.429 11.429v2.285M10.857 11.429v2.285"
    />
  </svg>
);

export default SvgAddGroup;
