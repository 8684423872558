import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';
import { useGetColorForControlState } from '@/hooks/useGetColorForControlState';
import { windowsUsersRolesWithStatusSelector } from '@/redux/collections/rolesCollection';
import ManageGroupStatusModal from '@/components/configuration/ManageGroupStatusModal';


const Role = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

const DotContainer = styled.span<{ $color: string }>`
  margin-right: 0.5rem;
  font-size: 0.75em;
  color: ${({ $color }) => $color}
`;

const ManageGroups = styled(Button)`
  width: fit-content;
  text-transform: none;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 10px 0;
`;

interface UserSpecificStatusProps {
  itemId: string,
  status: ControlState,
}
const UserSpecificStatus = ({ itemId, status }: UserSpecificStatusProps) => {
  const windowsRoles = useSelector(windowsUsersRolesWithStatusSelector(itemId));
  const getColorForControlState = useGetColorForControlState();
  const { t } = useTranslation(['common']);
  const [isManageStatusModalOpen, setIsManageStatusModalOpen] = useState(false);

  return (
    <>
      {(windowsRoles && status === ControlState.DEPENDS_ON_USER) && (
        <>
          {windowsRoles.map(role => (
            <Role key={role.id}>
              <DotContainer $color={getColorForControlState(role.status)}>
                {'\u2B24'}
              </DotContainer>
              <Typography variant="body2">
                {role.name}
              </Typography>
            </Role>
          ))}
          <ManageGroups onClick={() => setIsManageStatusModalOpen(true)}>
            {t('common:configuration.manageGroups')}
          </ManageGroups>
        </>
      )}
      <ManageGroupStatusModal
        open={isManageStatusModalOpen}
        onClose={() => setIsManageStatusModalOpen(false)}
        itemId={itemId}
      />
    </>
  );
};

export default UserSpecificStatus;
