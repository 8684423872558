import { useMemo } from 'react';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';


export function useIdsOfAllSelectedDevices() {
  const { selectedTreeItem } = useSelectedTreeItem();

  return useMemo(() => (selectedTreeItem?.type === TreeItemType.DevicesGroup
    ? selectedTreeItem.getAllDevices().map((item) => item.id)
    : [selectedTreeItem?.id]
  ), [selectedTreeItem]);
}
