/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as settings_service_pb from './settings_service_pb';


export class SettingsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    settings_service_pb.SettingsCollection,
    (request: settings_service_pb.GetSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_service_pb.SettingsCollection.deserializeBinary
  );

  getSettings(
    request: settings_service_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<settings_service_pb.SettingsCollection>;

  getSettings(
    request: settings_service_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: settings_service_pb.SettingsCollection) => void): grpcWeb.ClientReadableStream<settings_service_pb.SettingsCollection>;

  getSettings(
    request: settings_service_pb.GetSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: settings_service_pb.SettingsCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SettingsService/GetSettings',
        request,
        metadata || {},
        this.methodInfoGetSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SettingsService/GetSettings',
    request,
    metadata || {},
    this.methodInfoGetSettings);
  }

  methodInfoUpdateSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    settings_service_pb.SettingsGroup,
    (request: settings_service_pb.UpdateSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_service_pb.SettingsGroup.deserializeBinary
  );

  updateSettings(
    request: settings_service_pb.UpdateSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<settings_service_pb.SettingsGroup>;

  updateSettings(
    request: settings_service_pb.UpdateSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: settings_service_pb.SettingsGroup) => void): grpcWeb.ClientReadableStream<settings_service_pb.SettingsGroup>;

  updateSettings(
    request: settings_service_pb.UpdateSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: settings_service_pb.SettingsGroup) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SettingsService/UpdateSettings',
        request,
        metadata || {},
        this.methodInfoUpdateSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SettingsService/UpdateSettings',
    request,
    metadata || {},
    this.methodInfoUpdateSettings);
  }

  methodInfoGetUserSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    settings_service_pb.UserSettings,
    (request: settings_service_pb.GetUserSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_service_pb.UserSettings.deserializeBinary
  );

  getUserSettings(
    request: settings_service_pb.GetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<settings_service_pb.UserSettings>;

  getUserSettings(
    request: settings_service_pb.GetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: settings_service_pb.UserSettings) => void): grpcWeb.ClientReadableStream<settings_service_pb.UserSettings>;

  getUserSettings(
    request: settings_service_pb.GetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: settings_service_pb.UserSettings) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SettingsService/GetUserSettings',
        request,
        metadata || {},
        this.methodInfoGetUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SettingsService/GetUserSettings',
    request,
    metadata || {},
    this.methodInfoGetUserSettings);
  }

  methodInfoSetUserSettings = new grpcWeb.AbstractClientBase.MethodInfo(
    settings_service_pb.SetUserSettingsResponse,
    (request: settings_service_pb.SetUserSettingsRequest) => {
      return request.serializeBinary();
    },
    settings_service_pb.SetUserSettingsResponse.deserializeBinary
  );

  setUserSettings(
    request: settings_service_pb.SetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<settings_service_pb.SetUserSettingsResponse>;

  setUserSettings(
    request: settings_service_pb.SetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: settings_service_pb.SetUserSettingsResponse) => void): grpcWeb.ClientReadableStream<settings_service_pb.SetUserSettingsResponse>;

  setUserSettings(
    request: settings_service_pb.SetUserSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: settings_service_pb.SetUserSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.SettingsService/SetUserSettings',
        request,
        metadata || {},
        this.methodInfoSetUserSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.SettingsService/SetUserSettings',
    request,
    metadata || {},
    this.methodInfoSetUserSettings);
  }

}

