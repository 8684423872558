import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cell, Column } from 'react-table';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import ConfigurationTabContent from '@/components/configuration/ConfigurationTabContent';
import { urlCategoriesWithStatusSelector, UrlCategoryWithStatus } from '@/redux/collections/urlCategoriesCollection';
import ConfigTableLayout from '@/components/ConfigTableLayout';
import Filters from '@/components/configuration/Filters';
import useConfigTable from '@/components/ConfigTableLayout/useConfigTable';
import TableCellAccordion from '@/components/TableCellAccordion';
import ConfigCategoryModal from '@/components/configuration/ConfigCategoryModal';
import ActionIcons from '@/components/configuration/ConfigTableActionButtons';
import ConfirmationModal from '@/components/ConfirmationModal';
import { removeURLCategory } from '@/api/clients/categories';
import CategoryStatusSelect from '@/components/ConfigTableLayout/CategoryStatusSelect';
import FilterConfigModal from '@/components/FilterConfigModal';
import { ContentFilterType } from '@/api/owpb/pbFiles/filter_service_pb';


const ButtonsContainer = styled.div`
  display: flex;

  button {
    text-transform: none;
  }
`;

const UserCategories = () => {
  const { t } = useTranslation(['common']);
  const categories = useSelector(urlCategoriesWithStatusSelector);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [categoryToConfig, setCategoryToConfig] = useState<UrlCategoryWithStatus | undefined>();
  const [modalType, setModalType] = useState<ContentFilterType | null>(null);

  const data: UrlCategoryWithStatus[] = useMemo(() => categories, [categories]);

  const ActionButtons = () => (
    <>
      <ButtonsContainer>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsAddModalOpen(true)}
        >
          {t('common:configuration.addCategory')}
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setModalType(ContentFilterType.URL_FILTER);
          }}
        >
          {t('common:configuration.addUrl')}
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setModalType(ContentFilterType.PHRASE_FILTER);
          }}
        >
          {t('common:configuration.addWord')}
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setModalType(ContentFilterType.URL_REGEX_FILTER);
          }}
        >
          {t('common:configuration.addRegex')}
        </Button>
      </ButtonsContainer>
    </>
  );

  const columns: Array<Column<UrlCategoryWithStatus>> = React.useMemo(
    () => [
      {
        Header: t('common:configuration.categoryName'),
        id: 'name',
        width: 720,
        accessor: ({ id, name }) => ({ id, name }),
        Cell: ({ value, row }: Cell<UrlCategoryWithStatus>) => (
          <TableCellAccordion
            row={row}
            content={<Filters itemId={value.id} status={row.original.status} />}
          >
            {value.name}
          </TableCellAccordion>
        ),
      },
      {
        Header: t('common:common.status'),
        id: 'status',
        width: 240,
        Cell: ({ row }: Cell<UrlCategoryWithStatus>) => (
          <CategoryStatusSelect status={row.original.status} categoryId={row.original.id} />
        ),
        className: 'status',
      },
      {
        Header: '',
        id: 'actions',
        accessor: 'id',
        Cell: ({ row: { original } }: never) => (
          <ActionIcons
            center
            onEdit={() => {
              setCategoryToConfig(original);
              setIsEditModalOpen(true);
            }}
            onRemove={() => {
              setCategoryToConfig(original);
              setIsRemoveModalOpen(true);
            }}
          />
        ),
        width: 56,
        disableSortBy: true,
      },
    ],
    [t]
  );

  const tableInstance = useConfigTable<UrlCategoryWithStatus>({
    columns,
    data,
  });

  return (
    <>
      <ConfigurationTabContent
        title={t('common:userCategories')}
        actionButtons={<ActionButtons />}
      >
        <ConfigTableLayout<UrlCategoryWithStatus>
          tableInstance={tableInstance}
        />
      </ConfigurationTabContent>
      <ConfigCategoryModal
        variant="add"
        open={isAddModalOpen}
        handleClose={() => {
          setIsAddModalOpen(false);
        }}
      />
      <ConfigCategoryModal
        variant="edit"
        open={isEditModalOpen}
        initialValue={categoryToConfig}
        handleClose={() => {
          setIsEditModalOpen(false);
        }}
      />
      <ConfirmationModal
        open={isRemoveModalOpen}
        content={(
          <Trans
            i18nKey="common:categoryRemovalInfo"
            values={{ categoryName: categoryToConfig?.name ?? '' }}
          />
        )}
        handleClose={() => setIsRemoveModalOpen(false)}
        handleConfirm={() => {
          removeURLCategory(categoryToConfig?.id ?? '');
          setIsRemoveModalOpen(false);
        }}
      />
      {modalType !== null && (
        <FilterConfigModal
          open
          variant="add"
          filterType={modalType}
          handleClose={() => {
            setModalType(null);
          }}
        />
      )}
    </>
  );
};


export default UserCategories;
