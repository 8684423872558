import React from 'react';
import { Radio } from '@material-ui/core';
import { useField } from 'react-final-form';
import { PaymentType } from '@/api/owpb/pbFiles/subscription_service_pb';
import { FFBaseProps } from './types';


interface FFRadioProps extends FFBaseProps<string | null | PaymentType> {
  disabled?: boolean,
}

const FFRadio = ({
  name,
  config,
  disabled,
  ...otherProps
}: FFRadioProps) => {
  const { input } = useField(name, config);

  return (
    <Radio
      color="primary"
      name={name}
      onChange={input.onChange}
      checked={input.checked}
      value={input.value}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default FFRadio;
