import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Typography } from '@material-ui/core';
import {
  CardCvcElement, CardExpiryElement, CardNumberElement,
} from '@stripe/react-stripe-js';
import styled, { useTheme } from 'styled-components';
import { useField, useForm } from 'react-final-form';
import FFRadio from '@/components/finalForm/FFRadio';
import { PaymentType } from '@/api/owpb/pbFiles/subscription_service_pb';
import { przelewy24SupportedCurrencies } from '@/constants/common';


const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  margin: 20px 0 5px;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

const AdditionalInfo = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdditionalInfoElement = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 25px;
  }
`;

const RadioFormControlLabel = styled(FormControlLabel)`
  width: 100%;
`;

const StripeElementWrapper = styled.div`
  padding: 6px 0 7px;
  border-bottom: ${({ theme }) => theme.darkBorder};
`;

const CardPaymentInfo = styled(Typography)`
  margin-top: 20px;
`;

interface CheckoutPaymentMethodProps {
  selectedCurrency?: string,
}

const CheckoutPaymentMethod = ({ selectedCurrency }: CheckoutPaymentMethodProps) => {
  const { t } = useTranslation(['common']);
  const field = useField('paymentMethod');
  const theme = useTheme();
  const { change } = useForm();

  const StripeElementStyles = {
    style: {
      base: {
        fontSize: '16px',
        color: theme.palette.primary.dark,
      },
    },
  };

  const shouldShowP24 = useCallback(() => przelewy24SupportedCurrencies
    .find(c => c === selectedCurrency), [selectedCurrency]);


  useEffect(() => {
    if (!shouldShowP24()) {
      change('paymentMethod', undefined);
    }
  }, [change, selectedCurrency, shouldShowP24]);


  return (
    <>
      <RadioFormControlLabel
        control={(
          <FFRadio
            name="paymentMethod"
            disabled={!shouldShowP24()}
            config={{
              type: 'radio',
              value: PaymentType.PAYMENT_BY_P24.toString(),
            }}
          />
        )}
        label={t('common:checkout.transfer', { unavailableInfo: !shouldShowP24() ? t('common:checkout.transferUnavailable') : undefined })}
      />
      <RadioFormControlLabel
        control={(
          <FFRadio
            name="paymentMethod"
            config={{
              type: 'radio',
              value: PaymentType.PAYMENT_BY_CARD.toString(),
            }}
          />
        )}
        label={t('common:checkout.card')}
      />
      <RadioFormControlLabel
        control={(
          <FFRadio
            name="paymentMethod"
            config={{
              type: 'radio',
              value: PaymentType.PAYMENT_BY_PRO_FORMA_INVOICE.toString(),
            }}
          />
        )}
        label={t('common:checkout.invoice')}
      />
      {field.input.value === PaymentType.PAYMENT_BY_PRO_FORMA_INVOICE.toString() && (
        <Typography variant="body2">
          {t('common:checkout.invoiceInfo')}
        </Typography>
      )}
      {field.input.value === PaymentType.PAYMENT_BY_CARD.toString() && (
        <CardInfo>
          <StyledLabel htmlFor="cardNo">
            {`${t('common:checkout.cardNo')}*`}
          </StyledLabel>
          <StripeElementWrapper id="cardNo">
            <CardNumberElement
              options={StripeElementStyles}
            />
          </StripeElementWrapper>
          <AdditionalInfo>
            <AdditionalInfoElement>
              <StyledLabel htmlFor="cardExp">
                {`${t('common:checkout.cardExp')}*`}
              </StyledLabel>
              <StripeElementWrapper id="cardExp">
                <CardExpiryElement
                  options={StripeElementStyles}
                />
              </StripeElementWrapper>
            </AdditionalInfoElement>
            <AdditionalInfoElement>
              <StyledLabel htmlFor="cardExp">
                {`${t('common:checkout.cardCVC')}*`}
              </StyledLabel>
              <StripeElementWrapper id="cardCVC">
                <CardCvcElement
                  options={StripeElementStyles}
                />
              </StripeElementWrapper>
            </AdditionalInfoElement>
          </AdditionalInfo>
          <CardPaymentInfo variant="caption">
            {t('common:checkout.cardPaymentInfo')}
          </CardPaymentInfo>
        </CardInfo>
      )}
      {field.input.value === PaymentType.PAYMENT_BY_P24.toString() && (
        <>
          <Typography variant="body2">
            {t('common:checkout.transferInfo')}
          </Typography>
        </>
      )}
    </>
  );
};

export default CheckoutPaymentMethod;
