import React, { ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';


const StyledTreeItem = styled(TreeItem)<{ $isActive: boolean }>`
  > div {
    padding: 10px;
    background-color: ${({
    $isActive,
    theme,
  }) => ($isActive ? 'rgba(53, 121, 245, 0.16)' : theme.palette.common.white)};
  }

  color: ${({ theme }) => theme.palette.primary.dark};
`;

const Wrapper = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : theme.palette.primary.dark)};
  text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  cursor: default;

  & > p {
    margin: 0 5px;
  }

  & > svg {
    cursor: pointer;
  }
`;

export interface TreeNodeProps {
  node: DevicesGroupItem | DeviceItem,
  selectedItem: string | null,
  onSelect: (event: ChangeEvent<{}>, value: string) => void,
}

const MiniTreeNode = ({
  node,
  selectedItem,
  onSelect,
}: TreeNodeProps) => {
  const areChildrenGroups = (Array.isArray(node.children)
    ? (node.children as []).some((child: DevicesGroupItem) => child.isGroup)
    : false);

  const data = useMemo(() => (node as DevicesGroupItem).getSortedChildren(null, null), [node]);

  if (node.isGroup) {
    return (
      <StyledTreeItem
        $isActive={selectedItem === node.id}
        nodeId={node.id}
        key={node.id}
        label={(
          <Wrapper
            $isActive={selectedItem === node.id}
            onClick={(e) => onSelect(e, node.id)}
          >
            <Typography>
              {node.name}
            </Typography>
            {selectedItem === node.id && (
              <CheckIcon fontSize="small" />
            )}
          </Wrapper>
        )}
        // prevents rolling up on when element is clicked
        onLabelClick={(event) => {
          event.preventDefault();
        }}
      >
        {areChildrenGroups && data.map((childNode) => (
          <MiniTreeNode
            key={childNode.id}
            node={childNode as DevicesGroupItem | DeviceItem}
            selectedItem={selectedItem}
            onSelect={onSelect}
          />
        ))}
      </StyledTreeItem>
    );
  }

  return null;
};

export default MiniTreeNode;
