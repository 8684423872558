import React from 'react';
import { TableInstance } from 'react-table';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


const StyledTable = styled(Table)<{ $roleStatusLayout?: boolean}>`
  td {
    text-align: left;
    color: ${({ theme, $roleStatusLayout }) => (!$roleStatusLayout ? theme.palette.secondary.dark : theme.palette.common.black)};
  }
  td:last-child {
    text-align: ${({ $roleStatusLayout }) => ($roleStatusLayout ? 'left' : 'right')};
  }
`;

const ConfigSubTableCell = styled(TableCell)`
  border-color: ${({ theme }) => theme.palette.divider};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 3px 10px;
`;


interface ConfigSubTableLayoutProps<T extends Record<string, unknown>> {
  tableInstance: TableInstance<T>,
  roleStatusLayout?: boolean,
}

const ConfigSubTableLayout = <T extends Record<string, unknown>>({
  tableInstance,
  roleStatusLayout,
}: ConfigSubTableLayoutProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <StyledTable {...getTableProps()} $roleStatusLayout={roleStatusLayout}>
      <TableBody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <ConfigSubTableCell
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </ConfigSubTableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default ConfigSubTableLayout;
