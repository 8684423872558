import { screenPreviewMinWidth, screenProportions } from '@/constants/common';


// calculate column layout to adjust for screen preview minimum width
function CalculateColumnLayoutForMinWidth(
  containerWidth: number,
  gridGap: number,
): adminify.ScreenPreviewColumnLayout {
  const numberOfColumns = Math.floor(
    (containerWidth + gridGap) / (screenPreviewMinWidth + gridGap)
  );
  const columnWidth = ((containerWidth + gridGap) / numberOfColumns) - gridGap;
  return { numberOfColumns, columnWidth };
}

// calculate column layout to display specified number of columns
export function CalculateColumnLayoutForNumberOfColumns(
  containerWidth: number,
  numberOfColumns: number,
  gridGap: number,
): adminify.ScreenPreviewColumnLayout {
  const columnWidth = ((containerWidth + gridGap) / numberOfColumns) - gridGap;
  if (columnWidth < screenPreviewMinWidth) {
    return CalculateColumnLayoutForMinWidth(containerWidth, gridGap);
  }
  return { numberOfColumns, columnWidth };
}

// calculate column layout to fit all previews on screen
export function CalculateColumnLayoutForAutoTileSize(
  containerWidth: number,
  containerHeight: number,
  numberOfElements: number,
  gridGap: number,
  barHeight: number,
): adminify.ScreenPreviewColumnLayout {
  let optimalNumberOfColumns = 1;
  let optimalWidth = 0;
  for (let i = 1; i <= numberOfElements; i += 1) {
    const columns = i;
    const rows = Math.ceil(numberOfElements / columns);
    const maxWidthBasedOnColumns = ((containerWidth + gridGap) / columns) - gridGap;
    const maxWidthBasedOnRows = (((containerHeight + gridGap) / rows) - gridGap - 2 * barHeight)
      * screenProportions;
    const calculatedWith = Math.min(maxWidthBasedOnRows, maxWidthBasedOnColumns);
    if (optimalWidth < calculatedWith) {
      optimalNumberOfColumns = i;
      optimalWidth = calculatedWith;
    }
  }
  if (optimalWidth < screenPreviewMinWidth) {
    return CalculateColumnLayoutForMinWidth(containerWidth, gridGap);
  }
  return { numberOfColumns: optimalNumberOfColumns, columnWidth: optimalWidth };
}
