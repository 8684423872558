import * as React from 'react';


const SvgActiveComputer = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <defs>
      <circle id="activeComputer_svg__a" cx={3} cy={12} r={3} />
      <mask
        id="activeComputer_svg__b"
        x={0}
        y={0}
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16H0z" />
        <use fill="#fff" xlinkHref="#activeComputer_svg__a" />
      </mask>
    </defs>
    <g fill="none">
      <path d="M0 0h16v16H0V0z" />
      <path
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3h10a1 1 0 011 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1zM4.712 13.667h6.576M10 11v2.667M6 11v2.667"
      />
    </g>
    <use fill="#72E373" xlinkHref="#activeComputer_svg__a" />
    <use
      fillOpacity={0}
      stroke="#2C3E50"
      strokeWidth={1.8}
      mask="url(#activeComputer_svg__b)"
      xlinkHref="#activeComputer_svg__a"
    />
  </svg>
);

export default SvgActiveComputer;
