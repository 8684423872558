import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { AppHistoryItem } from '@/api/owpb/pbFiles/history_service_pb';
import { appDateTimeFormat } from '@/constants/common';
import { getFullTimeFromSeconds } from '@/utils/helpers';


export function useAppHistoryColumns() {
  const { t } = useTranslation(['common']);
  const columns: Array<Column<AppHistoryItem.AsObject>> = useMemo(() => ([
    {
      Header: t('common:common.date'),
      id: 'timestamp',
      accessor: ({ timestamp }) => dayjs.unix(timestamp).format(appDateTimeFormat),
    },
    {
      Header: t('common:common.app'),
      id: 'exeDescription',
      accessor: 'exeDescription',
    },
    {
      Header: t('common:common.file'),
      id: 'exeName',
      accessor: 'exeName',
    },
    {
      Header: t('common:common.title'),
      id: 'title',
      accessor: 'title',
    },
    {
      Header: t('common:common.address'),
      id: 'url',
      accessor: 'url',
    },
    {
      Header: t('common:common.computer'),
      id: 'deviceName',
      accessor: 'deviceName',
    },
    {
      Header: t('common:common.user'),
      id: 'userName',
      accessor: 'userName',
    },
    {
      Header: t('common:common.duration'),
      id: 'duration',
      accessor: ({ duration }) => (duration < 60 ? `${duration}s` : getFullTimeFromSeconds(duration)),
    },
    {
      Header: t('common:common.domain'),
      id: 'domainName',
      accessor: 'domainName',
    },
  ]), [t]);
  return columns;
}
// skipped properties:
// id: string,
// customerId: string,
// deviceId: string,
// categoryId: string,
// itemFlags: number,
// stateFlags: number,
// roleId: string,
