import {
  AppBar, CssBaseline, Toolbar, Typography, Button,
} from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { urlStructure } from '@/constants/urlStructure';
import SvgLogo from '@/components/fromSvg/Logo';
import WelcomeScreen from '@/components/WelcomeScreen';
import NotyContainer from '@/components/NotyContainer';
import { appHeaderHeight } from '@/constants/common';
import { authDataSelector, loginSelector } from '@/redux/user';
import { CustomerNameAndId } from '@/api/owpb/pbFiles/auth_service_pb';
import UserContextMenu from '@/components/UserContextMenu';
import { roleUsersCollectionSelectors } from '@/redux/collections/roleUsersCollection';
import { userPermissionsSelector } from '@/redux/userPermissions';
import { setShownWelcomeScreen, shownWelcomeScreenSelector } from '@/redux/layout';
import treeService from '@/TreeService';
import { ApiConsts } from '@/api/ApiConsts';
import PermissionsControl from '@/components/PermissionsControl';


const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: ${appHeaderHeight}px;
`;

const StyledToolbar = styled(Toolbar)`
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const AdminifyLogo = styled(SvgLogo)`
  width: 200px;
  height: auto;
`;

const NavLinks = styled.section`
  width: fit-content;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const StyledLink = styled(NavLink)<{ color?: string }>`
  color: ${({ theme, color }) => (!color ? theme.palette.primary.dark : theme.palette.primary.main)};
  margin: 0 20px;
  text-decoration: none;
  font-size: 1.3em;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-self: end;
`;


const ProfileButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-transform: none;
  padding-bottom: 0;

  :hover {
    background: transparent;
  }

  .MuiTouchRipple-root {
    display: none;
  }
`;

const CompanyName = styled(Typography)`
  margin-right: 32px;
`;

const Username = styled(Typography)`
  margin-right: 32px;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 1em;
`;

export interface AppLayoutProps {
  children: React.ReactNode,
}

const AppLayout = ({
  children,
}: AppLayoutProps) => {
  const theme = useContext(ThemeContext);
  const auth = useSelector(authDataSelector);
  const login = useSelector(loginSelector);
  const permissions = useSelector(userPermissionsSelector);
  const shownWelcomeScreen = useSelector(shownWelcomeScreenSelector);
  const allUsers = useSelector(roleUsersCollectionSelectors.selectAll);
  const user = allUsers.find(u => u.identy === login);
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const customerName = auth?.customersIdList.find(
    (customer: CustomerNameAndId.AsObject) => customer.customerId === auth.customerId
  )?.keyName;
  const [noDevices, setNoDevices] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setNoDevices(!permissions.isAdmin
      && treeService.devicesCollection.items.length === 0);
  }, [permissions]);

  const [isDisplayingWelcomeScreen, setIsDisplayingWelcomeScreen] = useState(
    !permissions.isAdmin
    && treeService.devicesCollection.items.length === 0
  );


  return (
    <div>
      <NotyContainer />
      <CssBaseline />
      <StyledAppBar position="relative" elevation={0}>
        <StyledToolbar>
          <AdminifyLogo />
          <NavLinks>
            <StyledLink
              to={urlStructure.accessControl}
              activeStyle={{ color: theme.palette.primary.main }}
            >
              {t('common:app.nav.accessControl')}
            </StyledLink>
            <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
              <StyledLink
                to={urlStructure.configuration}
                activeStyle={{ color: theme.palette.primary.main }}
              >
                {t('common:app.nav.config')}
              </StyledLink>
            </PermissionsControl>
          </NavLinks>
          <User>
            <ProfileButton
              endIcon={<ExpandMoreIcon />}
              onClick={
                (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
              }
            >
              <Typography variant="subtitle1">
                {login}
              </Typography>
            </ProfileButton>
            {(user && user.name !== login) && (
              <Username>
                {user.name}
              </Username>
            )}
            <CompanyName variant="caption">
              {customerName}
            </CompanyName>
          </User>
          {anchorEl && (
            <UserContextMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
          )}
        </StyledToolbar>
      </StyledAppBar>
      {children}
      {(noDevices && !shownWelcomeScreen) && (
        <WelcomeScreen
          isOpen={isDisplayingWelcomeScreen}
          onClose={() => {
            setIsDisplayingWelcomeScreen(false);
            dispatch(setShownWelcomeScreen(true));
          }}
        />
      )}
    </div>
  );
};


export default AppLayout;
