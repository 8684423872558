import React from 'react';
import { Dialog, Button, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ModalContent from './ModalContent';


const Controls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const ConfirmButton = styled(Button)<{ $isPrimary: boolean }>`
  background-color: ${({ theme, $isPrimary }) => (!$isPrimary ? theme.palette.error.main : theme.palette.primary.main)};
  color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.darkBorder};
  margin-right: 10px;
  width: 100%;
`;

const StyledDialogTitle = styled(DialogTitle)<{ $isWarning: boolean }>`
  color: ${({ theme, $isWarning }) => ($isWarning ? theme.palette.error.main : theme.palette.common.black)};
`;

export interface ConfirmationModalProps {
  open: boolean,
  title?: string,
  isWarning?: boolean,
  content: string | React.ReactNode,
  handleClose?: () => void,
  handleConfirm: () => void,
  confirmButtonText?: string,
  hideCancelBtn?: boolean,
  isPrimary?: boolean,
}

const ConfirmationModal = ({
  open,
  title,
  isWarning = false,
  content,
  handleClose,
  handleConfirm,
  confirmButtonText,
  hideCancelBtn = false,
  isPrimary = false,
  ...otherProps
}: ConfirmationModalProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog open={open} {...otherProps}>
      <StyledDialogTitle $isWarning={isWarning}>
        {title}
      </StyledDialogTitle>
      <ModalContent>
        {content}
        <Controls>
          {!hideCancelBtn && (
            <CancelButton onClick={handleClose}>
              {t('common.cancel')}
            </CancelButton>
          )}
          <ConfirmButton onClick={handleConfirm} $isPrimary={isPrimary}>
            {confirmButtonText ?? t('common:common.ok')}
          </ConfirmButton>
        </Controls>
      </ModalContent>
    </Dialog>
  );
};

export default ConfirmationModal;

