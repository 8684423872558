import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Cell, Column } from 'react-table';
import ConfigurationTabContent from '@/components/configuration/ConfigurationTabContent';
import Email from '@/components/fromSvg/Email';
import { Role } from '@/api/owpb/pbFiles/roles_service_pb';
import ConfigTableLayout from '@/components/ConfigTableLayout';
import UsersSubTable from '@/components/configuration/UsersSubTable';
import ConfigUserModal from '@/components/ConfigUserModal';
import { apiUsersRolesSelector } from '@/redux/collections/rolesCollection';
import useConfigTable from '@/components/ConfigTableLayout/useConfigTable';
import TableCellAccordion from '@/components/TableCellAccordion';


const ButtonsContainer = styled.div`
  display: flex;

  button {
    text-transform: none;
  }
`;

const ConfigurationUsers = () => {
  const { t } = useTranslation(['common']);
  const apiRoles = useSelector(apiUsersRolesSelector);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const ActionButtons = () => (
    <>
      <ButtonsContainer>
        <Button
          startIcon={<Email />}
          onClick={() => setIsInviteUserModalOpen(true)}
        >
          {t('common:configuration.inviteNewUser')}
        </Button>
      </ButtonsContainer>
      <Typography variant="caption">
        {t('common:configuration.toManageGroupsUseConsoleVersion')}
      </Typography>
    </>
  );

  const columns: Array<Column<Role.AsObject>> = React.useMemo(
    () => [
      {
        Header: t('common:app.groupName'),
        id: 'name',
        accessor: ({ id, name }) => ({ id, name }),
        Cell: ({ value, row }: Cell<Role.AsObject>) => (
          <TableCellAccordion
            row={row}
            content={<UsersSubTable itemId={value.id} />}
          >
            {value.name}
          </TableCellAccordion>
        ),
      },
    ],
    [t]
  );

  const tableInstance = useConfigTable<Role.AsObject>({
    columns,
    data: apiRoles,
  });

  return (
    <>
      <ConfigurationTabContent
        title={t('common:configuration.appAndConsoleUsers')}
        actionButtons={<ActionButtons />}
      >
        <ConfigTableLayout<Role.AsObject>
          tableInstance={tableInstance}
        />
      </ConfigurationTabContent>
      <ConfigUserModal
        open={isInviteUserModalOpen}
        handleClose={() => setIsInviteUserModalOpen(false)}
        variant="add"
      />
    </>
  );
};

export default ConfigurationUsers;
