import React from 'react';


const SvgUnblockAll = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.333 10.333a1.5 1.5 0 00-1.5 1.5V14.5a1.5 1.5 0 001.5 1.5h5.333a1.5 1.5 0 001.5-1.5v-2.667a1.5 1.5 0 00-1.5-1.5H7.333zm-2.5 1.5c0-1.38 1.12-2.5 2.5-2.5h5.333a2.5 2.5 0 012.5 2.5V14.5a2.5 2.5 0 01-2.5 2.5H7.333a2.5 2.5 0 01-2.5-2.5v-2.667z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13a.167.167 0 100 .334.167.167 0 000-.334zm-1.167.167a1.167 1.167 0 112.334 0 1.167 1.167 0 01-2.334 0zM10 4.333c-1.197 0-2.167.97-2.167 2.167v3.333a.5.5 0 01-1 0V6.5a3.167 3.167 0 116.333 0 .5.5 0 11-1 0c0-1.197-.97-2.167-2.166-2.167z"
      fill="#2C3E50"
    />
  </svg>
);

export default SvgUnblockAll;
