import {
  ControlState,
  DeviceGroupsSelectMode,
  AppControlState,
  InetControlState,
} from '@/api/owpb/pbFiles/basic_pb';


export function stringToDeviceGroupsSelectMode(str: string): DeviceGroupsSelectMode {
  switch (str) {
    case '1':
      return DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_ALL;
    case '2':
      return DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_DEPEND_ON_AGENT_GROUP;
    case '3':
      return DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_ONE_GROUP_BY_USER;
    default:
      return DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_MODE_NONE;
  }
}

export function stringToControlState(str: string): ControlState {
  switch (str) {
    case '1':
      return ControlState.BLOCK;
    case '2':
      return ControlState.ALLOW;
    case '3':
      return ControlState.DEPENDS_ON_USER;
    default:
      return ControlState.CHECK;
  }
}

export function controlStateToString(controlState: ControlState): string {
  switch (controlState) {
    case ControlState.CHECK:
      return '0';
    case ControlState.BLOCK:
      return '1';
    case ControlState.ALLOW:
      return '2';
    case ControlState.DEPENDS_ON_USER:
      return '3';
    default:
      return '0';
  }
}

export function stringToAppControlState(s: string): AppControlState {
  switch (s) {
    case '0': {
      return AppControlState.APP_CHECK;
    }
    case '1': {
      return AppControlState.APP_BLOCK;
    }
    case '2': {
      return AppControlState.APP_ALLOW;
    }
    case '4': {
      return AppControlState.APP_STRICT_TO_DESKTOP;
    }
    case '5': {
      return AppControlState.APP_USE_BLACK_LIST;
    }
    case '6': {
      return AppControlState.APP_USE_WHITE_LIST;
    }
    default:
      return AppControlState.APP_ALLOW;
  }
}

export function stringToInetControlState(s: string): InetControlState {
  switch (s) {
    case '0': {
      return InetControlState.INET_CHECK;
    }
    case '1': {
      return InetControlState.INET_BLOCK;
    }
    case '2': {
      return InetControlState.INET_ALLOW;
    }
    case '4': {
      return InetControlState.INET_STRICT;
    }
    default:
      return InetControlState.INET_CHECK;
  }
}
