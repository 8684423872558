import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import RemoveIcon from '@/components/fromSvg/RemoveIcon';
import SvgTable from '@/components/fromSvg/SvgTable';
import SettingsButton from '@/components/SettingsButton';
import RemoveHistoryModal from '@/components/RemoveHistoryModal';


const RemoveButton = styled(Button)`
  text-transform: none;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface BrowsingHistorySettingsProps {
  openColumnSelectionDialog: () => void,
  isEmpty: boolean,
}

const AppHistorySettings = ({
  openColumnSelectionDialog,
  isEmpty,
}: BrowsingHistorySettingsProps) => {
  const { t } = useTranslation(['common']);
  const [removeHistoryModalOpen, setRemoveHistoryModalOpen] = useState(false);

  return (
    <SettingsContainer>
      <RemoveButton
        onClick={() => setRemoveHistoryModalOpen(true)}
        startIcon={<RemoveIcon />}
        disabled={isEmpty}
      >
        {t('common:deleteHistory')}
      </RemoveButton>
      <SettingsButton
        onClick={openColumnSelectionDialog}
        startIcon={<SvgTable />}
        disabled={isEmpty}
      >
        {t('common:app.customizeTable')}
      </SettingsButton>
      <RemoveHistoryModal
        infoTitle={t('common:deleteStatisticsHistoryTitle')}
        infoMsg={(
          <Trans
            i18nKey="common:deleteHistoryInfo"
            components={[
              <ul>
                {[
                  t('common:deletedHistoryData.appStatistics'),
                  t('common:deletedHistoryData.internetDomainsStatistics'),
                  t('common:deletedHistoryData.detailedApplicationHistory'),
                ].map(removedElements => (
                  <li key={removedElements}>
                    {removedElements}
                  </li>
                ))}
              </ul>,
            ]}
          />
        )}
        isOpen={removeHistoryModalOpen}
        onClose={() => setRemoveHistoryModalOpen(false)}
        variant="appHistory"
      />
    </SettingsContainer>
  );
};

export default AppHistorySettings;
