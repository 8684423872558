import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormControlLabel, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useQuery } from 'react-query';
import FFCheckbox from '@/components/finalForm/FFCheckbox';
import FFTextInput from '@/components/finalForm/FFTextInput';
import FFColumnLayout from '@/components/finalForm/FFColumnLayout';
import { InvoiceRecipient } from '@/api/owpb/pbFiles/customer_service_pb';
import { getSystemInfo } from '@/api/clients/systemInfo';


export interface CheckoutPaymentAndInvoiceDataFormFields {
  buyerAndPayerDataIsTheSame: boolean,
  buyer: InvoiceRecipient.AsObject,
  payer?: InvoiceRecipient.AsObject,
}

interface InvoiceInfoProps {
  name: string,
}

const InvoiceInfo = ({ name }: InvoiceInfoProps) => {
  const { t } = useTranslation(['common']);
  const { data: systemInfo } = useQuery('getSystemInfo', getSystemInfo);

  return (
    <>
      <FFColumnLayout>
        <FFTextInput
          label={t('common:paymentData.companyName')}
          name={`${name}.name`}
        />
        <FFTextInput
          label={t('common:paymentData.taxId')}
          name={`${name}.taxId`}
        />
        <FFTextInput
          label={t('common:paymentData.country')}
          name={`${name}.country`}
          type="select"
          select
        >
          {systemInfo && systemInfo.countriesList.map((country) => (
            <MenuItem value={country.code} key={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </FFTextInput>
        <FFTextInput
          label={t('common:paymentData.address')}
          name={`${name}.addressLine1`}
        />
        <FFTextInput
          label={t('common:paymentData.addressContinued')}
          name={`${name}.addressLine2`}
        />
      </FFColumnLayout>
      <FFColumnLayout columns={2}>
        <FFTextInput
          label={t('common:paymentData.postalCode')}
          name={`${name}.postalCode`}
        />
        <FFTextInput
          label={t('common:paymentData.city')}
          name={`${name}.city`}
        />
      </FFColumnLayout>
    </>
  );
};

const Container = styled.div`
  margin-top: 40px;
`;

interface CheckoutPaymentAndInvoiceDataProps {
  buyerAndPayerDataIsTheSame: boolean,
}

const CheckoutPaymentAndInvoiceData = ({ buyerAndPayerDataIsTheSame }:
CheckoutPaymentAndInvoiceDataProps) => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <FFColumnLayout>
        <FormControlLabel
          control={(
            <FFCheckbox
              color="primary"
              name="buyerAndPayerDataIsTheSame"
              config={{
                type: 'checkbox',
              }}
            />
          )}
          label={t('common:paymentData.paymentAndInvoiceDataIsTheSame')}
        />
      </FFColumnLayout>
      <InvoiceInfo
        name="buyer"
      />
      { !buyerAndPayerDataIsTheSame && (
        <Container>
          <Typography variant="body1">
            {t('common:paymentData.invoiceData')}
          </Typography>
          <InvoiceInfo
            name="payer"
          />
        </Container>
      )}
    </>
  );
};

export default CheckoutPaymentAndInvoiceData;
