import styled from 'styled-components';


export interface ResizeHandleProps {
  $isResizing?: boolean,
}

const ResizeHandle = styled.div<ResizeHandleProps>`
  height: 100%;
  width: 5px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: w-resize;

  transition: background-color 150ms ease-in-out 100ms;

  background: ${({ $isResizing, theme }) => (
    $isResizing ? theme.palette.primary.main : 'transparent'
  )};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default ResizeHandle;
