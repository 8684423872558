import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDevicesTree } from '@/hooks/useDevicesTree';
import {
  expandedTreeNodesSelector,
  selectedTreeItemSelector,
  setExpandedTreeNodes,
  setSelectedTreeItem,
} from '@/redux/appOptions';
import TreeService from '@/TreeService';


export function usePrepareApp() {
  const dispatch = useDispatch();
  const { tree } = useDevicesTree();


  const selectedTreeItem = useSelector(selectedTreeItemSelector);
  const [isSelectedItemReady, setIsSelectedItemReady] = useState<boolean>(false);
  useEffect(() => {
    if (tree.length === 0) {
      return () => undefined;
    }
    // Jeżeli użytkownik loguje się pierwszy raz
    // to nie będzie miał zapisanej w backendzie wybranej grupy
    // Wtedy wybieramy pierwsza TreeItem w drzewie
    if (selectedTreeItem === null) {
      dispatch(setSelectedTreeItem(tree[0].id));
    // Gdy jednak mam wybrany jakiś TreeItem to sprawdzamy czy jest on w drzewie,
    // jeżeli go nie ma to znowu wypieramy pierwszy TreeItem
    } else {
      const isSelectedItemInTree = TreeService.devicesTree.any(
        (item) => (item.id === selectedTreeItem)
      );
      if (!isSelectedItemInTree) {
        dispatch(setSelectedTreeItem(tree[0].id));
      }
    }
    setIsSelectedItemReady(true);
    return () => undefined;
  }, [dispatch, selectedTreeItem, tree]);

  const [firstRun, setFirstRun] = useState<boolean>(true);
  const expandedTreeNodes = useSelector(expandedTreeNodesSelector);
  const [areExpandedNodesReady, setAreExpandedNodesReady] = useState<boolean>(false);
  useEffect(() => {
    if (tree.length === 0) {
      return () => undefined;
    }
    // Przy pierwszym uruchomieniu
    if (firstRun) {
      // Sprawdzamy czy są jakieś rozwinięte grupy w drzewie
      if (expandedTreeNodes.length === 0) {
        // Jeżeli nie to rozwijamy pierwszą grupę
        dispatch(setExpandedTreeNodes([tree[0].id]));
      } else {
        // Gdy coś jest rozwinięte to sprawdzamy czy te grupy dalej istnieją,
        // jeżeli choć jedna grupa nie istnieje to rozwijamy tylko pierwszą grupę.
        const expandedTreeItemsCount = TreeService.devicesTree.count(
          (item) => expandedTreeNodes.includes(item.id)
        );
        if (expandedTreeItemsCount !== expandedTreeNodes.length) {
          dispatch(setExpandedTreeNodes([tree[0].id]));
        }
      }
      setAreExpandedNodesReady(true);
    }
    setFirstRun(false);
    return () => undefined;
  }, [dispatch, expandedTreeNodes, firstRun, tree]);

  return isSelectedItemReady && areExpandedNodesReady;
}
