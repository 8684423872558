import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ControlState, InetControlState, AppControlState } from '@/api/owpb/pbFiles/basic_pb';
import { PlanType } from '@/api/owpb/pbFiles/customer_pb';
import { PaymentType, PriceInterval } from '@/api/owpb/pbFiles/subscription_service_pb';
import BlockComputer from '@/components/fromSvg/deviceLockIcons/BlockComputer';
import BlockKeyboard from '@/components/fromSvg/deviceLockIcons/BlockKeyboard';
import UnblockAll from '@/components/fromSvg/deviceLockIcons/UnblockAll';
import ContentControl from '@/components/fromSvg/internetAccessIcons/ContentControl';
import FullAccess from '@/components/fromSvg/internetAccessIcons/FullAccess';
import InternetBlockage from '@/components/fromSvg/internetAccessIcons/InternetBlockage';
import MultipleAccessSettings from '@/components/fromSvg/internetAccessIcons/MultipleAccessSettings';
import Restrictive from '@/components/fromSvg/internetAccessIcons/Restrictive';
import i18n from '@/utils/i18n';
import AppAllow from '@/components/fromSvg/appAccessIcons/AppAllow';
import AppRestrictive from '@/components/fromSvg/appAccessIcons/AppRestrictive';
import AppBlock from '@/components/fromSvg/appAccessIcons/AppBlock';
import AppControl from '@/components/fromSvg/appAccessIcons/AppControl';
import AppAccessMultipleSettings from '@/components/fromSvg/appAccessIcons/AppAccessMultipleSettings';
import { ApiConsts } from '@/api/ApiConsts';


export function inetControlStateToIcon(state: InetControlState | undefined): React.ReactNode {
  switch (state) {
    case InetControlState.INET_CHECK: {
      return <ContentControl />;
    }
    case InetControlState.INET_ALLOW: {
      return <FullAccess />;
    }
    case InetControlState.INET_BLOCK: {
      return <InternetBlockage />;
    }
    case InetControlState.INET_STRICT: {
      return <Restrictive />;
    }
    case undefined:
    default: {
      return <MultipleAccessSettings />;
    }
  }
}

export function appAccessToIcon(value: AppControlState | undefined): React.ReactNode {
  switch (value) {
    case AppControlState.APP_ALLOW: {
      return <AppAllow />;
    }
    case AppControlState.APP_BLOCK: {
      return <AppBlock />;
    }
    case AppControlState.APP_CHECK: {
      return <AppControl />;
    }
    case AppControlState.APP_STRICT_TO_DESKTOP: {
      return <AppRestrictive />;
    }
    case undefined:
    default: {
      return <AppAccessMultipleSettings />;
    }
  }
}

export function deviceLockToIcon(value: number | undefined): React.ReactNode {
  switch (value) {
    default:
    case ApiConsts.DEVICE_LOCK_DISABLED: {
      return <UnblockAll />;
    }
    case ApiConsts.DEVICE_LOCK_MOUSE:
    case ApiConsts.DEVICE_LOCK_MOUSE_KEYBOARD:
    case ApiConsts.DEVICE_LOCK_MONITOR:
    case ApiConsts.DEVICE_LOCK_MOUSE_KEYBOARD_MONITOR: {
      return <BlockComputer />;
    }
    case ApiConsts.DEVICE_LOCK_KEYBOARD: {
      return <BlockKeyboard />;
    }
    case undefined:
      return null;
  }
}

export const createGetColorForControlState = (theme: DefaultTheme) => (status: ControlState) => {
  switch (status) {
    case ControlState.ALLOW:
      return theme.statusColors.allow;
    case ControlState.CHECK:
      return theme.statusColors.check;
    case ControlState.BLOCK:
      return theme.statusColors.block;
    case ControlState.DEPENDS_ON_USER:
      return theme.statusColors.depends;
    default:
      return theme.palette.common.white;
  }
};

export const getPlanType = (type: PlanType | undefined) => {
  switch (type) {
    case PlanType.PLAN_STANDARD:
      return i18n.t('common:planType.standard');
    case PlanType.PLAN_PLUS:
      return i18n.t('common:planType.plus');
    case PlanType.PLAN_PROFESSIONAL:
      return i18n.t('common:planType.professional');
    default:
      return i18n.t('common:planType.noPlan');
  }
};

export function getPaymentType(planSummary: PaymentType | undefined) {
  if (!planSummary) {
    return i18n.t('common:paymentType.unknown');
  }
  switch (planSummary) {
    case PaymentType.PAYMENT_BY_CARD:
      return i18n.t('common:paymentType.card');
    case PaymentType.PAYMENT_BY_P24:
      return i18n.t('common:paymentType.p24');
    case PaymentType.PAYMENT_BY_PRO_FORMA_INVOICE:
      return i18n.t('common:paymentType.invoice');
    default:
      return i18n.t('common:paymentType.unknown');
  }
}

export function getFormattedSubscriptionPaymentInterval(count: number, interval: PriceInterval) {
  switch (interval) {
    case PriceInterval.DAY: {
      return i18n.t('common:common.daysWithCount', { count });
    }
    case PriceInterval.MONTH: {
      return i18n.t('common:common.monthsWithCount', { count });
    }
    case PriceInterval.WEEK: {
      return i18n.t('common:common.weeksWithCount', { count });
    }
    case PriceInterval.YEAR: {
      return i18n.t('common:common.yearsWithCount', { count });
    }
    default:
      return null;
  }
}
