import { Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import hasRequiredPermission from '@/utils/securityAndPermissions';
import { ApiConsts } from '@/api/ApiConsts';


const Link = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

const StyledTabs = styled(Tabs)<{ variant: string }>`
  border-bottom: ${({ theme, variant }) => (variant === 'fullWidth' ? theme.border : 'none')};

  a {
    color: ${({ theme }) => theme.palette.primary.dark};
    opacity: 1;
  }

  a.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  svg {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

interface TabLink {
  translationKey: string,
  link: string,
  permission: string,
}

interface AdminifyTabsProps {
  tabLinks: TabLink[],
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined,
}

const NavigationTabs = ({
  tabLinks, variant = 'standard',
}: AdminifyTabsProps) => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const [tabValue, setTabValue] = useState<string | number | boolean>(false);

  // set correct pathname if redirect occurs
  useEffect(() => {
    if (tabLinks.findIndex(tabLink => (location.pathname === tabLink.link)) !== -1) {
      setTabValue(location.pathname);
    } else {
      setTabValue(false);
    }
  }, [tabLinks, location.pathname]);

  const tabsPermissions: string[] = [];
  tabLinks.forEach(tab => tabsPermissions.push(tab.permission));

  if (!hasRequiredPermission(tabsPermissions)) {
    return null;
  }

  return (
    <StyledTabs
      value={tabValue}
      variant={variant}
      indicatorColor="primary"
      onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
        setTabValue(newValue);
      }}
    >
      {tabLinks.map((tabLink) => hasRequiredPermission(
        [tabLink.permission, ApiConsts.PermissionAPIAdmin]
      ) && (
        <Tab
          label={t(tabLink.translationKey)}
          value={tabLink.link}
          key={tabLink.link}
          component={Link}
          to={tabLink.link}
        />
      ))}
    </StyledTabs>
  );
};

export default NavigationTabs;
