import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { ApiConsts } from '@/api/ApiConsts';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { AppControlState } from '@/api/owpb/pbFiles/basic_pb';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import AccessControlContextMenu from '@/components/AccessControlContextMenu';
import { accessControlsMobileWidth } from '@/constants/common';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import AppAccessControlSubmenu from '@/components/AccessControlContextMenu/AppAccessControlSubmenu';
import { deviceIdFromAgentId } from '@/utils/helpers';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import AppAllow from '@/components/fromSvg/appAccessIcons/AppAllow';
import AppRestrictive from '@/components/fromSvg/appAccessIcons/AppRestrictive';
import AppControl from '@/components/fromSvg/appAccessIcons/AppControl';
import AppBlock from '@/components/fromSvg/appAccessIcons/AppBlock';
import AccessControlMenuCategory from './AccessControlMenuCategory';
import AccessControlElement from './AccessControlElement';


const ContextMenuButton = styled(Button)`
  display: flex;
  justify-content: flex-start;

  svg {
    font-size: 1.4em;
    margin-right: 15px;
  }
`;

interface AppAccessControlMenuProps {
  currentSetting?: AppControlState,
}

const AppAccessControlMenu = ({ currentSetting }: AppAccessControlMenuProps) => {
  const { t } = useTranslation(['common']);
  const { selectedTreeItem } = useSelectedTreeItem();
  const { width } = useWindowDimensions();
  const isMobile = width < accessControlsMobileWidth;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const isActiveVisible = selectedTreeItem && !selectedTreeItem.isGroup;

  const ids = useIdsOfAllSelectedDevices();
  const onlyDeviceIds = selectedTreeItem.type === TreeItemType.Agent
    ? [deviceIdFromAgentId(selectedTreeItem.id)]
    : ids;
  const handleAccessChange = (value: AppControlState) => {
    if (selectedTreeItem.id) {
      updateSettingsDeviceAccess(
        ApiConsts.SettingsDeviceAppAccess,
        value.toString(),
        onlyDeviceIds
      );
    }
  };

  return (
    <AccessControlMenuCategory title={t('common:app.appAccessControl')}>
      <AccessControlElement
        onClick={() => handleAccessChange(AppControlState.APP_ALLOW)}
        startIcon={<AppAllow />}
        disabled={isMobile || !selectedTreeItem}
        isActive={isActiveVisible && currentSetting === AppControlState.APP_ALLOW}
        isMobile={isMobile}
      >
        {t('common:app.fullAccess')}
      </AccessControlElement>
      <AccessControlElement
        onClick={() => handleAccessChange(AppControlState.APP_CHECK)}
        startIcon={<AppControl />}
        disabled={isMobile || !selectedTreeItem}
        isActive={isActiveVisible && currentSetting === AppControlState.APP_CHECK}
        isMobile={isMobile}
      >
        {t('common:app.control')}
      </AccessControlElement>
      <AccessControlElement
        onClick={() => handleAccessChange(AppControlState.APP_BLOCK)}
        startIcon={<AppBlock />}
        disabled={isMobile || !selectedTreeItem}
        isActive={isActiveVisible && currentSetting === AppControlState.APP_BLOCK}
        isMobile={isMobile}
      >
        {t('common:app.blocked')}
      </AccessControlElement>
      <AccessControlElement
        onClick={() => handleAccessChange(AppControlState.APP_STRICT_TO_DESKTOP)}
        startIcon={<AppRestrictive />}
        disabled={isMobile || !selectedTreeItem}
        isActive={isActiveVisible && currentSetting === AppControlState.APP_STRICT_TO_DESKTOP}
        isMobile={isMobile}
      >
        {t('common:app.appDesktop')}
      </AccessControlElement>
      {isMobile && (
        <ContextMenuButton
          onClick={
            (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setAnchorEl(event.currentTarget);
            }
          }
        >
          <AppAllow />
          <AppControl />
          <AppBlock />
          <AppRestrictive />
          <ExpandMoreIcon />
        </ContextMenuButton>
      )}
      {anchorEl && (
        <AccessControlContextMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        >
          <AppAccessControlSubmenu handleClose={() => setAnchorEl(null)} deviceId={onlyDeviceIds} />
        </AccessControlContextMenu>
      )}
    </AccessControlMenuCategory>
  );
};

export default AppAccessControlMenu;
