import { DefaultTheme } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';


const colors = {
  blue: '#3579F5',
  lightGrey: '#F9F9F9',
  grey: '#F4F4F4',
  borderGray: '#DEE1E3',
  darkGrey: '#959ea7',
  darkerGrey: '#2C3E50',
  white: '#fff',
  black: '#0e0e0e',
  red: '#EE6466',
  teal: '#BFDDCC',
  lightBlue: '#F3FBFF',
  greyBlue: '#4C83B2',
  deepBlue: '#16225F',
  oceanBlue: '#72B8CE',
  green: '#7FE180',
  yellow: '#ffe872',
};

const muiTheme = createMuiTheme({
  palette: {
    common: {
      white: colors.white,
      black: colors.black,
    },
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.blue,
      dark: colors.darkerGrey,
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: colors.grey,
      dark: colors.darkGrey,
    },
    error: {
      main: colors.red,
    },
    divider: colors.borderGray,
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
      marginBottom: 40,
      alignSelf: 'flex-start',
    },
    caption: {
      color: colors.darkGrey,
      margin: 0,
    },
    subtitle2: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 5,
  },
});

const customVariables = {
  border: `1px solid ${colors.borderGray}`,
  thickBorder: `2px solid ${colors.borderGray}`,
  darkBorder: `1px solid ${colors.darkerGrey}`,
  blueBorder: `1px solid ${colors.blue}`,
  authBackground: colors.lightBlue,
  treeStripeBackground: {
    light: colors.white,
    dark: colors.lightGrey,
  },
  table: {
    headerBackground: colors.lightGrey,
  },
  screenError: {
    backgroundColor: colors.borderGray,
    textColor: colors.darkerGrey,
  },
  constants: {
    navbarHeight: '100px',
    treeControlsHeight: '140px',
  },
  barChartColors: [colors.teal, colors.greyBlue, colors.deepBlue, colors.oceanBlue],
  statusColors: {
    allow: colors.green,
    check: colors.yellow,
    block: colors.red,
    depends: colors.blue,
  },
  disabledOpacity: 0.35,
};

export const lightTheme: DefaultTheme = {
  ...muiTheme,
  ...customVariables,
};

