import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import UneditableTextField from '@/components/UneditableTextField';
import { appDateFormat } from '@/constants/common';
import { PaymentType, Subscription, SubscriptionStatus } from '@/api/owpb/pbFiles/subscription_service_pb';
import { getPaymentType } from '@/utils/display';
import ConfirmationModal from '@/components/ConfirmationModal';
import { urlStructure } from '@/constants/urlStructure';
import { cancelSubscription } from '@/api/clients/subscription';
import { handleError } from '@/api/errorHandler';
import ManagePaymentMethodModal from '@/components/ManagePaymentMethodModal';
import { formatPrice } from '@/utils/helpers';
import PermissionsControl from '@/components/PermissionsControl';
import { ApiConsts } from '@/api/ApiConsts';


const CardFields = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px 0;
  grid-template-areas:
    "a b"
    "c c"
    "d d"
    "e e";
`;

const ActionElements = styled.div`
  margin-top: 20px;
  grid-area: e;

  > * {
    font-size: 1em;
  }
`;

const RemoveElement = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  cursor: pointer;
`;

const EditElement = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const PlansInfoLink = styled(Link)`
  text-decoration: none;

  > p {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 1em;
  }
`;

const NoSubscriptionInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin: 20px 0;
`;

const CardNumber = styled(Typography)`
  grid-area: d;
  margin-top: -10px;
`;

interface UserSubscriptionCardProps {
  subscription: Subscription.AsObject,
}

const UserSubscriptionCard = ({ subscription }: UserSubscriptionCardProps) => {
  const { t } = useTranslation(['common']);
  const [modalType, setModalType] = useState<string | null>(null);

  if (subscription.status !== SubscriptionStatus.SUBSCRIPTION_ACTIVE) {
    return (
      <>
        <NoSubscriptionInfo variant="body1">
          {t('common:userProfile.noSubscription')}
        </NoSubscriptionInfo>
        <PlansInfoLink to={urlStructure.licenseCheckout}>
          <Typography>
            {t('common:userProfile.activateSubscription')}
          </Typography>
        </PlansInfoLink>
      </>
    );
  }

  return (
    <>
      <CardFields>
        <UneditableTextField
          label={t('common:userProfile.subscriptionPaymentDate')}
          value={subscription.planSummary && subscription.planSummary.periodEnd !== 0
            ? dayjs.unix(subscription.planSummary.periodEnd).format(appDateFormat)
            : null}
          gridArea="a"
        />
        <UneditableTextField
          label={t('common:userProfile.subscriptionPaymentAmount')}
          value={`${formatPrice(subscription.planSummary?.total ?? 0)} ${subscription.planSummary?.price?.currency}`}
          gridArea="b"
        />
        <UneditableTextField
          label={t('common:userProfile.subscriptionPaymentType')}
          value={getPaymentType(subscription.lastPaymentType)}
          gridArea="c"
        />
        {subscription.lastPaymentType === PaymentType.PAYMENT_BY_CARD && (
          <CardNumber>
            {`**** **** **** ${subscription.cardLast4}`}
          </CardNumber>
        )}
        <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
          <ActionElements>
            <EditElement onClick={() => setModalType('editPaymentMethod')}>
              {t('common:userProfile.managePaymentMethod')}
            </EditElement>
            <PlansInfoLink to={urlStructure.licenseCheckout}>
              <Typography>
                {t('common:userProfile.adjustPlan')}
              </Typography>
            </PlansInfoLink>
            <RemoveElement onClick={() => setModalType('cancelSubscription')}>
              {t('common:userProfile.cancelSubscription')}
            </RemoveElement>
          </ActionElements>
        </PermissionsControl>
      </CardFields>
      <ConfirmationModal
        title={t('common:userProfile.cancelSubscription')}
        isWarning
        confirmButtonText={t('common:userProfile.yesCancelSubscription')}
        open={modalType === 'cancelSubscription'}
        content={(
          <Trans
            i18nKey="common:userProfile.cancelSubscriptionInfo"
          />
        )}
        handleClose={() => setModalType(null)}
        handleConfirm={() => {
          cancelSubscription().then().catch(handleError);
          setModalType(null);
        }}
      />
      <ManagePaymentMethodModal
        isOpen={modalType === 'editPaymentMethod'}
        onClose={() => setModalType(null)}
        currentPaymentMethod={subscription.lastPaymentType}
        currency={subscription.planSummary?.price?.currency}
      />
    </>
  );
};

export default UserSubscriptionCard;
