import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { useQuery } from 'react-query';
import {
  useStripe, useElements, CardNumberElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import AppLayout from '@/components/layout/AppLayout';
import CheckoutPaymentMethod from '@/components/CheckoutPaymentMethod';
import CheckoutSectionWrapper from '@/components/CheckoutSectionWrapper';
import { updateSubscription, getPlans } from '@/api/clients/subscription';
import PlanSelectionSection from '@/components/PlanSelectionSection';
import Loader from '@/components/Loader';
import { handleError } from '@/api/errorHandler';
import { PaymentType, Price, SubscriptionConfirmationMode } from '@/api/owpb/pbFiles/subscription_service_pb';
import { urlStructure } from '@/constants/urlStructure';
import CheckoutPaymentAndInvoiceData, { CheckoutPaymentAndInvoiceDataFormFields } from '@/components/CheckoutPaymentAndInvoiceData';
import {
  getCustomerInvoiceInfo,
  updateCustomerInvoiceInfo,
} from '@/api/clients/customerService';
import { getSystemInfo } from '@/api/clients/systemInfo';
import CheckoutSummary from '@/components/CheckoutSummary';
import { defaultApiCurrency, defaultNumberOfDevices, defaultPlanType } from '@/constants/common';
import { clearLicense, loginSelector } from '@/redux/user';
import { useSubscription } from '@/hooks/useSubscription';
import { scrollbarStyles } from '@/utils/styles';
import { store } from '@/redux/store';


const Container = styled.section`
  display: flex;
  justify-content: center;
  height: ${({ theme }) => (`calc(100vh - ${theme.constants.navbarHeight})`)};
`;

const StyledPaper = styled(Paper)`
  margin: 10px 10px 10px 30px;
  padding: 25px;
  width: 50vw;
  overflow: auto;
  ${scrollbarStyles}
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledTypography = styled(Typography)`
  margin-top: 20px;
`;

interface CheckoutFormValues extends CheckoutPaymentAndInvoiceDataFormFields {
  numberOfDevices: number,
  priceId: string,
  paymentMethod: string,
  name: string,
  email: string,
  currency: string,
}

const Checkout = () => {
  const { t } = useTranslation(['common']);
  const stripe = useStripe();
  const subscription = useSubscription();
  const elements = useElements();
  const history = useHistory();
  const login = useSelector(loginSelector);
  const state = history.location.state as { devices: number, priceId: string, currency: string };

  const {
    data: plans,
    isLoading: isPlansLoading,
  } = useQuery('getPlans', getPlans);

  const {
    data: customerInvoiceInfo,
    isLoading: isCustomerInvoiceInfoLoading,
  } = useQuery('getCustomerInvoiceInfo', getCustomerInvoiceInfo);
  const {
    data: systemInfo,
  } = useQuery('getSystemInfo', getSystemInfo);

  const defaultPlan = plans?.find(plan => plan.planType === defaultPlanType);

  const currentPlan = plans?.find(plan => plan.pricesList
    .find(price => price.id === subscription?.planSummary?.price?.id))
    ?? plans?.find(plan => plan.pricesList.find(price => price.id === state?.priceId));

  const onSubmit = async (values: CheckoutFormValues) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // first, update customer invoice info
    try {
      await updateCustomerInvoiceInfo(
        !values.buyerAndPayerDataIsTheSame,
        values.buyer,
        values.payer
      );
    } catch (error) {
      return;
    }

    const paymentType = parseInt(values.paymentMethod, 10);
    try {
      const response = await updateSubscription(
        paymentType,
        values.priceId,
        values.numberOfDevices,
      );

      const confirmMode = response.getConfirmationMode();

      // Redirekcja w czasie płatności może spowodować, że dane licencji nie zostaną odświeżone
      // (są odtwarzane z danych użytkownika zapisanych w sessionStorage) dlatego wymuszamy ponowne
      // ich pobranie
      if (confirmMode !== SubscriptionConfirmationMode.CONFIRMATION_NOT_NECESSARY) {
        store.dispatch(clearLicense());
      }

      switch (confirmMode) {
        case SubscriptionConfirmationMode.CONFIRMATION_NOT_NECESSARY: {
          history.push(urlStructure.orderStatus, [
            { paymentMethod: paymentType, isDowngrade: true },
          ]);
          break;
        }
        case SubscriptionConfirmationMode.NEED_STRIPE_CARD_SETUP: {
          const stripeRes = await stripe.confirmCardSetup(response.toObject().clientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
              // billing_details: values,
            },
          });
          if (stripeRes.error) {
            throw new Error(stripeRes.error.message);
          } else {
            history.push(urlStructure.orderStatus, [
              { paymentMethod: PaymentType.PAYMENT_BY_CARD, isDowngrade: true },
            ]);
          }
          break;
        }
        case SubscriptionConfirmationMode.NEED_STRIPE_CARD_PAYMENT: {
          const stripeRes = await stripe.confirmCardPayment(response.toObject().clientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
              // billing_details: values,
            },
          });
          if (stripeRes.error) {
            throw new Error(stripeRes.error.message);
          } else if (stripeRes.paymentIntent && stripeRes.paymentIntent.status === 'succeeded') {
            history.push(urlStructure.orderStatus, [
              { paymentMethod: PaymentType.PAYMENT_BY_CARD, isDowngrade: false },
            ]);
          }
          break;
        }
        case SubscriptionConfirmationMode.NEED_STRIPE_P24_PAYMENT: {
          const stripeRes = await stripe.confirmP24Payment(response.toObject().clientSecret, {
            payment_method: {
              billing_details: { email: values.email },
            },
            return_url: `${window.location.protocol}//${window.location.host}${urlStructure.orderStatus}?&devices=${values.numberOfDevices}&priceId=${values.priceId}&currency=${values.currency}`,
          });
          if (stripeRes.error) {
            throw new Error(stripeRes.error.message);
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      handleError(error);
    }
  };

  if (isPlansLoading || isCustomerInvoiceInfoLoading) {
    return (
      <Loader.LoaderContainer>
        <Loader />
      </Loader.LoaderContainer>
    );
  }
  if (!plans || !plans[0].pricesList) {
    return (
      <AppLayout>
        <StyledPaper>
          <StyledTypography>
            {t('common:checkout.noPlansAvailable')}
          </StyledTypography>
        </StyledPaper>
      </AppLayout>
    );
  }

  const isInvalidNumber = (numberOfDevices: number) => Math.sign(Number(numberOfDevices)) !== 1
    || !Number.isInteger(Number(numberOfDevices));

  const validate = (values: CheckoutFormValues) => {
    const errors: Partial<Record<keyof CheckoutFormValues, string>> = {};
    if (values.numberOfDevices && isInvalidNumber(values.numberOfDevices)) {
      errors.numberOfDevices = t('errors:wrongValueFormat');
    }
    return errors;
  };


  return (
    <AppLayout>
      <Form<CheckoutFormValues>
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          numberOfDevices: state?.devices
            ?? subscription?.planSummary?.maxDevices
            ?? defaultNumberOfDevices,
          priceId: (state?.priceId
            ?? subscription?.planSummary?.price?.id
            ?? defaultPlan?.pricesList[0].id)
            ?? plans[0].pricesList[0].id,
          currency: state?.currency
            ?? subscription?.planSummary?.price?.currency
            ?? defaultApiCurrency,
          buyerAndPayerDataIsTheSame: !customerInvoiceInfo?.hasPayer,
          buyer: customerInvoiceInfo?.buyer,
          payer: customerInvoiceInfo?.payer,
          email: login ?? undefined,
        }}
      >
        {({ handleSubmit, values, submitting }) => (
          <Container>
            <StyledPaper>
              <StyledForm
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleSubmit();
                }}
              >
                <CheckoutSectionWrapper
                  sectionNumber={1}
                  title={t('common:checkout.configureYourPlan')}
                >
                  {systemInfo && (
                    <PlanSelectionSection
                      plans={plans}
                      systemInfo={systemInfo}
                      currentPlan={currentPlan}
                    />
                  )}
                </CheckoutSectionWrapper>
                <CheckoutSectionWrapper
                  sectionNumber={2}
                  title={t('common:checkout.addPaymentInfo')}
                >
                  <CheckoutPaymentAndInvoiceData
                    buyerAndPayerDataIsTheSame={values.buyerAndPayerDataIsTheSame}
                  />
                </CheckoutSectionWrapper>
                <CheckoutSectionWrapper
                  sectionNumber={3}
                  title={t('common:checkout.selectPaymentMethod')}
                >
                  <CheckoutPaymentMethod selectedCurrency={values.currency} />
                </CheckoutSectionWrapper>
              </StyledForm>
            </StyledPaper>
            <CheckoutSummary
              isDisabledWithLoader={submitting}
              isButtonDisabled={!values.paymentMethod}
              onSubmit={handleSubmit}
              noOfDevices={!isInvalidNumber(values.numberOfDevices) ? values.numberOfDevices : 1}
              priceId={values.priceId}
              plan={plans.find(plan => plan.pricesList
                .find((price: Price.AsObject) => price.id === values.priceId)) ?? null}
            />
          </Container>
        )}
      </Form>
    </AppLayout>
  );
};


export default Checkout;
