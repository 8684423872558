import { useEffect, useState } from 'react';
import { useTreeItem } from '@/hooks/useTreeItem';
import { InetControlState, AppControlState } from '@/api/owpb/pbFiles/basic_pb';


export function useItemAccessStatus(itemId: string | null | undefined) {
  const [inetAccess, setInetAccess] = useState<InetControlState | undefined>();
  const [appAccess, setAppAccess] = useState<AppControlState | undefined>();
  const [deviceAccess, setDeviceAccess] = useState<number | undefined>();

  const { treeItem } = useTreeItem(itemId);

  useEffect(() => {
    if (treeItem) {
      setInetAccess(treeItem.inetAccess);
      setAppAccess(treeItem.appAccess);
      setDeviceAccess(treeItem.deviceLock);
    }
    // obserwowanie treeItem?.inetAccess jest tu kluczowe, bo to właśnie ten parametr się zmienia
    // obserwowanie samego `treeItem` nie odpala tego efektu
  }, [treeItem, treeItem?.inetAccess, treeItem?.appAccess, treeItem?.deviceLock]);
  return { appAccess, inetAccess, deviceAccess };
}
