import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ContentFilterType, URLCategory } from '@/api/owpb/pbFiles/filter_service_pb';
import { contentFiltersGroupedByCategorySelector } from '@/redux/collections/contentFiltersCollection';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';
import UserSpecificStatus from '@/components/configuration/UserSpecificStatus';
import FilterSubTable from './FilterSubTable';


export interface FiltersProps {
  itemId: URLCategory.AsObject['id'],
  status: ControlState,
}

const Filters = ({
  itemId,
  status,
}: FiltersProps) => {
  const allFilters = useSelector(contentFiltersGroupedByCategorySelector);

  const categoryFilter = useMemo(
    () => allFilters[Number(itemId)] ?? {},
    [allFilters, itemId]
  );


  return (
    <>
      <UserSpecificStatus itemId={itemId} status={status} />
      <FilterSubTable
        filters={categoryFilter[ContentFilterType.URL_FILTER]}
        type={ContentFilterType.URL_FILTER}
        categoryId={itemId}
      />
      <FilterSubTable
        filters={categoryFilter[ContentFilterType.PHRASE_FILTER]}
        type={ContentFilterType.PHRASE_FILTER}
        categoryId={itemId}
      />
      <FilterSubTable
        filters={categoryFilter[ContentFilterType.URL_REGEX_FILTER]}
        type={ContentFilterType.URL_REGEX_FILTER}
        categoryId={itemId}
      />
    </>
  );
};

export default Filters;
