import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { licenseSelector, setLicense } from '@/redux/user';
import { handleError } from '@/api/errorHandler';
import { License } from '@/api/owpb/pbFiles/customer_pb';
import { getCustomerLicense } from '@/api/clients/customerService';


export function useLicense(): License.AsObject | undefined {
  const dispatch = useDispatch();
  const license = useSelector(licenseSelector);

  useEffect(() => {
    if (!license) {
      getCustomerLicense()
        .then(res => {
          if (res) {
            dispatch(setLicense(res));
          }
        })
        .catch(handleError);
    }
  }, [dispatch, license]);

  return license;
}
