/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as customer_pb from './customer_pb';
import * as customer_service_pb from './customer_service_pb';


export class CustomerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_pb.Customer,
    (request: customer_service_pb.GetCustomerRequest) => {
      return request.serializeBinary();
    },
    customer_pb.Customer.deserializeBinary
  );

  getCustomer(
    request: customer_service_pb.GetCustomerRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.Customer>;

  getCustomer(
    request: customer_service_pb.GetCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void): grpcWeb.ClientReadableStream<customer_pb.Customer>;

  getCustomer(
    request: customer_service_pb.GetCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/GetCustomer',
        request,
        metadata || {},
        this.methodInfoGetCustomer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/GetCustomer',
    request,
    metadata || {},
    this.methodInfoGetCustomer);
  }

  methodInfoGetCustomerInvoiceInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_service_pb.CustomerInvoiceInfo,
    (request: customer_service_pb.GetCustomerInvoiceInfoRequest) => {
      return request.serializeBinary();
    },
    customer_service_pb.CustomerInvoiceInfo.deserializeBinary
  );

  getCustomerInvoiceInfo(
    request: customer_service_pb.GetCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_service_pb.CustomerInvoiceInfo>;

  getCustomerInvoiceInfo(
    request: customer_service_pb.GetCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_service_pb.CustomerInvoiceInfo) => void): grpcWeb.ClientReadableStream<customer_service_pb.CustomerInvoiceInfo>;

  getCustomerInvoiceInfo(
    request: customer_service_pb.GetCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_service_pb.CustomerInvoiceInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/GetCustomerInvoiceInfo',
        request,
        metadata || {},
        this.methodInfoGetCustomerInvoiceInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/GetCustomerInvoiceInfo',
    request,
    metadata || {},
    this.methodInfoGetCustomerInvoiceInfo);
  }

  methodInfoGetLicense = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_pb.License,
    (request: customer_service_pb.GetLicenseRequest) => {
      return request.serializeBinary();
    },
    customer_pb.License.deserializeBinary
  );

  getLicense(
    request: customer_service_pb.GetLicenseRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.License>;

  getLicense(
    request: customer_service_pb.GetLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_pb.License) => void): grpcWeb.ClientReadableStream<customer_pb.License>;

  getLicense(
    request: customer_service_pb.GetLicenseRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_pb.License) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/GetLicense',
        request,
        metadata || {},
        this.methodInfoGetLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/GetLicense',
    request,
    metadata || {},
    this.methodInfoGetLicense);
  }

  methodInfoUpdateCustomerInvoiceInfo = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_service_pb.CustomerInvoiceInfo,
    (request: customer_service_pb.UpdateCustomerInvoiceInfoRequest) => {
      return request.serializeBinary();
    },
    customer_service_pb.CustomerInvoiceInfo.deserializeBinary
  );

  updateCustomerInvoiceInfo(
    request: customer_service_pb.UpdateCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_service_pb.CustomerInvoiceInfo>;

  updateCustomerInvoiceInfo(
    request: customer_service_pb.UpdateCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_service_pb.CustomerInvoiceInfo) => void): grpcWeb.ClientReadableStream<customer_service_pb.CustomerInvoiceInfo>;

  updateCustomerInvoiceInfo(
    request: customer_service_pb.UpdateCustomerInvoiceInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_service_pb.CustomerInvoiceInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/UpdateCustomerInvoiceInfo',
        request,
        metadata || {},
        this.methodInfoUpdateCustomerInvoiceInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/UpdateCustomerInvoiceInfo',
    request,
    metadata || {},
    this.methodInfoUpdateCustomerInvoiceInfo);
  }

  methodInfoUpdateCustomerKeyDescription = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_pb.Customer,
    (request: customer_service_pb.UpdateCustomerKeyDescriptionRequest) => {
      return request.serializeBinary();
    },
    customer_pb.Customer.deserializeBinary
  );

  updateCustomerKeyDescription(
    request: customer_service_pb.UpdateCustomerKeyDescriptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.Customer>;

  updateCustomerKeyDescription(
    request: customer_service_pb.UpdateCustomerKeyDescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void): grpcWeb.ClientReadableStream<customer_pb.Customer>;

  updateCustomerKeyDescription(
    request: customer_service_pb.UpdateCustomerKeyDescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/UpdateCustomerKeyDescription',
        request,
        metadata || {},
        this.methodInfoUpdateCustomerKeyDescription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/UpdateCustomerKeyDescription',
    request,
    metadata || {},
    this.methodInfoUpdateCustomerKeyDescription);
  }

  methodInfoUpdateCustomerLanguage = new grpcWeb.AbstractClientBase.MethodInfo(
    customer_pb.Customer,
    (request: customer_service_pb.UpdateCustomerLanguageRequest) => {
      return request.serializeBinary();
    },
    customer_pb.Customer.deserializeBinary
  );

  updateCustomerLanguage(
    request: customer_service_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null): Promise<customer_pb.Customer>;

  updateCustomerLanguage(
    request: customer_service_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void): grpcWeb.ClientReadableStream<customer_pb.Customer>;

  updateCustomerLanguage(
    request: customer_service_pb.UpdateCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: customer_pb.Customer) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.CustomerService/UpdateCustomerLanguage',
        request,
        metadata || {},
        this.methodInfoUpdateCustomerLanguage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.CustomerService/UpdateCustomerLanguage',
    request,
    metadata || {},
    this.methodInfoUpdateCustomerLanguage);
  }

}

