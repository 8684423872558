import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ManageGroupDialog from '@/components/ManageGroupDialog';
import SvgAddGroup from '@/components/fromSvg/AddGroup';
import SvgSettings from '@/components/fromSvg/Settings';
import { deviceStatusFilterSelector, setDeviceStatusFilter } from '@/redux/appOptions';
import { DevicesStateFilter } from '@/TreeService/DevicesStateFilter';
import { setTreeSearchText, treeSearchTextSelector } from '@/redux/layout';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import { ApiConsts } from '@/api/ApiConsts';
import PermissionsControl from '@/components/PermissionsControl';
import SelectTreeColumnsModal from './SelectTreeColumnsModal';


const Controls = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-bottom: ${({ theme }) => theme.border};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 16px 10px 24px;
  height: ${({ theme }) => theme.constants.treeControlsHeight};
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ComputerText = styled(Typography)`
  text-transform: capitalize;
`;

const SearchField = styled(TextField)`
  margin-left: 16px;

  .MuiOutlinedInput-input {
    padding: 5.5px 8px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;

const StatusSelectAndSettings = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const SettingsButton = styled(Button)`
  min-width: unset;
  padding: 0 10px;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
`;

const AddGroupElement = styled(Button)`
  text-transform: none;
  width: fit-content;
  margin-top: 5px;
`;

const StatusSelect = styled(Select)`
  height: 30px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  width: 100%;
  margin-right: 15px;

  & > div:focus {
    background: transparent;
  }
`;

enum Modal {
  addGroup = 'addGroup',
  selectColumns = 'selectColumns',
}

const TreeControls = ({ ...otherProps }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const dispatch = useDispatch();
  const searchText = useSelector(treeSearchTextSelector);
  const statusFilter = useSelector(deviceStatusFilterSelector);
  const { selectedTreeItem } = useSelectedTreeItem();
  const [openModal, setOpenModal] = useState<Modal | null>(null);

  const handleSearch = ({ target }: React.ChangeEvent<{ value: string }>) => {
    dispatch(setTreeSearchText(target.value));
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setDeviceStatusFilter(event.target.value as DevicesStateFilter));
  };

  return (
    <Controls {...otherProps}>
      <SearchContainer>
        <ComputerText>
          {t('common:common.computer_plural')}
        </ComputerText>
        <SearchField
          variant="outlined"
          value={searchText}
          label={t('common:app.searchByName')}
          fullWidth
          onChange={handleSearch}
        />
      </SearchContainer>
      <StatusSelectAndSettings>
        <StatusSelect
          value={statusFilter}
          variant="outlined"
          onChange={handleStatusChange}
        >
          <MenuItem value={DevicesStateFilter.NONE}>
            {t('common:app.noFilter')}
          </MenuItem>
          <MenuItem value={DevicesStateFilter.CONNECTED}>
            {t('common:app.activeComputers')}
          </MenuItem>
          <MenuItem value={DevicesStateFilter.CONNECTED_WITH_ACTIVE_SESSIONS}>
            {t('common:app.activeSessions')}
          </MenuItem>
        </StatusSelect>
        <SettingsButton
          variant="outlined"
          size="small"
          onClick={() => {
            setOpenModal(Modal.selectColumns);
          }}
        >
          <SvgSettings stroke={theme.palette.secondary.dark} />
        </SettingsButton>
      </StatusSelectAndSettings>
      <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIDevices]}>
        <AddGroupElement
          startIcon={<SvgAddGroup />}
          onClick={() => {
            setOpenModal(Modal.addGroup);
          }}
        >
          {t('common:app.addGroup')}
        </AddGroupElement>
      </PermissionsControl>
      {openModal === Modal.selectColumns && (
        <SelectTreeColumnsModal
          isOpen={openModal === Modal.selectColumns}
          handleClose={() => setOpenModal(null)}
        />
      )}
      {openModal === Modal.addGroup && (
        <ManageGroupDialog
          isOpen={openModal === Modal.addGroup}
          handleClose={() => setOpenModal(null)}
          variant="add"
          initialValues={{
            name: '',
            parentId: selectedTreeItem.type === TreeItemType.DevicesGroup
              ? selectedTreeItem.id
              : selectedTreeItem.parentId,
          }}
        />
      )}
    </Controls>
  );
};

export default TreeControls;
