import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { expandedTreeNodesSelector } from '@/redux/appOptions';
import { FFBaseProps } from '@/components/finalForm/types';
import MiniTree from '@/components/MiniTree';
import { useDevicesTree } from '@/hooks/useDevicesTree';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';


interface FFMiniTreeSelectItemsProps extends FFBaseProps<string | null> {
  label: string,
  items: Array<DevicesGroupItem | DeviceItem>,
  expanded?: string[],
}

export const FFMiniTreeSelectForItems = ({
  name, config, label, items, expanded = [], ...otherProps
}: FFMiniTreeSelectItemsProps) => {
  const { input } = useField(name, config);
  const [expandedMiniTreeNodes, setExpandedMiniTreeNodes] = useState(expanded);
  return (
    <MiniTree
      onSelect={(event, value) => input.onChange(value)}
      onToggle={(event, nodes) => setExpandedMiniTreeNodes(nodes)}
      selectedItem={input.value}
      label={label}
      items={items}
      expanded={expandedMiniTreeNodes}
      {...otherProps}
    />
  );
};

interface FFMiniTreeSelectProps extends FFBaseProps<string | null> {
  label: string,
}

const FFMiniTreeSelect = ({
  name, config, label, ...otherProps
}: FFMiniTreeSelectProps) => {
  const { tree } = useDevicesTree();
  const expandedTreeNodes = useSelector(expandedTreeNodesSelector);

  return FFMiniTreeSelectForItems({
    name,
    config,
    label,
    items: tree,
    expanded: expandedTreeNodes,
    ...otherProps,
  });
};

export default FFMiniTreeSelect;
