import log from 'loglevel';
import { apiUrl } from '@/constants/common';
import { store } from '@/redux/store';
import { authMetaDataSelector } from '@/redux/user';
import { UpdatesServiceClient } from '../owpb/pbFiles/Updates_serviceServiceClientPb';
import {
  CollectionUpdate,
  CollectionUpdateRequest,
  GetVersionsRequest,
  MultipleCollectionsUpdateRequest,
} from '../owpb/pbFiles/updates_service_pb';
import { IProtoBufCollection } from './CollectionsInterfaces';

/**
 * Updater obsługujący aktualizacje kolekcji GRPC
 */
class CollectionsUpdater {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  private updatesServiceClient!: UpdatesServiceClient;
  private collections!: Array<IProtoBufCollection>;
  private versions!: Map<number, number>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor() {
    this.reInitialize();
  }

  /**
   * Dodanie kolekcji do listy aktualizowanych kolekcji
   */
  public addCollection(collection: IProtoBufCollection) {
    this.collections.push(collection);
  }

  /**
   * Usunięcie kolekcji z listy aktualizowanych kolekcji
   */
  public removeCollection(collection: IProtoBufCollection) {
    for (let i = 0; i < this.collections.length; i += 1) {
      if (this.collections[i].collectionId === collection.collectionId) {
        this.collections.splice(i, 1);
        return;
      }
    }
  }

  public reInitialize() {
    this.collections = new Array<IProtoBufCollection>();
    this.updatesServiceClient = new UpdatesServiceClient(apiUrl);
    this.versions = new Map<number, number>();
  }

  /**
   * Obsługa zdarzenia aktualizacji kolekcji, umożliwia aktualizację kolekcji dodanych
   * do obiektu
   */
  public handleCollectionUpdate(update: CollectionUpdate): boolean {
    this.versions.set(update.getId(), update.getToVersion());
    const collection = this.getCollectionById(update.getId());
    if (collection) {
      // Sprawdzamy czy mamy odpowiednią wersję kolekcji.
      // Dopuszczmy zawsze do aktualizacji z wersji 0 (lub 1 bo właściwie taka jest pierwsza)
      // Na wypadek gdyby stało się coś nieprzewidzianego
      if (collection.version !== update.getFromVersion() && update.getFromVersion() > 1) {
        return false;
      }
      return collection.update(update);
    }

    return true;
  }

  /**
   * Sprawdzenie wersji kolekcji i jej ewentualna aktualizacja gdy to konieczne.
   * Umożliwia jednorazową aktualizację kolekcji, nie dodanej do obiektu
   */
  public async checkCollectionVersion(collection: IProtoBufCollection): Promise<boolean> {
    let result = true;
    const currentVersion = this.versions.get(collection.collectionId);

    if (currentVersion === undefined || collection.version !== currentVersion) {
      const collectionUpdate = new CollectionUpdateRequest();
      collectionUpdate.setId(collection.collectionId);
      collectionUpdate.setFromVersion(0);

      const updateRequest = new MultipleCollectionsUpdateRequest();
      updateRequest.addItems(collectionUpdate);

      const updateResponse = await this.updatesServiceClient
        .getCollectionsUpdates(updateRequest, authMetaDataSelector(store.getState()));

      for (let i = 0; i < updateResponse.getItemsList().length; i += 1) {
        const update = updateResponse.getItemsList()[i];
        this.versions.set(update.getId(), update.getToVersion());
        if (!this.handleCollectionUpdate(update)) {
          result = false;
        }
      }
    }

    return result;
  }

  /**
   * Załadowanie aktualnych wersji kolekcji dodanych do obiektu
   */
  public async updateCollections(): Promise<boolean> {
    let result = true;
    try {
      const authMetaData = authMetaDataSelector(store.getState());
      const versionResponse = await this.updatesServiceClient
        .getVersions(new GetVersionsRequest(), authMetaData);

      const updateRequest = new MultipleCollectionsUpdateRequest();

      for (let i = 0; i < versionResponse.getItemsList().length; i += 1) {
        const versionInfo = versionResponse.getItemsList()[i];
        const collection = this.getCollectionById(versionInfo.getId());
        const currentVersion = versionInfo.getVersion();
        this.versions.set(versionInfo.getId(), currentVersion);

        if (collection) {
          let fromVersion = -1;
          if (collection.version < currentVersion) {
            fromVersion = collection.version;
          } else if (collection.version > currentVersion) {
            // Na wypadek gdyby stało się coś złego i nasza wersja była większa
            // od wersji na serwerze aktualizujemy od wersji 0
            fromVersion = 0;
          }

          if (fromVersion !== -1) {
            const collectionUpdate = new CollectionUpdateRequest();
            collectionUpdate.setId(versionInfo.getId());
            collectionUpdate.setFromVersion(fromVersion);
            updateRequest.addItems(collectionUpdate);
          }
        }
      }

      if (updateRequest.getItemsList().length > 0) {
        const updateResponse = await this.updatesServiceClient
          .getCollectionsUpdates(updateRequest, authMetaData);

        for (let i = 0; i < updateResponse.getItemsList().length; i += 1) {
          const update = updateResponse.getItemsList()[i];
          if (!this.handleCollectionUpdate(update)) {
            log.error('handleCollectionUpdate error. collectionId:', update.getId());
            result = false;
          }
        }
      }
    } catch (err) {
      log.error('updateCollections error:', err);
      result = false;
    }

    return result;
  }

  private getCollectionById(id: number): IProtoBufCollection | undefined {
    for (let i = 0; i < this.collections.length; i += 1) {
      if (this.collections[i].collectionId === id) {
        return this.collections[i];
      }
    }
    return undefined;
  }

  public isCollectionAdded(id: number) {
    return this.getCollectionById(id) !== undefined;
  }
}

export default new CollectionsUpdater();
