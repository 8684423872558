/* eslint-disable no-underscore-dangle */
import { CollectionHolder } from './CollectionsInterfaces';

/**
 * CollectionHolder przechowujący kolekcję w tablicy 
 */
export default class CollectionHolderArray<
  ObjectT extends { id: string }
  > implements CollectionHolder<ObjectT> {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  protected collectionId: number;
  protected _items: Array<ObjectT>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(collectionId: number) {
    this.collectionId = collectionId;
    this._items = new Array<ObjectT>();
  }

  public items(): Array<ObjectT> {
    return this._items;
  }

  public fullCollection(items: Array<ObjectT>): boolean {
    this._items = items;
    return true;
  }

  public createItem(item: ObjectT): boolean {
    this._items.push(item);
    return true;
  }

  public updateItem(itemId: string, item: ObjectT): boolean {
    for (let i = 0; i < this._items.length; i += 1) {
      if (this._items[i].id === itemId) {
        this._items[i] = item;
        return true;
      }
    }
    return false;
  }

  public deleteItem(itemId: string): boolean {
    for (let i = 0; i < this._items.length; i += 1) {
      if (this._items[i].id === itemId) {
        this._items.splice(i, 1);
        return true;
      }
    }
    return false;
  }
}
