import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { setAccessControlFilters, accessControlFiltersSelector } from '@/redux/layout';
import FFDatePickerForm from '@/components/finalForm/FFDatePicker';
import UserSelectAccessControlFilters from '@/components/UserSelectAccessControlFilters';


const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

interface FormValues {
  dates?: {
    dateFrom: number,
    dateTo: number,
  },
  user?: string | null,
}

const AccessControlFilters = () => {
  const dispatch = useDispatch();

  const storedFilters = useSelector(accessControlFiltersSelector);

  const applyFilters = (values: FormValues) => {
    const formattedValue = {
      dateFrom: values.dates?.dateFrom ?? storedFilters.dateFrom,
      dateTo: values.dates?.dateTo ?? storedFilters.dateTo,
      user: values.user ?? storedFilters.user,
    };
    dispatch(setAccessControlFilters(formattedValue));
  };

  return (
    <FiltersContainer>
      <Form<FormValues>
        onSubmit={(values: FormValues) => applyFilters(values)}
        initialValues={{
          dates: {
            dateFrom: storedFilters.dateFrom,
            dateTo: storedFilters.dateTo,
          },
          user: storedFilters.user,
        }}
        mutators={{
          clearUser: (args, state, utils) => {
            utils.changeValue(state, 'user', () => 'all');
          },
        }}
      >
        {({ form, handleSubmit }) => (
          <>
            <FFDatePickerForm
              name="dates"
              onChange={handleSubmit}
            />
            <UserSelectAccessControlFilters
              name="user"
              applyUserFilter={handleSubmit}
              onClear={form.mutators.clearUser}
            />
          </>
        )}
      </Form>
    </FiltersContainer>
  );
};

export default AccessControlFilters;
