import React, { useState } from 'react';
import { Form, useField } from 'react-final-form';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button, Dialog } from '@material-ui/core';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { FFBaseProps } from '@/components/finalForm/types';
import { appDateFormat } from '@/constants/common';
import { setDayEndHour, setDayStartHour } from '@/utils/helpers';
import ManipulateDateButton from './ManipulateDateButton';
import DialogContent from './DialogContent';


const Buttons = styled.div`
  width: fit-content;
  display: flex;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => theme.border};
  box-shadow: none;
`;

const DateIcon = styled(DateRangeIcon)`
  color: ${({ theme }) => theme.palette.secondary.dark};
`;

interface FormValues {
  dateFrom: Dayjs,
  dateTo: Dayjs,
}

export interface DateValue {
  dateFrom: number,
  dateTo: number,
}

export interface FFDatePickerFormProps extends FFBaseProps<DateValue> {
  onChange?: () => void,
}

const FFDatePickerForm = ({ onChange, name, config }: FFDatePickerFormProps) => {
  const { input } = useField(name, config);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (values: FormValues) => {
    setIsModalOpen(false);
    const formattedDates = {
      dateFrom: values.dateFrom.hour(0).unix(),
      dateTo: values.dateTo.hour(23).minute(59).unix(),
    };
    input.onChange(formattedDates);
    if (onChange) {
      onChange();
    }
  };

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      initialValues={{
        dateFrom: input.value?.dateFrom
          ? dayjs.unix(input.value?.dateFrom)
          : setDayStartHour(dayjs().subtract(7, 'days')),
        dateTo: input.value?.dateTo
          ? dayjs.unix(input.value?.dateTo)
          : setDayEndHour(dayjs()),
      }}
    >
      {({ values, handleSubmit }) => {
        const days = Math.round(dayjs.duration(values.dateTo.diff(values.dateFrom)).asDays());
        let amountOfDays: { amount: number, unit: 'day' | 'month' | 'year' } = { amount: days, unit: 'day' };
        // check if dateFrom is set to the first day of the month
        // check if dateTo is set to the last day of the month
        // calculate amount of months, works for years as well
        if (dayjs(values.dateFrom).date() === 1
          && dayjs(values.dateTo).date() === dayjs(values.dateTo).daysInMonth()) {
          // add 1 day, because dayjs diff function calculates whole months from 1st to 1st day.
          amountOfDays = { amount: dayjs(values.dateTo).add(1, 'day').diff(values.dateFrom, 'month'), unit: 'month' };
        }
        // todo: fix navigation for current year and going over current date
        return (
          <>
            <Buttons>
              <StyledButton
                variant="contained"
                startIcon={<DateIcon />}
                onClick={() => setIsModalOpen(true)}
              >
                {`${dayjs(values.dateFrom).format(appDateFormat)} - ${dayjs(values.dateTo).format(appDateFormat)}`}
              </StyledButton>
              <ManipulateDateButton
                direction="back"
                onChange={handleSubmit}
                range={amountOfDays.amount}
                timeUnit={amountOfDays.unit}
              />
              <ManipulateDateButton
                direction="forward"
                onChange={handleSubmit}
                range={amountOfDays.amount}
                timeUnit={amountOfDays.unit}
              />
            </Buttons>
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg">
              <DialogContent
                nameFrom="dateFrom"
                nameTo="dateTo"
                onSubmit={handleSubmit}
              />
            </Dialog>
          </>
        );
      }}
    </Form>
  );
};

export default FFDatePickerForm;
