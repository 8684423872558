import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import log from 'loglevel';
import commonPl from '@/locales/pl/common.json';
import commonEn from '@/locales/en/common.json';
import errorsPl from '@/locales/pl/errors.json';
import errorsEn from '@/locales/en/errors.json';
import { defaultLanguage, fallbackLanguage } from '@/constants/common';


i18n
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLanguage,
    lng: defaultLanguage,
    ns: [
      'common',
      'errors',
    ],
    defaultNS: 'common',
    debug: false,
    interpolation: {
      escapeValue: false,
      format(value: string, format: string | undefined) {
        if (format === 'uppercase') { return value.toUpperCase() }
        return value;
      },
    },
    react: {
      wait: true,
    },
    resources: {
      pl: {
        common: commonPl,
        errors: errorsPl,
      },
      en: {
        common: commonEn,
        errors: errorsEn,
      },
    },
  })
  .catch((error) => {
    log.error('i18n initialization failed');
    log.error(error);
  });

export default i18n;
