import React, {
  PropsWithChildren, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import Measure from 'react-measure';
import { useDispatch, useSelector } from 'react-redux';
import { screenPreviewViewOptionsSelector } from '@/redux/appOptions';
import { screenPreviewHeaderAndFooterHeight } from '@/constants/common';
import { setAccessControlContentAndTileSize } from '@/redux/layout';
import ScreenPreviewsGrid from '@/components/screensPreviewPageComponents/ScreenPreviewsGrid';
import {
  CalculateColumnLayoutForAutoTileSize,
  CalculateColumnLayoutForNumberOfColumns,
} from '@/components/AccessControlScreenPreviewPage/helpers';


const gridGap = 20;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const SettingsContainer = styled.div`
  margin: 5px 0;
  display: flex;
`;

const BoxContainer = styled.div`
  flex-grow: 1;
`;

interface AccessControlScreenPreviewPageProps {
  settingsComponent?: React.ReactNode,
}

const AccessControlScreenPreviewPage = ({
  settingsComponent,
  children,
}: PropsWithChildren<AccessControlScreenPreviewPageProps>) => {
  const { autoTileSize, numberOfColumns } = useSelector(screenPreviewViewOptionsSelector);
  const [containerDimensions, setContainerDimensions] = useState<{width: number, height: number}>();
  const screenPreviewsContainer = useRef<HTMLDivElement>();
  const numberOfChildren = screenPreviewsContainer?.current?.children.length ?? 0;
  const dispatch = useDispatch();
  const selectedNumberOfColumns = useMemo(() => {
    // autoTileSize
    if (autoTileSize && containerDimensions) {
      const calculatedData = CalculateColumnLayoutForAutoTileSize(
        containerDimensions.width,
        containerDimensions.height,
        numberOfChildren,
        gridGap,
        screenPreviewHeaderAndFooterHeight,
      );
      dispatch(setAccessControlContentAndTileSize({
        tileWidth: calculatedData.columnWidth,
        contentHeight: containerDimensions.height,
        contentWidth: containerDimensions.width,
      }));
      return calculatedData.numberOfColumns;
    }
    // specified amount of columns
    if (containerDimensions) {
      const calculatedData = CalculateColumnLayoutForNumberOfColumns(
        containerDimensions.width,
        numberOfColumns,
        gridGap
      );
      dispatch(setAccessControlContentAndTileSize({
        tileWidth: calculatedData.columnWidth,
        contentHeight: containerDimensions.height,
        contentWidth: containerDimensions.width,
      }));
      return calculatedData.numberOfColumns;
    }
    return numberOfColumns;
  }, [
    autoTileSize,
    containerDimensions,
    dispatch,
    numberOfColumns,
    numberOfChildren,
  ]);
  return (
    <>
      {settingsComponent && (
        <SettingsContainer>
          {settingsComponent}
        </SettingsContainer>
      )}
      <Measure
        client
        onResize={contentRect => setContainerDimensions({
          width: contentRect?.client?.width ?? 0,
          height: contentRect?.client?.height ?? 0,
        })}
      >
        {({ measureRef }) => (
          <Container
            role="tabpanel"
            ref={measureRef}
          >
            <BoxContainer>
              <ScreenPreviewsGrid
                gridGap={gridGap}
                columnSetting={selectedNumberOfColumns}
                ref={screenPreviewsContainer}
              >
                {children}
              </ScreenPreviewsGrid>
            </BoxContainer>
          </Container>
        )}
      </Measure>
    </>
  );
};

export default AccessControlScreenPreviewPage;
