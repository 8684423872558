import React from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useField } from 'react-final-form';
import { FFBaseProps } from './types';


interface FFCheckboxProps extends
  Omit<CheckboxProps, 'value' | 'checked' | 'name' | 'onChange'>,
  FFBaseProps<string | null> {
}

const FFCheckbox = ({
  name,
  config,
  ...otherProps
}: FFCheckboxProps) => {
  const {
    input: {
      checked, onChange, disabled, value,
    },
  } = useField(name, config);

  return (
    <Checkbox
      disabled={disabled}
      name={name}
      onChange={onChange}
      checked={checked}
      value={value}
      {...otherProps}
    />
  );
};

export default FFCheckbox;
