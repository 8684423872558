import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row } from 'react-table';


const StyledAccordion = styled(MuiAccordion)`
  border-top: ${({ theme }) => theme.border};
  box-shadow: none;
  margin: 0 0 0 -18px !important;

  &:first-child {
    border: none;
    margin-top: 0;
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  &:before {
    display: none;
  }
`;

const StyledSummary = styled(MuiAccordionSummary)`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  min-height: 0 !important;
  padding: 0;

  &.Mui-focused {
    background-color: transparent;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon {
    margin: -12px 0;
  }
`;

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
`;

interface AccordionProps {
  content: React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: Row<any>,
}

const Accordion = ({
  content,
  children,
  row,
  ...otherProps
}: PropsWithChildren<AccordionProps>) => (
  <StyledAccordion
    square
    expanded={row.isExpanded ?? false}
    onChange={() => row.toggleRowExpanded()}
  >
    <StyledSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <RowContainer
        {...otherProps}
      >
        {children}
      </RowContainer>
    </StyledSummary>
    <StyledMuiAccordionDetails>
      {content}
    </StyledMuiAccordionDetails>
  </StyledAccordion>
);


export default Accordion;
