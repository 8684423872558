import { css } from 'styled-components';


export const scrollbarStyles = css`
  --thumbBG: ${({ theme }) => theme.palette.secondary.dark};
  --scrollbarBG: ${({ theme }) => theme.palette.background.default};
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 8px;
  }
`;
