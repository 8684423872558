import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';


const ControlElement = styled(Button)<{ $isActive: boolean, $additionalStyles: boolean }>`
  background-color: ${({
    theme,
    $isActive,
    $additionalStyles,
  }) => (($isActive && $additionalStyles) ? theme.palette.secondary.main : theme.palette.common.white)};
  display: flex;
  margin-right: 5px;
  padding-left: 2px;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  text-transform: none;

  :hover {
    text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  }

  .MuiButton-startIcon {
    margin-right: 5px;

    svg {
      font-size: 1.2em;
    }
  }
`;

interface AccessControlElementProps {
  onClick: () => void,
  isActive: boolean,
  startIcon: React.ReactNode,
  disabled?: boolean,
  children: React.ReactNode,
  activeDependentBgColor?: boolean,
  fullWidth?: boolean,
  isMobile?: boolean,
}

const AccessControlElement = ({
  onClick,
  isActive,
  startIcon,
  disabled = false,
  children,
  activeDependentBgColor = false,
  fullWidth = false,
  isMobile = false,
}: AccessControlElementProps) => {
  if (isMobile) { return null }

  return (
    <ControlElement
      onClick={onClick}
      $isActive={isActive}
      startIcon={startIcon}
      disabled={disabled}
      $additionalStyles={activeDependentBgColor}
      fullWidth={fullWidth}
    >
      {children}
    </ControlElement>
  );
};

export default AccessControlElement;
