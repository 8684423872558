import React from 'react';
import styled from 'styled-components';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import ButtonFooter from '@/components/GenericOptionsModal/ButtonFooter';
import GenericOptionsModal from './GenericOptionsModal';


const StyledForm = styled.form<{ $fixedWidth: boolean, $noMargins: boolean }>`
  width: ${({ $fixedWidth }) => ($fixedWidth ? '400px' : '100%')};
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: ${({ $noMargins }) => (!$noMargins && '20px;')};
  }
`;

export interface FFGenericOptionsModalProps<FormFields> {
  title: React.ReactNode,
  isOpen: boolean,
  onSubmit: (values: FormFields) => void,
  onClose: () => void,
  confirmButtonText?: string,
  cancelButtonText?: string,
  children: (formRenderProps: FormRenderProps<FormFields>) => React.ReactNode,
  formProps?: Omit<FormProps<FormFields>, 'onSubmit'>,
  fixedWidth?: boolean,
  noMargins?: boolean,
  disabledSubmitButtonWhenNoValue?: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FFGenericOptionsModal = <FormFields extends Record<string, any>>({
  title,
  isOpen,
  onSubmit,
  onClose,
  confirmButtonText,
  cancelButtonText,
  children,
  formProps,
  fixedWidth = false,
  noMargins = false,
  disabledSubmitButtonWhenNoValue = false,
// eslint-disable-next-line arrow-body-style
}: FFGenericOptionsModalProps<FormFields>) => {
  return (
    <GenericOptionsModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form<FormFields>
        onSubmit={onSubmit}
        {...formProps}
      >
        {(formRenderProps) => (
          <StyledForm
            onSubmit={formRenderProps.handleSubmit}
            $fixedWidth={fixedWidth}
            $noMargins={noMargins}
          >
            {children(formRenderProps)}
            <ButtonFooter
              onConfirm={formRenderProps.handleSubmit}
              onClose={onClose}
              confirmButtonText={confirmButtonText}
              cancelButtonText={cancelButtonText}
              disabledSubmit={disabledSubmitButtonWhenNoValue
              && !Object.entries(formRenderProps.values).length}
            />
          </StyledForm>
        )}
      </Form>
    </GenericOptionsModal>
  );
};


export default FFGenericOptionsModal;
