import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { TableInstance } from 'react-table';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import { accessControlContentSizeSelector } from '@/redux/layout';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import Tooltip from '@/components/Tooltip';
import { scrollbarStyles } from '@/utils/styles';
import SelectColumnsModal from '@/components/HistoryTable/SelectColumnsModal';
import Header from '@/components/HistoryTable/Header';
import NoDataScreen from '@/components/NoDataScreen';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import StyledTableCell from './StyledTableCell';


const StyledTableContainer = styled(TableContainer)<{ $width: number, $height: number }>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  overflow: auto;
  ${scrollbarStyles}
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: auto;
  display: block;
  overflow: hidden !important;
`;

const TooltipChild = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;


const scrollableTargetId = 'scrollableTableContainer';

export interface TableLayoutProps<T extends Record<string, unknown>> {
  tableInstance: TableInstance<T>,
  infiniteScrollProps: {
    next: () => void,
    hasMore: boolean,
  },
  isColumnSelectionDialogOpen: boolean,
  closeColumnSelectionDialog: () => void,
  resetScrollPositionOnTreeItemSelection: boolean,
}

function useScrollReset<T>(
  resetScroll: () => void,
  watchedParam: T,
  initState: T
) {
  const [lastValue, setLastValue] = useState<T>(initState);

  useEffect(() => {
    if (lastValue !== watchedParam) {
      resetScroll();
      setLastValue(watchedParam);
    }
  }, [watchedParam, lastValue, resetScroll]);
}

const HistoryTableLayout = <T extends Record<string, unknown>>({
  tableInstance,
  infiniteScrollProps,
  isColumnSelectionDialogOpen,
  closeColumnSelectionDialog,
  resetScrollPositionOnTreeItemSelection,
}: TableLayoutProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
  } = tableInstance;

  const { width, height } = useSelector(accessControlContentSizeSelector);

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const ids = useIdsOfAllSelectedDevices();
  const { t } = useTranslation(['common']);

  const resetScroll = useCallback(() => {
    if (scrollableContainerRef?.current) {
      // eslint-disable-next-line no-param-reassign
      scrollableContainerRef.current.scrollTop = 0;
      // eslint-disable-next-line no-param-reassign
      scrollableContainerRef.current.scrollLeft = 0;
    }
  }, []);
  const { selectedTreeItem } = useSelectedTreeItem();
  useScrollReset<string>(
    resetScrollPositionOnTreeItemSelection ? resetScroll : () => undefined,
    selectedTreeItem.id,
    ''
  );
  useScrollReset<adminify.TableSortingAndPagination['sortedBy'] | undefined>(
    resetScroll,
    state.sortBy.length > 0 ? state.sortBy[0] : undefined,
    undefined
  );

  return (
    <>
      <StyledTableContainer
        $width={width}
        $height={height}
        id={scrollableTargetId}
        ref={scrollableContainerRef}
      >
        {rows.length ? (
          <StyledInfiniteScroll
            {...infiniteScrollProps}
            dataLength={tableInstance.rows.length}
            scrollableTarget={scrollableTargetId}
            loader={(
              <Loader.LoaderContainer>
                <Loader />
              </Loader.LoaderContainer>
            )}
          >
            <MaterialTable
              component="div"
              {...getTableProps()}
            >
              <Header headerGroups={headerGroups} />
              {/* Spacer for sticky header */}
              <Header
                isPlaceholder
                headerGroups={headerGroups}
              />
              <TableBody
                component="div"
                {...getTableBodyProps()}
              >
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      component="div"
                      {...row.getRowProps()}
                    >
                      {row.cells.map(cell => (
                        <StyledTableCell
                          component="div"
                          {...cell.getCellProps()}
                        >
                          <Tooltip
                            title={cell.value}
                            arrow
                            placement="top-start"
                          >
                            <TooltipChild>
                              {cell.render('Cell')}
                            </TooltipChild>
                          </Tooltip>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MaterialTable>
          </StyledInfiniteScroll>
        ) : (
          <NoDataScreen
            message={ids.length ? t('common:common.noDataInfo') : t('common:common.noDevices')}
          />
        )}
      </StyledTableContainer>
      <SelectColumnsModal<T>
        isOpen={isColumnSelectionDialogOpen}
        handleClose={closeColumnSelectionDialog}
        allColumns={allColumns}
      />
    </>
  );
};


export default HistoryTableLayout;
