import log from 'loglevel';
import { reduxToObservable, store } from '@/redux/store';
import { setUserAppOptions } from './appOptions';


class AppOptionsManager {
  private unsubscribeFunction: null | (() => void) = null;

  private lastAppOptionsString: string = '';

  public subscribe() {
    this.unsubscribeFunction = reduxToObservable().subscribe({
      next: (state) => {
        const appOptionsString = JSON.stringify(state.appOptions);
        const skipDispatch = this.lastAppOptionsString === '';
        if (appOptionsString !== this.lastAppOptionsString) {
          this.lastAppOptionsString = appOptionsString;
          if (!skipDispatch) {
            void store.dispatch(setUserAppOptions());
          }
        }
      },
    }).unsubscribe;
  }

  public unsubscribe() {
    if (this.unsubscribeFunction) {
      this.unsubscribeFunction();
      this.unsubscribeFunction = null;
    } else {
      log.warn('AppOptionsManager.unsubscribe is null');
    }
  }
}

export default AppOptionsManager;
