import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import AppLayout from '@/components/layout/AppLayout';
import UserProfilePaper from '@/components/UserProfilePaper';
import { getCustomer } from '@/api/clients/customers';
import { getCustomerInvoiceInfo } from '@/api/clients/customerService';
import { handleError } from '@/api/errorHandler';
import { Customer } from '@/api/owpb/pbFiles/customer_pb';
import { CustomerInvoiceInfo } from '@/api/owpb/pbFiles/customer_service_pb';
import UserLicenseCard from '@/components/UserLicenseCard';
import UserInfoCard from '@/components/UserInfoCard';
import UserSubscriptionCard from '@/components/UserSubscriptionCard';
import PaymentAndInvoiceDataCard from '@/components/PaymentAndInvoiceDataCard';
import { getSystemInfo } from '@/api/clients/systemInfo';
import { SystemI18nInfo } from '@/api/owpb/pbFiles/basic_pb';
import { useSubscription } from '@/hooks/useSubscription';
import ScrollableContainer from '../../components/ScrollableContainer';


const UserProfile = () => {
  const { t } = useTranslation(['common']);
  const subscription = useSubscription();
  const [customer, setCustomer] = useState<Customer.AsObject>();
  const [customerInvoiceInfo, setCustomerInvoiceInfo] = useState<CustomerInvoiceInfo.AsObject>();
  const [systemInfo, setSystemInfo] = useState<SystemI18nInfo.AsObject>();

  const fetchCustomerData = () => {
    getCustomer().then(res => res && setCustomer(res)).catch(handleError);
  };

  const fetchPaymentData = () => {
    getCustomerInvoiceInfo().then(res => res && setCustomerInvoiceInfo(res)).catch(handleError);
  };

  const fetchSystemInfo = () => {
    getSystemInfo().then(res => {
      setSystemInfo(res);
    }).catch(handleError);
  };

  useEffect(() => {
    fetchCustomerData();
    fetchPaymentData();
    fetchSystemInfo();
  }, []);

  return (
    <AppLayout>
      <ScrollableContainer>
        <Grid container>
          <Grid item xs={12} md={6} lg={8}>
            <UserProfilePaper title={t('common:common.user')}>
              {customer && systemInfo && (
                <UserInfoCard customer={customer} languageInfo={systemInfo.languagesList} />
              )}
            </UserProfilePaper>
            <UserProfilePaper title={t('common:paymentData.paymentData')}>
              {customerInvoiceInfo && (
                <PaymentAndInvoiceDataCard
                  customerInvoiceInfo={customerInvoiceInfo}
                  refetchData={fetchPaymentData}
                />
              )}
            </UserProfilePaper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <UserProfilePaper title={t('common:common.license')}>
              {customer && <UserLicenseCard />}
            </UserProfilePaper>
            <UserProfilePaper title={t('common:common.subscription')}>
              {subscription && (
                <UserSubscriptionCard
                  subscription={subscription}
                />
              )}
            </UserProfilePaper>
          </Grid>
        </Grid>
      </ScrollableContainer>
    </AppLayout>
  );
};

export default UserProfile;
