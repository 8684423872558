import React from 'react';
import { Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FFTextInput from '@/components/finalForm/FFTextInput';
import AuthLayout from '@/components/layout/AuthLayout';
import { urlStructure } from '@/constants/urlStructure';
import { validateEmail } from '@/utils/validators';
import AuthButton from '@/components/AuthButton';
import { forgotPassword } from '@/api/clients/auth';
import ForgotPasswordSvg from '@/components/fromSvg/auth/ForgotPassword';


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 10px;
  }
`;

interface LoginFormFields {
  email: string,
}

const ForgotPassword = () => {
  const { t } = useTranslation(['common', 'errors']);

  const validate = (values: LoginFormFields) => {
    const errors: Partial<Record<keyof LoginFormFields, string>> = {};

    if (values.email && !validateEmail(values.email)) {
      errors.email = t('errors:invalidEmail');
    }
    return errors;
  };

  return (
    <AuthLayout
      sideContent={(<ForgotPasswordSvg />)}
      footer={(
        <Typography variant="caption">
          {`${t('common:auth.login.noAccount')} `}
          <Link to={urlStructure.register}>
            {t('common:auth.registerYourself')}
          </Link>
        </Typography>
      )}
    >
      <Form<LoginFormFields>
        onSubmit={(values) => forgotPassword(values.email)}
        validate={validate}
      >
        {({ handleSubmit, form, submitting }) => (
          <StyledForm
            onSubmit={async (values) => {
              await handleSubmit(values);
              form.reset();
            }}
          >
            <Typography variant="h5">
              {t('common:auth.forgotPassword.title')}
            </Typography>
            <FFTextInput
              name="email"
              label={t('common:auth.login.email')}
              type="text"
            />
            <AuthButton
              fullWidth
              variant="contained"
              type="submit"
              label={t('common:auth.forgotPassword.title')}
              showLoader={submitting}
            />
          </StyledForm>
        )}
      </Form>
    </AuthLayout>
  );
};


export default ForgotPassword;
