import i18n from './i18n';


export const isEmpty = (value: unknown) => value === undefined || value === null || value === '';

export function validateEmail(value: string) {
  /**
   * Custom email validation.
   * part before '@':
   * - catch -!#$%&'*+/=?^`{}|~ chars (before, after dot)
   * part after '@' (domain):
   * - matches all domains
   */
  return (
    !isEmpty(value)
    && /^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*@(?:[A-Z0-9](?:[A-Z0-9\\-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9]{2,})$/i.test(value)
  );
}

export function validatePassword(value: string) {
  let errorMessage;
  if (value.length < 6) { errorMessage = i18n.t('errors:passwordMinLength') }
  return errorMessage;
}
