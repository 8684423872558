import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserInvitationStatusRequest } from '@/api/clients/invitation';
import { UserStatus } from '@/api/owpb/pbFiles/basic_pb';
import AuthLayout from '@/components/layout/AuthLayout';
import Loader from '@/components/Loader';
import { useSearchParams } from '@/hooks/useSearchParams';
import AccountInfo from '@/pages/auth/setPassword/AccountInfo';
import PasswordForm from '@/pages/auth/setPassword/PasswordForm';
import SetPasswordSvg from '@/components/fromSvg/auth/SetPassword';
import AccountLinkInfo from './AccountLinkInfo';


type ScreenToRender = 'loader'
| 'userAlreadyHaveAccount'
| 'createPassword'
| 'resetPassword'
| 'invitationAlreadyUsed'
| 'invalidInvitation'
| 'unknownProblem';

const SetPasswordContent = () => {
  const { t } = useTranslation(['common']);
  const [screenToRender, setScreenToRender] = useState<ScreenToRender>('loader');
  const searchParams = useSearchParams();
  const login = searchParams.get('login');
  // invitationid jest podany gdy potwierdzamy zaproszenie użytkownika,
  const invitationId = searchParams.get('invitationid');
  // resetToken jest podany gdy resetujemy hasło użytkownika
  const resetToken = searchParams.get('resettoken');

  useEffect(() => {
    if (invitationId) {
      void getUserInvitationStatusRequest(invitationId)
        .then((res) => {
          if (!res || res.invitationStatus === UserStatus.UNKNOWN_USER_STATUS) {
            setScreenToRender('invalidInvitation');
          } else if (res.invitationStatus === UserStatus.USER_ACTIVE) {
            setScreenToRender('invitationAlreadyUsed');
          } else if (res.invitationStatus === UserStatus.USER_WAITING_FOR_ACTIVATION) {
            if (res.userStatus === UserStatus.USER_WAITING_FOR_ACTIVATION) {
              setScreenToRender('createPassword');
            } else if (res.userStatus === UserStatus.USER_ACTIVE) {
              setScreenToRender('userAlreadyHaveAccount');
            } else {
              setScreenToRender('unknownProblem');
            }
          } else {
            setScreenToRender('unknownProblem');
          }
        });
    } else if (resetToken) {
      setScreenToRender('resetPassword');
    } else {
      setScreenToRender('invalidInvitation');
    }
  }, [login, resetToken, invitationId]);

  switch (screenToRender) {
    case 'loader':
      return <Loader />;
    case 'userAlreadyHaveAccount':
      return (
        <AccountLinkInfo invitationId={invitationId as string} />
      );
    case 'createPassword':
      return (
        <PasswordForm
          login={login as string}
          invitationId={invitationId as string}
          resetToken={null}
        />
      );
    case 'resetPassword':
      return (
        <PasswordForm
          login={login as string}
          invitationId={null}
          resetToken={resetToken as string}
        />
      );
    case 'invitationAlreadyUsed':
      return (
        <AccountInfo
          title={t('common:auth.setPassword.invitationAlreadyUsed.title')}
          text={t('common:auth.setPassword.invitationAlreadyUsed.text')}
        />
      );
    case 'invalidInvitation':
      return (
        <AccountInfo
          title={t('common:auth.setPassword.invalidInvitation.title')}
          text={t('common:auth.setPassword.invalidInvitation.text')}
        />
      );
    case 'unknownProblem':
    default:
      return (
        <AccountInfo
          title={t('common:auth.setPassword.unknownProblem.title')}
          text={t('common:auth.setPassword.unknownProblem.text')}
        />
      );
  }
};

const SetPassword = () => (
  <AuthLayout
    sideContent={(<SetPasswordSvg />)}
  >
    <SetPasswordContent />
  </AuthLayout>
);


export default SetPassword;
