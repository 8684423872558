import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import agentRpcClients, { GetScreenshotOptions } from '@/api/agentRpcClients';
import { ErrorWithStatus } from '@/api/ErrorWithStatus';
import { authMetaDataSelector } from '@/redux/user';
import { previewRefreshModalFrequency } from '@/constants/common';
import useInterval from '@/hooks/useInterval';


type ReturnTuple = [
  image: string | undefined,
  isLoadingImage: boolean,
  errorMessage: string | undefined,
  resetLoading: () => void
];

export function useScreenPreviewImage(
  agentOrDeviceId: string | undefined,
  displayNo: number | undefined,
  getScreenOptions: GetScreenshotOptions,
): ReturnTuple {
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  /* getScreenOptions is memoized with separated properties,
   * like this [getScreenOptions.quality, getScreenOptions.width, getScreenOptions.height]
   * to fix JS disability to comper objects */
  const memoizedGetScreenOptions = useMemo(
    () => getScreenOptions,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getScreenOptions.quality, getScreenOptions.width, getScreenOptions.height]
  );

  const authMetaData = useSelector(authMetaDataSelector);
  const loadImage = useCallback(() => {
    if (displayNo !== undefined && agentOrDeviceId) {
      agentRpcClients.getScreenshotDisplay(
        agentOrDeviceId,
        displayNo,
        authMetaData,
        memoizedGetScreenOptions,
      )
        .then((data) => {
          setImage(data);
          setErrorMessage(undefined);
          setIsLoadingImage(false);
        })
        .catch((error) => {
          const errorWithStatus = new ErrorWithStatus(error);
          setErrorMessage(errorWithStatus.message);
          setIsLoadingImage(false);
        });
    } else {
      setIsLoadingImage(false);
    }
  }, [agentOrDeviceId, authMetaData, displayNo, memoizedGetScreenOptions]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  useInterval(() => {
    loadImage();
  }, previewRefreshModalFrequency * 1000);

  return [image, isLoadingImage, errorMessage, () => setIsLoadingImage(true)];
}
