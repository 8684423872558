import React from 'react';


const SvgAdjust = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <g fill="none">
      <path d="M0 0h16v16H0z" />
      <g
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={9.625} cy={3.125} r={1.625} />
        <path d="M1.5 3.667H8M11.25 3.667h3.25" />
        <circle cx={4.75} cy={8} r={1.625} />
        <path d="M1.5 8.542h1.625M6.375 8.542H14.5" />
        <circle cx={12.062} cy={12.875} r={1.625} />
        <path d="M1.5 12.75h8.938M13.688 12.583h.812" />
      </g>
    </g>
  </svg>
);

export default SvgAdjust;
