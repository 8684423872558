import React from 'react';


const SvgRefresh = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <defs>
      <path id="refresh_svg__a" d="M0 0h16v16H0z" />
      <mask
        id="refresh_svg__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16H0z" />
        <use fill="#fff" xlinkHref="#refresh_svg__a" />
      </mask>
    </defs>
    <g fill="none">
      <use xlinkHref="#refresh_svg__a" />
      <g mask="url(#refresh_svg__b)">
        <path d="M0 0h16v16H0V0z" />
        <g
          stroke="#2C3E50"
          strokeDasharray="0 0 0 0"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M13.333 7.333A5.4 5.4 0 003 6M2.667 2.667V6H6" />
        </g>
        <g
          stroke="#2C3E50"
          strokeDasharray="0 0 0 0"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M2.667 8.667A5.4 5.4 0 0013 10M13.333 13.333V10H10" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgRefresh;
