import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palette.secondary.main};

    @media (min-width: 768px) {
      overflow: hidden;
    }
  }
`;
