import React from 'react';
import { useField } from 'react-final-form';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { setDayEndHour, setDayStartHour } from '@/utils/helpers';


const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => theme.border};
  max-width: 40px;
  min-width: 40px;

  span {
    margin: 0;
  }

  svg {
    height: 0.6em;
    width: 0.6em;
  }
`;

interface FFDateManipulateButtonProps {
  direction: 'forward' | 'back',
  onChange: () => void,
  range: number,
  timeUnit: 'month' | 'year' | 'day',
}

const ManipulateDateButton = ({
  direction, onChange, range, timeUnit,
}: FFDateManipulateButtonProps) => {
  const fromField = useField('dateFrom');
  const toField = useField('dateTo');
  const isForward = direction === 'forward';
  const today = dayjs();
  // disable forward button when dateTo is same as or after today (or same moth/year as current)
  const disabled = isForward && toField.input.value.isSameOrAfter(today, timeUnit);

  const handleChange = () => {
    // set from and to fields to a date back/forward the amount of time (range)
    // in time unit(year/month/day) specified by timeUnit prop
    fromField.input.onChange(isForward
      ? setDayEndHour(fromField.input.value.add(range, timeUnit).startOf(timeUnit))
      : setDayStartHour(fromField.input.value.subtract(range, timeUnit).startOf(timeUnit)));
    toField.input.onChange(isForward
      ? setDayStartHour(toField.input.value.add(range, timeUnit).endOf(timeUnit))
      : setDayEndHour(toField.input.value.subtract(range, timeUnit).endOf(timeUnit)));
    onChange();
  };

  return (
    <StyledButton
      startIcon={isForward ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
      disabled={disabled}
      onClick={handleChange}
    />
  );
};

export default ManipulateDateButton;
