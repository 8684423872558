import React from 'react';
import { useTheme } from '@material-ui/core/styles';


interface SvgSettingsProps {
  stroke?: string,
  onClick?: () => void,
}

const SvgSettings = ({ stroke, onClick, ...otherProps }: SvgSettingsProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      onClick={onClick}
      {...otherProps}
    >
      <defs>
        <path id="settings_svg__a" d="M0 0h16v16H0z" />
        <mask
          id="settings_svg__b"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <path d="M0 0h16v16H0z" />
          <use fill="#fff" xlinkHref="#settings_svg__a" />
        </mask>
      </defs>
      <g fill="none">
        <use xlinkHref="#settings_svg__a" />
        <g mask="url(#settings_svg__b)">
          <path d="M0 0h16v16H0V0z" />
          <path
            stroke={stroke ?? theme.palette.primary.dark}
            strokeDasharray="0 0 0 0"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.883 2.878c.284-1.17 1.95-1.17 2.234 0a1.15 1.15 0 001.715.71c1.029-.626 2.207.551 1.58 1.58a1.15 1.15 0 00.71 1.715c1.17.284 1.17 1.95 0 2.234a1.15 1.15 0 00-.71 1.715c.626 1.029-.551 2.207-1.58 1.58a1.15 1.15 0 00-1.715.71c-.284 1.17-1.95 1.17-2.234 0a1.15 1.15 0 00-1.715-.71c-1.029.626-2.207-.551-1.58-1.58a1.15 1.15 0 00-.71-1.715c-1.17-.284-1.17-1.95 0-2.234a1.15 1.15 0 00.71-1.715c-.626-1.029.551-2.207 1.58-1.58.665.405 1.531.047 1.715-.71h0z"
          />
          <circle
            cx={8}
            cy={8}
            stroke={stroke ?? theme.palette.primary.dark}
            strokeDasharray="0 0 0 0"
            strokeLinecap="round"
            strokeLinejoin="round"
            r={2}
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgSettings;
