import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Loader from '@/components/Loader';
import { scrollbarStyles } from '@/utils/styles';
import ExclamationMark from '@/components/fromSvg/ExclamationMark';


const MissingScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  width: 100%;
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.screenError.backgroundColor};
`;

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.screenError.textColor};
  text-align: center;
  margin-top: 2px;
  line-height: 14px;
  overflow: hidden;
`;

const LoaderContainer = styled.div<{ $isModal?: boolean }>`
  display: grid;
  min-height: ${({ $isModal }) => ($isModal ? '70vh' : 'unset')};
`;

const Screenshot = styled.img<{ onClick?: () => void }>`
  width: 100%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  border-bottom: ${({ theme }) => theme.border};
`;

const ScreenshotContainer = styled.div<{ $isModal?: boolean }>`
  max-width: 100%;
  overflow: ${({ $isModal }) => ($isModal ? 'auto' : 'hidden')};
  ${scrollbarStyles}
`;

export interface ScreenProps {
  isLoadingImage: boolean,
  image: string | undefined,
  isModal?: boolean,
  onScreenClick?: () => void,
  errorMessage?: string,
}

const Screen = React.memo(({
  isLoadingImage,
  image,
  isModal,
  onScreenClick = undefined,
  errorMessage,
}: ScreenProps) => {
  if (isLoadingImage) {
    return (
      <LoaderContainer $isModal={isModal}>
        <Loader />
      </LoaderContainer>
    );
  }

  if (errorMessage) {
    return (
      <MissingScreen>
        <ExclamationMark />
        <ErrorText variant="caption">
          {errorMessage}
        </ErrorText>
      </MissingScreen>
    );
  }

  return (
    <ScreenshotContainer $isModal={isModal}>
      <Screenshot
        src={image}
        alt="screenshot"
        onClick={onScreenClick}
      />
    </ScreenshotContainer>
  );
});


export default Screen;
