import { combineReducers } from '@reduxjs/toolkit';
import systemCategoriesCollectionReducer, { SystemCategoriesCollectionState } from '@/redux/collections/systemCategoriesCollection';
import appOptionsReducer, { AppOptionsState } from './appOptions';
import layoutReducer, { LayoutState } from './layout';
import userReducer, { UserState } from './user';
import userPermissionsReducer, { UserPermissionsState } from './userPermissions';
import roleUsersCollectionReducer, { RoleUsersCollectionState } from './collections/roleUsersCollection';
import rolesCollectionReducer, { RolesCollectionState } from './collections/rolesCollection';
import descriptionsCollectionReducer, { DescriptionsCollectionState } from './collections/descriptionsCollection';
import contentFiltersCollectionReducer, { ContentFiltersCollectionState } from './collections/contentFiltersCollection';
import urlCategoriesCollectionReducer, { UrlCategoriesCollectionState } from './collections/urlCategoriesCollection';
import settingsCollectionReducer, { SettingsCollectionState } from './collections/settingsCollection';
import screensPreviewReducer, { ScreensPreviewState } from './screensPreview';


const reducers = {
  user: userReducer,
  appOptions: appOptionsReducer,
  layout: layoutReducer,
  userPermissions: userPermissionsReducer,
  roleUsersCollection: roleUsersCollectionReducer,
  rolesCollection: rolesCollectionReducer,
  descriptionsCollection: descriptionsCollectionReducer,
  contentFiltersCollection: contentFiltersCollectionReducer,
  urlCategoriesCollection: urlCategoriesCollectionReducer,
  systemCategoriesCollection: systemCategoriesCollectionReducer,
  settingsCollection: settingsCollectionReducer,
  screensPreview: screensPreviewReducer,
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
export interface RootState {
  user: UserState,
  appOptions: AppOptionsState,
  layout: LayoutState,
  userPermissions: UserPermissionsState,
  roleUsersCollection: RoleUsersCollectionState,
  rolesCollection: RolesCollectionState,
  descriptionsCollection: DescriptionsCollectionState,
  contentFiltersCollection: ContentFiltersCollectionState,
  urlCategoriesCollection: UrlCategoriesCollectionState,
  systemCategoriesCollection: SystemCategoriesCollectionState,
  settingsCollection: SettingsCollectionState,
  screensPreview: ScreensPreviewState,
}
