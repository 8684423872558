import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { apiUrl, defaultTablePageSize } from '@/constants/common';
import { HistoryServiceClient } from '@/api/owpb/pbFiles/History_serviceServiceClientPb';
import type { AccessControlFilters } from '@/redux/layout';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import {
  GetAppHistoryStatsRequest,
  GetWebHistoryRequest,
  GetAppHistoryUsersRequest,
  DomainAndUser,
  DeleteWebHistoryRequest,
  DeleteAppHistoryRequest,
  GetUrlDomainHistoryStatsRequest,
  GetAppHistoryRequest,
} from '../owpb/pbFiles/history_service_pb';
import { handleError } from '../errorHandler';


const client = new HistoryServiceClient(apiUrl);


function parseUserString(userString: string | undefined | null) {
  if (userString === undefined
    || userString === null
    || userString === 'all') {
    return null;
  }
  const user = JSON.parse(userString);
  const formattedUser: DomainAndUser | null = new DomainAndUser();
  formattedUser.setDomainName(user.domainName);
  formattedUser.setUserName(user.userName);
  formattedUser.setFullName(user.fullName);
  return [formattedUser];
}


export function getAppStats(
  deviceId: string[],
  filters: AccessControlFilters | null,
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetAppHistoryStatsRequest();
  request.setDevicesIdList(deviceId);

  if (filters?.dateFrom) {
    request.setTimeFrom(filters.dateFrom);
  }

  if (filters?.dateTo) {
    request.setTimeTo(filters.dateTo);
  }

  const user = parseUserString(filters?.user);
  if (user) {
    request.setUsersList(user);
  }

  return client.getAppHistoryStats(request, authMetaData)
    .then((res) => res.toObject().itemsList)
    .catch(handleError);
}

export async function getDomainStats(
  deviceId: string[],
  filters: AccessControlFilters | null,
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetUrlDomainHistoryStatsRequest();
  request.setDevicesIdList(deviceId);
  if (filters?.dateFrom) {
    request.setTimeFrom(filters.dateFrom);
  }

  if (filters?.dateTo) {
    request.setTimeTo(filters.dateTo);
  }

  const user = parseUserString(filters?.user);
  if (user) {
    request.setUsersList(user);
  }

  try {
    const response = await client.getUrlDomainHistoryStats(request, authMetaData);
    return response.toObject().itemsList;
  } catch (error) {
    handleError(error);
    throw error;
  }
}


export function getBrowsingHistory(
  deviceId: string[],
  filters: AccessControlFilters | null,
  sortingAndPagination?: adminify.TableSortingAndPagination,
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetWebHistoryRequest();
  // number of rows per page
  request.setTake(defaultTablePageSize);
  request.setDevicesIdList(deviceId);

  if (sortingAndPagination?.sortedBy) {
    const { id, desc } = sortingAndPagination.sortedBy;
    request.setSortedBy(id);
    if (desc) {
      request.setReverseOrder(desc);
    }
  }

  if (sortingAndPagination?.skip) {
    request.setSkip(sortingAndPagination.skip);
  }

  if (filters?.dateFrom) {
    request.setTimeFrom(filters.dateFrom);
  }

  if (filters?.dateTo) {
    request.setTimeTo(filters.dateTo);
  }

  const user = parseUserString(filters?.user);
  if (user) {
    request.setUsersList(user);
  }

  return client.getWebHistory(request, authMetaData)
    .then((res) => res.toObject())
    .catch(handleError);
}

export function getAppHistory(
  deviceId: string[],
  filters: AccessControlFilters | null,
  sortingAndPagination?: adminify.TableSortingAndPagination,
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetAppHistoryRequest();
  // number of rows per page
  request.setTake(defaultTablePageSize);
  request.setDevicesIdList(deviceId);

  if (sortingAndPagination?.sortedBy) {
    const { id, desc } = sortingAndPagination.sortedBy;
    request.setSortedBy(id);
    if (desc) {
      request.setReverseOrder(desc);
    }
  }

  if (sortingAndPagination?.skip) {
    request.setSkip(sortingAndPagination.skip);
  }

  if (filters?.dateFrom) {
    request.setTimeFrom(filters.dateFrom);
  }

  if (filters?.dateTo) {
    request.setTimeTo(filters.dateTo);
  }

  return client.getAppHistory(request, authMetaData)
    .then((res) => res.toObject())
    .catch(handleError);
}

export async function deleteBrowsingHistory(timestamp: number) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteWebHistoryRequest();
  request.setLtTimestamp(timestamp);

  try {
    const response = await client.deleteWebHistory(request, authMetaData);
    if (response.toObject()) {
      showNoty('success', i18n.t('common:notifications.removeHistorySuccess'));
    }
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function deleteAppHistory(timestamp: number) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteAppHistoryRequest();
  request.setLtTimestamp(timestamp);

  try {
    const response = await client.deleteAppHistory(request, authMetaData);
    if (response.toObject()) {
      showNoty('success', i18n.t('common:notifications.removeHistorySuccess'));
    }
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export function getAppHistoryUsersRequest(
  deviceIds: Array<string>, dates?: adminify.DateRange | null
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetAppHistoryUsersRequest();
  request.setDevicesIdList(deviceIds);
  if (dates) {
    request.setTimeFrom(dates.dateFrom);
    request.setTimeTo(dates.dateTo);
  }
  return client.getAppHistoryUsers(request, authMetaData)
    .then((res) => res.toObject().itemsList)
    .catch(handleError);
}
