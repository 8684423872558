// source: customer_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var customer_pb = require('./customer_pb.js');
goog.object.extend(proto, customer_pb);
goog.exportSymbol('proto.owpb.CustomerInvoiceInfo', null, global);
goog.exportSymbol('proto.owpb.GetCustomerInvoiceInfoRequest', null, global);
goog.exportSymbol('proto.owpb.GetCustomerRequest', null, global);
goog.exportSymbol('proto.owpb.GetLicenseRequest', null, global);
goog.exportSymbol('proto.owpb.InvoiceRecipient', null, global);
goog.exportSymbol('proto.owpb.UpdateCustomerInvoiceInfoRequest', null, global);
goog.exportSymbol('proto.owpb.UpdateCustomerKeyDescriptionRequest', null, global);
goog.exportSymbol('proto.owpb.UpdateCustomerLanguageRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.InvoiceRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.InvoiceRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.InvoiceRecipient.displayName = 'proto.owpb.InvoiceRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.CustomerInvoiceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.CustomerInvoiceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.CustomerInvoiceInfo.displayName = 'proto.owpb.CustomerInvoiceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetCustomerInvoiceInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.GetCustomerInvoiceInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetCustomerInvoiceInfoRequest.displayName = 'proto.owpb.GetCustomerInvoiceInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.GetCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetCustomerRequest.displayName = 'proto.owpb.GetCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.UpdateCustomerInvoiceInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.UpdateCustomerInvoiceInfoRequest.displayName = 'proto.owpb.UpdateCustomerInvoiceInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.UpdateCustomerKeyDescriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.UpdateCustomerKeyDescriptionRequest.displayName = 'proto.owpb.UpdateCustomerKeyDescriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.UpdateCustomerLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.UpdateCustomerLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.UpdateCustomerLanguageRequest.displayName = 'proto.owpb.UpdateCustomerLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.GetLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.GetLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.GetLicenseRequest.displayName = 'proto.owpb.GetLicenseRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.InvoiceRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.InvoiceRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.InvoiceRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.InvoiceRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    taxId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.InvoiceRecipient}
 */
proto.owpb.InvoiceRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.InvoiceRecipient;
  return proto.owpb.InvoiceRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.InvoiceRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.InvoiceRecipient}
 */
proto.owpb.InvoiceRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.InvoiceRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.InvoiceRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.InvoiceRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.InvoiceRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTaxId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line1 = 3;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line2 = 4;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setAddressLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string tax_id = 7;
 * @return {string}
 */
proto.owpb.InvoiceRecipient.prototype.getTaxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.InvoiceRecipient} returns this
 */
proto.owpb.InvoiceRecipient.prototype.setTaxId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.CustomerInvoiceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.CustomerInvoiceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.CustomerInvoiceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CustomerInvoiceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasBuyer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    hasPayer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    buyer: (f = msg.getBuyer()) && proto.owpb.InvoiceRecipient.toObject(includeInstance, f),
    payer: (f = msg.getPayer()) && proto.owpb.InvoiceRecipient.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.CustomerInvoiceInfo}
 */
proto.owpb.CustomerInvoiceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.CustomerInvoiceInfo;
  return proto.owpb.CustomerInvoiceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.CustomerInvoiceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.CustomerInvoiceInfo}
 */
proto.owpb.CustomerInvoiceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBuyer(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPayer(value);
      break;
    case 3:
      var value = new proto.owpb.InvoiceRecipient;
      reader.readMessage(value,proto.owpb.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setBuyer(value);
      break;
    case 4:
      var value = new proto.owpb.InvoiceRecipient;
      reader.readMessage(value,proto.owpb.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setPayer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.CustomerInvoiceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.CustomerInvoiceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.CustomerInvoiceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CustomerInvoiceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasBuyer();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHasPayer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBuyer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.owpb.InvoiceRecipient.serializeBinaryToWriter
    );
  }
  f = message.getPayer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.owpb.InvoiceRecipient.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool has_buyer = 1;
 * @return {boolean}
 */
proto.owpb.CustomerInvoiceInfo.prototype.getHasBuyer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
 */
proto.owpb.CustomerInvoiceInfo.prototype.setHasBuyer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool has_payer = 2;
 * @return {boolean}
 */
proto.owpb.CustomerInvoiceInfo.prototype.getHasPayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
 */
proto.owpb.CustomerInvoiceInfo.prototype.setHasPayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional InvoiceRecipient buyer = 3;
 * @return {?proto.owpb.InvoiceRecipient}
 */
proto.owpb.CustomerInvoiceInfo.prototype.getBuyer = function() {
  return /** @type{?proto.owpb.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.owpb.InvoiceRecipient, 3));
};


/**
 * @param {?proto.owpb.InvoiceRecipient|undefined} value
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
*/
proto.owpb.CustomerInvoiceInfo.prototype.setBuyer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
 */
proto.owpb.CustomerInvoiceInfo.prototype.clearBuyer = function() {
  return this.setBuyer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.CustomerInvoiceInfo.prototype.hasBuyer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InvoiceRecipient payer = 4;
 * @return {?proto.owpb.InvoiceRecipient}
 */
proto.owpb.CustomerInvoiceInfo.prototype.getPayer = function() {
  return /** @type{?proto.owpb.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.owpb.InvoiceRecipient, 4));
};


/**
 * @param {?proto.owpb.InvoiceRecipient|undefined} value
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
*/
proto.owpb.CustomerInvoiceInfo.prototype.setPayer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
 */
proto.owpb.CustomerInvoiceInfo.prototype.clearPayer = function() {
  return this.setPayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.CustomerInvoiceInfo.prototype.hasPayer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.owpb.CustomerInvoiceInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.CustomerInvoiceInfo} returns this
 */
proto.owpb.CustomerInvoiceInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetCustomerInvoiceInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetCustomerInvoiceInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetCustomerInvoiceInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetCustomerInvoiceInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetCustomerInvoiceInfoRequest}
 */
proto.owpb.GetCustomerInvoiceInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetCustomerInvoiceInfoRequest;
  return proto.owpb.GetCustomerInvoiceInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetCustomerInvoiceInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetCustomerInvoiceInfoRequest}
 */
proto.owpb.GetCustomerInvoiceInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetCustomerInvoiceInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetCustomerInvoiceInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetCustomerInvoiceInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetCustomerInvoiceInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetCustomerRequest}
 */
proto.owpb.GetCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetCustomerRequest;
  return proto.owpb.GetCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetCustomerRequest}
 */
proto.owpb.GetCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.UpdateCustomerInvoiceInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.UpdateCustomerInvoiceInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    buyer: (f = msg.getBuyer()) && proto.owpb.InvoiceRecipient.toObject(includeInstance, f),
    hasPayer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    payer: (f = msg.getPayer()) && proto.owpb.InvoiceRecipient.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.UpdateCustomerInvoiceInfoRequest;
  return proto.owpb.UpdateCustomerInvoiceInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.UpdateCustomerInvoiceInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.owpb.InvoiceRecipient;
      reader.readMessage(value,proto.owpb.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setBuyer(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPayer(value);
      break;
    case 3:
      var value = new proto.owpb.InvoiceRecipient;
      reader.readMessage(value,proto.owpb.InvoiceRecipient.deserializeBinaryFromReader);
      msg.setPayer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.UpdateCustomerInvoiceInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.UpdateCustomerInvoiceInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuyer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.owpb.InvoiceRecipient.serializeBinaryToWriter
    );
  }
  f = message.getHasPayer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPayer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.owpb.InvoiceRecipient.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional InvoiceRecipient buyer = 1;
 * @return {?proto.owpb.InvoiceRecipient}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.getBuyer = function() {
  return /** @type{?proto.owpb.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.owpb.InvoiceRecipient, 1));
};


/**
 * @param {?proto.owpb.InvoiceRecipient|undefined} value
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
*/
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.setBuyer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.clearBuyer = function() {
  return this.setBuyer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.hasBuyer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_payer = 2;
 * @return {boolean}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.getHasPayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.setHasPayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional InvoiceRecipient payer = 3;
 * @return {?proto.owpb.InvoiceRecipient}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.getPayer = function() {
  return /** @type{?proto.owpb.InvoiceRecipient} */ (
    jspb.Message.getWrapperField(this, proto.owpb.InvoiceRecipient, 3));
};


/**
 * @param {?proto.owpb.InvoiceRecipient|undefined} value
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
*/
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.setPayer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.clearPayer = function() {
  return this.setPayer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.hasPayer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.UpdateCustomerInvoiceInfoRequest} returns this
 */
proto.owpb.UpdateCustomerInvoiceInfoRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.UpdateCustomerKeyDescriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.UpdateCustomerKeyDescriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyDescription: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.UpdateCustomerKeyDescriptionRequest}
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.UpdateCustomerKeyDescriptionRequest;
  return proto.owpb.UpdateCustomerKeyDescriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.UpdateCustomerKeyDescriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.UpdateCustomerKeyDescriptionRequest}
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.UpdateCustomerKeyDescriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.UpdateCustomerKeyDescriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key_description = 1;
 * @return {string}
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.prototype.getKeyDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.UpdateCustomerKeyDescriptionRequest} returns this
 */
proto.owpb.UpdateCustomerKeyDescriptionRequest.prototype.setKeyDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.UpdateCustomerLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.UpdateCustomerLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.UpdateCustomerLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.UpdateCustomerLanguageRequest}
 */
proto.owpb.UpdateCustomerLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.UpdateCustomerLanguageRequest;
  return proto.owpb.UpdateCustomerLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.UpdateCustomerLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.UpdateCustomerLanguageRequest}
 */
proto.owpb.UpdateCustomerLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.UpdateCustomerLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.UpdateCustomerLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.UpdateCustomerLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.UpdateCustomerLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.owpb.UpdateCustomerLanguageRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.UpdateCustomerLanguageRequest} returns this
 */
proto.owpb.UpdateCustomerLanguageRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.GetLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.GetLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.GetLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.GetLicenseRequest}
 */
proto.owpb.GetLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.GetLicenseRequest;
  return proto.owpb.GetLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.GetLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.GetLicenseRequest}
 */
proto.owpb.GetLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.GetLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.GetLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.GetLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.GetLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.owpb);
