import { Observable, Subject } from 'rxjs';
import {
  CollectionUpdate,
  DeleteItemId,
  ItemUpdate,
  UpdateType,
} from '@/api/owpb/pbFiles/updates_service_pb';
import { CollectionHolder, IProtoBufCollection } from './CollectionsInterfaces';

/**
 * Obsługa kolekcji GRPC - implementacja IProtoBufCollection
 */
export class ProtoBuffCollection<ObjectT extends { id: string }> implements IProtoBufCollection {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  readonly collectionId: number;
  version: number;
  private readonly itemCreator: (bytes: Uint8Array) => ObjectT;
  private readonly itemArrayCreator: (bytes: Uint8Array) => Array<ObjectT>;
  private updateSubject: Subject<IProtoBufCollection>;
  private collectionHolder: CollectionHolder<ObjectT>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  /**
   *
   * @param collectionId Identyfikator kolekcji
   * @param TCreator Funkcja tworząca element kolekcji z Uint8Array
   * @param TArrayCreator Funkcja tworząca tablicę elementów kolekcji z Uint8Array
   * @param collectionHolder Instancja przechowująca elementy kolekcji
   */
  constructor(
    collectionId: number,
    TItemCreator: (bytes: Uint8Array) => ObjectT,
    TArrayCreator: (bytes: Uint8Array) => Array<ObjectT>,
    collectionHolder: CollectionHolder<ObjectT>,
  ) {
    this.collectionId = collectionId;
    this.itemCreator = TItemCreator;
    this.itemArrayCreator = TArrayCreator;
    this.collectionHolder = collectionHolder;
    this.version = 0;
    this.updateSubject = new Subject<IProtoBufCollection>();
  }

  get items(): Array<ObjectT> {
    return this.collectionHolder.items();
  }

  get update$(): Observable<IProtoBufCollection> {
    return this.updateSubject.asObservable();
  }

  update(update: CollectionUpdate): boolean {
    const updatesList = update.getUpdatesList();
    for (let i = 0; i < updatesList.length; i += 1) {
      if (!this.performUpdate(updatesList[i])) {
        return false;
      }
    }

    this.version = update.getToVersion();
    this.updateSubject.next(this);
    return true;
  }

  private performUpdate(itemUpdate: ItemUpdate): boolean {
    let result = false;

    const payload = itemUpdate.getPayload_asU8();
    switch (itemUpdate.getOperation()) {
      case UpdateType.FULL_COLLECTION: {
        result = this.collectionHolder.fullCollection(this.itemArrayCreator(payload));
        break;
      }
      case UpdateType.CREATE_ITEM: {
        result = this.collectionHolder.createItem(this.itemCreator(payload));
        break;
      }
      case UpdateType.UPDATE_ITEM: {
        const updatedItem = this.itemCreator(payload);
        result = this.collectionHolder.updateItem(updatedItem.id, updatedItem);
        break;
      }
      case UpdateType.DELETE_ITEM: {
        const deletedItemId = DeleteItemId.deserializeBinary(payload).toObject().id;
        result = this.collectionHolder.deleteItem(deletedItemId);
        break;
      }
      default:
        throw new Error('Unknown UpdateType in ProtoBuffCollection.updateItem() ');
    }
    return result;
  }
}
