import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import AccessControlElement from '@/components/accessControlMenu/AccessControlElement';
import { ApiConsts } from '@/api/ApiConsts';
import FullAccess from '@/components/fromSvg/internetAccessIcons/FullAccess';
import ContentControl from '@/components/fromSvg/internetAccessIcons/ContentControl';
import InternetBlockage from '@/components/fromSvg/internetAccessIcons/InternetBlockage';
import Restrictive from '@/components/fromSvg/internetAccessIcons/Restrictive';
import AccessControlContextSubMenu from '@/components/AccessControlContextMenu/AccessControlContextSubMenu';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { InetControlState } from '@/api/owpb/pbFiles/basic_pb';
import { useTreeItem } from '@/hooks/useTreeItem';


const StyledMenuItem = styled(MenuItem)`
  padding: 0;

  button {
    border-radius: 0;
    padding: 10px 0 10px 15px;
    justify-content: flex-start;
    margin: 0;
  }
`;

interface InetAccessControlSubmenuProps {
  handleClose: () => void,
  deviceId: string | string[],
}

const InetAccessControlSubmenu = ({ handleClose, deviceId }: InetAccessControlSubmenuProps) => {
  const { t } = useTranslation(['common']);
  const { treeItem } = useTreeItem(!Array.isArray(deviceId) ? deviceId : '');

  const handleAccessChange = (value: string) => {
    updateSettingsDeviceAccess(
      ApiConsts.SettingsDeviceInetAccess,
      value,
      Array.isArray(deviceId) ? deviceId : [deviceId]
    );
  };

  return (
    <AccessControlContextSubMenu title={t('common:app.internetAccessControl')}>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange('2')}
          isActive={treeItem?.inetAccess === InetControlState.INET_ALLOW}
          activeDependentBgColor
          fullWidth
          startIcon={<FullAccess />}
        >
          {t('common:app.fullAccess')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange('0')}
          isActive={treeItem?.inetAccess === InetControlState.INET_CHECK}
          activeDependentBgColor
          fullWidth
          startIcon={<ContentControl />}
        >
          {t('common:app.contentControl')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange('1')}
          isActive={treeItem?.inetAccess === InetControlState.INET_BLOCK}
          activeDependentBgColor
          fullWidth
          startIcon={<InternetBlockage />}
        >
          {t('common:app.blockedInternet')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange('4')}
          isActive={treeItem?.inetAccess === InetControlState.INET_STRICT}
          activeDependentBgColor
          fullWidth
          startIcon={<Restrictive />}
        >
          {t('common:app.restrictive')}
        </AccessControlElement>
      </StyledMenuItem>
    </AccessControlContextSubMenu>
  );
};


export default InetAccessControlSubmenu;
