import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';
import SvgComputer from '@/components/fromSvg/Computer';
import { screenPreviewHeaderAndFooterHeight } from '@/constants/common';
import { scrollbarStyles } from '@/utils/styles';


export const StyledPaper = styled(Paper)<{ $isPopup?: boolean, $width?: number, $imageHeight?: number }>`
  --header-and-footer-height: ${screenPreviewHeaderAndFooterHeight}px;

  display: grid;
  grid-template-rows: ${({ $imageHeight, $isPopup }) => (
    $isPopup
      ? 'var(--header-and-footer-height) auto var(--header-and-footer-height)'
      : `var(--header-and-footer-height) ${$imageHeight}px var(--header-and-footer-height)`
  )};
  align-items: stretch;

  width: ${({ $isPopup, $width }) => ($isPopup ? '100%' : `${$width}px`)};
  height: ${({ $isPopup }) => ($isPopup ? '100%' : 'unset')};
  overflow: auto;
  margin: 0 auto;
  overflow-x: hidden;
  ${scrollbarStyles};
`;

export const ComputerIcon = styled(SvgComputer)`
  min-height: 1.3em;
  min-width: 1.3em;
`;

export const Name = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`;
