import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${({ theme }) => theme.border};
  width: fit-content;
  padding: 10px;
  margin: 0 10px;
  height: 100%;

  :nth-child(3) {
    border-right: none;
  }
`;

const Elements = styled.div`
  display: flex;
  align-items: center;
`;

interface AccessControlMenuElementProps {
  title: string,
  children: React.ReactNode,
}

const AccessControlMenuCategory = ({
  title, children,
}: AccessControlMenuElementProps) => (
  <Container>
    <Typography variant="caption">
      {title}
    </Typography>
    <Elements>
      {children}
    </Elements>
  </Container>
);

export default AccessControlMenuCategory;
