import React from 'react';
import { Container, Grid, Hidden } from '@material-ui/core';
import styled from 'styled-components';
import SvgLogo from '@/components/fromSvg/Logo';
import NotyContainer from '@/components/NotyContainer';


const StyledGrid = styled(Grid)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const ImgGridElement = styled(Grid)`
  background-color: ${({ theme }) => theme.authBackground};
`;


const StyledContainer = styled(Container)`
  width: 80%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AuthContainer = styled(Container)`
  width: 70%;
  height: 100%;
  display: grid;
  padding: 10vh 0 80px;
`;

const AdminifyLogo = styled(SvgLogo)`
  width: 200px;
  padding-bottom: 20px;
  height: auto;
  align-self: flex-start;
`;

const AuthLinks = styled(Container)`
  align-self: end;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export interface AuthLayoutProps {
  children: React.ReactNode,
  sideContent: React.ReactElement,
  footer?: React.ReactElement,
}

const AuthLayout = ({
  children,
  sideContent,
  footer,
}: AuthLayoutProps) => (
  <StyledGrid container>
    <NotyContainer />
    <Hidden only="xs">
      <ImgGridElement item xs={12} md={7}>
        <StyledContainer>
          { sideContent }
        </StyledContainer>
      </ImgGridElement>
    </Hidden>
    <Grid item xs={12} md={5}>
      <AuthContainer>
        <AdminifyLogo />
        {children}
        {footer && (
          <AuthLinks>
            {footer}
          </AuthLinks>
        )}
      </AuthContainer>
    </Grid>
  </StyledGrid>
);

export default AuthLayout;
