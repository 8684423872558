import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ApiConsts } from '@/api/ApiConsts';
import CollectionsUpdater from '@/api/collections/CollectionsUpdater';
import FiltersService from '@/api/services/FiltersService';
import ConfigurationMainContainer from '@/components/configuration/ConfigurationMainContainer';
import { urlStructure } from '@/constants/urlStructure';
import SystemCategories from '@/pages/app/configuration/SystemCategories';
import SecuredRoute from '@/components/SecuredRoute';
import ConfigurationUsers from './ConfigurationUsers';
import UserCategories from './UserCategories';


const Configuration = () => {
  useEffect(() => {
    let needUpdate = false;
    if (!CollectionsUpdater.isCollectionAdded(ApiConsts.CollectionIDUrlCategories)) {
      CollectionsUpdater.addCollection(FiltersService.urlCategoriesCollection);
      needUpdate = true;
    }
    if (!CollectionsUpdater.isCollectionAdded(ApiConsts.CollectionIDSystemUrlCategories)) {
      CollectionsUpdater.addCollection(FiltersService.systemCategoriesCollection);
      needUpdate = true;
    }
    if (!CollectionsUpdater.isCollectionAdded(ApiConsts.CollectionIDContentFilters)) {
      CollectionsUpdater.addCollection(FiltersService.contentFiltersCollection);
      needUpdate = true;
    }
    if (needUpdate) {
      void CollectionsUpdater.updateCollections();
    }
  }, []);

  return (
    <ConfigurationMainContainer>
      <Switch>
        <Redirect
          exact
          from={urlStructure.configuration}
          to={urlStructure.configurationUsers}
        />
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.configurationUsers}
        >
          <ConfigurationUsers />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAdmin,
            ApiConsts.PermissionAPIFilters,
          ]}
          exact
          path={urlStructure.configurationUserCategories}
        >
          <UserCategories />
        </SecuredRoute>
        <SecuredRoute
          allowedPermissions={[
            ApiConsts.PermissionAPIAdmin,
          ]}
          exact
          path={urlStructure.configurationSystemCategories}
        >
          <SystemCategories />
        </SecuredRoute>
      </Switch>
    </ConfigurationMainContainer>
  );
};


export default Configuration;
