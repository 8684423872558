import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { handleError } from '@/api/errorHandler';
import { SystemServiceClient } from '../owpb/pbFiles/System_serviceServiceClientPb';
import { GetSystemI18nRequest } from '../owpb/pbFiles/system_service_pb';


const systemServiceClient: SystemServiceClient = new SystemServiceClient(apiUrl);

export async function getSystemInfo() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetSystemI18nRequest();

  try {
    const response = await systemServiceClient.getSystemI18n(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}
