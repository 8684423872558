import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle } from '@material-ui/core';
import ModalContent from '../ModalContent';


export interface GenericOptionsModalProps {
  title: React.ReactNode,
  isOpen: boolean,
  onClose: () => void,
  children: React.ReactNode,
  disableBackdropClick?: boolean,
}

const GenericOptionsModal = ({
  title,
  isOpen,
  onClose,
  children,
  disableBackdropClick = false,
}: GenericOptionsModalProps) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    disableBackdropClick={disableBackdropClick}
  >
    <DialogTitle>
      {title}
    </DialogTitle>
    <ModalContent>
      {children}
    </ModalContent>
  </Dialog>
);


export default GenericOptionsModal;
