import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { scrollbarStyles } from '@/utils/styles';


const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 30px;
  ${scrollbarStyles}
`;

const SettingsContainer = styled.div`
  margin: 5px 0;
  display: flex;
`;

interface AccessControlStatisticsPageProps {
  settingsComponent?: React.ReactNode,
}

const AccessControlStatisticsPage = ({
  settingsComponent,
  children,
}: PropsWithChildren<AccessControlStatisticsPageProps>) => (
  <>
    {settingsComponent && (
      <SettingsContainer>
        {settingsComponent}
      </SettingsContainer>
    )}
    <Container
      role="tabpanel"
    >
      {children}
    </Container>
  </>
);

export default AccessControlStatisticsPage;
