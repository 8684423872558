import React, { PropsWithChildren } from 'react';
import { useField } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import { FFBaseProps } from './types';


interface BaseProps extends FFBaseProps<string | null> {
  variant?: 'standard' | 'filled' | 'outlined',
  placeholder?: string,
  label?: string,
  type?: string,
  required?: boolean,
  error?: string,
  reserveSpaceForHelperText?: boolean,
  disabled?: boolean,
  fullWidth?: boolean,
  InputProps?: object,
  noEmptyValue?: boolean,
  undefReplacement?: undefined | string,
}

export interface InputProps extends BaseProps {
  select?: false | undefined,
  multiline?: boolean,
  rows?: number,
}

export interface SelectProps extends BaseProps {
  select: true,
  children: React.ReactNode,
}

export type FFTextInputProps = InputProps | SelectProps;

type FocusEvent = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

const FFTextInput = ({
  name,
  variant = 'standard',
  disabled,
  placeholder,
  type,
  required,
  error,
  config,
  children,
  undefReplacement,
  reserveSpaceForHelperText,
  noEmptyValue,
  ...otherProps
}: PropsWithChildren<FFTextInputProps>) => {
  const { input, meta } = useField(name, config);
  const theme = useTheme();

  let { value } = input;
  if (undefReplacement && value === undefined) {
    value = undefReplacement;
  }

  if (undefReplacement && noEmptyValue && value === '') {
    value = undefReplacement;
  }

  const emptyHelperText = reserveSpaceForHelperText ? ' ' : undefined;
  const helperText = (meta.touched && meta.error) ? meta.error : emptyHelperText;
  return (
    <TextField
      type={type ?? input.type}
      variant={variant}
      onBlur={input.onBlur as FocusEvent}
      onFocus={input.onFocus as FocusEvent}
      onChange={input.onChange}
      error={meta.touched && meta.error !== undefined}
      helperText={helperText}
      value={value}
      name={name}
      placeholder={placeholder}
      required={required}
      InputLabelProps={{
        style: { color: theme.palette.secondary.dark },
      }}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </TextField>
  );
};

export default FFTextInput;
