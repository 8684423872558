import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Cell, Column, useFlexLayout, useTable,
} from 'react-table';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { GenericOptionsModal } from '@/components/GenericOptionsModal';
import { RoleWithStatus, windowsUsersRolesWithStatusSelector } from '@/redux/collections/rolesCollection';
import CategoryStatusSelect from '@/components/ConfigTableLayout/CategoryStatusSelect';
import ConfigSubTableLayout from '@/components/ConfigTableLayout/ConfigSubTableLayout';


const CloseButton = styled(Button)`
  text-transform: none;
  margin-top: 15px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

interface ManageGroupStatusModalProps {
  open: boolean,
  onClose: () => void,
  itemId: string,
}

const ManageGroupStatusModal = ({ open, onClose, itemId }: ManageGroupStatusModalProps) => {
  const { t } = useTranslation(['common']);
  const windowsRoles = useSelector(windowsUsersRolesWithStatusSelector(itemId));
  const data: RoleWithStatus[] = useMemo(() => windowsRoles, [windowsRoles]);

  const columns: Array<Column<RoleWithStatus>> = React.useMemo(
    () => [
      {
        Header: t('common:common.group'),
        id: 'role',
        width: 220,
        Cell: ({ row }: Cell<RoleWithStatus>) => row.original.name,
      },
      {
        Header: t('common:common.status'),
        id: 'status',
        width: 275,
        Cell: ({ row }: Cell<RoleWithStatus>) => (
          <CategoryStatusSelect
            status={row.original.status}
            categoryId={itemId}
            roleId={row.original.id}
          />
        ),
      },
    ],
    [itemId, t]
  );

  const tableInstance = useTable<RoleWithStatus>({
    columns,
    data,
  }, useFlexLayout);


  return (
    <GenericOptionsModal
      title={t('common:configuration.status.dependsOnUser')}
      isOpen={open}
      onClose={onClose}
    >
      <ConfigSubTableLayout<RoleWithStatus>
        tableInstance={tableInstance}
        roleStatusLayout
      />
      <CloseButton onClick={onClose}>
        {t('common:common.close')}
      </CloseButton>
    </GenericOptionsModal>
  );
};

export default ManageGroupStatusModal;
