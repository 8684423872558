import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import FFCheckbox from '@/components/finalForm/FFCheckbox';
import {
  screenPreviewSettingsSelector,
  setScreenPreviewNameSettings,
} from '@/redux/appOptions';


const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface PreviewNameSettingsValues {
  name: boolean,
  domain: boolean,
  login: boolean,
  nameAndSurname: boolean,
}

export interface PreviewSettingsProps {
  isOpen: boolean,
  onClose: () => void,
}

const PreviewNameSettings = ({ isOpen, onClose }: PreviewSettingsProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const nameSettings = useSelector(screenPreviewSettingsSelector);

  return (
    <FFGenericOptionsModal<PreviewNameSettingsValues>
      title={t('common:app.settings')}
      isOpen={isOpen}
      onSubmit={(values) => {
        const formattedValues = { ...values, name: undefined };
        dispatch(setScreenPreviewNameSettings(formattedValues));
        onClose();
      }}
      onClose={onClose}
      formProps={{
        initialValues: nameSettings,
      }}
    >
      {() => (
        <FieldsContainer>
          <FormControlLabel
            control={(
              <FFCheckbox
                color="primary"
                name="name"
                disabled
                config={{
                  type: 'checkbox',
                  defaultValue: 'true',
                }}
              />
            )}
            label={t('common:app.showComputerName')}
          />
          <FormControlLabel
            control={(
              <FFCheckbox
                color="primary"
                name="domain"
                config={{
                  type: 'checkbox',
                }}
              />
            )}
            label={t('common:app.showDomainName')}
          />
          <FormControlLabel
            control={(
              <FFCheckbox
                color="primary"
                name="login"
                config={{
                  type: 'checkbox',
                }}
              />
            )}
            label={t('common:app.showLogin')}
          />
          <FormControlLabel
            control={(
              <FFCheckbox
                color="primary"
                name="nameAndSurname"
                config={{
                  type: 'checkbox',
                }}
              />
            )}
            label={t('common:app.showNameAndSurname')}
          />
        </FieldsContainer>
      )}
    </FFGenericOptionsModal>
  );
};

export default PreviewNameSettings;
