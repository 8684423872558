/* eslint-disable @typescript-eslint/lines-between-class-members */
import { ApiConsts } from '@/api/ApiConsts';
import CollectionHolderRedux from '@/api/collections/CollectionHolderRedux';
import { contentFiltersCollectionHolderFunctions } from '@/redux/collections/contentFiltersCollection';
import { systemCategoriesCollectionHolderFunctions } from '@/redux/collections/systemCategoriesCollection';
import { urlCategoriesCollectionHolderFunctions } from '@/redux/collections/urlCategoriesCollection';
import { ProtoBuffCollection } from '../collections/ProtoBuffCollection';
import {
  ContentFilter,
  ContentFiltersCollection,
  URLCategoriesCollection,
  URLCategory,
} from '../owpb/pbFiles/filter_service_pb';


class FiltersService {
  public urlCategoriesCollection!: ProtoBuffCollection<URLCategory.AsObject>;
  public systemCategoriesCollection!: ProtoBuffCollection<URLCategory.AsObject>;
  public contentFiltersCollection!: ProtoBuffCollection<ContentFilter.AsObject>;
  // public appCategoriesCollection!: ProtoBuffCollection<AppCategory.AsObject>;
  // public appFiltersCollection!: ProtoBuffCollection<AppFilter.AsObject>;

  constructor() {
    this.reInitialize();
  }

  public reInitialize(): void {
    this.urlCategoriesCollection = new ProtoBuffCollection<URLCategory.AsObject>(
      ApiConsts.CollectionIDUrlCategories,
      (bytes) => URLCategory.deserializeBinary(bytes).toObject(),
      (bytes) => URLCategoriesCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<URLCategory.AsObject>(
        ApiConsts.CollectionIDUrlCategories,
        urlCategoriesCollectionHolderFunctions,
      )
    );
    this.systemCategoriesCollection = new ProtoBuffCollection<URLCategory.AsObject>(
      ApiConsts.CollectionIDSystemUrlCategories,
      (bytes) => URLCategory.deserializeBinary(bytes).toObject(),
      (bytes) => URLCategoriesCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<URLCategory.AsObject>(
        ApiConsts.CollectionIDSystemUrlCategories,
        systemCategoriesCollectionHolderFunctions,
      )
    );
    this.contentFiltersCollection = new ProtoBuffCollection<ContentFilter.AsObject>(
      ApiConsts.CollectionIDContentFilters,
      (bytes) => ContentFilter.deserializeBinary(bytes).toObject(),
      (bytes) => ContentFiltersCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<ContentFilter.AsObject>(
        ApiConsts.CollectionIDContentFilters,
        contentFiltersCollectionHolderFunctions,
      )
    );
    // this.appCategoriesCollection = new ProtoBuffCollection<AppCategory.AsObject>(
    //   ApiConsts.CollectionIDAppCategories,
    //   (bytes) => AppCategory.deserializeBinary(bytes).toObject(),
    //   (bytes) => AppCategoriesCollection.deserializeBinary(bytes).toObject().itemsList,
    //   new CollectionHolderRedux<AppCategory.AsObject>(
    //     ApiConsts.CollectionIDAppCategories,
    //     CollectionHolderFunctions,
    //   )
    // );
    // this.appFiltersCollection = new ProtoBuffCollection<AppFilter.AsObject>(
    //   ApiConsts.CollectionIDAppFilters,
    //   (bytes) => AppFilter.deserializeBinary(bytes).toObject(),
    //   (bytes) => AppFiltersCollection.deserializeBinary(bytes).toObject().itemsList,
    //   new CollectionHolderRedux<AppFilter.AsObject>(
    //     ApiConsts.CollectionIDAppFilters,
    //     CollectionHolderFunctions,
    //   )
    // );
  }
}

export default new FiltersService();
