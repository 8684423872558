import { DefaultRootState } from 'react-redux';
import log from 'loglevel';
import { Action, configureStore, Middleware } from '@reduxjs/toolkit';
import { sessionClear, sessionSetItem } from '@/utils/helpers';
import { sessionStoreKey } from '@/constants/common';
import rootReducer, { RootState } from './rootReducer';


const updateSessionStorage: Middleware<
(action: Action) => Action,
RootState
> = ({ getState }) => (next: AppDispatch) => (action: Action) => {
  if (action.type.startsWith('USER/')) {
    if (action.type.startsWith('USER/storeUser')) {
      return next(action);
    }
    if (action.type === 'USER/clearUser') {
      const result = next(action);
      sessionClear();
      return result;
    }
    const result = next(action);
    const { user } = getState();
    sessionSetItem(sessionStoreKey.user, JSON.stringify(user))
      .catch((error) => {
        log.warn('Failed to store user store');
        log.warn(error);
      });
    return result;
  }
  return next(action);
};


export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(updateSessionStorage),
});

export function reduxToObservable() {
  return {
    subscribe({ next }: { next: (state: DefaultRootState) => void}) {
      const unsubscribe = store.subscribe(() => next(store.getState()));
      next(store.getState());
      return { unsubscribe };
    },
  };
}

export type AppDispatch = typeof store.dispatch;
