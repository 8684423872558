/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as roles_service_pb from './roles_service_pb';


export class RolesServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateRole = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.Role,
    (request: roles_service_pb.CreateRoleRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.Role.deserializeBinary
  );

  createRole(
    request: roles_service_pb.CreateRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.Role>;

  createRole(
    request: roles_service_pb.CreateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void): grpcWeb.ClientReadableStream<roles_service_pb.Role>;

  createRole(
    request: roles_service_pb.CreateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/CreateRole',
        request,
        metadata || {},
        this.methodInfoCreateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/CreateRole',
    request,
    metadata || {},
    this.methodInfoCreateRole);
  }

  methodInfoCreateRoleUser = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.RoleUser,
    (request: roles_service_pb.CreateRoleUserRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.RoleUser.deserializeBinary
  );

  createRoleUser(
    request: roles_service_pb.CreateRoleUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.RoleUser>;

  createRoleUser(
    request: roles_service_pb.CreateRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUser) => void): grpcWeb.ClientReadableStream<roles_service_pb.RoleUser>;

  createRoleUser(
    request: roles_service_pb.CreateRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUser) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/CreateRoleUser',
        request,
        metadata || {},
        this.methodInfoCreateRoleUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/CreateRoleUser',
    request,
    metadata || {},
    this.methodInfoCreateRoleUser);
  }

  methodInfoDeleteRole = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.DeleteRoleResponse,
    (request: roles_service_pb.DeleteRoleRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.DeleteRoleResponse.deserializeBinary
  );

  deleteRole(
    request: roles_service_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.DeleteRoleResponse>;

  deleteRole(
    request: roles_service_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.DeleteRoleResponse) => void): grpcWeb.ClientReadableStream<roles_service_pb.DeleteRoleResponse>;

  deleteRole(
    request: roles_service_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.DeleteRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/DeleteRole',
        request,
        metadata || {},
        this.methodInfoDeleteRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/DeleteRole',
    request,
    metadata || {},
    this.methodInfoDeleteRole);
  }

  methodInfoDeleteRoleUser = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.DeleteRoleUserResponse,
    (request: roles_service_pb.DeleteRoleUserRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.DeleteRoleUserResponse.deserializeBinary
  );

  deleteRoleUser(
    request: roles_service_pb.DeleteRoleUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.DeleteRoleUserResponse>;

  deleteRoleUser(
    request: roles_service_pb.DeleteRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.DeleteRoleUserResponse) => void): grpcWeb.ClientReadableStream<roles_service_pb.DeleteRoleUserResponse>;

  deleteRoleUser(
    request: roles_service_pb.DeleteRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.DeleteRoleUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/DeleteRoleUser',
        request,
        metadata || {},
        this.methodInfoDeleteRoleUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/DeleteRoleUser',
    request,
    metadata || {},
    this.methodInfoDeleteRoleUser);
  }

  methodInfoGetCurrentUserPermissions = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.UserPermissions,
    (request: roles_service_pb.GetCurrentUserPermissionsRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.UserPermissions.deserializeBinary
  );

  getCurrentUserPermissions(
    request: roles_service_pb.GetCurrentUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.UserPermissions>;

  getCurrentUserPermissions(
    request: roles_service_pb.GetCurrentUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.UserPermissions) => void): grpcWeb.ClientReadableStream<roles_service_pb.UserPermissions>;

  getCurrentUserPermissions(
    request: roles_service_pb.GetCurrentUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.UserPermissions) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/GetCurrentUserPermissions',
        request,
        metadata || {},
        this.methodInfoGetCurrentUserPermissions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/GetCurrentUserPermissions',
    request,
    metadata || {},
    this.methodInfoGetCurrentUserPermissions);
  }

  methodInfoGetRoles = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.RolesCollection,
    (request: roles_service_pb.GetRolesRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.RolesCollection.deserializeBinary
  );

  getRoles(
    request: roles_service_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.RolesCollection>;

  getRoles(
    request: roles_service_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.RolesCollection) => void): grpcWeb.ClientReadableStream<roles_service_pb.RolesCollection>;

  getRoles(
    request: roles_service_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.RolesCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/GetRoles',
        request,
        metadata || {},
        this.methodInfoGetRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/GetRoles',
    request,
    metadata || {},
    this.methodInfoGetRoles);
  }

  methodInfoGetRoleUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.RoleUsersCollection,
    (request: roles_service_pb.GetRoleUsersRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.RoleUsersCollection.deserializeBinary
  );

  getRoleUsers(
    request: roles_service_pb.GetRoleUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.RoleUsersCollection>;

  getRoleUsers(
    request: roles_service_pb.GetRoleUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUsersCollection) => void): grpcWeb.ClientReadableStream<roles_service_pb.RoleUsersCollection>;

  getRoleUsers(
    request: roles_service_pb.GetRoleUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUsersCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/GetRoleUsers',
        request,
        metadata || {},
        this.methodInfoGetRoleUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/GetRoleUsers',
    request,
    metadata || {},
    this.methodInfoGetRoleUsers);
  }

  methodInfoGetPermissionDescriptions = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.PermissionDescriptionsCollection,
    (request: roles_service_pb.GetPermissionDescriptionsRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.PermissionDescriptionsCollection.deserializeBinary
  );

  getPermissionDescriptions(
    request: roles_service_pb.GetPermissionDescriptionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.PermissionDescriptionsCollection>;

  getPermissionDescriptions(
    request: roles_service_pb.GetPermissionDescriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.PermissionDescriptionsCollection) => void): grpcWeb.ClientReadableStream<roles_service_pb.PermissionDescriptionsCollection>;

  getPermissionDescriptions(
    request: roles_service_pb.GetPermissionDescriptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.PermissionDescriptionsCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/GetPermissionDescriptions',
        request,
        metadata || {},
        this.methodInfoGetPermissionDescriptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/GetPermissionDescriptions',
    request,
    metadata || {},
    this.methodInfoGetPermissionDescriptions);
  }

  methodInfoUpdatePermissionValue = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.Role,
    (request: roles_service_pb.UpdatePermissionValueRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.Role.deserializeBinary
  );

  updatePermissionValue(
    request: roles_service_pb.UpdatePermissionValueRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.Role>;

  updatePermissionValue(
    request: roles_service_pb.UpdatePermissionValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void): grpcWeb.ClientReadableStream<roles_service_pb.Role>;

  updatePermissionValue(
    request: roles_service_pb.UpdatePermissionValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/UpdatePermissionValue',
        request,
        metadata || {},
        this.methodInfoUpdatePermissionValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/UpdatePermissionValue',
    request,
    metadata || {},
    this.methodInfoUpdatePermissionValue);
  }

  methodInfoUpdateRole = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.Role,
    (request: roles_service_pb.UpdateRoleRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.Role.deserializeBinary
  );

  updateRole(
    request: roles_service_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.Role>;

  updateRole(
    request: roles_service_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void): grpcWeb.ClientReadableStream<roles_service_pb.Role>;

  updateRole(
    request: roles_service_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.Role) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/UpdateRole',
        request,
        metadata || {},
        this.methodInfoUpdateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/UpdateRole',
    request,
    metadata || {},
    this.methodInfoUpdateRole);
  }

  methodInfoUpdateRoleUser = new grpcWeb.AbstractClientBase.MethodInfo(
    roles_service_pb.RoleUser,
    (request: roles_service_pb.UpdateRoleUserRequest) => {
      return request.serializeBinary();
    },
    roles_service_pb.RoleUser.deserializeBinary
  );

  updateRoleUser(
    request: roles_service_pb.UpdateRoleUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<roles_service_pb.RoleUser>;

  updateRoleUser(
    request: roles_service_pb.UpdateRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUser) => void): grpcWeb.ClientReadableStream<roles_service_pb.RoleUser>;

  updateRoleUser(
    request: roles_service_pb.UpdateRoleUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: roles_service_pb.RoleUser) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.RolesService/UpdateRoleUser',
        request,
        metadata || {},
        this.methodInfoUpdateRoleUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.RolesService/UpdateRoleUser',
    request,
    metadata || {},
    this.methodInfoUpdateRoleUser);
  }

}

