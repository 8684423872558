/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import agentRpcClients from '@/api/agentRpcClients';
import { agentScreenOptionsSelector } from '@/redux/appOptions';
import { authMetaDataSelector } from './user';


export const fetchScreen = createAsyncThunk<
{ treeItemIdAndScreenNo: string, imageUrl: string }, // Return type of the payload creator
string, // First argument to the payload creator
{ state: DefaultRootState }
>(
  'SCREENS_PREVIEW/fetchScreen',
  async (treeItemIdAndScreenNo, { getState }) => {
    const state = getState();
    const authMetaData = authMetaDataSelector(state);
    const screenOptions = agentScreenOptionsSelector(state);

    const [agentOrDeviceId, displayNo] = treeItemIdAndScreenNo.split(';');

    // eslint-disable-next-line no-useless-catch
    try {
      const imageUrl = await agentRpcClients.getScreenshotDisplay(
        agentOrDeviceId,
        Number(displayNo),
        authMetaData,
        screenOptions,
        true
      );

      return { treeItemIdAndScreenNo, imageUrl };
    } catch (error) {
      throw error;
    }
  }
);

export const setScreenVisible = createAsyncThunk<void, string, { state: DefaultRootState }>(
  'SCREENS_PREVIEW/setScreenVisible',
  (treeItemIdAndScreenNo, { dispatch }) => {
    dispatch(addToVisibleScreens(treeItemIdAndScreenNo));
    void dispatch(fetchScreen(treeItemIdAndScreenNo));
  }
);

interface ImageObject {
  imageUrl?: string | null,
  isLoading: boolean,
  error?: null | string,
}

/* ===========SLICE============= */
export interface ScreensPreviewState {
  visibleScreens: string[],
  screenImages: Record<string, ImageObject>,
}


const initialState = {
  visibleScreens: [],
  screenImages: {},
} as ScreensPreviewState;

const screensPreview = createSlice({
  name: 'SCREENS_PREVIEW',
  initialState,
  reducers: {
    addToVisibleScreens(state: ScreensPreviewState, action: PayloadAction<string>) {
      if (state.visibleScreens.indexOf(action.payload) === -1) {
        return {
          ...state,
          visibleScreens: [
            ...state.visibleScreens,
            action.payload,
          ],
        };
      }
      return state;
    },
    removeFromVisibleScreens(state: ScreensPreviewState, action: PayloadAction<string>) {
      const index = state.visibleScreens.indexOf(action.payload);
      if (index >= 0) {
        return {
          ...state,
          visibleScreens: [
            ...state.visibleScreens.slice(0, index),
            ...state.visibleScreens.slice(index + 1),
          ],
        };
      }
      return state;
    },
    removeScreensData(state: ScreensPreviewState, action: PayloadAction<string[]>) {
      action.payload.forEach((treeItemIdAndScreenNo) => {
        const url = state.screenImages[treeItemIdAndScreenNo]?.imageUrl;
        if (url) {
          URL.revokeObjectURL(url);
        }
        // eslint-disable-next-line no-param-reassign
        delete state.screenImages[treeItemIdAndScreenNo];
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchScreen.pending, (state, action) => ({
      ...state,
      screenImages: {
        ...state.screenImages,
        [action.meta.arg]: {
          ...state.screenImages[action.meta.arg],
          isLoading: true,
        },
      },
    }));
    builder.addCase(fetchScreen.fulfilled, (state, action) => {
      const prevUrl = state.screenImages[action.payload?.treeItemIdAndScreenNo]?.imageUrl;
      if (prevUrl) {
        URL.revokeObjectURL(prevUrl);
      }

      return ({
        ...state,
        screenImages: {
          ...state.screenImages,
          [action.payload?.treeItemIdAndScreenNo]: {
            imageUrl: action.payload.imageUrl,
            isLoading: false,
          },
        },
      });
    });
    builder.addCase(fetchScreen.rejected, (state, action) => {
      const prevUrl = state.screenImages[action.meta.arg]?.imageUrl;
      if (prevUrl) {
        URL.revokeObjectURL(prevUrl);
      }
      return ({
        ...state,
        screenImages: {
          ...state.screenImages,
          [action.meta.arg]: {
            imageUrl: null,
            isLoading: false,
            error: action.error.message,
          },
        },
      });
    });
  },
});

export const {
  addToVisibleScreens,
  removeFromVisibleScreens,
  removeScreensData,
} = screensPreview.actions;

export default screensPreview.reducer;

/* ===========Selectors============= */
export const visibleScreensSelector = (state: DefaultRootState) => (
  state.screensPreview.visibleScreens
);
export const screenImagesSelector = (state: DefaultRootState) => (
  state.screensPreview.screenImages
);
