/* eslint-disable no-underscore-dangle */
import { AppControlState, InetControlState } from '@/api/owpb/pbFiles/basic_pb';
import { DevicesGroup } from '@/api/owpb/pbFiles/devices_service_pb';
import { sortBy } from '@/utils/helpers';
import { DeviceItem } from './DeviceItem';
import { DeviceTreeItemBase, TreeItemType } from './DeviceTreeItemBase';


export class DevicesGroupItem extends DeviceTreeItemBase {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  readonly type: TreeItemType.DevicesGroup = TreeItemType.DevicesGroup;
  private group: DevicesGroup.AsObject;
  public children: Array<DevicesGroupItem | DeviceItem>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(group: DevicesGroup.AsObject) {
    super();
    this._id = group.id;
    this._isGroup = true;
    this.group = group;
    this.appAccess = AppControlState.APP_ALLOW;
    this.inetAccess = InetControlState.INET_CHECK;
    this.deviceLock = 0;
    this.children = new Array<DevicesGroupItem | DeviceItem>();
    this.updateItem(group);
  }

  updateItem(obj: DevicesGroup.AsObject): void {
    this._name = obj.name;
    this.parentId = obj.parentId;
  }

  getAllDevices(): Array<DeviceItem> {
    const allChildren: DeviceItem[] = [];
    this.forChildren((item) => {
      if (item instanceof DeviceItem) {
        allChildren.push(item);
      }
    });
    return allChildren;
  }

  getAllDevicesSorted(
    parameterName: adminify.SortColumn,
    sortDirection: adminify.SortDirection
  ): Array<DeviceItem> {
    const allChildren: DeviceItem[] = [];
    this.forChildren((item) => {
      if (item instanceof DeviceItem) {
        allChildren.push(item);
      }
    });
    return sortBy(allChildren, parameterName, sortDirection) as DeviceItem[];
  }
}
