import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import UnblockAll from '@/components/fromSvg/deviceLockIcons/UnblockAll';
import BlockKeyboard from '@/components/fromSvg/deviceLockIcons/BlockKeyboard';
import BlockComputer from '@/components/fromSvg/deviceLockIcons/BlockComputer';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { ApiConsts } from '@/api/ApiConsts';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { useIdsOfAllSelectedDevices } from '@/hooks/useIdsOfAllSelectedDevices';
import AccessControlContextMenu from '@/components/AccessControlContextMenu';
import { accessControlsMobileWidth } from '@/constants/common';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import DeviceAccessControlSubmenu from '@/components/AccessControlContextMenu/DeviceAccessControlSubmenu';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import { deviceIdFromAgentId } from '@/utils/helpers';
import AccessControlMenuCategory from './AccessControlMenuCategory';
import AccessControlElement from './AccessControlElement';


const ContextMenuButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  svg {
    font-size: 1.4em;
    margin-right: 15px;
  }
`;

interface DeviceAccessControlMenuProps {
  currentSetting?: number,
}

const DeviceAccessControlMenu = ({ currentSetting }: DeviceAccessControlMenuProps) => {
  const { t } = useTranslation(['common']);
  const { selectedTreeItem } = useSelectedTreeItem();
  const { width } = useWindowDimensions();
  const isMobile = width < accessControlsMobileWidth;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const isActiveVisible = selectedTreeItem && !selectedTreeItem.isGroup;

  const ids = useIdsOfAllSelectedDevices();
  const onlyDeviceIds = selectedTreeItem.type === TreeItemType.Agent
    ? [deviceIdFromAgentId(selectedTreeItem.id)]
    : ids;
  const handleAccessChange = (value: number) => {
    if (selectedTreeItem.id) {
      updateSettingsDeviceAccess(
        ApiConsts.SettingsDeviceLock,
        value.toString(),
        onlyDeviceIds
      );
    }
  };

  return (
    <AccessControlMenuCategory title={t('common:app.deviceAccessControl')}>
      <AccessControlElement
        onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_DISABLED)}
        startIcon={<UnblockAll />}
        isActive={isActiveVisible && currentSetting === ApiConsts.DEVICE_LOCK_DISABLED}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.unblockAll')}
      </AccessControlElement>

      <AccessControlElement
        startIcon={<BlockKeyboard />}
        onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_KEYBOARD)}
        isActive={isActiveVisible && currentSetting === ApiConsts.DEVICE_LOCK_KEYBOARD}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.blockKeyboard')}
      </AccessControlElement>

      <AccessControlElement
        startIcon={<BlockComputer />}
        onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_MAX_VALUE)}
        isActive={isActiveVisible && currentSetting === ApiConsts.DEVICE_LOCK_MAX_VALUE}
        disabled={isMobile || !selectedTreeItem}
        isMobile={isMobile}
      >
        {t('common:app.blockComputer')}
      </AccessControlElement>
      {isMobile && (
        <ContextMenuButton
          onClick={
            (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setAnchorEl(event.currentTarget);
            }
          }
        >
          <UnblockAll />
          <BlockKeyboard />
          <BlockComputer />
          <ExpandMoreIcon />
        </ContextMenuButton>
      )}
      {anchorEl && (
        <AccessControlContextMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        >
          <DeviceAccessControlSubmenu
            handleClose={() => setAnchorEl(null)}
            deviceId={onlyDeviceIds}
          />
        </AccessControlContextMenu>
      )}
    </AccessControlMenuCategory>
  );
};

export default DeviceAccessControlMenu;
