/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { URLCategory } from '@/api/owpb/pbFiles/filter_service_pb';
import { settingsCollectionSelectors } from '@/redux/collections/settingsCollection';
import { Settings } from '@/api/Settings';
import { ControlState } from '@/api/owpb/pbFiles/basic_pb';


export interface SystemCategoryWithStatus extends URLCategory.AsObject, Record<string, unknown> {
  status: ControlState,
}

const adapter = createEntityAdapter<URLCategory.AsObject>({});

export type SystemCategoriesCollectionState = ReturnType<typeof adapter.getInitialState>;

const systemCategoriesCollection = createSlice({
  name: 'SYSTEM_CATEGORIES_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const systemCategoriesCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.systemCategoriesCollection
);

export const systemCategoriesWithStatusSelector = createSelector(
  systemCategoriesCollectionSelectors.selectAll,
  settingsCollectionSelectors.selectAll,
  (systemCategories, settingsCollection): SystemCategoryWithStatus[] => {
    const settings = new Settings(settingsCollection);
    return systemCategories.map((category: URLCategory.AsObject) => ({
      ...category,
      status: settings.getURLCategoryState(category.id),
    }));
  }
);

export const systemCategoriesCollectionHolderFunctions = {
  ...systemCategoriesCollection.actions,
  items: systemCategoriesCollectionSelectors.selectAll,
};


export default systemCategoriesCollection.reducer;
