import { apiUrl } from '@/constants/common';
import i18n from '@/utils/i18n';
import { ConfirmUserInvitationRequest, GetUserInvitationStatusRequest } from '../owpb/pbFiles/invitation_service_pb';
import { InvitationServiceClient } from '../owpb/pbFiles/Invitation_serviceServiceClientPb';
import { handleError } from '../errorHandler';
import { UserAuthType } from '../owpb/pbFiles/basic_pb';


const client = new InvitationServiceClient(apiUrl);


export function getUserInvitationStatusRequest(invitationId: string) {
  const request = new GetUserInvitationStatusRequest();
  request.setInvitationId(invitationId);
  const metadata = { lng: i18n.language };
  return client.getUserInvitationStatus(request, metadata)
    .then((res) => res.toObject())
    .catch(handleError);
}

export function confirmUserInvitation(invitationId: string, password?: string) {
  const request = new ConfirmUserInvitationRequest();
  request.setInvitationId(invitationId);
  request.setAuthType(UserAuthType.USER_AUTH_BY_PASSWD);
  if (password) {
    request.setPasswdOrToken(password);
  }
  const metadata = { lng: i18n.language };
  return client.confirmUserInvitation(request, metadata)
    .then((res) => res.toObject());
}
