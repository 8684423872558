import { Subscription } from 'rxjs';
import { deviceStatusFilterSelector, getUserAppOptions } from '@/redux/appOptions';
import AppOptionsManager from '@/redux/AppOptionsManager';
import { setShownWelcomeScreen, treeSearchTextSelector } from '@/redux/layout';
import { reduxToObservable, store } from '@/redux/store';
import {
  clearUser, setAuthData, setLicense, setLogin, setSelectedDevicesGroupId, setSubscription,
  UserState,
} from '@/redux/user';
import { userAvailableDeviceGroupsIdSelector, userDeviceGroupSelectModeSelector } from '@/redux/userPermissions';
import TreeService from '@/TreeService';
import { sessionClear } from '@/utils/helpers';
import agentRpcClients from './agentRpcClients';
import { ApiConsts } from './ApiConsts';
import cancelablePromiseManager from './CancelablePromiseManager';
import CollectionUpdater from './collections/CollectionsUpdater';
import { ErrorWithStatus } from './ErrorWithStatus';
import { Code } from './google/pbFiles/code_pb';
import { CollectionUpdate } from './owpb/pbFiles/updates_service_pb';
import PushSub from './PushSub';
import RolesService from './services/RolesService';
import { Settings } from './Settings';


// Przechowuje zainicjowane subskrybcje. Umożliwia anulowanie subskrybcji przy wylogowaniu
const subscriptions: Array<Subscription> = new Array<Subscription>();
let sessionRestored: boolean = false;
const appOptionsManager = new AppOptionsManager();

function unsubscribeAll() {
  subscriptions.forEach(sub => sub.unsubscribe());
}

/** Obsługa wylogowania użytkownika */
export function logout(): void {
  // Anulowanie synchronizacji  appOptions użytkownika z serwerem
  appOptionsManager.unsubscribe();

  // anulowanie wszystkich CancelablePromise
  cancelablePromiseManager.cancelAll();

  // Desubskrypcja observable
  unsubscribeAll();

  // Zatrzymanie streamu eventów push-sub
  PushSub.stopHandleEvents();

  // Reincjalizacja obiektów - na wypadek ponownego logowania użytkownika
  CollectionUpdater.reInitialize();
  TreeService.reInitialize();
  agentRpcClients.reInitialize();

  // Wyczyszczenie danych sesji
  store.dispatch(clearUser());
  // Wyczyszczenie informacji o pokazaniu ekranu powitalnego
  store.dispatch(setShownWelcomeScreen(false));
  sessionClear();
}

/** Obsługa zalogowania użytkownika, zainicjowanie niezbędnych kolekcji, subskrybcji itd. */
export async function userLoggedIn(
  userLogin: string,
  authData: adminify.AuthData
): Promise<boolean> {
  const { dispatch } = store;
  dispatch(setAuthData(authData));
  dispatch(setLogin(userLogin));

  // Podpinamy obsługę błędu w eventStream
  subscriptions.push(PushSub.eventStreamError$.subscribe((error: ErrorWithStatus) => {
    // Wylogowanie jeśli nieważny token (np. gdy restart backendu)
    if (error.code === Code.UNAUTHENTICATED) {
      logout();
    }
    // FireFox przy wciśnięciu f5 zamyka stream i generuje błąd
    // code: 2 details: "Http response at 400 or 500 level"
  }));

  // Pobranie appOptions użytkownika z serwera
  await dispatch(getUserAppOptions());

  // Podpinamy obsługę zdarzenia aktualizacji kolekcji
  subscriptions.push(PushSub.collectionsUpdate$.subscribe((update: CollectionUpdate) => {
    CollectionUpdater.handleCollectionUpdate(update);
  }));

  subscriptions.push(PushSub.deviceConnected$.subscribe(([deviceId, deviceConnected]) => {
    TreeService.devicesTree.onDeviceConnected(deviceId, deviceConnected);
  }));

  subscriptions.push(PushSub.deviceDisconnected$.subscribe((deviceId) => {
    TreeService.devicesTree.onDeviceDisconnected(deviceId);
  }));

  subscriptions.push(PushSub.licenseChanged$.subscribe((license) => {
    dispatch(setLicense(license.toObject()));
  }));

  subscriptions.push(PushSub.subscriptionChanged$.subscribe((subscription) => {
    dispatch(setSubscription(subscription.toObject()));
  }));

  subscriptions.push(TreeService.settingsCollection.update$.subscribe(() => {
    TreeService.devicesTree.onSettingsChanged(new Settings(TreeService.settingsCollection.items));
  }));

  subscriptions.push(RolesService.rolesCollection.update$.subscribe(() => {
    const oldAvailableGroupsId = userAvailableDeviceGroupsIdSelector(store.getState());
    const oldDeviceGroupSelectMode = userDeviceGroupSelectModeSelector(store.getState());

    RolesService.onRolesCollectionChanged();

    // Jeśli zmieniono opcje dotyczące grup dostępnych dla użytkownika, wylogowujemy
    const newAvailableGroupsId = userAvailableDeviceGroupsIdSelector(store.getState());
    const newDeviceGroupSelectMode = userDeviceGroupSelectModeSelector(store.getState());
    if (RolesService.permissionsComputed
       && (oldDeviceGroupSelectMode !== newDeviceGroupSelectMode
        || JSON.stringify(oldAvailableGroupsId) !== JSON.stringify(newAvailableGroupsId))) {
      logout();
    }
  }));

  subscriptions.push(RolesService.roleUsersCollection.update$.subscribe(() => {
    RolesService.onRoleUsersCollectionChanged();
  }));

  // Obsługa zmiany filtrowania w drzewie komputerów
  reduxToObservable().subscribe({
    next: (state) => {
      TreeService.devicesTree.setDevicesStateFilter(deviceStatusFilterSelector(state));
      TreeService.devicesTree.setDevicesNameFilter(treeSearchTextSelector(state));
    },
  });

  // Dodajemy do CollectionUpdater kolekcje które są niezbędne przez cały czas działania aplikacji
  CollectionUpdater.addCollection(TreeService.devicesCollection);
  CollectionUpdater.addCollection(TreeService.devicesGroupCollection);
  CollectionUpdater.addCollection(TreeService.settingsCollection);
  CollectionUpdater.addCollection(RolesService.descriptionsCollection);
  CollectionUpdater.addCollection(RolesService.rolesCollection);
  CollectionUpdater.addCollection(RolesService.roleUsersCollection);

  // Uruchamiamy obsługę streamu zdarzeń - dopiero po podpięciu zdarzeń do PushSubClient
  PushSub.startHandleEvents();

  // Aktualizujemy kolekcje dodane do CollectionUpdater'a
  const result = await CollectionUpdater.updateCollections();

  // Wysyłamy zdarzenie TopicGetDeviceConnected dla ustalenia aktywnych komputerów
  PushSub.publish(ApiConsts.TopicGetDeviceConnected, '', '');

  // Subskrypcja zmian w appOptions i wysyłanie zmienionej wersji na serwer
  appOptionsManager.subscribe();
  return result;
}

/** Wybranie grupy urządzeń dostępnej w drzewie. Należy wywołać po zalogowaniu, użytkownika
 * a przed użyciem TreeService.devicesTree
 * Jeśli groupId === '*' to drzewo wyświetla wszystkie grupy dostępne dla użytkownika
 */
export function selectDevicesGroupId(groupId: string) {
  TreeService.initializeTree(groupId, userAvailableDeviceGroupsIdSelector(store.getState()));
  store.dispatch(setSelectedDevicesGroupId(groupId));
}

/** Próba przywrócenia sesji użytkownika po wciśnięciu F5. True jeśli odtworzenie sesji możliwe */
export async function restoreUserSession(userState: UserState): Promise<boolean> {
  if (sessionRestored) {
    return true;
  }

  if (userState) {
    if (userState.login != null && userState.authData?.token != null
      && userState.authData?.userId != null) {
      const result = await userLoggedIn(userState.login, userState.authData);
      if (result) {
        selectDevicesGroupId(userState.selectedDevicesGroupId);
        sessionRestored = true;
      }

      return result;
    }
  }
  return false;
}
