import i18n from 'i18next';
import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { DevicesServiceClient } from '@/api/owpb/pbFiles/Devices_serviceServiceClientPb';
import {
  CreateDevicesGroupRequest,
  UpdateDevicesGroupRequest,
  DeleteDevicesGroupRequest,
  UpdateDeviceRequest,
  DeleteDeviceRequest,
} from '@/api/owpb/pbFiles/devices_service_pb';
import { showNoty } from '@/utils/helpers';
import { handleError } from '../errorHandler';


const client = new DevicesServiceClient(apiUrl);

export function createGroup(parentId: string, name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new CreateDevicesGroupRequest();
  request.setName(name);
  request.setParentId(parentId);

  client.createDevicesGroup(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.addGroupSuccess')))
    .catch(handleError);
}

export function updateGroup(id: string, parentId: string, name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateDevicesGroupRequest();
  request.setId(id);
  request.setName(name);
  request.setParentId(parentId);

  client.updateDevicesGroup(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.editGroupSuccess')))
    .catch(handleError);
}

export function removeGroup(id: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteDevicesGroupRequest();
  request.setId(id);

  client.deleteDevicesGroup(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.removeGroupSuccess')))
    .catch(handleError);
}

export function updateDevice(id: string, parentId: string, name: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateDeviceRequest();
  request.setId(id);
  request.setName(name);
  request.setParentId(parentId);

  client.updateDevice(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.editDeviceSuccess')))
    .catch(handleError);
}

export function removeDevice(id: string) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new DeleteDeviceRequest();
  request.setId(id);

  client.deleteDevice(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.removeDeviceSuccess')))
    .catch(handleError);
}
