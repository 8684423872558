import styled from 'styled-components';
import { scrollbarStyles } from '@/utils/styles';


const ModalContent = styled.div`
  overflow-x: hidden;
  padding: 20px;
  width: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  ${scrollbarStyles}
`;

export default ModalContent;
