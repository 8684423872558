import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { useDevicesTree } from '@/hooks/useDevicesTree';
import ManageGroupDialog from '@/components/ManageGroupDialog';
import UnableToRemoveModal from '@/components/UnableToRemoveModal';
import ManageDeviceDialog from '@/components/ManageDeviceDialog';
import RemovalConfirmationModal from '@/components/RemovalConfirmationModal';
import TreeControls from '@/components/Tree/TreeControls';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import { scrollbarStyles } from '@/utils/styles';
import TreeDisplay from './TreeDisplay';


const Container = styled.div`
  background-color: ${({ theme }) => theme.treeStripeBackground.dark};
  height: 100%;
  border-right: ${({ theme }) => theme.border};
`;

const ScrollableWrapper = styled.div`
  overflow: auto;
  height: ${({ theme }) => `calc(100% - ${theme.constants.treeControlsHeight})`};
  ${scrollbarStyles}
`;

const Tree = () => {
  const { tree } = useDevicesTree();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isUnableToRemoveModal, setIsUnableToRemoveModal] = useState(false);
  const { selectedTreeItem } = useSelectedTreeItem();

  return (
    <Container>
      <TreeControls />
      <ScrollableWrapper>
        <TreeDisplay
          tree={tree}
          openGroupSettingsModal={() => setIsSettingsModalOpen(true)}
          openGroupRemovalModal={() => {
            if (selectedTreeItem.children.length) {
              setIsUnableToRemoveModal(true);
            } else {
              setIsRemoveModalOpen(true);
            }
          }}
        />
      </ScrollableWrapper>
      {selectedTreeItem && (
        <>
          {selectedTreeItem.isGroup ? (
            <ManageGroupDialog
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              variant="edit"
              id={selectedTreeItem.id}
              initialValues={{ name: selectedTreeItem.name, parentId: selectedTreeItem.parentId }}
            />
          ) : (
            <ManageDeviceDialog
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              id={selectedTreeItem.id}
              initialValues={{ name: selectedTreeItem.name, parentId: selectedTreeItem.parentId }}
            />
          )}
          { selectedTreeItem.type !== TreeItemType.Agent && (
            <RemovalConfirmationModal
              open={isRemoveModalOpen}
              item={selectedTreeItem}
              handleClose={() => setIsRemoveModalOpen(false)}
              variant={selectedTreeItem.isGroup ? 'group' : 'device'}
            />
          )}
          <UnableToRemoveModal
            open={isUnableToRemoveModal}
            groupName={selectedTreeItem.name}
            handleClose={() => setIsUnableToRemoveModal(false)}
          />
        </>
      )}
    </Container>
  );
};


export default Tree;
