/* eslint-disable @typescript-eslint/no-unsafe-call */
import { BehaviorSubject } from 'rxjs';
import { List } from 'ts-generic-collections-linq';
import { ApiConsts } from '@/api/ApiConsts';
import { AppControlState, InetControlState } from '@/api/owpb/pbFiles/basic_pb';
import { Device, DevicesGroup } from '@/api/owpb/pbFiles/devices_service_pb';
import { DeviceConnected } from '@/api/owpb/pbFiles/pushsub_events_pb';
import { Settings } from '@/api/Settings';
import { DevicesStateFilter } from '@/TreeService/DevicesStateFilter';
import { deviceIdFromAgentId } from '@/utils/helpers';
import { AgentItem } from './TreeItems/AgentItem';
import { DeviceItem } from './TreeItems/DeviceItem';
import { DevicesGroupItem } from './TreeItems/DevicesGroupItem';
import { DisplayItem } from './TreeItems/DisplayItem';
import { TreeItem } from './types';


export class DevicesTree extends List<TreeItem> {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  public devicesTreeSubject: BehaviorSubject<number>;
  // Lista grup dostępnych dla użytkownika (do których posiada uprawnienia)
  private availableGroupsId: List<string>;
  private devicesNameFilter: string = '';
  private devicesStateFilter: DevicesStateFilter = DevicesStateFilter.NONE;
  // Mapa idGrupy=IdGrupy parent umożliwia sprawdzenie czy dana grupa jest potomkiem innej
  private groupIdToParentId: Map<string, string>;
  // Id grupy wybranej przez użytkownika lub '*'. Jeśli != '*' pokazujemy tylko podgrupy danej grupy
  private rootGroupId: string = '*';
  private rootItems: Array<DevicesGroupItem>;
  private waitForInitialize: boolean;
  /** Ponieważ obiekt tree jest instancją złożonej klasy,
   * to React (np. useState) nie jest wstanie odróżnić starszej wersji od nowszej.
   * To sprawia że nie odpala się rerender komponentów które subskrybują this.devicesTreeSubject.
   * Wersjonowanie drzewa za pomocą zmiennej `version` naprawia ten problem. */
  private version: number = 0;

  /* eslint-enable @typescript-eslint/lines-between-class-members */

  /**
   * @param rootGroupId id grupy głównej wybranej przez użytkownika lub '*'
   * @param availableGroups dostępne grupy urządzeń. Jeśli nie wypełnione dostępne wszystkie grupy
   * @param waitForInitialize gdy true obiekt nie inicjuje elementów drzewa do czasu wywołania
   *  metody initialize
   */
  constructor(
    rootGroupId: string = '*',
    availableGroups: Array<string> = ['*'],
    waitForInitialize: boolean = false,
  ) {
    super();
    this.devicesTreeSubject = new BehaviorSubject<number>(this.version);
    this.availableGroupsId = new List<string>(availableGroups);
    this.groupIdToParentId = new Map<string, string>();
    this.rootGroupId = rootGroupId;
    this.rootItems = new Array<DevicesGroupItem>();
    this.waitForInitialize = waitForInitialize;
  }

  public initialize(
    rootGroupId: string,
    availableGroupsId: Array<string>,
    devicesGroups: Array<DevicesGroup.AsObject>,
    devices: Array<Device.AsObject>,
    settings: Settings
  ) {
    this.waitForInitialize = false;
    this.availableGroupsId = new List<string>(availableGroupsId);
    this.rootGroupId = rootGroupId;
    this.clear();
    this.initializeDevicesGroups(devicesGroups);
    this.initializeDevices(devices);
    this.onSettingsChanged(settings);
  }

  public initializeItems(
    collectionId: typeof ApiConsts.CollectionIDDevicesGroup | typeof ApiConsts.CollectionIDDevices,
    items: DevicesGroup.AsObject[] | Device.AsObject[]
  ) {
    if (this.waitForInitialize) {
      return;
    }

    if (collectionId === ApiConsts.CollectionIDDevicesGroup) {
      this.initializeDevicesGroups(items as DevicesGroup.AsObject[]);
    } else if (collectionId === ApiConsts.CollectionIDDevices) {
      this.initializeDevices(items as Device.AsObject[]);
    }
  }

  public initializeDevicesGroups(devicesGroups: DevicesGroup.AsObject[]) {
    this.rootItems = [];
    this.groupIdToParentId.clear();

    // Tworzymy mapę groupIdToParentId, przed dodaniem grup
    devicesGroups.forEach((group) => {
      this.groupIdToParentId.set(group.id, group.parentId);
    });

    // Dodajemy poszczególne grupy
    devicesGroups.forEach((group) => {
      this.addItemAndSetDependency(new DevicesGroupItem(group));
    });
    this.updateVersion();
  }

  public initializeDevices(devices: Device.AsObject[]) {
    devices.forEach((device) => {
      this.addItemAndSetDependency(new DeviceItem(device));
    });
    this.updateVersion();
  }

  public addItem(
    collectionId: typeof ApiConsts.CollectionIDDevicesGroup | typeof ApiConsts.CollectionIDDevices,
    item: DevicesGroup.AsObject | Device.AsObject
  ) {
    if (this.waitForInitialize) {
      return;
    }

    if (collectionId === ApiConsts.CollectionIDDevicesGroup) {
      this.addDevicesGroup(item as DevicesGroup.AsObject);
    } else if (collectionId === ApiConsts.CollectionIDDevices) {
      this.addDevice(item as Device.AsObject);
    }
  }

  /** Dodaje element do drzewa */
  public addDevicesGroup(group: DevicesGroup.AsObject) {
    this.addItemAndSetDependency(new DevicesGroupItem(group));
    this.updateVersion();
  }

  /** Dodaje element do drzewa */
  public addDevice(device: Device.AsObject) {
    this.addItemAndSetDependency(new DeviceItem(device));
    this.updateVersion();
  }

  public updateItem(itemId: string, item: DevicesGroup.AsObject | Device.AsObject) {
    if (this.waitForInitialize) {
      return;
    }

    const oldItem = this.getById(itemId);
    if (oldItem) {
      const parentChanged = oldItem.parentId !== item.parentId;
      if (parentChanged) {
        this.getParentForItem(oldItem)?.removeChildById(oldItem.id);
        const newParentItem = this.getById(item.parentId);
        if (newParentItem) {
          newParentItem.addChild(oldItem);
        }
        oldItem.parentId = item.parentId;

        // Zmiana parenta - ponownie ustalamy stan grup
        this.setGroupsState();
        this.setGroupsIsConnectedState();
      }

      if (oldItem instanceof DeviceItem) {
        oldItem.updateItem(item as Device.AsObject);
      } else if (oldItem instanceof DevicesGroupItem) {
        oldItem.updateItem(item as DevicesGroup.AsObject);
      }

      this.updateVersion();
    }
  }

  public deleteItem(itemId: string) {
    if (this.waitForInitialize) {
      return;
    }

    const item = this.getById(itemId);
    if (item) {
      this.getParentForItem(item)?.removeChildById(itemId);
      this.remove((it: TreeItem) => it.id === itemId);
      // Po usunięciu elementu aktualizujemy stan grup
      this.setGroupsState();
      this.setGroupsIsConnectedState();
    }
  }

  public deleteWithChildren(itemId: string, updateVersion: boolean) {
    const item = this.getById(itemId);
    if (item) {
      item.children.forEach((childItem: TreeItem) => (
        this.deleteWithChildren(childItem.id, false)
      ));
      this.getParentForItem(item)?.removeChildById(itemId);
      this.remove((it: TreeItem) => it.id === itemId);
      if (updateVersion) {
        this.updateVersion();
      }
    }
  }

  public getAllGroups(): Array<DevicesGroupItem> {
    const result = new Array<DevicesGroupItem>();
    for (let i = 0; i < this.length; i += 1) {
      const item = this.elementAt(i);
      if (item.isGroup) {
        result.push(item as DevicesGroupItem);
      }
    }
    return result;
  }

  /** Zwraca element o podanym id lub null */
  public getById(id: string): TreeItem | null {
    return this.firstOrDefault((item: TreeItem) => item.id === id);
  }

  public getAgentById(id: string): AgentItem | null {
    const result = this.firstOrDefault((item: TreeItem) => item.id === id);
    if (result instanceof AgentItem) {
      return result;
    }
    return null;
  }

  public getDeviceById(id: string): DeviceItem | null {
    const result = this.firstOrDefault((item: TreeItem) => item.id === id);
    if (result instanceof DeviceItem) {
      return result;
    }
    return null;
  }

  public getRootItems(): Array<DevicesGroupItem> {
    return this.rootItems;
  }

  /** Obsługa komunikatu DeviceConnected. Dodanie do drzewa elementu AgentItem */
  public onDeviceConnected(agentId: string, deviceConnected: DeviceConnected) {
    // deviceConnected może być wywoływane dla danego agenta wielokrotnie w czasie działania
    // również w wypadku gdy zmieni się liczba monitorów podpiętych do komputera
    let agentItem = this.getAgentById(agentId);
    if (agentItem) {
      const displaysCountBefore = agentItem.displaysCount;
      agentItem.updateItem(deviceConnected);
      if (displaysCountBefore !== agentItem.displaysCount) {
        agentItem.getChildrenIds().forEach(id => this.deleteItem(id));
        this.addDisplaysToAgentItem(agentItem);
      }
      // Todo: pominąć updateVersion() jeśli brak zmian ?
      this.updateVersion();
    } else {
      const deviceId = deviceIdFromAgentId(agentId);
      const device = this.getDeviceById(deviceId);
      if (device) {
        // Podłączenie agenta powoduje dodanie elementu AgentItem oraz ewentualnie
        // elementów DisplayItem. Jeśli dane urządzenia ma podpięty jeden AgentItem będzie on
        // niewidoczny
        agentItem = new AgentItem(agentId, deviceConnected);
        agentItem.visible = device.children.length > 0;
        if (device.children.length === 1) {
          // pokazujemy poprzednio niewidocznego agenta
          device.children[0].visible = true;
        }
        device.addChild(agentItem);
        super.add(agentItem);
        this.addDisplaysToAgentItem(agentItem);
        this.setItemIsConnected(agentItem, true);
        this.updateVersion();
      }
    }
  }

  /** Obsługa komunikatu DeviceDisconnected. Usunięcie z drzewa elementu AgentItem */
  public onDeviceDisconnected(agentId: string) {
    const agentItem = this.getAgentById(agentId);
    if (agentItem) {
      const device = this.getParentForItem(agentItem) as DeviceItem;
      this.setItemIsConnected(agentItem, false);
      this.deleteWithChildren(agentItem.id, true);

      // Ukrywamy agenta jeśli na urządzeniu pozostał tylko jeden
      if (device) {
        if (device.children.length === 1) {
          device.children[0].visible = false;
        }
      }
      this.updateVersion();
    }
  }

  /** Aktualizacja pól w elementach drzewa zależnych od ustawień (stan kontroli Internetu itd.) */
  public onSettingsChanged(settings: Settings) {
    if (this.waitForInitialize) {
      return;
    }

    let changed = false;
    // Ustalamy ustawienia dla komputerów
    for (let i = 0; i < this.length; i += 1) {
      const item = this.elementAt(i);
      if (!item.isGroup) {
        if (this.setDeviceSettings(settings, item)) {
          changed = true;
        }
      }
    }

    // Jeśli była zmiana przeliczamy ustawienia dla grup
    if (changed) {
      this.setGroupsState();
    }
  }

  public setDevicesNameFilter(nameFilter: string) {
    if (this.devicesNameFilter !== nameFilter.toLocaleLowerCase()) {
      this.devicesNameFilter = nameFilter.toLocaleLowerCase();

      // Ukrywamy urządzenia nie spełniające warunków filtru
      for (let i = 0; i < this.length; i += 1) {
        this.setNameFilterForItem(this.elementAt(i));
      }
      this.updateVersion();
    }
  }

  public setDevicesStateFilter(devicesStateFilter: DevicesStateFilter) {
    if (this.devicesStateFilter !== devicesStateFilter) {
      this.devicesStateFilter = devicesStateFilter;

      // Ukrywamy urządzenia nie spełniające warunków filtru
      for (let i = 0; i < this.length; i += 1) {
        this.setStateFilterForItem(this.elementAt(i));
      }
      this.updateVersion();
    }
  }

  public setItemIsConnected(item: TreeItem, value: boolean) {
    // eslint-disable-next-line no-param-reassign
    item.isConnected = value;
    this.setStateFilterForItem(item);

    const parents = this.getAllParentsForItem(item);
    if (value) {
      parents.forEach((parentItem: TreeItem) => {
        // eslint-disable-next-line no-param-reassign
        parentItem.isConnected = true;
        this.setStateFilterForItem(parentItem);
      });
    } else {
      parents.forEach((parentItem: TreeItem) => {
        // rozłączamy jeśli wszystkie dzieci rozłączone
        let isDisconnected = true;
        // rozłączamy jeśli wszystkie dzieci rozłączone
        for (let i = 0; i < parentItem.children.length; i += 1) {
          if (parentItem.children[i].isConnected) {
            isDisconnected = false;
            break;
          }
        }
        if (isDisconnected) {
          // eslint-disable-next-line no-param-reassign
          parentItem.isConnected = false;
          this.setStateFilterForItem(parentItem);
        }
      });
    }
  }

  /** Zwraca true jeśli grupa o groupId jest potomkiem grupy ancestorId */
  public isSubgroupOf(groupId: string, ancestorId: string): boolean {
    if (groupId === ancestorId) {
      return true;
    }
    const parentId = this.groupIdToParentId.get(groupId);
    if (!parentId) {
      return false;
    }
    if (parentId === ancestorId) {
      return true;
    }
    return this.isSubgroupOf(parentId, ancestorId);
  }

  /**  Dodaje dzieci typu DisplayItem do elementu AgentItem. */
  private addDisplaysToAgentItem(agentItem: AgentItem) {
    for (let i = 0; i < agentItem.displaysCount; i += 1) {
      const displayItem = new DisplayItem(agentItem.id, i);
      // Jeśli tylko 1 monitor ukrywamy go
      displayItem.visible = agentItem.displaysCount > 1;
      agentItem.addChild(displayItem);
      super.add(displayItem);
    }
  }

  /** Dodaje element i ustawia jego zależności, budując strukturę drzewa */
  private addItemAndSetDependency(item: TreeItem) {
    // Pominięcie elementów niedostępnych
    if (item.isGroup) {
      if (!this.isGroupAvailable(item.id)) {
        return;
      }
      // Uzupełniamy groupIdToParentId: niezbędne dla obsługi dodawania nowych grup w trakcie pracy
      this.groupIdToParentId.set(item.id, item.parentId);

      // Jeśli mamy ustawioną rootGroupId dodajemy tylko grupy do niej należące
      if (this.rootGroupId !== '*') {
        if (!this.isSubgroupOf(item.id, this.rootGroupId)) {
          return;
        }
      }
    } else if (!this.isGroupAvailable(item.parentId)) {
      return;
    }

    // Odszukujemy parenta dla danego itemu
    const parent = this.getById(item.parentId);
    if (parent) {
      parent.addChild(item);
    }

    // Odszukujemy elementy dla których element powinien być parentem
    const items = this.getNodesWithParentId(item.id);
    for (let i = 0; i < items.length; i += 1) {
      item.addChild(items[i]);
    }

    super.add(item);
    this.setStateFilterForItem(item);
    this.setNameFilterForItem(item);

    if (item.isGroup) {
      // Jeśli mamy podaną rootGroupId to tylko ta grupa może zostać dodana do rootItems
      if (this.rootGroupId !== '*') {
        if (item.id === this.rootGroupId) {
          this.rootItems.push(item as DevicesGroupItem);
        }
      } else {
        // Jeśli brak rootGroupId to do rootItems dodajemy grupy nie posiadającą parenta
        if (item.parentId === '' || !parent) {
          this.rootItems.push(item as DevicesGroupItem);
        }
        // Sprawdzamy rootItems: powinny znajdować się w nich tylko elementy nie mające parenta
        // Ponieważ kolejność dodawania grup jest nieznana grupa nie mająca parenta w trakcie jej
        // dodawania może otrzymać go później, wtedy usuwamy ją z rootItems
        for (let i = this.rootItems.length - 1; i >= 0; i -= 1) {
          if (this.getById(this.rootItems[i].parentId)) {
            this.rootItems.splice(1, 1);
          }
        }
      }
    }
  }

  //* * Zwraca listę wszystkich parentów dla itemu. */
  private getAllParentsForItem(item: TreeItem | null): Array<TreeItem> {
    const result = new Array<TreeItem>();
    let currentItem = item;
    while (currentItem != null) {
      currentItem = this.getById(currentItem.parentId);
      if (currentItem) {
        result.push(currentItem);
      }
    }
    return result;
  }

  private getGrandFatherForItem(item: TreeItem): TreeItem | null {
    const parent = this.getParentForItem(item);
    return parent ? this.getParentForItem(parent) : null;
  }

  /** Zwraca stan grupy na podstawie elementów tablicy */
  // eslint-disable-next-line class-methods-use-this
  private getGroupStateFromArray<T>(states: Array<T | undefined>, defaultState: T): T | undefined {
    if (states.length === 1) {
      return states[0];
    }
    if (states.length > 0) {
      return undefined;
    }
    return defaultState;
  }

  private getParentForItem(item: TreeItem): TreeItem | null {
    return this.getById(item.parentId);
  }

  /** Zwraca wszystkie elementy z podanym parentId */
  private getNodesWithParentId(parentId: string): Array<TreeItem> { // todo better type
    const result = new Array<TreeItem>();

    for (let i = 0; i < this.length; i += 1) {
      const item = this.elementAt(i);
      if (item.parentId === parentId) {
        result.push(item);
      }
    }
    return result;
  }

  private isAllGroupsAvailable(): boolean {
    return this.availableGroupsId.length === 0
      || (this.availableGroupsId.length === 1 && this.availableGroupsId.first() === '*');
  }

  private isGroupAvailable(groupId: string): boolean {
    if (this.isAllGroupsAvailable()) {
      return true;
    }
    return this.availableGroupsId.firstOrDefault((id: string) => id === groupId) !== null;
  }

  private isRootItem(item: TreeItem): boolean {
    for (let i = 0; i < this.rootItems.length; i += 1) {
      if (this.rootItems[i] === item) {
        return true;
      }
    }
    return false;
  }

  //* * Ustawia ustawienia urządzenia, zwraca true jeśli uległy zmianie */

  // eslint-disable-next-line class-methods-use-this
  private setDeviceSettings(settings: Settings, item: TreeItem): boolean {
    const inetAccess = settings.getDeviceInetAccess(item.id);
    const appAccess = settings.getDeviceAppAccess(item.id);
    const deviceLock = settings.getDeviceLock(item.id);
    const result = inetAccess !== item.inetAccess || appAccess !== item.appAccess
      || deviceLock !== item.deviceLock;
    if (result) {
      /* eslint-disable no-param-reassign */
      item.inetAccess = inetAccess;
      item.appAccess = appAccess;
      item.deviceLock = deviceLock;
      /* eslint-enable no-param-reassign */
    }
    return result;
  }

  /** Ustawia stan grup na podstawie stanu urządzeń */
  private setGroupsState() {
    for (let i = 0; i < this.length; i += 1) {
      const item = this.elementAt(i);
      if (item.isGroup) {
        const inetStates = new Array<InetControlState | undefined>();
        const appStates = new Array<AppControlState | undefined>();
        const lockStates = new Array<number | undefined>();
        const devices = (item as DevicesGroupItem).getAllDevices();
        devices.forEach((device) => {
          if (inetStates.indexOf(device.inetAccess) === -1) {
            inetStates.push(device.inetAccess);
          }
          if (appStates.indexOf(device.appAccess) === -1) {
            appStates.push(device.appAccess);
          }
          if (lockStates.indexOf(device.deviceLock) === -1) {
            lockStates.push(device.deviceLock);
          }
        });
        item.inetAccess = this.getGroupStateFromArray<InetControlState>(inetStates,
          InetControlState.INET_CHECK);
        item.appAccess = this.getGroupStateFromArray<AppControlState>(appStates,
          AppControlState.APP_ALLOW);
        item.deviceLock = this.getGroupStateFromArray<number>(lockStates, 0);
      }
    }
    this.updateVersion();
  }

  /** Ustawia stan IsConnected dla wszystkich grup na podstawie stanu urządzeń */
  private setGroupsIsConnectedState() {
    let changed = false;
    for (let i = 0; i < this.length; i += 1) {
      const item = this.elementAt(i);
      if (item.isGroup) {
        const devices = (item as DevicesGroupItem).getAllDevices();
        const isConnected = devices.find(x => !x.isGroup && x.isConnected) !== undefined;
        if (isConnected && !item.isConnected) {
          changed = true;
        }
        item.isConnected = isConnected;
      }
    }
    if (changed) {
      this.updateVersion();
    }
  }

  private setNameFilterForItem(item: TreeItem) {
    if (this.isRootItem(item)) {
      // eslint-disable-next-line no-param-reassign
      item.filteredByNameFilter = false;
      return; // Zawsze pokazujemy główną grupę
    }

    if (this.devicesNameFilter && this.devicesNameFilter.length > 0) {
      const checkIsNameInFilter = (name: string) => (
        name.toLowerCase().indexOf(this.devicesNameFilter) !== -1
      );

      // Jeśli element jest typu:
      // AgentItem i DisplayItem: to rezultat zależy od tego czy pokazujemy jego parenta
      // typu DeviceItem
      // DeviceItem: rezultat należy od nazwy urządzenia
      // DevicesGroupItem: rezultat zależy od tego czy wśród dzieci elementu
      // znajduje się pokazywany DeviceItem
      let itemToCheckName: TreeItem | null = null;
      let filtered = true;

      if (item instanceof DisplayItem) {
        const grandFather = this.getGrandFatherForItem(item);
        if (grandFather instanceof DeviceItem) {
          itemToCheckName = grandFather;
        }
      } else if (item instanceof DisplayItem) {
        itemToCheckName = this.getParentForItem(item);
      } else if (item instanceof DeviceItem) {
        itemToCheckName = item;
      } else if (item instanceof DevicesGroupItem) {
        const childWithNameInFilter = item.findFirstChildren((childItem) => (childItem
          instanceof DeviceItem && checkIsNameInFilter(childItem.name)));
        // eslint-disable-next-line no-param-reassign
        if (childWithNameInFilter) {
          filtered = false;
        }
      }
      if (itemToCheckName) {
        filtered = !checkIsNameInFilter(itemToCheckName.name);
      }
      // eslint-disable-next-line no-param-reassign
      item.filteredByNameFilter = filtered;
    } else {
      // eslint-disable-next-line no-param-reassign
      item.filteredByNameFilter = false;
    }
  }

  private setStateFilterForItem(item: TreeItem) {
    if (this.isRootItem(item)) {
      // eslint-disable-next-line no-param-reassign
      item.filteredByStateFilter = false;
      return; // Zawsze pokazujemy główną grupę
    }

    switch (this.devicesStateFilter) {
      case DevicesStateFilter.CONNECTED: {
        // eslint-disable-next-line no-param-reassign
        item.filteredByStateFilter = !item.isConnected;
        break;
      }
      case DevicesStateFilter.CONNECTED_WITH_ACTIVE_SESSIONS: {
        let filtered = true;
        if (item.isConnected) {
          // Jeśli element jest typu:
          // AgentItem: to rezultat zależy od aktywnej sesji użytkownika Windows na tym agencie
          // DisplayItem: to rezultat zależy od aktywnej sesji parenta (zawsze typu AgentItem)
          // DeviceItem, DevicesGroupItem: to rezultat zależy od tego czy wśród dzieci elementu
          // znajduje się AgentItem z aktywną sesją
          if (item instanceof DisplayItem) {
            const parent = this.getParentForItem(item);
            if (parent instanceof AgentItem) {
              filtered = !parent.windowsUserSeesionIsActive;
            }
          } else if (item instanceof AgentItem) {
            filtered = !item.windowsUserSeesionIsActive;
          } else if (item instanceof DeviceItem || item instanceof DevicesGroupItem) {
            const childWithActiveSession = item.findFirstChildren((childItem) => (childItem
              instanceof AgentItem && childItem.windowsUserSeesionIsActive));
            filtered = childWithActiveSession === undefined;
          }
        }
        // eslint-disable-next-line no-param-reassign
        item.filteredByStateFilter = filtered;
        break;
      }
      default:
        // eslint-disable-next-line no-param-reassign
        item.filteredByStateFilter = false;
        break;
    }
  }

  private updateVersion() {
    this.version += 1;
    this.devicesTreeSubject.next(this.version);
  }
}
