import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';
import { Typography, Button, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlanInfo, SubscriptionStatus } from '@/api/owpb/pbFiles/subscription_service_pb';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { urlStructure } from '@/constants/urlStructure';
import { SystemI18nInfo } from '@/api/owpb/pbFiles/basic_pb';
import { getFormattedSubscriptionPaymentInterval } from '@/utils/display';
import { defaultPlanType } from '@/constants/common';
import { subscriptionSelector } from '@/redux/user';


const BasicPlanInfo = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 100%;

    :not(:last-child) {
      margin-right: 40px;
    }
  }
`;

const PlanButtons = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const PlanButton = styled(Button)`
  width: 250px;
  padding: 15px;
  text-transform: none;
`;

const StyledLabel = styled(Typography)`
  margin-top: 15px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 0.85em;
`;

const PlansInfoLink = styled(Button)<{ component?: React.ReactNode, to?: string}>`
  text-decoration: none;
  text-transform: none;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 20px;
`;

interface PlanSelectionSectionProps {
  plans: PlanInfo.AsObject[],
  systemInfo: SystemI18nInfo.AsObject,
  currentPlan?: PlanInfo.AsObject,
}

const PlanSelectionSection = ({
  plans, systemInfo, currentPlan,
}: PlanSelectionSectionProps) => {
  const { t } = useTranslation(['common']);
  const { input } = useField('currency');
  const subscription = useSelector(subscriptionSelector);

  const defaultPlan = plans?.find(plan => plan.planType === defaultPlanType) ?? plans[0];

  const [activePlan, setActivePlan] = useState<PlanInfo.AsObject>(currentPlan ?? defaultPlan);

  const { change, getFieldState } = useForm();

  const setPrice = (plan: PlanInfo.AsObject) => {
    const currentPrice = activePlan.pricesList.find(price => price.id === getFieldState('priceId')?.value);
    const newPrice = plan.pricesList.filter((priceItem) => priceItem.currency === input.value)
      ?.find((priceObject) => (
        priceObject.interval === currentPrice?.interval
        && priceObject.intervalCount === currentPrice?.intervalCount
      )) ?? plan.pricesList.filter((priceItem) => priceItem.currency === input.value)[0];
    change('priceId', newPrice?.id);
    setActivePlan(plan);
  };

  useEffect(() => {
    setPrice(activePlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  return (
    <>
      <BasicPlanInfo>
        <FFTextInput
          select
          name="currency"
          label={t('common:checkout.currency')}
          // disable select when subscription is active and has a currency already defined
          disabled={subscription?.status === SubscriptionStatus.SUBSCRIPTION_ACTIVE
          && !!currentPlan?.pricesList[0].currency}
        >
          {systemInfo.currenciesList.map((currency) => (
            <MenuItem
              value={currency}
              key={currency}
            >
              {currency}
            </MenuItem>
          ))}
        </FFTextInput>
        <FFTextInput
          name="numberOfDevices"
          label={t('common:checkout.noOfDevices')}
          InputProps={{
            inputProps: {
              min: 1, type: 'number', step: 1,
            },
          }}
          noEmptyValue
          undefReplacement="1"
        />
        <FFTextInput
          select
          name="priceId"
          label={t('common:checkout.period')}
        >
          {activePlan.pricesList
            .filter((priceItem) => priceItem.currency === input.value)?.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {getFormattedSubscriptionPaymentInterval(item.intervalCount, item.interval)}
              </MenuItem>
            ))}
        </FFTextInput>
      </BasicPlanInfo>
      <StyledLabel>
        {t('common:checkout.planType')}
      </StyledLabel>
      <PlanButtons>
        {plans.map((plan) => (
          <PlanButton
            key={plan.name}
            variant="outlined"
            color={plan.name === activePlan.name ? 'primary' : undefined}
            onClick={() => setPrice(plan)}
          >
            {plan.name}
          </PlanButton>
        ))}
      </PlanButtons>
      <PlansInfoLink component={NavLink} to={urlStructure.planConfiguration}>
        {t('common:checkout.comparePlans')}
      </PlansInfoLink>
    </>
  );
};


export default PlanSelectionSection;
