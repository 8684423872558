import { useState, useEffect } from 'react';


export default function useWindowDimensions(): { width: number, height: number} {
  const getWindowWidth = () => Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const getWindowHeight = () => Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const [width, setWidth] = useState<number>(getWindowWidth());
  const [height, setHeight] = useState<number>(getWindowHeight());
  useEffect(() => {
    const handleResize = () => {
      setWidth(getWindowWidth());
      setHeight(getWindowHeight());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return { width, height };
}
