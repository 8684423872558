import React, { PropsWithChildren } from 'react';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { scrollbarStyles } from '@/utils/styles';


const ScrollableElement = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.constants.navbarHeight})`};
  overflow: auto;
  ${scrollbarStyles}
`;

const ScrollableContainer = ({ children }: PropsWithChildren<{}>) => (
  <ScrollableElement>
    <Container>
      <>
        { children }
      </>
    </Container>
  </ScrollableElement>
);

export default ScrollableContainer;
