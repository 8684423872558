import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TreeItem from '@material-ui/lab/TreeItem';
import Device from '@/components/Tree/Device';
import { selectedTreeSortationSelector, setSelectedTreeItem } from '@/redux/appOptions';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import Label from './TreeItemLabel';


const StyledTreeItem = styled(TreeItem)`
  background-color: ${({ theme }) => theme.treeStripeBackground.dark};
  color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
`;

export interface TreeNodeProps {
  node: DevicesGroupItem | DeviceItem,
  handleOpenSettings: () => void,
  handleOpenRemove: () => void,
}

const TreeNode = ({
  node, handleOpenSettings, handleOpenRemove,
}: TreeNodeProps) => {
  const dispatch = useDispatch();
  const treeSortation = useSelector(selectedTreeSortationSelector);

  if (node.isFiltered) {
    return null;
  }

  if (node.isGroup) {
    return (
      <StyledTreeItem
        nodeId={node.id}
        key={node.id}
        label={(
          <Label
            item={node}
            type="group"
            handleSettings={handleOpenSettings}
            handleRemove={handleOpenRemove}
          />
        )}
        onLabelClick={(event) => {
          event.preventDefault();
          return dispatch(setSelectedTreeItem(node.id));
        }}
      >
        {(node as DevicesGroupItem)
          .getSortedChildren(treeSortation[0], treeSortation[1])
          .map((childNode) => (
            <TreeNode
              key={childNode.id}
              node={childNode as DevicesGroupItem | DeviceItem}
              handleOpenSettings={handleOpenSettings}
              handleOpenRemove={handleOpenRemove}
            />
          ))}
      </StyledTreeItem>
    );
  }

  return (
    <Device
      key={node.id}
      node={node as DeviceItem}
      label={(
        <Label
          item={node}
          type="device"
          handleSettings={handleOpenSettings}
          handleRemove={handleOpenRemove}
        />
      )}
    />
  );
};

export default TreeNode;
