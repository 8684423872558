import CollectionHolderArray from '@/api/collections/CollectionHolderArray';
import { Device, DevicesGroup } from '@/api/owpb/pbFiles/devices_service_pb';
import { DevicesTree } from './DevicesTree';

/** Klasa holdera przechowująca elementy kolekcji współpracująca z drzewem urządzeń (DevicesTree).
 * Po każdej zmianie w kolekcji wywoływana jest odpowiednia metoda w drzewie.
 */
export class TreeCollectionHolder<ObjectT extends Device.AsObject | DevicesGroup.AsObject>
  extends CollectionHolderArray<ObjectT> {
  private devicesTree: DevicesTree;

  constructor(collectionId: number, devicesTree: DevicesTree) {
    super(collectionId);
    this.devicesTree = devicesTree;
  }

  public fullCollection(items: Array<ObjectT>): boolean {
    if (super.fullCollection(items)) {
      this.devicesTree.initializeItems(this.collectionId, items);
      return true;
    }
    return false;
  }

  public createItem(item: ObjectT): boolean {
    if (super.createItem(item)) {
      this.devicesTree.addItem(this.collectionId, item);
      return true;
    }
    return false;
  }

  public updateItem(itemId: string, item: ObjectT): boolean {
    if (super.updateItem(itemId, item)) {
      this.devicesTree.updateItem(item.id, item);
      return true;
    }
    return false;
  }

  public deleteItem(itemId: string): boolean {
    if (super.deleteItem(itemId)) {
      this.devicesTree.deleteItem(itemId);
      return true;
    }
    return false;
  }
}

