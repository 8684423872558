import React from 'react';
import styled from 'styled-components';


const GridContainer = styled.div<{columns: number}>`
  margin: 15px 0;
  width: 100%;
  display: grid;
  row-gap: 15px;
  column-gap: 30px;
  grid-template-columns: ${({ columns }) => '1fr '.repeat(columns)};
  & > * {
    width: 100%;
  }
`;

interface FFColumnLayoutProps {
  columns?: number,
}

const FFColumnLayout = ({ children, columns = 1 }:
React.PropsWithChildren<FFColumnLayoutProps>) => (
  <GridContainer columns={columns}>
    {children}
  </GridContainer>
);

export default FFColumnLayout;
