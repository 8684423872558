import React, { useState } from 'react';
import { Column, useTable } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { ContentFilter, ContentFilterType } from '@/api/owpb/pbFiles/filter_service_pb';
import ConfigSubTableLayout from '@/components/ConfigTableLayout/ConfigSubTableLayout';
import ActionIcons from '@/components/configuration/ConfigTableActionButtons';
import ConfirmationModal from '@/components/ConfirmationModal';
import { getFilterType, removeURLCategoryFilter } from '@/api/clients/categories';
import FilterConfigModal from '@/components/FilterConfigModal';


const getTranslations = (type: ContentFilterType, t: TFunction) => ({
  [ContentFilterType.URL_FILTER]: {
    title: t('common:configuration.websiteAddress'),
    empty: t('common:configuration.noWebsiteAddress'),
  },
  [ContentFilterType.PHRASE_FILTER]: {
    title: t('common:configuration.word'),
    empty: t('common:configuration.noWord'),
  },
  [ContentFilterType.URL_REGEX_FILTER]: {
    title: t('common:configuration.regularExpresion'),
    empty: t('common:configuration.noRegularExpresion'),
  },
}[type]);

const Container = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

const Title = styled(Typography)`
  border-color: ${({ theme }) => theme.palette.divider};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export interface FilterSubTableProps {
  filters: ContentFilter.AsObject[] | undefined,
  type: ContentFilterType,
  categoryId: string,
}

const FilterSubTable = ({
  filters = [],
  type,
  categoryId,
}: FilterSubTableProps) => {
  const { t } = useTranslation(['common']);
  const translations = getTranslations(type, t);
  const [filterToEdit, setFilterToEdit] = useState<ContentFilter.AsObject | undefined>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const columns: Array<Column<ContentFilter.AsObject>> = React.useMemo(
    () => [
      {
        Header: 'data',
        id: 'data',
        accessor: 'data',
      },
      {
        Header: 'actions',
        id: 'actions',
        Cell: ({ row: { original } }: never) => (
          <ActionIcons
            onEdit={() => {
              setFilterToEdit(original);
              setIsEditModalOpen(true);
            }}
            onRemove={() => {
              setFilterToEdit(original);
              setIsRemoveModalOpen(true);
            }}
          />
        ),
      },
    ],
    []
  );
  const tableInstance = useTable<ContentFilter.AsObject>({ columns, data: filters });

  return (
    <Container>
      <Title variant="subtitle2">
        {translations.title}
      </Title>
      {filters.length !== 0
        ? (
          <ConfigSubTableLayout
            tableInstance={tableInstance}
          />
        )
        : (
          <Typography variant="caption">
            {translations.empty}
          </Typography>
        )}
      {filterToEdit !== undefined && (
        <FilterConfigModal
          open={isEditModalOpen}
          variant="edit"
          handleClose={() => setIsEditModalOpen(false)}
          filterType={filterToEdit.filterType}
          initialValue={filterToEdit}
          categoryId={categoryId}
        />
      )}
      <ConfirmationModal
        open={isRemoveModalOpen}
        content={(
          <Trans
            i18nKey="common:filterRemovalInfo"
            values={
              {
                filterType: getFilterType(filterToEdit?.filterType).toLowerCase(),
                filterValue: filterToEdit?.data,
              }
            }
          />
        )}
        handleClose={() => setIsRemoveModalOpen(false)}
        handleConfirm={() => {
          removeURLCategoryFilter(filterToEdit?.id ?? '', [categoryId], filterToEdit?.filterType);
          setIsRemoveModalOpen(false);
        }}
      />
    </Container>
  );
};


export default FilterSubTable;
