import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogTitle, Paper, Dialog, Button, FormControlLabel,
} from '@material-ui/core';
import FFCheckbox from '@/components/finalForm/FFCheckbox';
import { Columns, selectedTreeColumnsSelector, setSelectedTreeColumns } from '@/redux/appOptions';


const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  & > div:first-child {
    margin-bottom: 20px;
  }
`;

const ControlButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.default};
  padding: 5px 40px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.darkBorder};
  padding: 5px 40px;
  margin-right: 10px;
`;

const StyledCheckbox = styled(FFCheckbox)`
  margin-left: 0;

  label {
    margin-right: 0;
  }
`;

export interface SelectTreeColumnsModalProps {
  isOpen: boolean,
  handleClose: () => void,
}

const SelectTreeColumnsModal = ({
  isOpen,
  handleClose,
}: SelectTreeColumnsModalProps) => {
  const { t } = useTranslation(['common']);
  const selectedColumns = useSelector(selectedTreeColumnsSelector);
  const dispatch = useDispatch();

  const onSubmit = (values: Columns) => {
    dispatch(setSelectedTreeColumns(values));
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {t('common:app.tree.settings')}
      </DialogTitle>
      <Paper>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            description: selectedColumns.description,
            domainName: selectedColumns.domainName,
            username: selectedColumns.username,
          }}
        >
          {({ handleSubmit }) => (
            <StyledForm onSubmit={handleSubmit}>
              <FormControlLabel
                label={t('common:app.tree.name')}
                control={(
                  <StyledCheckbox
                    color="primary"
                    name="name"
                    config={{
                      type: 'checkbox',
                      defaultValue: 'true',
                    }}
                    disabled
                  />
                )}
              />
              <FormControlLabel
                control={(
                  <StyledCheckbox
                    color="primary"
                    name="description"
                    config={{
                      type: 'checkbox',
                    }}
                  />
                )}
                label={t('common:app.tree.description')}
              />
              <FormControlLabel
                control={(
                  <StyledCheckbox
                    color="primary"
                    name="domainName"
                    config={{
                      type: 'checkbox',
                    }}
                  />
                )}
                label={t('common:app.tree.domain')}
              />
              <FormControlLabel
                control={(
                  <StyledCheckbox
                    color="primary"
                    name="username"
                    config={{
                      type: 'checkbox',
                    }}
                  />
                )}
                label={t('common:app.tree.username')}
              />
              <ControlButtons>
                <CancelButton onClick={handleClose}>
                  {t('common:common.cancel')}
                </CancelButton>
                <SubmitButton onClick={handleSubmit}>
                  {t('common:common.apply')}
                </SubmitButton>
              </ControlButtons>
            </StyledForm>
          )}
        </Form>
      </Paper>
    </Dialog>
  );
};

export default SelectTreeColumnsModal;
