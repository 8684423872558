import { Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';
import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const Container = styled.div`
  display: flex;
`;

const PredefinedOptions = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.palette.common.white};

  button {
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0;
  }
`;

const PredefinedOption = styled(Button)<{ $isActive: boolean }>`
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.palette.primary.main : theme.palette.common.white)};
  color: ${({ $isActive, theme }) => ($isActive ? theme.palette.common.white : theme.palette.primary.dark)};
`;

const CalendarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const Calendars = styled.div`
  display: flex;
`;

const ApplyButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  margin: 0 10px 10px 0;
  width: 250px;
  align-self: flex-end;
`;

export interface DialogContentProps {
  nameFrom: string,
  nameTo: string,
  config?: UseFieldConfig<MaterialUiPickersDate | null>,
  onSubmit: () => void,
}

const DialogContent = ({
  nameFrom,
  nameTo,
  config,
  onSubmit,
}: DialogContentProps) => {
  const { t } = useTranslation(['common']);
  const today = dayjs();

  const fromField = useField(nameFrom, config);
  const toField = useField(nameTo, config);
  const dateFrom = fromField.input.value;
  const dateTo = toField.input.value;
  const onChangeFromField = (fromValue: MaterialUiPickersDate) => {
    if (dateTo && fromValue && fromValue.isAfter(dateTo)) {
      toField.input.onChange(fromValue);
    }
    fromField.input.onChange(fromValue);
  };
  const onChangeToField = (toValue: MaterialUiPickersDate) => {
    if (dateFrom && toValue && toValue.isBefore(dateFrom)) {
      fromField.input.onChange(toValue);
    }
    toField.input.onChange(toValue);
  };
  return (
    <Container>
      <PredefinedOptions>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today);
            toField.input.onChange(today);
          }}
          $isActive={!!(dateFrom?.isSame(today, 'day') && dateTo?.isSame(today, 'day'))}
        >
          {t('common:app.today')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.weekday(0));
            toField.input.onChange(today);
          }}
          $isActive={!!(dateFrom?.isSame(today.weekday(0), 'day') && dateTo?.isSame(today.weekday(6), 'day'))}
        >
          {t('common:app.thisWeek')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.weekday(-7));
            toField.input.onChange(today.weekday(-1));
          }}
          $isActive={!!(dateFrom?.isSame(today.weekday(-7), 'day') && dateTo?.isSame(today.weekday(-1), 'day'))}
        >
          {t('common:app.lastWeek')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.weekday(-14));
            toField.input.onChange(today.weekday(-1));
          }}
          $isActive={!!(dateFrom?.isSame(today.weekday(-14), 'day') && dateTo?.isSame(today.weekday(-1), 'day'))}
        >
          {t('common:app.lastFortnight')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.startOf('month'));
            toField.input.onChange(today);
          }}
          $isActive={!!(dateFrom?.isSame(today.startOf('month'), 'day') && dateTo?.isSame(today, 'day'))}
        >
          {t('common:app.thisMonth')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.subtract(1, 'month').startOf('month'));
            toField.input.onChange(today.subtract(1, 'month').endOf('month'));
          }}
          $isActive={!!(dateFrom?.isSame(today.subtract(1, 'month').startOf('month'), 'day')
            && dateTo?.isSame(today.subtract(1, 'month').endOf('month'), 'day'))}
        >
          {t('common:app.lastMonth')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.startOf('year'));
            toField.input.onChange(today);
          }}
          $isActive={!!(dateFrom?.isSame(today.startOf('year'), 'day') && dateTo?.isSame(today, 'day'))}
        >
          {t('common:app.thisYear')}
        </PredefinedOption>
        <PredefinedOption
          onClick={() => {
            fromField.input.onChange(today.subtract(1, 'year').startOf('year'));
            toField.input.onChange(today.subtract(1, 'year').endOf('year'));
          }}
          $isActive={!!(dateFrom?.isSame(today.subtract(1, 'year').startOf('year'), 'day')
            && dateTo?.isSame(today.subtract(1, 'year').endOf('year'), 'day'))}
        >
          {t('common:app.lastYear')}
        </PredefinedOption>
      </PredefinedOptions>
      <CalendarsContainer>
        <Calendars>
          <DatePicker
            name={nameFrom}
            orientation="landscape"
            variant="static"
            openTo="date"
            value={fromField.input.value}
            onChange={onChangeFromField}
            ToolbarComponent={() => null}
            disableFuture
          />
          <DatePicker
            name={nameTo}
            orientation="landscape"
            variant="static"
            openTo="date"
            value={toField.input.value}
            onChange={onChangeToField}
            ToolbarComponent={() => null}
            disableFuture
          />
        </Calendars>
        <ApplyButton onClick={onSubmit}>
          {t('common:common.apply')}
        </ApplyButton>
      </CalendarsContainer>
    </Container>
  );
};

export default DialogContent;
