import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import {
  expandedTreeNodesSelector,
  selectedTreeColumnsSelector,
  selectedTreeSortationSelector,
  setExpandedTreeNodes,
  setTreeSortation,
} from '@/redux/appOptions';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import CustomTreeView from '@/components/CustomTreeView';
import TreeNode from './TreeNode';


const Wrapper = styled.div<{ noOfColumns: number }>`
  --other-columns: ${({ noOfColumns }) => `repeat(${noOfColumns}, 300px)`};
  --grid: auto var(--other-columns);
  --header-grid: minmax(250px, auto) var(--other-columns);
  --cell-side-padding: 16px;
  height: ${({ theme }) => (`calc(100% - ${theme.constants.treeControlsHeight})`)};
  display: inline-block;
  min-width: 100%;
`;

const HeadersContainer = styled.div`
  display: grid;
  grid-template-columns: var(--header-grid);
  border-bottom: ${({ theme }) => theme.border};
  width: min-content;
  min-width: max(100%, 300px);
`;


const Header = styled.div<{ isHidden?: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'initial')};

  padding: 6px var(--cell-side-padding);
`;


export interface TreeDisplayProps {
  tree: Array<DevicesGroupItem | DeviceItem>,
  openGroupSettingsModal: () => void,
  openGroupRemovalModal: () => void,
}

const TreeDisplay = ({
  tree,
  openGroupSettingsModal,
  openGroupRemovalModal,
}: TreeDisplayProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const { selectedTreeItem } = useSelectedTreeItem();
  const selectedColumns = useSelector(selectedTreeColumnsSelector);
  const treeSortation = useSelector(selectedTreeSortationSelector);
  const expandedTreeNodes = useSelector(expandedTreeNodesSelector);


  const getNumberOfColumns = () => Object.values(selectedColumns)
    .reduce((n, item) => (n as number) + (item ? 1 : 0), -1);

  const handleSorting = (columnName: adminify.SortColumn) => {
    if (treeSortation[1] === 'asc') {
      return dispatch(setTreeSortation([columnName, 'desc']));
    }
    return dispatch(setTreeSortation([columnName, 'asc']));
  };

  return (
    <Wrapper noOfColumns={getNumberOfColumns()}>
      <HeadersContainer>
        <Header>
          <TableSortLabel
            active={treeSortation[0] === 'name'}
            direction={treeSortation[1] ?? undefined}
            IconComponent={ArrowDropDownIcon}
            onClick={() => handleSorting('name')}
          >
            {t('common:app.tree.name')}
          </TableSortLabel>

        </Header>
        <Header isHidden={!selectedColumns.description}>
          <TableSortLabel
            active={treeSortation[0] === 'description'}
            direction={treeSortation[1] ?? undefined}
            IconComponent={ArrowDropDownIcon}
            onClick={() => handleSorting('description')}
          >
            {t('common:app.tree.description')}
          </TableSortLabel>
        </Header>
        <Header isHidden={!selectedColumns.domainName}>
          <TableSortLabel
            active={treeSortation[0] === 'domainName'}
            direction={treeSortation[1] ?? undefined}
            IconComponent={ArrowDropDownIcon}
            onClick={() => handleSorting('domainName')}
          >
            {t('common:app.tree.domain')}
          </TableSortLabel>
        </Header>
        <Header isHidden={!selectedColumns.username}>
          <TableSortLabel
            active={treeSortation[0] === 'username'}
            direction={treeSortation[1] ?? undefined}
            IconComponent={ArrowDropDownIcon}
            onClick={() => handleSorting('username')}
          >
            {t('common:app.tree.username')}
          </TableSortLabel>
        </Header>
      </HeadersContainer>

      <CustomTreeView
        // below undefined is replace with null to inform TreeView that we control selection
        selected={selectedTreeItem?.id ?? null}
        expanded={expandedTreeNodes}
        onNodeToggle={(event, nodes) => dispatch(setExpandedTreeNodes(nodes))}
      >
        {tree.filter(item => !item.isFiltered).map((rootNode) => (
          <TreeNode
            key={rootNode.id}
            node={rootNode}
            handleOpenSettings={openGroupSettingsModal}
            handleOpenRemove={openGroupRemovalModal}
          />
        ))}
      </CustomTreeView>
    </Wrapper>
  );
};


export default TreeDisplay;
