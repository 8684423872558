import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import EmptyState from '@/components/fromSvg/EmptyState';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledEmptyState = styled(EmptyState)`
  font-size: 6em;
  margin-bottom: 20px;
`;

interface NoDataScreenProps {
  message: string,
}
const NoDataScreen = ({ message }: NoDataScreenProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      <StyledEmptyState />
      <Typography variant="h6">
        {t('common:common.noData')}
      </Typography>
      <Typography>
        {message}
      </Typography>
    </Container>
  );
};

export default NoDataScreen;
