import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import AppLayout from '@/components/layout/AppLayout';


const PlanConfiguration = () => {
  const { t } = useTranslation(['common']);
  return (
    <AppLayout>
      <Typography variant="h4">
        {t('common:planConfiguration.adjustPlan')}
      </Typography>
    </AppLayout>
  );
};

export default PlanConfiguration;
