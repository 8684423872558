import React from 'react';
import Computers from '@/components/fromSvg/Computers';
import Computer from '@/components/fromSvg/Computer';
import User from '@/components/fromSvg/User';
import ActiveComputer from '@/components/fromSvg/ActiveComputer';
import ActiveGroup from '@/components/fromSvg/ActiveGroup';
import ActiveAgent from '@/components/fromSvg/ActiveAgent';


interface TreeItemLabelIconProps {
  isActive: boolean,
  itemType: string,
}

const TreeItemLabelIcon = ({ isActive, itemType }: TreeItemLabelIconProps) => {
  if (itemType === 'group') {
    return isActive ? <ActiveGroup /> : <Computers />;
  }
  if (itemType === 'device') {
    return isActive ? <ActiveComputer /> : <Computer />;
  }
  if (itemType === 'agent') {
    return isActive ? <ActiveAgent /> : <User />;
  }
  return null;
};

export default TreeItemLabelIcon;
