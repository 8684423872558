import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Typography } from '@material-ui/core';
import UneditableTextField from '@/components/UneditableTextField';
import { getPlanType } from '@/utils/display';
import { appDateFormat } from '@/constants/common';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { updateCustomerDescription } from '@/api/clients/customers';
import { useLicense } from '@/hooks/useLicense';
import PermissionsControl from '@/components/PermissionsControl';
import { ApiConsts } from '@/api/ApiConsts';


const CardFields = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px 0;
  grid-template-areas:
    "a b"
    "c c"
    "d d";
`;

const EditDescription = styled(Typography)`
  font-size: 1em;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  margin-top: 20px;
`;

const UserLicenseCard = () => {
  const { t } = useTranslation(['common']);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const license = useLicense();

  const handleSubmit = (values: { description: string }) => {
    updateCustomerDescription(values.description);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <CardFields>
        <UneditableTextField
          label={t('common:userProfile.plan')}
          value={getPlanType(license?.planType)}
          gridArea="a"
        />
        <UneditableTextField
          label={t('common:userProfile.expirationDate')}
          value={license && license.expiryDate !== 0
            ? dayjs.unix(license.expiryDate).format(appDateFormat)
            : null}
          gridArea="b"
        />
        <UneditableTextField
          label={t('common:userProfile.maxDevices')}
          value={license?.maxDevices}
          gridArea="c"
        />
        <UneditableTextField
          label={t('common:userProfile.description')}
          value={license?.keyDescription}
          multiline
          gridArea="d"
        />
      </CardFields>
      <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
        <EditDescription onClick={() => setIsEditModalOpen(true)}>
          {t('common:userProfile.editLicenseDescription')}
        </EditDescription>
      </PermissionsControl>
      <FFGenericOptionsModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title={t('common:userProfile.editLicenseDescription')}
        onSubmit={handleSubmit}
        confirmButtonText={t('common:common.save')}
        formProps={{
          initialValues: {
            description: license?.keyDescription,
          },
        }}
      >
        {() => (
          <FFTextInput
            name="description"
            label={t('common:userProfile.description')}
            multiline
          />
        )}
      </FFGenericOptionsModal>
    </>
  );
};

export default UserLicenseCard;
