/* eslint-disable no-underscore-dangle */
import { AnyAction, Update } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { store } from '@/redux/store';
import { CollectionHolder } from './CollectionsInterfaces';


interface HolderFunctions<ObjectT> {
  fullCollection: (items: Array<ObjectT>) => AnyAction,
  createItem: (item: ObjectT) => AnyAction,
  updateItem: (update: Update<ObjectT>) => AnyAction,
  deleteItem: (itemId: string) => AnyAction,
  items: (store: DefaultRootState) => Array<ObjectT>,
}

/**
 * CollectionHolder przechowujący kolekcję w Reduxie
 */
export default class CollectionHolderRedux<
  ObjectT extends { id: string }
  > implements CollectionHolder<ObjectT> {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  protected collectionId: number;
  private holderFunctions: HolderFunctions<ObjectT>;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(
    collectionId: number,
    holderFunctions: HolderFunctions<ObjectT>
  ) {
    this.collectionId = collectionId;
    this.holderFunctions = holderFunctions;
  }

  public items(): Array<ObjectT> {
    return this.holderFunctions.items(store.getState());
  }

  public fullCollection(items: Array<ObjectT>): boolean {
    store.dispatch(this.holderFunctions.fullCollection(items));
    return true;
  }

  public createItem(item: ObjectT): boolean {
    store.dispatch(this.holderFunctions.createItem(item));
    return true;
  }

  public updateItem(itemId: string, item: ObjectT): boolean {
    store.dispatch(this.holderFunctions.updateItem({
      id: itemId,
      changes: item,
    }));
    return true;
  }

  public deleteItem(itemId: string): boolean {
    store.dispatch(this.holderFunctions.deleteItem(itemId));
    return true;
  }
}
