import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import SwipeableViews from 'react-swipeable-views';
import { Typography, Link as MuiLink } from '@material-ui/core';
import AuthLayout from '@/components/layout/AuthLayout';
import FFTextInput from '@/components/finalForm/FFTextInput';
import AuthButton from '@/components/AuthButton';
import { validateEmail } from '@/utils/validators';
import { urlStructure } from '@/constants/urlStructure';
import { pricelistUrl, privacyPolicyUrl, termsAndServiceUrl } from '@/constants/common';
import SvgOpenInNew from '@/components/fromSvg/OpenInNew';
import { createTrialAccount } from '@/api/clients/trial';
import { handleError } from '@/api/errorHandler';
import { ErrorWithStatus } from '@/api/ErrorWithStatus';
import { showNoty } from '@/utils/helpers';
import FFCheckboxWithLabel from '@/components/finalForm/FFCheckboxWithLabel';
import RegisterSvg from '@/components/fromSvg/auth/Register';


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledSwipeableViews = styled(SwipeableViews)`
  width: 100%;
`;

const StyledSvgOpenInNew = styled(SvgOpenInNew)`
  margin-right: 0.250rem;
`;

interface RegisterFormFields {
  email: string,
  termsAndServices: boolean,
  privacyPolicy: boolean,
}

const Register = () => {
  const { t } = useTranslation(['common']);
  const [pageIndex, setPageIndex] = useState(0);

  const validate = (values: RegisterFormFields) => {
    const errors: Partial<Record<keyof RegisterFormFields, string>> = {};

    if (!values.termsAndServices) {
      errors.termsAndServices = t('errors:consentIsRequired');
    }
    if (!values.privacyPolicy) {
      errors.privacyPolicy = t('errors:consentIsRequired');
    }
    if (!values.email) {
      errors.email = t('errors:fieldCanNotBeEmpty');
    }
    if (values.email && !validateEmail(values.email)) {
      errors.email = t('errors:invalidEmail');
    }
    return errors;
  };

  const onSubmit = async ({ email }: RegisterFormFields) => {
    try {
      await createTrialAccount(email);
      setPageIndex(1);
      return {};
    } catch (error) {
      const errorWithStatus: ErrorWithStatus = await new Promise((resolve) => {
        handleError(error, (err: ErrorWithStatus) => resolve(err));
      });
      showNoty('error', errorWithStatus.message);
      return { [FORM_ERROR]: errorWithStatus.message };
    }
  };

  return (
    <AuthLayout
      sideContent={(
        <RegisterSvg
          title={t('common:auth.registerImageTitle')}
          text={t('common:auth.registerImageText')}
        />
      )}
      footer={(
        <>
          <Typography variant="caption">
            <StyledSvgOpenInNew />
            <MuiLink
              href={pricelistUrl}
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              {t('common:auth.register.seeThePricelist')}
            </MuiLink>
          </Typography>
          <Typography variant="caption">
            <Trans
              i18nKey="common:auth.register.doYouHaveAnAccount"
              components={[<Link to={urlStructure.login} />]}
            />
          </Typography>
        </>
      )}
    >
      <StyledSwipeableViews index={pageIndex} disabled>
        <Form<RegisterFormFields>
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            termsAndServices: false,
            privacyPolicy: false,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Typography variant="h5">
                {t('common:auth.register.title')}
              </Typography>
              <FFTextInput
                name="email"
                label={t('common:auth.login.email')}
                type="text"
                reserveSpaceForHelperText
              />
              <FFCheckboxWithLabel
                name="termsAndServices"
                color="primary"
                config={{
                  type: 'checkbox',
                }}
                label={(
                  <Trans
                    i18nKey="common:auth.register.acceptTermsAndServices"
                    components={[
                      <MuiLink
                        href={termsAndServiceUrl}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      />,
                    ]}
                  />
                )}
              />
              <FFCheckboxWithLabel
                name="privacyPolicy"
                color="primary"
                config={{
                  type: 'checkbox',
                }}
                label={(
                  <Trans
                    i18nKey="common:auth.register.acceptPrivacyPolicy"
                    components={[

                      <MuiLink
                        href={privacyPolicyUrl}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      />,
                    ]}
                  />
                )}
              />
              <AuthButton
                fullWidth
                variant="contained"
                type="submit"
                label={t('common:auth.registerYourself')}
                showLoader={submitting}
              />
              {/* {submitError && `error: ${submitError}`} */}
            </StyledForm>
          )}
        </Form>
        <div>
          <Typography variant="h5">
            {t('common:auth.register.weSendYouEmail')}
          </Typography>
          <Typography>
            {t('common:auth.register.openLinkInEmail')}
          </Typography>
        </div>
      </StyledSwipeableViews>
    </AuthLayout>
  );
};


export default Register;
