import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  PlanInfo,
  PlanSummary,
  SubscriptionStatus,
  UpcomingInvoice,
} from '@/api/owpb/pbFiles/subscription_service_pb';
import { getUpcomingInvoice } from '@/api/clients/subscription';
import { handleError } from '@/api/errorHandler';
import { appDateFormat } from '@/constants/common';
import ButtonWithLoader from '@/components/ButtonWithLoader';
import { getFormattedSubscriptionPaymentInterval, getPlanType } from '@/utils/display';
import { formatPrice } from '@/utils/helpers';
import { useSubscription } from '@/hooks/useSubscription';


const Summary = styled(Paper)`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: fit-content;
`;

const PlaceOrderButton = styled(ButtonWithLoader)`
  margin-top: 20px;
`;

const SummaryTitle = styled(Typography)`
  font-weight: 600;
`;

const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => theme.thickBorder};
  margin: 10px 0;
  padding-bottom: 5px;
`;

const SummarySectionElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface CheckoutSummaryProps {
  onSubmit: () => void,
  noOfDevices: number,
  plan: PlanInfo.AsObject | null,
  priceId: string,
  isButtonDisabled: boolean,
  isDisabledWithLoader: boolean,
}

interface PlanSummaryProps {
  planSummary: PlanSummary.AsObject | undefined,
  title: string,
}

const PlanSummarySection = ({ planSummary, title }: PlanSummaryProps) => {
  const { t } = useTranslation(['common']);
  return (
    <SummarySection>
      <SummarySectionElement>
        <Typography variant="caption">
          {title}
        </Typography>
        <Typography variant="caption">
          {getPlanType(planSummary?.planType)}
        </Typography>
      </SummarySectionElement>

      <SummarySectionElement>
        <Typography variant="caption">
          {t('common:checkout.noOfDevices')}
        </Typography>
        <Typography variant="caption">
          {planSummary?.maxDevices}
        </Typography>
      </SummarySectionElement>

      <SummarySectionElement>
        <Typography variant="caption">
          {t('common:checkout.price')}
        </Typography>
        <Typography variant="caption">
          {`${formatPrice(planSummary?.total ?? 0)} ${planSummary?.price?.currency}`}
        </Typography>
      </SummarySectionElement>

      <SummarySectionElement>
        <Typography variant="caption">
          {t('common:checkout.period')}
        </Typography>
        <Typography variant="caption">
          {planSummary?.price && getFormattedSubscriptionPaymentInterval(
            planSummary?.price?.intervalCount,
            planSummary?.price?.interval
          )}
        </Typography>
      </SummarySectionElement>
    </SummarySection>
  );
};

const CheckoutSummary = ({
  onSubmit, noOfDevices, plan, priceId, isButtonDisabled, isDisabledWithLoader,
}: CheckoutSummaryProps) => {
  const { t } = useTranslation(['common']);
  const priceInfo = plan?.pricesList.find(p => p.id === priceId);
  const [upcomingInvoice, setUpcomingInvoice] = useState<UpcomingInvoice.AsObject>();
  const subscription = useSubscription();

  useEffect(() => {
    getUpcomingInvoice(priceId, noOfDevices)
      .then(res => setUpcomingInvoice(res)).catch(handleError);
  }, [priceId, noOfDevices]);

  const isSubscriptionNotModified = subscription?.status === SubscriptionStatus.SUBSCRIPTION_ACTIVE
    && subscription?.planSummary?.planType === upcomingInvoice?.planSummary?.planType
    && subscription?.planSummary?.maxDevices === upcomingInvoice?.planSummary?.maxDevices
    && subscription?.planSummary?.price?.currency === upcomingInvoice?.planSummary?.price?.currency
    && subscription?.planSummary?.price?.interval === upcomingInvoice?.planSummary?.price?.interval
    && subscription?.planSummary?.price?.intervalCount
      === upcomingInvoice?.planSummary?.price?.intervalCount;

  return (
    <Summary square elevation={0}>
      <SummaryTitle variant="h6">
        {t('common:checkout.summary')}
      </SummaryTitle>
      {
        subscription
        && subscription.status === SubscriptionStatus.SUBSCRIPTION_ACTIVE
        && (
          <PlanSummarySection
            planSummary={subscription?.planSummary}
            title={t('common:checkout.currentPlan')}
          />
        )
      }
      {
        isSubscriptionNotModified
          ? (
            <SummarySection>
              <SummarySectionElement>
                <Typography variant="caption">
                  {t('common:checkout.selectedPlan')}
                </Typography>
                <Typography variant="caption">
                  {t('common:checkout.subscriptionNotModified')}
                </Typography>
              </SummarySectionElement>
            </SummarySection>
          )
          : (
            <PlanSummarySection
              planSummary={upcomingInvoice?.planSummary}
              title={t('common:checkout.selectedPlan')}
            />
          )
      }
      { upcomingInvoice && upcomingInvoice.proration > 0 && (
        <SummarySectionElement>
          <Typography variant="caption">
            {t('common:checkout.proration')}
          </Typography>
          <Typography variant="caption">
            {`-${formatPrice(upcomingInvoice?.proration)} ${upcomingInvoice?.planSummary?.price?.currency}`}
          </Typography>
        </SummarySectionElement>
      )}
      <SummarySectionElement>
        <Typography variant="caption">
          {t('common:checkout.toPay')}
        </Typography>
        <Typography variant="caption">
          {`${formatPrice(upcomingInvoice?.total ?? 0)} ${upcomingInvoice?.planSummary?.price?.currency} (${t('common:checkout.taxIncluded')})`}
        </Typography>
      </SummarySectionElement>
      <SummarySectionElement>
        <Typography variant="caption">
          {t('common:checkout.nextBillingPeriod')}
        </Typography>
        {priceInfo && (
          <Typography variant="caption">
            {(upcomingInvoice && upcomingInvoice.planSummary
              && upcomingInvoice.planSummary.periodEnd)
              ? dayjs.unix(upcomingInvoice.planSummary.periodEnd).format(appDateFormat)
              : dayjs().format(appDateFormat)}
          </Typography>
        )}
      </SummarySectionElement>

      <PlaceOrderButton
        label={t('common:checkout.placeOrder')}
        onClick={() => onSubmit()}
        variant="contained"
        isButtonDisabled={isButtonDisabled || isSubscriptionNotModified}
        showLoader={isDisabledWithLoader}
      />
    </Summary>
  );
};

export default CheckoutSummary;
