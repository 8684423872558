import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import UneditableTextField from '@/components/UneditableTextField';
import { Customer } from '@/api/owpb/pbFiles/customer_pb';
import { loginSelector } from '@/redux/user';
import { logout } from '@/api';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import { updateRoleUser } from '@/api/clients/roles';
import { roleUsersCollectionSelectors } from '@/redux/collections/roleUsersCollection';
import ChangePasswordModal from '@/components/ChangePasswordModal';
import { deleteAccountEmailAddress } from '@/constants/common';
import { Language } from '@/api/owpb/pbFiles/basic_pb';
import { updateCustomerLanguage } from '@/api/clients/customers';
import hasRequiredPermission from '@/utils/securityAndPermissions';
import { ApiConsts } from '@/api/ApiConsts';
import PermissionsControl from '@/components/PermissionsControl';


const CardFields = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px 15px;
  grid-template-areas:
    "a b"
    "c d"
`;

const ActionElements = styled.div`
  margin-top: 20px;

  > * {
    font-size: 1em;
  }
`;

const EditElement = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const RemoveElement = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  cursor: pointer;
`;

const StyledTextField = styled(TextField)<{ $gridArea?: string }>`
  grid-area: ${({ $gridArea }) => $gridArea};
  width: 220px;

  > label {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

const StyledRemoveAccountLink = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: none;
`;

interface UserLicenseCardProps {
  customer: Customer.AsObject,
  languageInfo: Language.AsObject[],
}

const UserInfoCard = ({ customer, languageInfo }: UserLicenseCardProps) => {
  const { t } = useTranslation(['common']);
  const login = useSelector(loginSelector);
  const allUsers = useSelector(roleUsersCollectionSelectors.selectAll);
  const user = allUsers.find(u => u.identy === login);
  const [modalType, setModalType] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState(customer.language);

  const handleEditUsername = (data: { username: string }) => {
    if (user) {
      updateRoleUser(user.id, user.identy, user.roleId, data.username);
    }
  };

  const handleLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(event.target.value);
    updateCustomerLanguage(event.target.value);
  };

  return (
    <>
      <CardFields>
        <UneditableTextField
          label={t('common:common.email')}
          value={login}
          gridArea="a"
        />
        <UneditableTextField
          label={t('common:userProfile.username')}
          value={user?.name}
          gridArea="b"
        />
        <UneditableTextField
          label={t('common:userProfile.organisation')}
          value={customer.keyName}
          gridArea="c"
        />
        <StyledTextField
          label={t('common:userProfile.emailCommunicationLanguage')}
          value={selectedLanguage}
          $gridArea="d"
          select
          onChange={handleLangChange}
          disabled={!hasRequiredPermission([ApiConsts.PermissionAPIAdmin])}
        >
          {languageInfo && languageInfo.map(language => (
            <MenuItem key={language.code} value={language.code}>
              {language.name}
            </MenuItem>
          ))}
        </StyledTextField>
        <ActionElements>
          <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
            <EditElement onClick={() => setModalType('editUsername')}>
              {t('common:userProfile.editUsername')}
            </EditElement>
          </PermissionsControl>
          <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
            <EditElement onClick={() => setModalType('changePassword')}>
              {t('common:userProfile.changePassword')}
            </EditElement>
          </PermissionsControl>
          <EditElement onClick={logout}>
            {t('common:userProfile.logout')}
          </EditElement>
          <PermissionsControl allowedPermissions={[ApiConsts.PermissionAPIAdmin]}>
            <RemoveElement onClick={() => setModalType('deleteAccount')}>
              {t('common:userProfile.deleteAccount')}
            </RemoveElement>
          </PermissionsControl>
        </ActionElements>
      </CardFields>
      <FFGenericOptionsModal
        isOpen={modalType === 'editUsername'}
        onClose={() => setModalType(null)}
        title={t('common:userProfile.editUsername')}
        onSubmit={(values: { username: string }) => {
          handleEditUsername(values);
          setModalType(null);
        }}
        confirmButtonText={t('common:common.save')}
        formProps={{
          initialValues: {
            username: user?.name,
          },
        }}
      >
        {() => (
          <FFTextInput
            name="username"
            label={t('common:userProfile.username')}
          />
        )}
      </FFGenericOptionsModal>
      <ConfirmationModal
        title={t('common:userProfile.deleteAccount')}
        isWarning
        hideCancelBtn
        open={modalType === 'deleteAccount'}
        content={(
          <>
            {t('common:userProfile.deleteAccountInfo')}
            <StyledRemoveAccountLink href={`mailto:${deleteAccountEmailAddress}`}>
              {deleteAccountEmailAddress}
            </StyledRemoveAccountLink>
          </>
        )}
        handleClose={() => setModalType(null)}
        handleConfirm={() => setModalType(null)}
      />
      <ChangePasswordModal
        isOpen={modalType === 'changePassword'}
        handleClose={() => setModalType(null)}
      />
    </>
  );
};

export default UserInfoCard;
