import {
  createAsyncThunk, createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { DevicesStateFilter } from '@/TreeService/DevicesStateFilter';
import { authMetaDataSelector } from '@/redux/user';
import settingsClient from '@/api/clients/settingsClient';
import { GetUserSettingsRequest, SetUserSettingsRequest } from '@/api/owpb/pbFiles/settings_service_pb';
import { previewTileWidthSelector } from '@/redux/layout';
import { screenProportions } from '@/constants/common';


export interface Columns {
  description: boolean,
  domainName: boolean,
  username: boolean,
}

export interface ViewOptions {
  autoTileSize: boolean,
  numberOfColumns: number,
  previewQuality: number,
  previewRefreshFrequency: number,
}

export interface PreviewNameSettings {
  domain: boolean,
  login: boolean,
  nameAndSurname: boolean,
}

export type TreeSortation = [adminify.SortColumn, adminify.SortDirection];


export const getUserAppOptions = createAsyncThunk(
  'APP_OPTIONS/getUserAppOptions',
  async (_, { getState }) => {
    const authMetaData = authMetaDataSelector(getState() as DefaultRootState);
    const request = new GetUserSettingsRequest();
    request.setId('webapp');
    const response = await settingsClient.getUserSettings(request, authMetaData);
    return JSON.parse(response.getValue());
  }
);

export const setUserAppOptions = createAsyncThunk(
  'APP_OPTIONS/setUserAppOptions',
  async (_, { getState }) => {
    const state = getState() as DefaultRootState;
    const authMetaData = authMetaDataSelector(state);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const appOptions = appOptionsSelector(state);
    const request = new SetUserSettingsRequest();
    request.setId('webapp');
    request.setValue(JSON.stringify(appOptions));
    const response = await settingsClient.setUserSettings(request, authMetaData);
    return response.toObject();
  }
);

/* ===========SLICE============= */
export interface AppOptionsState {
  selectedTreeItemId: string | null,
  columns: Columns,
  treeSortation: TreeSortation,
  screenPreviewViewOptions: ViewOptions,
  screenPreviewNameSettings: PreviewNameSettings,
  filter: number,
  expandedTreeNodes: string[],
}

const initialState = {
  selectedTreeItemId: null,
  columns: {
    description: false,
    domainName: false,
    username: true,
  },
  treeSortation: ['name', 'asc'] as TreeSortation,
  screenPreviewViewOptions: {
    autoTileSize: false,
    numberOfColumns: 4,
    previewQuality: 100,
    previewRefreshFrequency: 15,
  },
  screenPreviewNameSettings: {
    domain: false,
    login: false,
    nameAndSurname: false,
  },
  filter: DevicesStateFilter.NONE,
  expandedTreeNodes: [],
} as AppOptionsState;

const appOptions = createSlice({
  name: 'APP_OPTIONS',
  initialState,
  reducers: {
    setAppOptions(_state: AppOptionsState, action: PayloadAction<AppOptionsState>) {
      return action.payload;
    },
    setSelectedTreeItem(state: AppOptionsState, action: PayloadAction<string>) {
      return {
        ...state,
        selectedTreeItemId: action.payload,
      };
    },
    setSelectedTreeColumns(state: AppOptionsState, action: PayloadAction<Columns>) {
      return {
        ...state,
        columns: action.payload,
      };
    },
    setTreeSortation(state: AppOptionsState, action: PayloadAction<TreeSortation>) {
      return {
        ...state,
        treeSortation: action.payload,
      };
    },
    setScreenPreviewViewOptions(state: AppOptionsState, action: PayloadAction<ViewOptions>) {
      return {
        ...state,
        screenPreviewViewOptions: action.payload,
      };
    },
    setScreenPreviewNameSettings(
      state: AppOptionsState,
      action: PayloadAction<PreviewNameSettings>
    ) {
      return {
        ...state,
        screenPreviewNameSettings: action.payload,
      };
    },
    setDeviceStatusFilter(state: AppOptionsState, action: PayloadAction<DevicesStateFilter>) {
      return {
        ...state,
        filter: action.payload,
      };
    },
    setExpandedTreeNodes(state: AppOptionsState, action: PayloadAction<string[]>) {
      return {
        ...state,
        expandedTreeNodes: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAppOptions.fulfilled, (state, action) => {
      if (action.payload === '' || action.payload == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    });
  },
});

export const {
  setSelectedTreeItem,
  setSelectedTreeColumns,
  setTreeSortation,
  setScreenPreviewViewOptions,
  setScreenPreviewNameSettings,
  setDeviceStatusFilter,
  setExpandedTreeNodes,
} = appOptions.actions;

export default appOptions.reducer;

/* ===========Selectors============= */
export const appOptionsSelector = (state: DefaultRootState) => (
  state.appOptions
);

export const selectedTreeItemSelector = (state: DefaultRootState) => (
  state.appOptions.selectedTreeItemId
);

export const selectedTreeColumnsSelector = (state: DefaultRootState) => (
  state.appOptions.columns
);

export const selectedTreeSortationSelector = (state: DefaultRootState) => (
  state.appOptions.treeSortation
);

export const screenPreviewViewOptionsSelector = (state: DefaultRootState) => (
  state.appOptions.screenPreviewViewOptions
);

export const agentScreenOptionsSelector = createSelector(
  screenPreviewViewOptionsSelector,
  previewTileWidthSelector,
  ({ previewQuality }, imageWidth) => {
    const imageHeight = Math.round((1 / screenProportions) * imageWidth);
    return ({
      quality: previewQuality,
      width: imageWidth,
      height: imageHeight,
    });
  }
);

export const screenPreviewSettingsSelector = (state: DefaultRootState) => (
  state.appOptions.screenPreviewNameSettings
);

export const deviceStatusFilterSelector = (state: DefaultRootState) => (
  state.appOptions.filter
);

export const expandedTreeNodesSelector = (state: DefaultRootState) => (
  state.appOptions.expandedTreeNodes
);
