import React, {
  PropsWithChildren, useState, useEffect,
} from 'react';
import styled from 'styled-components';
import Measure from 'react-measure';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { setAccessControlContentSize } from '@/redux/layout';


const StyledPaper = styled(Paper)`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
`;

const SettingsContainer = styled.div`
  margin: 5px 0;
  display: flex;
`;

interface AccessControlHistoryPageProps {
  settingsComponent?: React.ReactNode,
}

const AccessControlHistoryPage = ({
  settingsComponent,
  children,
}: PropsWithChildren<AccessControlHistoryPageProps>) => {
  const [containerDimensions, setContainerDimensions] = useState<{width: number, height: number}>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (containerDimensions) {
      dispatch(setAccessControlContentSize({
        height: containerDimensions.height,
        width: containerDimensions.width,
      }));
    }
  }, [
    containerDimensions,
    dispatch,
  ]);

  return (
    <>
      {settingsComponent && (
        <SettingsContainer>
          {settingsComponent}
        </SettingsContainer>
      )}
      <Measure
        client
        onResize={contentRect => setContainerDimensions({
          width: contentRect?.client?.width ?? 0,
          height: contentRect?.client?.height ?? 0,
        })}
      >
        {({ measureRef }) => (
          <StyledPaper
            ref={measureRef}
          >
            {children}
          </StyledPaper>
        )}
      </Measure>
    </>
  );
};

export default AccessControlHistoryPage;
