import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { getFullTimeFromSeconds } from '@/utils/helpers';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const StyledMeter = styled.span<{ color: string, length: string }>`
  display: block;
  height: 8px;
  min-width: 15px;
  width: ${({ length }) => `${length}%`};
  border-radius: 15px;
  background-color: ${({ color }) => color};
  position: relative;
  overflow: hidden;
  margin-bottom: 7px;
`;

const AppName = styled(Typography)`
  font-weight: bold;
  margin-right: 5px;
`;

const MeterLabel = styled.div`
  display: flex;
`;

export interface StatsElementProps {
  name: string,
  value: number,
  total: number,
  color: string,
  topValue: number,
}

const StatsElement = ({
  name, value, total, color, topValue,
}: StatsElementProps) => {
  const time = value > 60 ? getFullTimeFromSeconds(value) : `${value}s`;
  const percent = ((value / total) * 100).toFixed(2);
  const length = ((value / topValue) * 100).toFixed(2);

  return (
    <Container>
      <StyledMeter color={color} length={length} />
      <MeterLabel>
        <AppName variant="subtitle2">
          {`${name}:`}
        </AppName>
        {`  ${time} (${percent}%)`}
      </MeterLabel>
    </Container>
  );
};

export default StatsElement;
