/* eslint-disable no-underscore-dangle */
import { sortBy } from '@/utils/helpers';
import { Device } from '../../api/owpb/pbFiles/devices_service_pb';
import { DeviceTreeItemBase, TreeItemType } from './DeviceTreeItemBase';
import { AgentItem } from './AgentItem';


export class DeviceItem extends DeviceTreeItemBase {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  readonly type: TreeItemType.Device = TreeItemType.Device;
  public description: string = '';
  public children: Array<AgentItem>;

  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor(device: Device.AsObject) {
    super();
    this._id = device.id;
    this.description = device.description;
    this.children = new Array<AgentItem>();
    this.updateItem(device);
  }

  updateItem(obj: Device.AsObject): void {
    this._name = obj.name;
    this.description = obj.description;
    this.parentId = obj.parentId;
  }

  getAllDevices(): DeviceItem[] {
    return [this];
  }

  getAllDevicesSorted(
    parameterName: adminify.SortColumn,
    sortDirection: adminify.SortDirection
  ) {
    return sortBy([this], parameterName, sortDirection) as DeviceItem[];
  }
}
