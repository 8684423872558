import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';


const StyledTableCell = styled(TableCell)`
  border-color: ${({ theme }) => theme.palette.divider};
  border-right-style: solid;
  border-right-width: 1px;
`;

export default StyledTableCell;
