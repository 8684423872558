import log from 'loglevel';
import { from } from 'rxjs';
import { handleError } from '@/api/errorHandler';
import { apiUrl } from '@/constants/common';
import { store } from '@/redux/store';
import { authMetaDataSelector } from '@/redux/user';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { AuthServiceClient } from '../owpb/pbFiles/Auth_serviceServiceClientPb';
import {
  ForgotenPasswordRequest,
  GetUserAuthTokenRequest,
  ResetPasswordRequest,
  UserAuthTokenResponse,
  ChangeUserPasswordRequest,
} from '../owpb/pbFiles/auth_service_pb';
import { AppType } from '../owpb/pbFiles/basic_pb';


const authServiceClient: AuthServiceClient = new AuthServiceClient(apiUrl);

/** Autoryzacja użytkownika */
export async function getUserAuthToken(
  userLogin: string,
  passwd: string,
  customerId?: string,
): Promise<adminify.AuthData> {
  const request = new GetUserAuthTokenRequest();
  request.setLogin(userLogin);
  request.setPasswd(passwd);
  request.setAppType(AppType.WEB_APP);
  if (customerId) {
    request.setCustomerId(customerId);
  }
  const metadata = { lng: i18n.language };
  const userAuthTokenObservable = from(
    authServiceClient.getUserAuthToken(request, metadata)
  );
  return new Promise((resolve, reject) => {
    userAuthTokenObservable.subscribe(
      (response: UserAuthTokenResponse) => {
        // Jeśli mamy odpowiedź to znaczy, że dane logowania są prawidłowe. Możliwe przypadki:
        // - login podpięty jest pod jednego klienta: otrzymujemy token, customersIdList zawiera
        //   1 rekord z danymi klienta na któego się zalogowaliśmy
        // - login podpięty jest do wielu klientów, a request nie zawierał customerId. W odpowiedzi
        //   pole token jest puste, customersIdList zawiera dane klientów podpiętych pod login
        // - login podpięty jest do wielu klientów, a request zawierał customerId. W odpowiedzi
        //   pole customersIdList zawiera 1 rekord z danymi klienta na któego się zalogowaliśmy
        const data = response.toObject();
        if (data.token.length > 0 || data.customersIdList.length > 0) {
          resolve(data);
        } else {
          log.error('Login failed, probably not implemented login path', response);
        }
      },
      reject
    );
  });
}

export function forgotPassword(login: string) {
  const request = new ForgotenPasswordRequest();
  const authMetaData = authMetaDataSelector(store.getState());

  request.setLogin(login);

  return authServiceClient.sendForgotenPassword(request, authMetaData)
    .then(() => showNoty('success', i18n.t('common:notifications.retrievePasswordSuccess')))
    .catch(handleError);
}

export function resetPassword(login: string, token: string, newPasswd: string) {
  const request = new ResetPasswordRequest();
  request.setLogin(login);
  request.setToken(token);
  request.setNewPasswd(newPasswd);

  const metadata = { lng: i18n.language };
  return authServiceClient.resetPassword(request, metadata)
    .then((res) => res.toObject());
}

export function changePassword(oldPassword: string, newPassword: string) {
  const request = new ChangeUserPasswordRequest();
  const authMetaData = authMetaDataSelector(store.getState());

  request.setOldPasswd(oldPassword);
  request.setNewPasswd(newPassword);

  authServiceClient.changeUserPassword(request, authMetaData)
    .then(() => showNoty('success', i18n.t('notifications.changePasswordSuccess')))
    .catch(handleError);
}
