import { apiUrl, defaultApiCurrency } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { handleError } from '@/api/errorHandler';
import { CustomerServiceClient } from '@/api/owpb/pbFiles/Customer_serviceServiceClientPb';
import {
  InvoiceRecipient,
  GetCustomerInvoiceInfoRequest,
  UpdateCustomerInvoiceInfoRequest,
  GetLicenseRequest,
} from '@/api/owpb/pbFiles/customer_service_pb';


const client = new CustomerServiceClient(apiUrl);

const createInvoiceRecipientObject = (invoiceInfo: InvoiceRecipient.AsObject) => {
  const invoiceRecipient = new InvoiceRecipient();
  invoiceRecipient.setAddressLine1(invoiceInfo.addressLine1);
  invoiceRecipient.setAddressLine2(invoiceInfo.addressLine2);
  invoiceRecipient.setCity(invoiceInfo.city);
  invoiceRecipient.setCountry(invoiceInfo.country);
  invoiceRecipient.setName(invoiceInfo.name);
  invoiceRecipient.setTaxId(invoiceInfo.taxId);
  invoiceRecipient.setPostalCode(invoiceInfo.postalCode);
  return invoiceRecipient;
};

export async function updateCustomerInvoiceInfo(
  hasPayer: boolean,
  buyerInfo: InvoiceRecipient.AsObject,
  payerInfo: InvoiceRecipient.AsObject | undefined
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateCustomerInvoiceInfoRequest();

  request.setBuyer(createInvoiceRecipientObject(buyerInfo));
  if (hasPayer && payerInfo) {
    request.setHasPayer(hasPayer);
    request.setPayer(createInvoiceRecipientObject(payerInfo));
  } else {
    request.setHasPayer(false);
    request.clearPayer();
  }
  request.setCurrency(defaultApiCurrency);

  try {
    await client.updateCustomerInvoiceInfo(request, authMetaData);
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getCustomerInvoiceInfo() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetCustomerInvoiceInfoRequest();

  try {
    const response = await client.getCustomerInvoiceInfo(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getCustomerLicense() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetLicenseRequest();

  try {
    const response = await client.getLicense(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}
