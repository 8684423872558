import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { DeviceGroupsSelectMode } from '@/api/owpb/pbFiles/basic_pb';
import { PermissionValue } from '@/api/owpb/pbFiles/roles_service_pb';

/** Uprawnienia aktualinie zalogowanego użytkownika */
export interface UserPermissionsState {
  // Identyfikatory grup urządzeń dostępnych dla użytkownika
  // Jeśli nie ma ograniczeń w dostępie grup tablica zawiera jeden element == '*'
  availableDevicesGroupsId: Array<string>,
  // Tryb wyboru grup urządzeń
  deviceGroupsSelectMode: DeviceGroupsSelectMode,
  // True jeśli użytkownik ma uprawnienia Adminia
  isAdmin: boolean,
  permissionList: Array<PermissionValue.AsObject>,
}


const initialState = {
  availableDevicesGroupsId: [],
  deviceGroupsSelectMode: DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_ALL,
  isAdmin: false,
  permissionList: [],
} as UserPermissionsState;

const userPermissions = createSlice({
  name: 'PERMISSIONS',
  initialState,
  reducers: {
    setAvailableDevicesGroupsId(state: UserPermissionsState, action: PayloadAction<string[]>) {
      return {
        ...state,
        availableDevicesGroupsId: action.payload,
      };
    },

    setDeviceGroupSelectMode(state: UserPermissionsState,
      action: PayloadAction<DeviceGroupsSelectMode>) {
      return {
        ...state,
        deviceGroupsSelectMode: action.payload,
      };
    },

    setUserPermissionList(state: UserPermissionsState,
      action: PayloadAction<Array<PermissionValue.AsObject>>) {
      return {
        ...state,
        permissionList: action.payload,
      };
    },
  },
});

export const {
  setAvailableDevicesGroupsId,
  setDeviceGroupSelectMode,
  setUserPermissionList,
} = userPermissions.actions;

export const userPermissionsSelector = (state: DefaultRootState) => (
  state.userPermissions
);

export const userAvailableDeviceGroupsIdSelector = (state: DefaultRootState) => (
  state.userPermissions.availableDevicesGroupsId
);

export const userDeviceGroupSelectModeSelector = (state: DefaultRootState) => (
  state.userPermissions.deviceGroupsSelectMode
);

export const userPermissionListSelector = (state: DefaultRootState) => (
  state.userPermissions.permissionList
);

export default userPermissions.reducer;

