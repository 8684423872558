/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as devices_service_pb from './devices_service_pb';


export class DevicesServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateDevicesGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DevicesGroup,
    (request: devices_service_pb.CreateDevicesGroupRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DevicesGroup.deserializeBinary
  );

  createDevicesGroup(
    request: devices_service_pb.CreateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DevicesGroup>;

  createDevicesGroup(
    request: devices_service_pb.CreateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroup) => void): grpcWeb.ClientReadableStream<devices_service_pb.DevicesGroup>;

  createDevicesGroup(
    request: devices_service_pb.CreateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroup) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/CreateDevicesGroup',
        request,
        metadata || {},
        this.methodInfoCreateDevicesGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/CreateDevicesGroup',
    request,
    metadata || {},
    this.methodInfoCreateDevicesGroup);
  }

  methodInfoGetDevices = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DevicesCollection,
    (request: devices_service_pb.GetDevicesRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DevicesCollection.deserializeBinary
  );

  getDevices(
    request: devices_service_pb.GetDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DevicesCollection>;

  getDevices(
    request: devices_service_pb.GetDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesCollection) => void): grpcWeb.ClientReadableStream<devices_service_pb.DevicesCollection>;

  getDevices(
    request: devices_service_pb.GetDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/GetDevices',
        request,
        metadata || {},
        this.methodInfoGetDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/GetDevices',
    request,
    metadata || {},
    this.methodInfoGetDevices);
  }

  methodInfoGetDevicesGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DevicesGroupCollection,
    (request: devices_service_pb.GetDevicesGroupRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DevicesGroupCollection.deserializeBinary
  );

  getDevicesGroup(
    request: devices_service_pb.GetDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DevicesGroupCollection>;

  getDevicesGroup(
    request: devices_service_pb.GetDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroupCollection) => void): grpcWeb.ClientReadableStream<devices_service_pb.DevicesGroupCollection>;

  getDevicesGroup(
    request: devices_service_pb.GetDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroupCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/GetDevicesGroup',
        request,
        metadata || {},
        this.methodInfoGetDevicesGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/GetDevicesGroup',
    request,
    metadata || {},
    this.methodInfoGetDevicesGroup);
  }

  methodInfoDeleteDevice = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DeleteDeviceResponse,
    (request: devices_service_pb.DeleteDeviceRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DeleteDeviceResponse.deserializeBinary
  );

  deleteDevice(
    request: devices_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DeleteDeviceResponse>;

  deleteDevice(
    request: devices_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteDeviceResponse) => void): grpcWeb.ClientReadableStream<devices_service_pb.DeleteDeviceResponse>;

  deleteDevice(
    request: devices_service_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/DeleteDevice',
        request,
        metadata || {},
        this.methodInfoDeleteDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/DeleteDevice',
    request,
    metadata || {},
    this.methodInfoDeleteDevice);
  }

  methodInfoDeleteDevicesGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DeleteDevicesGroupResponse,
    (request: devices_service_pb.DeleteDevicesGroupRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DeleteDevicesGroupResponse.deserializeBinary
  );

  deleteDevicesGroup(
    request: devices_service_pb.DeleteDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DeleteDevicesGroupResponse>;

  deleteDevicesGroup(
    request: devices_service_pb.DeleteDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteDevicesGroupResponse) => void): grpcWeb.ClientReadableStream<devices_service_pb.DeleteDevicesGroupResponse>;

  deleteDevicesGroup(
    request: devices_service_pb.DeleteDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteDevicesGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/DeleteDevicesGroup',
        request,
        metadata || {},
        this.methodInfoDeleteDevicesGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/DeleteDevicesGroup',
    request,
    metadata || {},
    this.methodInfoDeleteDevicesGroup);
  }

  methodInfoDeleteManyDevices = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DeleteManyDevicesResponse,
    (request: devices_service_pb.DeleteManyDevicesRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DeleteManyDevicesResponse.deserializeBinary
  );

  deleteManyDevices(
    request: devices_service_pb.DeleteManyDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DeleteManyDevicesResponse>;

  deleteManyDevices(
    request: devices_service_pb.DeleteManyDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteManyDevicesResponse) => void): grpcWeb.ClientReadableStream<devices_service_pb.DeleteManyDevicesResponse>;

  deleteManyDevices(
    request: devices_service_pb.DeleteManyDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteManyDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/DeleteManyDevices',
        request,
        metadata || {},
        this.methodInfoDeleteManyDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/DeleteManyDevices',
    request,
    metadata || {},
    this.methodInfoDeleteManyDevices);
  }

  methodInfoDeleteManyDevicesGroups = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DeleteManyDevicesGroupsResponse,
    (request: devices_service_pb.DeleteManyDevicesGroupsRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DeleteManyDevicesGroupsResponse.deserializeBinary
  );

  deleteManyDevicesGroups(
    request: devices_service_pb.DeleteManyDevicesGroupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DeleteManyDevicesGroupsResponse>;

  deleteManyDevicesGroups(
    request: devices_service_pb.DeleteManyDevicesGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteManyDevicesGroupsResponse) => void): grpcWeb.ClientReadableStream<devices_service_pb.DeleteManyDevicesGroupsResponse>;

  deleteManyDevicesGroups(
    request: devices_service_pb.DeleteManyDevicesGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DeleteManyDevicesGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/DeleteManyDevicesGroups',
        request,
        metadata || {},
        this.methodInfoDeleteManyDevicesGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/DeleteManyDevicesGroups',
    request,
    metadata || {},
    this.methodInfoDeleteManyDevicesGroups);
  }

  methodInfoUpdateDevice = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.Device,
    (request: devices_service_pb.UpdateDeviceRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.Device.deserializeBinary
  );

  updateDevice(
    request: devices_service_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.Device>;

  updateDevice(
    request: devices_service_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.Device) => void): grpcWeb.ClientReadableStream<devices_service_pb.Device>;

  updateDevice(
    request: devices_service_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.Device) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/UpdateDevice',
        request,
        metadata || {},
        this.methodInfoUpdateDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/UpdateDevice',
    request,
    metadata || {},
    this.methodInfoUpdateDevice);
  }

  methodInfoUpdateDevicesGroup = new grpcWeb.AbstractClientBase.MethodInfo(
    devices_service_pb.DevicesGroup,
    (request: devices_service_pb.UpdateDevicesGroupRequest) => {
      return request.serializeBinary();
    },
    devices_service_pb.DevicesGroup.deserializeBinary
  );

  updateDevicesGroup(
    request: devices_service_pb.UpdateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<devices_service_pb.DevicesGroup>;

  updateDevicesGroup(
    request: devices_service_pb.UpdateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroup) => void): grpcWeb.ClientReadableStream<devices_service_pb.DevicesGroup>;

  updateDevicesGroup(
    request: devices_service_pb.UpdateDevicesGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: devices_service_pb.DevicesGroup) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.DevicesService/UpdateDevicesGroup',
        request,
        metadata || {},
        this.methodInfoUpdateDevicesGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.DevicesService/UpdateDevicesGroup',
    request,
    metadata || {},
    this.methodInfoUpdateDevicesGroup);
  }

}

