import React from 'react';
import { useSelectedTreeItem } from '@/hooks/useSelectedTreeItem';
import { AgentItem } from '@/TreeService/TreeItems/AgentItem';
import { DeviceItem } from '@/TreeService/TreeItems/DeviceItem';
import { TreeItemType } from '@/TreeService/TreeItems/DeviceTreeItemBase';
import { DisplayItem } from '@/TreeService/TreeItems/DisplayItem';
import ScreenPreview from './ScreenPreview';


interface DevicePreviewsProps {
  device: DeviceItem,
}

const DevicePreviews = ({ device }: DevicePreviewsProps) => {
  const { selectedTreeItem } = useSelectedTreeItem();

  if (device.isFiltered) {
    return null;
  }

  if (!device.isConnected) {
    return (
      <ScreenPreview
        deviceName={device.name}
        agentOrDeviceId={device.id}
      />
    );
  }

  return (
    <>
      {device.children.map((agent: AgentItem) => {
        if (agent.isFiltered
          || (selectedTreeItem.type === TreeItemType.Agent && agent.id !== selectedTreeItem.id)) {
          return null;
        }
        return (
          agent.children.map((display: DisplayItem) => (
            <ScreenPreview
              key={display.id}
              deviceName={device.name}
              agent={agent}
              agentOrDeviceId={agent.id}
              displayNo={display.displayNo}
            />
          ))
        );
      })}
    </>
  );
};

export default DevicePreviews;
