import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import Noty from 'noty';
import log from 'loglevel';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { notyConfig } from '@/constants/notyConfig';
import { sessionGetItem } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { lightTheme } from '@/theme/lightTheme';
import { GlobalStyle } from '@/theme/globalStyle';
import { store } from '@/redux/store';
import { sessionStoreKey } from '@/constants/common';
import { UserState } from '@/redux/user';
import Loader from '@/components/Loader';
import { restoreUserSession } from './api';
import MainSwitch from './pages';
import 'noty/src/noty.scss';
import 'noty/src/themes/relax.scss';
import 'dayjs/locale/pl';


Noty.overrideDefaults(notyConfig);
log.setDefaultLevel('error');
if (process.env.NODE_ENV === 'development') {
  log.setLevel('info', false);
}

dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.locale(i18n.language);

const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY ?? '', {
  locale: i18n.language as StripeElementLocale,
});

const queryClient = new QueryClient();

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const data = await sessionGetItem(sessionStoreKey.user);
      const userState: UserState = JSON.parse(data ?? '{}');
      if (userState?.login) {
        await restoreUserSession(userState);
      }
      setIsLoading(false);
    })();
  });

  if (isLoading) {
    return (
      <Loader.LoaderContainer $isAppLoader>
        <Loader />
      </Loader.LoaderContainer>
    );
  }

  return (
    <Router>
      <MainSwitch />
    </Router>
  );
};


const AppWithProviders = () => (
  <StoreProvider store={store}>
    <Elements stripe={stripePromise}>
      <MuiPickersUtilsProvider utils={DayjsUtils} locale={i18n.language}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={lightTheme}>
              <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <GlobalStyle />
                <App />
              </ThemeProvider>
            </MuiThemeProvider>
          </QueryClientProvider>
        </I18nextProvider>
      </MuiPickersUtilsProvider>
    </Elements>
  </StoreProvider>
);

export default AppWithProviders;
