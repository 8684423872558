import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { WebHistoryItem } from '@/api/owpb/pbFiles/history_service_pb';
import { appDateTimeFormat } from '@/constants/common';


export function useBrowsingHistoryColumns() {
  const { t } = useTranslation(['common']);
  const columns: Array<Column<WebHistoryItem.AsObject>> = useMemo(() => ([
    {
      Header: t('common:common.date'),
      id: 'timestamp',
      accessor: ({ timestamp }) => dayjs.unix(timestamp).format(appDateTimeFormat),
    },
    {
      Header: t('common:app.urlDomain'),
      id: 'urlDomain',
      accessor: 'urlDomain',
    },
    {
      Header: t('common:common.address'),
      id: 'url',
      accessor: 'url',
      width: 200,
    },
    {
      Header: t('common:common.title'),
      id: 'title',
      accessor: 'title',
    },
    {
      Header: t('common:common.categoryName'),
      id: 'categoryName',
      accessor: 'categoryName',
    },
    {
      Header: t('common:common.computer'),
      id: 'deviceName',
      accessor: 'deviceName',
    },
    {
      Header: t('common:common.domain'),
      id: 'domainName',
      accessor: 'domainName',
    },
    {
      Header: t('common:common.user'),
      id: 'userName',
      accessor: 'userName',
    },
    {
      Header: t('common:common.fullName'),
      id: 'fullName',
      accessor: 'fullName',
    },
  ]), [t]);
  return columns;
}
// skipped properties:
// id: string,
// customerId: string,
// deviceId: string,
// categoryId: string,
// itemFlags: number,
// stateFlags: number,
// roleId: string,
