import React from 'react';
import { useTheme } from 'styled-components';


interface SvgTableProps {
  stroke?: string,
}

const SvgTable = ({ stroke, ...otherProps }: SvgTableProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...otherProps}
    >
      <defs>
        <path id="table_svg__a" d="M0 0h16v16H0z" />
        <mask
          id="table_svg__b"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <path d="M0 0h16v16H0z" />
          <use fill="#fff" xlinkHref="#table_svg__a" />
        </mask>
      </defs>
      <g fill="none">
        <use xlinkHref="#table_svg__a" />
        <g mask="url(#table_svg__b)">
          <path d="M0 0h16v16H0V0z" />
          <path
            stroke={stroke ?? theme.palette.secondary.dark}
            strokeDasharray="0 0 0 0"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.667 2.667h6.666a2 2 0 012 2v6.666a2 2 0 01-2 2H4.667a2 2 0 01-2-2V4.667a2 2 0 012-2zM2.667 8h10M8 2.667v10.666"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgTable;
