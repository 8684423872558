import React from 'react';
import { useTranslation } from 'react-i18next';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { changePassword } from '@/api/clients/auth';
import { validatePassword } from '@/utils/validators';


interface FormValues {
  oldPassword: string,
  newPassword: string,
  repeatNewPassword: string,
}

interface ChangePasswordModalProps {
  isOpen: boolean,
  handleClose: () => void,
}

const ChangePasswordModal = ({ isOpen, handleClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation(['common']);

  const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (values.newPassword) {
      errors.newPassword = validatePassword(values.newPassword);
    }

    if (!values.oldPassword) {
      errors.oldPassword = t('errors:fieldCanNotBeEmpty');
    }

    if (!values.newPassword) {
      errors.newPassword = t('errors:fieldCanNotBeEmpty');
    }

    if (!values.repeatNewPassword) {
      errors.repeatNewPassword = t('errors:fieldCanNotBeEmpty');
    }

    if (values.newPassword !== values.repeatNewPassword) {
      errors.repeatNewPassword = t('errors:passwordsDoNotMatch');
    }

    return errors;
  };

  const handleSave = (values: FormValues) => {
    changePassword(values.oldPassword, values.newPassword);
    handleClose();
  };

  return (
    <FFGenericOptionsModal<FormValues>
      isOpen={isOpen}
      onClose={handleClose}
      title={t('common:userProfile.changePassword')}
      onSubmit={handleSave}
      confirmButtonText={t('common:userProfile.changePassword')}
      formProps={{
        validate,
      }}
      fixedWidth
    >
      {() => (
        <>
          <FFTextInput
            name="oldPassword"
            label={t('common:userProfile.oldPassword')}
            type="password"
          />
          <FFTextInput
            name="newPassword"
            label={t('common:userProfile.newPassword')}
            type="password"
          />
          <FFTextInput
            name="repeatNewPassword"
            label={t('common:userProfile.repeatNewPassword')}
            type="password"
          />
        </>

      )}
    </FFGenericOptionsModal>
  );
};

export default ChangePasswordModal;
