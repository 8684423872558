import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSubscription, subscriptionSelector } from '@/redux/user';
import { handleError } from '@/api/errorHandler';
import { getSubscription } from '@/api/clients/subscription';
import { Subscription } from '@/api/owpb/pbFiles/subscription_service_pb';


export function useSubscription(): Subscription.AsObject | null {
  const dispatch = useDispatch();
  const subscription = useSelector(subscriptionSelector);

  useEffect(() => {
    if (!subscription) {
      getSubscription()
        .then(res => {
          if (res) {
            dispatch(setSubscription(res));
          }
        })
        .catch(handleError);
    }
  }, [dispatch, subscription]);

  return subscription;
}
