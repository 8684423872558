import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { usePrepareApp } from '@/hooks/usePrepareApp';
import { urlStructure } from '@/constants/urlStructure';
import { isAuthenticatedSelector } from '@/redux/user';
import Loader from '@/components/Loader';
import UserProfile from '@/pages/app/UserProfile';
import Configuration from '@/pages/app/configuration';
import AccessControl from '@/pages/app/accessControl';
import License from '@/pages/app/license';
import FilesForDownload from '@/pages/app/FilesForDownload';


const App = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isAppReady = usePrepareApp();

  if (!isAuthenticated) {
    return <Redirect to={urlStructure.login} />;
  }

  if (!isAppReady) {
    return (
      <Loader.LoaderContainer $isAppLoader>
        <Loader />
      </Loader.LoaderContainer>
    );
  }

  return (
    <Switch>
      <Route path={urlStructure.accessControl}>
        <AccessControl />
      </Route>
      <Route path={urlStructure.configuration}>
        <Configuration />
      </Route>
      <Route path={urlStructure.userprofile}>
        <UserProfile />
      </Route>
      <Route path={urlStructure.filesForDownload}>
        <FilesForDownload />
      </Route>
      <Route path={urlStructure.license}>
        <License />
      </Route>
    </Switch>
  );
};


export default App;
