import * as React from 'react';


const SvgExclamationMark = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    viewBox="0 0 32 32"
    {...otherProps}
  >
    <path d="M9.3 5c-8.5 5.1-8.1 17.9.6 22.5 11.6 6 23.9-7.1 17.1-18.2C23.3 3.2 15.4 1.2 9.3 5zm7.7 8c0 2.2-.4 4-1 4-.5 0-1-1.8-1-4s.5-4 1-4c.6 0 1 1.8 1 4zm0 8.5c0 .8-.4 1.5-1 1.5-.5 0-1-.7-1-1.5s.5-1.5 1-1.5c.6 0 1 .7 1 1.5z" />
  </svg>
);

export default SvgExclamationMark;
