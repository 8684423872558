import { Menu } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { scrollbarStyles } from '@/utils/styles';


const StyledMenu = styled(Menu)`
  border-radius: 0;

  & > .MuiPaper-root {
    ${scrollbarStyles}
  }
`;

interface AccessControlContextMenuProps {
  anchorEl: null | HTMLElement | HTMLButtonElement,
  handleClose: () => void,
}

const AccessControlContextMenu = ({
  anchorEl, handleClose, children,
}: React.PropsWithChildren<AccessControlContextMenuProps>) => (
  <StyledMenu
    elevation={2}
    anchorEl={anchorEl}
    open={!!anchorEl}
    onClose={handleClose}
  >
    {children}
  </StyledMenu>
);


export default AccessControlContextMenu;
