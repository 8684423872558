import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';


const ControlButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  margin-top: 50px;
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.default};
  padding: 5px 40px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.border};
  padding: 5px 40px;
  margin-right: 10px;
`;

interface ButtonFooterStandardProps {
  justConfirm?: false | undefined,
  onConfirm: () => void,
  onClose: () => void,
  confirmButtonText?: string,
  cancelButtonText?: string,
  disabled? : boolean,
  disabledSubmit?: boolean,
}

interface ButtonFooterJustCloseProps {
  justConfirm: true,
  onConfirm: () => void,
  onClose?: undefined,
  confirmButtonText?: string,
  cancelButtonText?: undefined,
  disabled? : boolean,
  disabledSubmit?: boolean,
}

export type ButtonFooterProps = ButtonFooterStandardProps | ButtonFooterJustCloseProps;

const ButtonFooter = ({
  justConfirm = false,
  onConfirm,
  onClose,
  confirmButtonText,
  cancelButtonText,
  disabled = false,
  disabledSubmit = false,
}: ButtonFooterProps) => {
  const { t } = useTranslation(['common']);

  return (
    <ControlButtons>
      <ConfirmButton
        color="primary"
        onClick={onConfirm}
        disabled={disabled || disabledSubmit}
      >
        {confirmButtonText ?? t('common:common.apply')}
      </ConfirmButton>
      {!justConfirm && (
        <CancelButton
          onClick={onClose}
          disabled={disabled}
        >
          {cancelButtonText ?? t('common:common.cancel')}
        </CancelButton>
      )}
    </ControlButtons>
  );
};


export default ButtonFooter;
