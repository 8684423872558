import React from 'react';
import {
  Dialog, Typography, Button, MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { apiUsersRolesSelector } from '@/redux/collections/rolesCollection';
import { Role, RoleUser } from '@/api/owpb/pbFiles/roles_service_pb';
import { updateRoleUser, createRoleUser } from '@/api/clients/roles';
import ModalContent from './ModalContent';


const StyledModalContent = styled(ModalContent)`
  padding: 30px;
`;

const StyledForm = styled.form`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`;

const Controls = styled.div`
  display: flex;
  margin-top: 20px;
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.darkBorder};
  margin-right: 20px;
`;

interface FormFields {
  email: string,
  username: string,
  group: string,
  message: string,
}

interface InviteNewUserModalProps {
  open: boolean,
  handleClose: () => void,
  variant: 'add' | 'edit',
  initialData?: RoleUser.AsObject,
}

const ConfigUserModal = ({
  open, handleClose, variant, initialData,
}: InviteNewUserModalProps) => {
  const { t } = useTranslation(['common']);
  const apiRoles = useSelector(apiUsersRolesSelector);

  const handleAccept = (data: FormFields) => {
    if (variant === 'add') {
      createRoleUser(data.group, data.username, data.email);
    }
    if (initialData) {
      updateRoleUser(initialData.id, initialData.identy, data.group, data.username);
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <StyledModalContent>
        <Typography variant="h6">
          {variant === 'add'
            ? t('common:configuration.inviteNewUser')
            : t('common:configuration.editUser')}
        </Typography>
        <Form<FormFields>
          onSubmit={(values: FormFields) => handleAccept(values)}
          initialValues={initialData && {
            email: initialData.identy,
            username: initialData.name,
            group: initialData.roleId,
          }}
        >
          {({ handleSubmit }) => (
            <StyledForm onSubmit={handleSubmit}>
              <FFTextInput
                name="email"
                label={t('common:common.email')}
                disabled={variant === 'edit'}
              />
              <FFTextInput
                name="username"
                label={t('common:common.username')}
              />
              <FFTextInput
                name="group"
                label={t('common:configuration.selectGroup')}
                select
              >
                {apiRoles.map((role: Role.AsObject) => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </FFTextInput>
              {variant === 'add' && (
                <FFTextInput
                  name="message"
                  label={t('common:common.message')}
                  multiline
                  variant="outlined"
                  rows={4}
                />
              )}
              <Controls>
                <CancelButton
                  onClick={handleClose}
                  fullWidth
                >
                  {t('common:common.cancel')}
                </CancelButton>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  {variant === 'add'
                    ? t('common:configuration.sendInvite')
                    : t('common:common.apply')}
                </Button>
              </Controls>
            </StyledForm>
          )}
        </Form>
      </StyledModalContent>
    </Dialog>
  );
};

export default ConfigUserModal;
