import { ApiConsts } from '@/api/ApiConsts';
import CollectionHolderRedux from '@/api/collections/CollectionHolderRedux';
import { ProtoBuffCollection } from '@/api/collections/ProtoBuffCollection';
import {
  Device, DevicesCollection, DevicesGroup, DevicesGroupCollection,
} from '@/api/owpb/pbFiles/devices_service_pb';
import { SettingsCollection, SettingsGroup } from '@/api/owpb/pbFiles/settings_service_pb';
import { Settings } from '@/api/Settings';
import { settingsCollectionHolderFunctions } from '@/redux/collections/settingsCollection';
import { store } from '@/redux/store';
import { userAvailableDeviceGroupsIdSelector } from '@/redux/userPermissions';
import { DevicesTree } from './DevicesTree';
import { TreeCollectionHolder } from './TreeCollectionHolder';
import { DevicesGroupItem } from './TreeItems/DevicesGroupItem';


class TreeService {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  public devicesTree!: DevicesTree;
  public devicesCollection!: ProtoBuffCollection<Device.AsObject>;
  public devicesGroupCollection!: ProtoBuffCollection<DevicesGroup.AsObject>;
  public settingsCollection!: ProtoBuffCollection<SettingsGroup.AsObject>;

  constructor() {
    this.reInitialize();
  }

  public initializeTree(
    rootGroupId: string,
    availableGroupsId: Array<string>
  ) {
    this.devicesTree.initialize(rootGroupId,
      availableGroupsId,
      this.devicesGroupCollection.items,
      this.devicesCollection.items,
      new Settings(this.settingsCollection.items));
  }

  public reInitialize(): void {
    this.devicesTree = new DevicesTree('*', [], true);
    this.devicesGroupCollection = new ProtoBuffCollection<DevicesGroup.AsObject>(
      ApiConsts.CollectionIDDevicesGroup,
      (bytes) => DevicesGroup.deserializeBinary(bytes).toObject(),
      (bytes) => DevicesGroupCollection.deserializeBinary(bytes).toObject().itemsList,
      new TreeCollectionHolder<DevicesGroup.AsObject>(ApiConsts.CollectionIDDevicesGroup,
        this.devicesTree)
    );

    this.devicesCollection = new ProtoBuffCollection<Device.AsObject>(
      ApiConsts.CollectionIDDevices,
      (bytes) => Device.deserializeBinary(bytes).toObject(),
      (bytes) => DevicesCollection.deserializeBinary(bytes).toObject().itemsList,
      new TreeCollectionHolder<Device.AsObject>(ApiConsts.CollectionIDDevices, this.devicesTree)
    );

    this.settingsCollection = new ProtoBuffCollection<SettingsGroup.AsObject>(
      ApiConsts.CollectionIDSettings,
      (bytes) => SettingsGroup.deserializeBinary(bytes).toObject(),
      (bytes) => SettingsCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<SettingsGroup.AsObject>(
        ApiConsts.CollectionIDSettings,
        settingsCollectionHolderFunctions
      )
    );
  }

  /** Zwraca identyfikatory wszystkich podgrup grupy o podanym id */
  public getSubGroupsId(groupId: string): Array<string> {
    const result = new Array<string>();

    const addChildren = (parentId: string) => {
      this.devicesGroupCollection.items.forEach(group => {
        if (group.parentId === parentId) {
          result.push(group.id);
          addChildren(group.id);
        }
      });
    };
    addChildren(groupId);

    return result;
  }

  /** Zwraca dostępne dla użytkownika grupy urządzeń, na podstawie identyfikatorów
   *  dostępnych grup zapisanych w reduxie. Zwracana jest "płaska" struktura grup
   */
  public getAvailableDevicesGroups(): Array<DevicesGroup.AsObject> {
    const availableGroupsId = userAvailableDeviceGroupsIdSelector(store.getState());
    if (availableGroupsId.length === 1 && availableGroupsId[0] === '*') {
      return this.devicesGroupCollection.items;
    }

    const result = new Array<DevicesGroup.AsObject>();
    this.devicesGroupCollection.items.forEach(group => {
      if (availableGroupsId.indexOf(group.id) > -1) {
        result.push(group);
      }
    });

    return result;
  }

  /** Zwraca dostępne grupy urządzeń, analogicznie do getAvailableDevicesGroups
   * ale zwracany wynik ma strukturę drzewa
   */
  public getAvailableDevicesGroupsAsTree(): Array<DevicesGroupItem> {
    const tree = new DevicesTree('*', [], true);
    tree.initializeDevicesGroups(this.getAvailableDevicesGroups());
    return tree.getRootItems();
  }

  /** Zwraca id grupy do której należy urządzenie lub undefined gdy grupa nie
   * znaleziona lub użytkownik nie ma uprawnień do grupy
   */
  public getAvailableGroupForDevice(deviceId: string): string | undefined {
    const device = this.devicesCollection.items.find(x => x.id === deviceId);
    if (device) {
      if (!this.getAvailableDevicesGroups().find(x => x.id === device.parentId)) {
        return undefined;
      }
      return device.parentId;
    }
    return undefined;
  }
}

export default new TreeService();
