import React from 'react';


const SvgAppControl = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.976 10.442a.4.4 0 01.388-.304H18a.4.4 0 01.388.497l-1.6 6.461a.4.4 0 01-.388.304H2.764a.4.4 0 01-.388-.496l1.6-6.462zm.701.496L3.275 16.6h12.812l1.402-5.662H4.677z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6 6.23c0-.22.179-.4.4-.4h11.2a.4.4 0 01.223.069l1.499 1.009h2.01a.4.4 0 01.4.4v2.988h-.8V7.708H14.8a.4.4 0 01-.223-.069l-1.5-1.008H2.4V16.6h1.733v.8H2a.4.4 0 01-.4-.4V6.23z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.426 10.539h.374v-6.67c0-.48-.376-.869-.84-.869h-8.4c-.464 0-.84.39-.84.87v6.669h9.706z"
      fill="#FFE782"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.32 3.87c0-.688.541-1.27 1.24-1.27h8.4c.697 0 1.24.582 1.24 1.27v7.068H4.32V3.87zm1.24-.47c-.23 0-.44.197-.44.47v6.268h9.28V3.87c0-.273-.21-.47-.44-.47h-8.4z"
      fill="#2C3E50"
    />
  </svg>
);

export default SvgAppControl;
