/* eslint-disable @typescript-eslint/unbound-method */
import { DefaultRootState } from 'react-redux';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { ContentFilter, ContentFilterType } from '@/api/owpb/pbFiles/filter_service_pb';


interface FiltersByType {
  [ContentFilterType.URL_FILTER]: ContentFilter.AsObject[],
  [ContentFilterType.PHRASE_FILTER]: ContentFilter.AsObject[],
  [ContentFilterType.URL_REGEX_FILTER]: ContentFilter.AsObject[],
}
export type FiltersByCategoryAndType = Record<string, FiltersByType>;

const adapter = createEntityAdapter<ContentFilter.AsObject>({});

export type ContentFiltersCollectionState = ReturnType<typeof adapter.getInitialState>;

const contentFiltersCollection = createSlice({
  name: 'CONTENT_FILTERS_COLLECTION',
  initialState: adapter.getInitialState(),
  reducers: {
    fullCollection: adapter.setAll,
    createItem: adapter.addOne,
    updateItem: adapter.updateOne,
    deleteItem: adapter.removeOne,
  },
});

export const contentFiltersCollectionSelectors = adapter.getSelectors<DefaultRootState>(
  (state) => state.contentFiltersCollection
);

export const contentFiltersGroupedByCategorySelector = createSelector(
  contentFiltersCollectionSelectors.selectAll,
  (filters): FiltersByCategoryAndType => {
    const filtersByCategory: FiltersByCategoryAndType = {};
    filters.forEach((filter) => {
      filter.categoriesIdList.forEach((categoryId) => {
        const categoryObject: FiltersByType = filtersByCategory[categoryId]
          ?? {
            [ContentFilterType.URL_FILTER]: [],
            [ContentFilterType.PHRASE_FILTER]: [],
            [ContentFilterType.URL_REGEX_FILTER]: [],
          };
        categoryObject[filter.filterType].push(filter);
        filtersByCategory[categoryId] = categoryObject;
      });
    });
    return filtersByCategory;
  }
);

export const contentFiltersCollectionHolderFunctions = {
  ...contentFiltersCollection.actions,
  items: contentFiltersCollectionSelectors.selectAll,
};


export default contentFiltersCollection.reducer;
