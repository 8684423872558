import React from 'react';
import { useTranslation } from 'react-i18next';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { createURLCategory, updateURLCategory } from '@/api/clients/categories';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';
import { UrlCategoryWithStatus } from '@/redux/collections/urlCategoriesCollection';


export interface ConfirmationModalProps {
  open: boolean,
  variant: 'add' | 'edit',
  handleClose: () => void,
  initialValue?: UrlCategoryWithStatus,
}

const ConfigCategoryModal = ({
  open, variant, handleClose, initialValue,
}: ConfirmationModalProps) => {
  const { t } = useTranslation(['common']);

  const handleSave = (values: { name: string }) => {
    if (variant === 'add') {
      createURLCategory(values.name);
    } else if (initialValue) {
      updateURLCategory(initialValue.id, values.name);
    }
    handleClose();
  };

  return (
    <FFGenericOptionsModal
      isOpen={open}
      onClose={handleClose}
      title={variant === 'add' ? t('common:configuration.addCategory') : t('common:configuration.editCategory')}
      onSubmit={handleSave}
      confirmButtonText={variant === 'add' ? t('common:common.add') : t('common:common.apply')}
      formProps={{
        initialValues: { name: initialValue?.name },
      }}
    >
      {() => (
        <FFTextInput name="name" label={t('common:configuration.categoryName')} />
      )}
    </FFGenericOptionsModal>
  );
};

export default ConfigCategoryModal;

