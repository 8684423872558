import React from 'react';


const SvgEmptyState = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 57.849 48.543"
    {...otherProps}
  >
    <path
      fill="#DEE1E3"
      fillRule="evenodd"
      d="M48.79 1.124c1.024.73 1.8 1.76 2.224 2.953l6.767 19.615c.045.13.068.268.068.406v18.295a6.195 6.195 0 01-1.782 4.346 6.047 6.047 0 01-4.295 1.804H6.077a6.047 6.047 0 01-4.295-1.804A6.194 6.194 0 010 42.393V24.22c0-.137.023-.273.067-.403L6.83 4.088a6.16 6.16 0 012.224-2.96A6.045 6.045 0 0112.555 0h32.739a6.04 6.04 0 013.496 1.124zm-38.333 2.01a3.693 3.693 0 00-1.334 1.772L2.918 23.005h7.345v-6.387c0-.326.128-.639.355-.87.228-.23.538-.36.86-.36h2.89V8.31c0-.326.128-.639.356-.87.227-.23.537-.36.859-.36H42.95c.322 0 .631.13.86.36.227.231.355.544.355.87v7.08h2.206c.322 0 .631.129.86.36.227.23.355.543.355.87v6.386h7.383L48.723 4.897a3.69 3.69 0 00-1.335-1.766 3.621 3.621 0 00-2.094-.671H12.555a3.624 3.624 0 00-2.098.674zm6.341 12.254V9.54h24.936v5.85H16.798zm28.358 7.617v-5.157H12.693v5.157h4.395c.281 0 .554.098.77.278.218.18.367.432.422.71a13.86 13.86 0 002.083 4.624c2.061 2.938 4.965 4.428 8.63 4.428s6.569-1.49 8.63-4.428a13.857 13.857 0 002.083-4.627c.057-.278.206-.527.423-.707a1.21 1.21 0 01.768-.278h4.259zm10.262 19.388A3.717 3.717 0 0154.348 45a3.629 3.629 0 01-2.576 1.082H6.077A3.628 3.628 0 013.5 45a3.717 3.717 0 01-1.07-2.608V25.464h13.727a16.62 16.62 0 002.168 4.496c1.75 2.53 5.02 5.545 10.668 5.545s8.918-3.016 10.668-5.545a16.633 16.633 0 002.167-4.496h13.59v16.929z"
    />
  </svg>
);

export default SvgEmptyState;
