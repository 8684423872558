import { Metadata } from 'grpc-web';
import { apiUrl } from '@/constants/common';
import { AgentServiceRpcClient } from './owpb/pbFiles/AgentServiceRpcClient';
import { ScreenshotDisplayRequest } from './owpb/pbFiles/agent_pb';
import cancelablePromiseManager from './CancelablePromiseManager';


export interface GetScreenshotOptions {
  height?: number,
  width?: number,
  quality?: number,
}

class AgentRpcClients {
  private agentServices!: { [agentId: string]: AgentServiceRpcClient };

  constructor() {
    this.reInitialize();
  }

  public reInitialize() {
    this.agentServices = {};
  }

  public async getScreenshotDisplay(
    agentId: string,
    displayNo: number,
    authMetaData: Metadata,
    options: GetScreenshotOptions,
    asBlobUrl: boolean = false,
  ) {
    const contentType = { type: 'image/jpg' };
    if (options?.quality && (options.quality < 1 || options.quality > 100)) {
      throw Error('options.quality in AgentRpcClients.getScreenshotDisplay() should be between 1 and 100');
    }
    const agent = this.getAgent(agentId, authMetaData);
    const request = new ScreenshotDisplayRequest();
    request.setDisplayNo(displayNo);
    request.setQuality(options?.quality ?? 100);

    if (options?.height) {
      request.setHeight(Math.round(options.height));
    }
    if (options?.width) {
      request.setWidth(Math.round(options.width));
    }
    const promise = agent.screenshotDisplayAsCancelable(request);
    cancelablePromiseManager.addPromise(promise);
    const response = await promise;

    if (asBlobUrl) {
      const image = new Blob([response.getPayload_asU8()], contentType);
      return URL.createObjectURL(image);
    }
    return `data:${contentType.type};base64,${response.getPayload_asB64()}`;
  }

  private getAgent(agentId: string, authMetaData: Metadata): AgentServiceRpcClient {
    let agent = this.agentServices[agentId];
    if (!agent) {
      agent = new AgentServiceRpcClient(apiUrl, authMetaData, agentId);
      this.agentServices[agentId] = agent;
    }
    return agent;
  }
}

export default new AgentRpcClients();
