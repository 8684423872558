import {
  PermissionDescription, Role, RoleType, RoleUser,
} from './owpb/pbFiles/roles_service_pb';

/** Obsługa pobierania uprawnień dla danej roli/loginu */
export class PermissionsFinder {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  private descriptions: Array<PermissionDescription.AsObject>;
  private roles: Array<Role.AsObject>;
  private roleUsers: Array<RoleUser.AsObject>;

  constructor(roles: Array<Role.AsObject>, roleUsers: Array<RoleUser.AsObject>,
    descriptions: Array<PermissionDescription.AsObject>) {
    this.roles = roles;
    this.roleUsers = roleUsers;
    this.descriptions = descriptions;
  }

  public findRoleById(roleId: string): Role.AsObject | undefined {
    return this.roles.find(role => role.id === roleId);
  }

  /** Odszukuje rolę (typu API_USERS) dla podanego loginu */
  public findRoleForLogin(login: string): Role.AsObject | undefined {
    for (let i = 0; i < this.roleUsers.length; i += 1) {
      const user = this.roleUsers[i];
      if (user.identy === login) {
        const role = this.findRoleById(user.roleId);
        // Sprawdzamy typ roli: na wypadek gdyby taki sam identyfikator
        // był użyty w roli typu API_USERS jak i WINDOWS_USERS
        if (role && role.roleType === RoleType.API_USERS) {
          return role;
        }
      }
    }
    return undefined;
  }

  /** Zwraca wartość uprawnienia dla roli o podanym id */
  public getPermissionValueForRoleId(roleId: string,
    permissionId: string, splitValue: boolean = false): string | undefined {
    const role = this.findRoleById(roleId);
    if (!role) {
      return undefined;
    }
    return this.getPermissionValueForRole(role, permissionId, splitValue);
  }

  /** Zwraca wartość uprawnienia dla roli */
  public getPermissionValueForRole(role: Role.AsObject,
    permissionId: string, splitValue: boolean = false): string | undefined {
    const permission = role.permissionsList.find(p => p.id === permissionId);
    if (permission) {
      if (splitValue) {
        const r = this.splitSettingsValue(permission.value);
        return r.value1;
      }
      return permission.value;
    }

    // Zwracamy wartość domyślną dla danego ustawienia
    const defaultPermission = this.descriptions.find(p => p.id === permissionId);
    if (defaultPermission && splitValue) {
      const r = this.splitSettingsValue(defaultPermission.defaultValue);
      return r.value1;
    }

    return defaultPermission?.defaultValue;
  }

  /** Zwraca wartość uprawnienia dla roli, gdy uprawnienie nie ustawione zwraca podaną
   * wartość domyślną
   */
  public getPermissionValueForRoleWithDefault(role: Role.AsObject,
    permissionId: string, defaultValue: string): string {
    const result = this.getPermissionValueForRole(role, permissionId);
    if (result === undefined) { // getPermissionValueForRole może zwrócić '', jest to poprawne
      return defaultValue;
    }
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  public splitSettingsValue(value: string): {value1: string | undefined,
    value2: string | undefined, } {
    if (value.length === 0) {
      return { value1: undefined, value2: undefined };
    }
    const idx = value.indexOf(':');
    if (idx > -1) {
      return { value1: value.substr(0, idx), value2: value.substr(idx + 1) };
    }
    return { value1: value, value2: undefined };
  }
}
