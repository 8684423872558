// source: trial_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var basic_pb = require('./basic_pb.js');
goog.object.extend(proto, basic_pb);
goog.exportSymbol('proto.owpb.ConfirmCreateTrialCustomerRequest', null, global);
goog.exportSymbol('proto.owpb.ConfirmCreateTrialCustomerResponse', null, global);
goog.exportSymbol('proto.owpb.CreateOAuth2TrialCustomerRequest', null, global);
goog.exportSymbol('proto.owpb.CreateOAuth2TrialCustomerResponse', null, global);
goog.exportSymbol('proto.owpb.CreateTrialCustomerRequest', null, global);
goog.exportSymbol('proto.owpb.CreateTrialCustomerResponse', null, global);
goog.exportSymbol('proto.owpb.CreateTrialCustomerStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.CreateTrialCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.CreateTrialCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.CreateTrialCustomerRequest.displayName = 'proto.owpb.CreateTrialCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.CreateTrialCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.CreateTrialCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.CreateTrialCustomerResponse.displayName = 'proto.owpb.CreateTrialCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.CreateOAuth2TrialCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.CreateOAuth2TrialCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.CreateOAuth2TrialCustomerRequest.displayName = 'proto.owpb.CreateOAuth2TrialCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.CreateOAuth2TrialCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.CreateOAuth2TrialCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.CreateOAuth2TrialCustomerResponse.displayName = 'proto.owpb.CreateOAuth2TrialCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.ConfirmCreateTrialCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.ConfirmCreateTrialCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.ConfirmCreateTrialCustomerRequest.displayName = 'proto.owpb.ConfirmCreateTrialCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.owpb.ConfirmCreateTrialCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.owpb.ConfirmCreateTrialCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.owpb.ConfirmCreateTrialCustomerResponse.displayName = 'proto.owpb.ConfirmCreateTrialCustomerResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.CreateTrialCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.CreateTrialCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.CreateTrialCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateTrialCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    language: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.CreateTrialCustomerRequest}
 */
proto.owpb.CreateTrialCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.CreateTrialCustomerRequest;
  return proto.owpb.CreateTrialCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.CreateTrialCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.CreateTrialCustomerRequest}
 */
proto.owpb.CreateTrialCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {!proto.owpb.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.CreateTrialCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.CreateTrialCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.CreateTrialCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateTrialCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.owpb.CreateTrialCustomerRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.CreateTrialCustomerRequest} returns this
 */
proto.owpb.CreateTrialCustomerRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProductType product_type = 2;
 * @return {!proto.owpb.ProductType}
 */
proto.owpb.CreateTrialCustomerRequest.prototype.getProductType = function() {
  return /** @type {!proto.owpb.ProductType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.owpb.ProductType} value
 * @return {!proto.owpb.CreateTrialCustomerRequest} returns this
 */
proto.owpb.CreateTrialCustomerRequest.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.owpb.CreateTrialCustomerRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.CreateTrialCustomerRequest} returns this
 */
proto.owpb.CreateTrialCustomerRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.CreateTrialCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.CreateTrialCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.CreateTrialCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateTrialCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.CreateTrialCustomerResponse}
 */
proto.owpb.CreateTrialCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.CreateTrialCustomerResponse;
  return proto.owpb.CreateTrialCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.CreateTrialCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.CreateTrialCustomerResponse}
 */
proto.owpb.CreateTrialCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.CreateTrialCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.CreateTrialCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.CreateTrialCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateTrialCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CreateTrialCustomerStatus status = 1;
 * @return {!proto.owpb.CreateTrialCustomerStatus}
 */
proto.owpb.CreateTrialCustomerResponse.prototype.getStatus = function() {
  return /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.owpb.CreateTrialCustomerStatus} value
 * @return {!proto.owpb.CreateTrialCustomerResponse} returns this
 */
proto.owpb.CreateTrialCustomerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.CreateOAuth2TrialCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.CreateOAuth2TrialCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oauth2Token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    language: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.CreateOAuth2TrialCustomerRequest}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.CreateOAuth2TrialCustomerRequest;
  return proto.owpb.CreateOAuth2TrialCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.CreateOAuth2TrialCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.CreateOAuth2TrialCustomerRequest}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauth2Token(value);
      break;
    case 2:
      var value = /** @type {!proto.owpb.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.CreateOAuth2TrialCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.CreateOAuth2TrialCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOauth2Token();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string oauth2_token = 1;
 * @return {string}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.getOauth2Token = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.CreateOAuth2TrialCustomerRequest} returns this
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.setOauth2Token = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProductType product_type = 2;
 * @return {!proto.owpb.ProductType}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.getProductType = function() {
  return /** @type {!proto.owpb.ProductType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.owpb.ProductType} value
 * @return {!proto.owpb.CreateOAuth2TrialCustomerRequest} returns this
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.CreateOAuth2TrialCustomerRequest} returns this
 */
proto.owpb.CreateOAuth2TrialCustomerRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.CreateOAuth2TrialCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.CreateOAuth2TrialCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.CreateOAuth2TrialCustomerResponse}
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.CreateOAuth2TrialCustomerResponse;
  return proto.owpb.CreateOAuth2TrialCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.CreateOAuth2TrialCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.CreateOAuth2TrialCustomerResponse}
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.CreateOAuth2TrialCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.CreateOAuth2TrialCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CreateTrialCustomerStatus status = 1;
 * @return {!proto.owpb.CreateTrialCustomerStatus}
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.prototype.getStatus = function() {
  return /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.owpb.CreateTrialCustomerStatus} value
 * @return {!proto.owpb.CreateOAuth2TrialCustomerResponse} returns this
 */
proto.owpb.CreateOAuth2TrialCustomerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.ConfirmCreateTrialCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.ConfirmCreateTrialCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passwd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.ConfirmCreateTrialCustomerRequest}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.ConfirmCreateTrialCustomerRequest;
  return proto.owpb.ConfirmCreateTrialCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.ConfirmCreateTrialCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.ConfirmCreateTrialCustomerRequest}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.ConfirmCreateTrialCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.ConfirmCreateTrialCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPasswd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string invitation_id = 1;
 * @return {string}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.getInvitationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.ConfirmCreateTrialCustomerRequest} returns this
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.setInvitationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string passwd = 2;
 * @return {string}
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.getPasswd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.owpb.ConfirmCreateTrialCustomerRequest} returns this
 */
proto.owpb.ConfirmCreateTrialCustomerRequest.prototype.setPasswd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.owpb.ConfirmCreateTrialCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.owpb.ConfirmCreateTrialCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.owpb.ConfirmCreateTrialCustomerResponse}
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.owpb.ConfirmCreateTrialCustomerResponse;
  return proto.owpb.ConfirmCreateTrialCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.owpb.ConfirmCreateTrialCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.owpb.ConfirmCreateTrialCustomerResponse}
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.owpb.ConfirmCreateTrialCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.owpb.ConfirmCreateTrialCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CreateTrialCustomerStatus status = 1;
 * @return {!proto.owpb.CreateTrialCustomerStatus}
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.prototype.getStatus = function() {
  return /** @type {!proto.owpb.CreateTrialCustomerStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.owpb.CreateTrialCustomerStatus} value
 * @return {!proto.owpb.ConfirmCreateTrialCustomerResponse} returns this
 */
proto.owpb.ConfirmCreateTrialCustomerResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.owpb.CreateTrialCustomerStatus = {
  CREATE_TRIAL_CUSTOMER_STATUS_NONE: 0,
  TRIAL_CUSTOMER_CREATED: 1,
  TRIAL_ALREADY_CONFIRMED: 2,
  TRIAL_CONFIRM_ID_IS_INVALID: 3,
  TRIAL_USER_ALREADY_EXISTS: 4,
  TRIAL_WAITING_FOR_CONFIRMATION: 5
};

goog.object.extend(exports, proto.owpb);
