/* eslint-disable no-underscore-dangle */
/**
 * Bazowy element drzewa
 */

import { AppControlState, InetControlState } from '@/api/owpb/pbFiles/basic_pb';
import type { DisplayItem } from '@/TreeService/TreeItems/DisplayItem';
import { sortBy } from '@/utils/helpers';
import { TreeItem } from '../types';


export enum TreeItemType {
  DevicesGroup = 'DEVICES_GROUP',
  Device = 'DEVICE',
  Agent = 'AGENT',
  Display = 'DISPLAY',
}


export class DeviceTreeItemBase {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  protected _id: string = '';
  protected _isGroup: boolean = false;
  protected _name: string = '';
  public children: Array<TreeItem>;
  public isConnected: boolean = false; // Tylko dla modyfikacji przez obiekt DevicesTree !
  public filteredByStateFilter: boolean = false;
  public filteredByNameFilter: boolean = false;
  public parentId: string = '';
  public visible: boolean = true;
  // Stan kontroli dostępu do Internetu. Dla grupy może być undefined co oznacza
  // że komputery należące do grupy mają różną wartość tego pola
  public inetAccess: InetControlState | undefined = InetControlState.INET_CHECK;
  // Stan kontroli dostępu do Aplikacji
  public appAccess: AppControlState | undefined = AppControlState.APP_ALLOW;
  // Stan blokad urządzenia (flagi)
  public deviceLock: number | undefined = 0;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor() {
    this.children = new Array<TreeItem>();
  }

  get id(): string {
    return this._id;
  }

  get isGroup(): boolean {
    return this._isGroup;
  }

  get name(): string {
    return this._name;
  }

  public addChild(child: TreeItem) {
    // eslint-disable-next-line no-param-reassign
    child.parentId = this.id;
    this.children.push(child);
  }

  /** Wywołuje callbackFunc dla wszystkich dzieci do czasu znalezienia elementu
   * spełniającego podany warunek, zwraca znaleziony element lub undefined
   */
  public findFirstChildren(callbackFunc: (item: TreeItem) => boolean): TreeItem | undefined {
    for (let i = 0; i < this.children.length; i += 1) {
      const result = callbackFunc(this.children[i]);
      if (result) {
        return this.children[i];
      }
    }
    for (let i = 0; i < this.children.length; i += 1) {
      const result = this.children[i].findFirstChildren(callbackFunc);
      if (result) {
        return result;
      }
    }
    return undefined;
  }


  /** Wywołuje callbackFunc dla wszystkich dzieci */
  public forChildren(callbackFunc: (item: TreeItem) => void, skipThisItem: boolean = true) {
    if (!skipThisItem) {
      callbackFunc((this as unknown) as TreeItem);
    }
    for (let i = 0; i < this.children.length; i += 1) {
      this.children[i].forChildren(callbackFunc, false);
    }
  }


  /** Zwraca dziecko o podanym id należące do aktualnego elementu */
  public getChildById(id: string): TreeItem | null {
    for (let i = 0; i < this.children.length; i += 1) {
      const item = this.children[i];
      if (item.id === id) {
        return item;
      }
    }
    return null;
  }

  /** Zwraca dziecko o podanym id należące do aktualnego elementu lub jednego z jego podelementów */
  public getChildByIdNested(id: string): TreeItem | null {
    if (this.id === id) {
      return (this as unknown) as TreeItem;
    }

    for (let i = 0; i < this.children.length; i += 1) {
      const item = this.children[i].getChildByIdNested(id);
      if (item !== null) {
        return item;
      }
    }
    return null;
  }

  /** Zwraca id dzieci */
  public getChildrenIds(): Array<string> {
    const result = new Array<string>();
    this.children.forEach((item: TreeItem) => result.push(item.id));
    return result;
  }

  /** Zwraca dzieci i wnuki które są typu DisplayItem
   * Jest nadpisywana w DisplayItem */
  public getAllDisplays(): Array<DisplayItem> {
    const allChildren: DisplayItem[] = [];
    this.forChildren((item) => {
      if (item.type === TreeItemType.Display) {
        allChildren.push(item);
      }
    });
    return allChildren;
  }

  public getSortedChildren(
    parameterName: adminify.SortColumn,
    sortDirection: adminify.SortDirection
  ): DeviceTreeItemBase[] {
    return sortBy(this.children, parameterName, sortDirection);
  }

  get isFiltered(): boolean {
    return this.filteredByNameFilter || this.filteredByStateFilter;
  }

  public removeChildById(id: string): TreeItem | null {
    for (let i = 0; i < this.children.length; i += 1) {
      const item = this.children[i];
      if (item.id === id) {
        item.parentId = '';
        this.children.splice(i, 1);
        return item;
      }
    }
    return null;
  }
}
