import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { createGetColorForControlState } from '@/utils/display';


export function useGetColorForControlState() {
  const theme = useTheme();
  return useMemo(
    () => createGetColorForControlState(theme),
    [theme]
  );
}
