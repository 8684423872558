import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import AccessControlElement from '@/components/accessControlMenu/AccessControlElement';
import { ApiConsts } from '@/api/ApiConsts';
import AccessControlContextSubMenu from '@/components/AccessControlContextMenu/AccessControlContextSubMenu';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { useTreeItem } from '@/hooks/useTreeItem';
import UnblockAll from '@/components/fromSvg/deviceLockIcons/UnblockAll';
import BlockKeyboard from '@/components/fromSvg/deviceLockIcons/BlockKeyboard';
import BlockComputer from '@/components/fromSvg/deviceLockIcons/BlockComputer';


const StyledMenuItem = styled(MenuItem)`
  padding: 0;

  button {
    border-radius: 0;
    padding: 10px 0 10px 15px;
    justify-content: flex-start;
    margin: 0;
  }
`;

interface DeviceAccessControlSubmenuProps {
  handleClose: () => void,
  deviceId: string | string[],
}

const DeviceAccessControlSubmenu = ({ handleClose, deviceId }: DeviceAccessControlSubmenuProps) => {
  const { t } = useTranslation(['common']);
  const { treeItem } = useTreeItem(!Array.isArray(deviceId) ? deviceId : '');

  const handleAccessChange = (value: number) => {
    updateSettingsDeviceAccess(
      ApiConsts.SettingsDeviceLock,
      value.toString(),
      Array.isArray(deviceId) ? deviceId : [deviceId]
    );
  };

  return (
    <AccessControlContextSubMenu title={t('common:app.deviceAccessControl')}>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          fullWidth
          onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_DISABLED)}
          startIcon={<UnblockAll />}
          isActive={treeItem?.deviceLock === ApiConsts.DEVICE_LOCK_DISABLED}
          activeDependentBgColor
        >
          {t('common:app.unblockAll')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          fullWidth
          startIcon={<BlockKeyboard />}
          onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_KEYBOARD)}
          isActive={treeItem?.deviceLock === ApiConsts.DEVICE_LOCK_KEYBOARD}
          activeDependentBgColor
        >
          {t('common:app.blockKeyboard')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          fullWidth
          startIcon={<BlockComputer />}
          onClick={() => handleAccessChange(ApiConsts.DEVICE_LOCK_MAX_VALUE)}
          isActive={treeItem?.deviceLock === ApiConsts.DEVICE_LOCK_MAX_VALUE}
          activeDependentBgColor
        >
          {t('common:app.blockComputer')}
        </AccessControlElement>
      </StyledMenuItem>
    </AccessControlContextSubMenu>
  );
};


export default DeviceAccessControlSubmenu;
