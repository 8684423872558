/**
 * @fileoverview gRPC-Web generated client stub for owpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as invitation_service_pb from './invitation_service_pb';


export class InvitationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoConfirmUserInvitation = new grpcWeb.AbstractClientBase.MethodInfo(
    invitation_service_pb.ConfirmUserInvitationResponse,
    (request: invitation_service_pb.ConfirmUserInvitationRequest) => {
      return request.serializeBinary();
    },
    invitation_service_pb.ConfirmUserInvitationResponse.deserializeBinary
  );

  confirmUserInvitation(
    request: invitation_service_pb.ConfirmUserInvitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<invitation_service_pb.ConfirmUserInvitationResponse>;

  confirmUserInvitation(
    request: invitation_service_pb.ConfirmUserInvitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: invitation_service_pb.ConfirmUserInvitationResponse) => void): grpcWeb.ClientReadableStream<invitation_service_pb.ConfirmUserInvitationResponse>;

  confirmUserInvitation(
    request: invitation_service_pb.ConfirmUserInvitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: invitation_service_pb.ConfirmUserInvitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.InvitationService/ConfirmUserInvitation',
        request,
        metadata || {},
        this.methodInfoConfirmUserInvitation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.InvitationService/ConfirmUserInvitation',
    request,
    metadata || {},
    this.methodInfoConfirmUserInvitation);
  }

  methodInfoGetUserInvitationStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    invitation_service_pb.UserInvitationStatusResponse,
    (request: invitation_service_pb.GetUserInvitationStatusRequest) => {
      return request.serializeBinary();
    },
    invitation_service_pb.UserInvitationStatusResponse.deserializeBinary
  );

  getUserInvitationStatus(
    request: invitation_service_pb.GetUserInvitationStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<invitation_service_pb.UserInvitationStatusResponse>;

  getUserInvitationStatus(
    request: invitation_service_pb.GetUserInvitationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: invitation_service_pb.UserInvitationStatusResponse) => void): grpcWeb.ClientReadableStream<invitation_service_pb.UserInvitationStatusResponse>;

  getUserInvitationStatus(
    request: invitation_service_pb.GetUserInvitationStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: invitation_service_pb.UserInvitationStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/owpb.InvitationService/GetUserInvitationStatus',
        request,
        metadata || {},
        this.methodInfoGetUserInvitationStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/owpb.InvitationService/GetUserInvitationStatus',
    request,
    metadata || {},
    this.methodInfoGetUserInvitationStatus);
  }

}

