import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import AccessControlElement from '@/components/accessControlMenu/AccessControlElement';
import { ApiConsts } from '@/api/ApiConsts';
import AccessControlContextSubMenu from '@/components/AccessControlContextMenu/AccessControlContextSubMenu';
import { updateSettingsDeviceAccess } from '@/api/clients/settingsFunctions';
import { AppControlState } from '@/api/owpb/pbFiles/basic_pb';
import { useTreeItem } from '@/hooks/useTreeItem';
import AppAllow from '@/components/fromSvg/appAccessIcons/AppAllow';
import AppRestrictive from '@/components/fromSvg/appAccessIcons/AppRestrictive';
import AppBlock from '@/components/fromSvg/appAccessIcons/AppBlock';
import AppControl from '@/components/fromSvg/appAccessIcons/AppControl';


const StyledMenuItem = styled(MenuItem)`
  padding: 0;

  button {
    border-radius: 0;
    padding: 10px 0 10px 15px;
    justify-content: flex-start;
    margin: 0;
  }
`;

interface AppAccessControlSubmenuProps {
  handleClose: () => void,
  deviceId: string | string[],
}

const AppAccessControlSubmenu = ({ handleClose, deviceId }: AppAccessControlSubmenuProps) => {
  const { t } = useTranslation(['common']);
  const { treeItem } = useTreeItem(!Array.isArray(deviceId) ? deviceId : '');

  const handleAccessChange = (value: AppControlState) => {
    updateSettingsDeviceAccess(
      ApiConsts.SettingsDeviceAppAccess,
      value.toString(),
      Array.isArray(deviceId) ? deviceId : [deviceId]
    );
  };

  return (
    <AccessControlContextSubMenu title={t('common:app.appAccessControl')}>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange(AppControlState.APP_ALLOW)}
          startIcon={<AppAllow />}
          fullWidth
          isActive={treeItem?.appAccess === AppControlState.APP_ALLOW}
          activeDependentBgColor
        >
          {t('common:app.fullAccess')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange(AppControlState.APP_CHECK)}
          startIcon={<AppControl />}
          fullWidth
          isActive={treeItem?.appAccess === AppControlState.APP_CHECK}
          activeDependentBgColor
        >
          {t('common:app.control')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange(AppControlState.APP_BLOCK)}
          startIcon={<AppBlock />}
          fullWidth
          isActive={treeItem?.appAccess === AppControlState.APP_BLOCK}
          activeDependentBgColor
        >
          {t('common:app.blocked')}
        </AccessControlElement>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleClose}>
        <AccessControlElement
          onClick={() => handleAccessChange(AppControlState.APP_STRICT_TO_DESKTOP)}
          startIcon={<AppRestrictive />}
          fullWidth
          isActive={treeItem?.appAccess === AppControlState.APP_STRICT_TO_DESKTOP}
          activeDependentBgColor
        >
          {t('common:app.appDesktop')}
        </AccessControlElement>
      </StyledMenuItem>
    </AccessControlContextSubMenu>
  );
};

export default AppAccessControlSubmenu;
