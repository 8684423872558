import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper, Dialog, Typography, Button, DialogTitle,
} from '@material-ui/core';
import styled from 'styled-components';


const DialogContent = styled(Paper)`
  padding: 25px;
  width: 400px;
`;

const CancelButton = styled(Button)`
  border: ${({ theme }) => theme.blueBorder};
  margin-top: 20px;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-left: 0;
`;

export interface UnableToRemoveModalProps {
  open: boolean,
  groupName: string,
  handleClose: () => void,
}

const UnableToRemoveModal = ({
  open, groupName, handleClose, ...otherProps
}: UnableToRemoveModalProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog open={open} {...otherProps}>
      <DialogContent>
        <StyledDialogTitle>
          {`${t('common:unableToRemove')} ${groupName}`}
        </StyledDialogTitle>
        <Typography>
          {t('common:unableToRemoveGroup')}
        </Typography>
        <CancelButton onClick={handleClose}>
          {t('common:common.cancel')}
        </CancelButton>
      </DialogContent>
    </Dialog>
  );
};

export default UnableToRemoveModal;

