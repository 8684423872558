import React from 'react';
import { useTheme } from 'styled-components';


interface SvgRemoveIconProps {
  stroke?: string,
  onClick?: () => void,
}

const SvgRemoveIcon = ({ stroke, onClick, ...otherPops }: SvgRemoveIconProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="0 0 16 16.031"
      onClick={onClick}
      {...otherPops}
    >
      <defs>
        <path id="removeIcon_svg__a" d="M0 0h16v16.031H0z" />
        <mask
          id="removeIcon_svg__b"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <path d="M0 0h16v16.031H0z" />
          <use fill="#fff" xlinkHref="#removeIcon_svg__a" />
        </mask>
      </defs>
      <g fill="none">
        <use xlinkHref="#removeIcon_svg__a" />
        <g mask="url(#removeIcon_svg__b)">
          <path d="M0 0h16v17.281H0V0z" />
          <path
            stroke={stroke ?? theme.palette.secondary.dark}
            strokeDasharray="0 0 0 0"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.667 4.667h10.666M6.667 7.333v4M9.333 7.333v4M3.333 4.667l.667 8C4 13.403 4.597 14 5.333 14h5.334c.736 0 1.333-.597 1.333-1.333l.667-8M6 4.667v-2C6 2.298 6.298 2 6.667 2h2.666c.369 0 .667.298.667.667v2"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgRemoveIcon;
