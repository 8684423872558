import React from 'react';
import styled from 'styled-components';


const Ellipsis = styled.div`
  @keyframes loaders-ellipsis-1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loaders-ellipsis-2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }

  @keyframes loaders-ellipsis-3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  --loader-color: ${({ theme }) => theme.palette.primary.main};
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  align-self: center;
  justify-self: center;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--loader-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: loaders-ellipsis-1 600ms infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: loaders-ellipsis-2 600ms infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: loaders-ellipsis-2 600ms infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: loaders-ellipsis-3 600ms infinite;
    }
  }

`;

export const LoaderContainer = styled.div<{ $isAppLoader?: boolean }>`
  display: grid;
  height: ${({ $isAppLoader = false }) => ($isAppLoader ? '100vh' : '100%')};
`;

interface LoaderProps {
  className?: string,
}

const Loader = ({ className = '' }: LoaderProps) => (
  <Ellipsis className={className}>
    <div />
    <div />
    <div />
    <div />
  </Ellipsis>
);

Loader.LoaderContainer = LoaderContainer;

export default Loader;

