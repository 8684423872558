import React, { useState } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import log from 'loglevel';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { logout, selectDevicesGroupId, userLoggedIn } from '@/api';
import { ApiConsts } from '@/api/ApiConsts';
import { getUserAuthToken } from '@/api/clients/auth';
import { ErrorWithStatus } from '@/api/ErrorWithStatus';
import { DeviceGroupsSelectMode } from '@/api/owpb/pbFiles/basic_pb';
import PushSub from '@/api/PushSub';
import LoginSvg from '@/components/fromSvg/auth/Login';
import AuthButton from '@/components/AuthButton';
import { FFMiniTreeSelectForItems } from '@/components/finalForm/FFMiniTreeSelect';
import FFTextInput from '@/components/finalForm/FFTextInput';
import AuthLayout from '@/components/layout/AuthLayout';
import { urlStructure } from '@/constants/urlStructure';
import { store } from '@/redux/store';
import { userPermissionsSelector } from '@/redux/userPermissions';
import TreeService from '@/TreeService';
import { DevicesGroupItem } from '@/TreeService/TreeItems/DevicesGroupItem';
import { showNoty } from '@/utils/helpers';
import { validateEmail } from '@/utils/validators';


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 10px;
  }
`;

interface LoginFormFields {
  email: string,
  password: string,
}

interface SelectCustomerFormFields {
  customerId: string,
}

interface SelectDevicesGroupFormFields {
  groupId: string,
}

enum LoginResult {
  Fail,
  MustSelectCustomer,
  Success,
}

const Login = () => {
  const { t } = useTranslation(['common', 'errors']);
  const history = useHistory();
  const [pageIndex, setPageIndex] = useState(0);
  const [
    prevLoginValues,
    setPrevLoginFormValues,
  ] = useState<LoginFormFields | undefined>(undefined);
  const [prevAuthData, setPrevAuthData] = useState<adminify.AuthData | undefined>(undefined);
  const [
    availableDevicesGroups,
    setAvailableDevicesGroups,
  ] = useState<Array<DevicesGroupItem>>([]);

  const validate = (values: LoginFormFields) => {
    const errors: Partial<Record<keyof LoginFormFields, string>> = {};

    if (values.email && !validateEmail(values.email)) {
      errors.email = t('errors:invalidEmail');
    }
    return errors;
  };

  const tryLogin = async (
    email: string,
    passwd: string,
    customerId?: string,
  ): Promise<LoginResult> => {
    let result = LoginResult.Fail;
    try {
      logout();
      const authData = await getUserAuthToken(email, passwd, customerId);
      if (authData.token.length > 0) {
        await userLoggedIn(email, authData);
        result = LoginResult.Success;
      } else if (authData.customersIdList.length > 0) {
        result = LoginResult.MustSelectCustomer;
      }
      setPrevAuthData(authData);
    } catch (error) {
      const errorWithStatus = new ErrorWithStatus(error);
      const message = errorWithStatus.message !== '' ? errorWithStatus.message
        : t('common:auth.login.loginFailed');
      showNoty('error', message);
      log.error(error);
    }
    return result;
  };

  const showAppWithDevicesGroup = (deviceGroupId: string) => {
    selectDevicesGroupId(deviceGroupId);
    // Wysyłamy zdarzenie TopicGetDeviceConnected dla ustalenia aktywnych komputerów
    // Obsługa logowania mogła trwać długo (wybór grup itd). Więc w międzyczasie mogły
    // podłączyć się kolejne komputery i nie zostało to jeszcze obsłużone
    PushSub.publish(ApiConsts.TopicGetDeviceConnected, '', '');
    history.push(urlStructure.accessControl);
  };

  //* Zwraca identyfikator agenta, działa tylko na komputerze z zainstalowanym agentem */
  const getAgentIdOnWhichWeRun = async (): Promise<string> => fetch('https://opiekunucznia.pl/agentinfo')
    .then(response => response.json())
    .then((responseJson) => responseJson.did)
    .catch(() => '');

  const checkDeviceGroupPermission = async () => {
    const showSelectGroupError = (msg: string) => {
      showNoty('error', msg);
      logout();
      setPageIndex(0);
    };

    const userPermissions = userPermissionsSelector(store.getState());
    if (userPermissions.availableDevicesGroupsId.length === 0) {
      showSelectGroupError(t('errors:noAccessToDevicesGroup'));
      return;
    }

    let currentDeviceId: string;
    switch (userPermissions.deviceGroupsSelectMode) {
      case DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_ONE_GROUP_BY_USER:
        setAvailableDevicesGroups(TreeService.getAvailableDevicesGroupsAsTree());
        setPageIndex(2);
        break;
      case DeviceGroupsSelectMode.DEVICE_GROUPS_SELECT_DEPEND_ON_AGENT_GROUP:
        currentDeviceId = await getAgentIdOnWhichWeRun();
        if (!currentDeviceId) {
          showSelectGroupError(t('errors:agentNotDetected'));
        } else {
          const parentGroupId = TreeService.getAvailableGroupForDevice(currentDeviceId);
          if (!parentGroupId) {
            showSelectGroupError(t('errors:deviceGroupUnavailable'));
          } else {
            showAppWithDevicesGroup(parentGroupId);
          }
        }
        break;
      default:
        showAppWithDevicesGroup('*');
    }
  };

  const firstDevicesGroupId = (): string => {
    const groups = availableDevicesGroups;
    if (groups.length > 0) {
      return groups[0].id;
    }
    return '';
  };

  const getKeyNameWithDescription = (customer: adminify.Customer): string => {
    if (customer.keyDescription) {
      return `${customer.keyName} (${customer.keyDescription})`;
    }
    return customer.keyName;
  };

  const onSubmitLoginPage = async (values: LoginFormFields) => {
    const loginResult = await tryLogin(values.email, values.password);
    if (loginResult === LoginResult.MustSelectCustomer) {
      setPrevLoginFormValues(values);
      setPageIndex(1);
    } else if (loginResult === LoginResult.Success) {
      await checkDeviceGroupPermission();
    }
  };

  const onSubmitSelectCustomerPage = async (values: SelectCustomerFormFields) => {
    if (prevLoginValues) {
      const loginResult = await tryLogin(prevLoginValues.email,
        prevLoginValues.password, values.customerId);
      if (loginResult === LoginResult.Success) {
        await checkDeviceGroupPermission();
      }
    }
  };

  const onSubmitSelectDevicesGroupPage = (values: SelectDevicesGroupFormFields) => {
    if (values) {
      showAppWithDevicesGroup(values.groupId);
    }
  };

  const onPrevButtonClicked = () => {
    logout();
    setPrevAuthData(undefined);
    setPageIndex(0);
  };

  return (
    <AuthLayout
      sideContent={(
        <LoginSvg
          title={t('common:auth.loginImageTitle')}
          text={t('common:auth.loginImageText')}
        />
      )}
      footer={(
        <>
          <Typography variant="caption">
            <Link to={urlStructure.forgotPassword}>
              {t('common:auth.login.forgotPassword')}
            </Link>
          </Typography>
        </>
      )}
    >
      <SwipeableViews index={pageIndex} disabled>
        <Form<LoginFormFields>
          onSubmit={onSubmitLoginPage}
          validate={validate}
          initialValues={process.env.NODE_ENV === 'development'
            ? { email: process.env.PREFILL_USER, password: process.env.PREFILL_PASSWORD }
            : undefined}
        >
          {({
            handleSubmit, form, submitting,
          }) => (
            <StyledForm
              onSubmit={async (values) => {
                const isLogged = await handleSubmit(values);
                if (isLogged) {
                  form.reset();
                }
              }}
            >
              <Typography variant="h5">
                {t('common:auth.login.title')}
              </Typography>
              <FFTextInput
                name="email"
                label={t('common:auth.login.email')}
                type="text"
              />
              <FFTextInput
                name="password"
                label={t('common:auth.login.password')}
                type="password"
              />
              <AuthButton
                fullWidth
                showLoader={submitting}
                variant="contained"
                type="submit"
                label={t('common:auth.login.logIn')}
              />
            </StyledForm>
          )}
        </Form>

        <Form
          onSubmit={onSubmitSelectCustomerPage}
        >
          {({ handleSubmit, form, submitting }) => (
            <StyledForm
              onSubmit={async (values) => {
                await handleSubmit(values);
                form.reset();
              }}
            >
              <Typography variant="h5">
                {t('common:auth.login.selectCustomerTitle')}
              </Typography>
              <Typography variant="caption">
                {t('common:auth.login.loginAs', { email: prevLoginValues?.email })}
              </Typography>
              <FFTextInput
                select
                name="customerId"
                label={t('common:auth.login.selectCustomer')}
                config={{
                  defaultValue: prevAuthData?.customersIdList[0]?.customerId,
                }}
              >
                {(prevAuthData?.customersIdList ?? []).map((customer: adminify.Customer) => (
                  <MenuItem value={customer.customerId} key={customer.customerId}>
                    {getKeyNameWithDescription(customer)}
                  </MenuItem>
                ))}
              </FFTextInput>
              <AuthButton
                showLoader={submitting}
                fullWidth
                variant="contained"
                type="submit"
                label={t('common:auth.login.buttonNext')}
              />
              <AuthButton
                showLoader={false}
                isButtonDisabled={submitting}
                fullWidth
                onClick={() => {
                  onPrevButtonClicked();
                  form.reset();
                }}
                label={t('common:auth.login.buttonPrev')}
              />
            </StyledForm>
          )}
        </Form>

        <Form
          onSubmit={onSubmitSelectDevicesGroupPage}
        >
          {({ handleSubmit, form, submitting }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Typography variant="h5">
                {t('common:auth.login.selectDevicesGroup')}
              </Typography>
              <Typography variant="caption">
                {t('common:auth.login.loginAs', { email: prevLoginValues?.email })}
              </Typography>
              <FFMiniTreeSelectForItems
                name="groupId"
                label={t('common:auth.login.selectGroup')}
                items={availableDevicesGroups}
                config={{
                  defaultValue: firstDevicesGroupId(),
                }}
              />
              <AuthButton
                fullWidth
                variant="contained"
                type="submit"
                label={t('common:auth.login.buttonNext')}
                showLoader={submitting}
              />
              <AuthButton
                fullWidth
                onClick={() => {
                  onPrevButtonClicked();
                  form.reset();
                }}
                label={t('common:auth.login.buttonPrev')}
                isButtonDisabled={submitting}
                showLoader={false}
              />
            </StyledForm>
          )}
        </Form>
      </SwipeableViews>
    </AuthLayout>
  );
};

export default Login;
