import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';


const StyledTextField = styled(TextField)<{$gridArea?: string}>`
  grid-area: ${({ $gridArea }) => $gridArea};
  & > div > input, textarea {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

interface UneditableTextFieldProps {
  multiline?: boolean,
  label: string,
  value: string | number | undefined | null,
  gridArea?: string,
}

const UneditableTextField = ({
  multiline, label, value, gridArea,
}: UneditableTextFieldProps) => (
  <StyledTextField
    $gridArea={gridArea}
    label={label}
    multiline={multiline}
    rowsMax={4}
    value={(value && value !== '') ? value : '-'}
    onChange={undefined}
    disabled
    InputProps={{ disableUnderline: true }}
  />
);

export default UneditableTextField;
