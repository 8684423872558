import { apiUrl } from '@/constants/common';
import { authMetaDataSelector } from '@/redux/user';
import { store } from '@/redux/store';
import { handleError } from '@/api/errorHandler';
import { showNoty } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { SubscriptionServiceClient } from '../owpb/pbFiles/Subscription_serviceServiceClientPb';
import {
  UpdateSubscriptionRequest,
  GetPlansCollectionRequest,
  CancelSubscriptionRequest,
  GetUpcomingInvoiceRequest,
  PaymentType,
  GetSubscriptionRequest,
  ChangePaymentSettingsRequest,
} from '../owpb/pbFiles/subscription_service_pb';


const subscriptionServiceClient: SubscriptionServiceClient = new SubscriptionServiceClient(apiUrl);

export async function updateSubscription(
  paymentType: PaymentType,
  priceId: string,
  maxDevices: number,
) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new UpdateSubscriptionRequest();
  request.setPaymentType(paymentType);
  request.setPriceId(priceId);
  request.setMaxDevices(maxDevices);

  return subscriptionServiceClient.updateSubscription(request, authMetaData);
}

export async function getPlans() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetPlansCollectionRequest();

  try {
    const response = await subscriptionServiceClient.getPlans(request, authMetaData);
    return response.toObject().itemsList;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getUpcomingInvoice(priceId: string, devices: number) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetUpcomingInvoiceRequest();
  request.setPriceId(priceId);
  request.setMaxDevices(devices);

  try {
    const response = await subscriptionServiceClient.getUpcomingInvoice(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function getSubscription() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new GetSubscriptionRequest();

  try {
    const response = await subscriptionServiceClient.getSubscription(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export function cancelSubscription() {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new CancelSubscriptionRequest();

  return subscriptionServiceClient.cancelSubscription(request, authMetaData)
    .then(() => showNoty('success', i18n.t('notifications.cancelSubscriptionSuccess')))
    .catch(handleError);
}

export async function updatePaymentMethod(paymentType: PaymentType) {
  const authMetaData = authMetaDataSelector(store.getState());
  const request = new ChangePaymentSettingsRequest();

  request.setPaymentType(paymentType);

  try {
    const response = await subscriptionServiceClient.changePaymentSettings(request, authMetaData);
    return response.toObject();
  } catch (error) {
    handleError(error);
    throw error;
  }
}
