import { apiUrl } from '@/constants/common';
import i18n from '@/utils/i18n';
import { ProductType } from '../owpb/pbFiles/basic_pb';
import { TrialServiceClient } from '../owpb/pbFiles/Trial_serviceServiceClientPb';
import { CreateTrialCustomerRequest } from '../owpb/pbFiles/trial_service_pb';


const trialServiceClient: TrialServiceClient = new TrialServiceClient(apiUrl);

export async function createTrialAccount(email: string) {
  const request = new CreateTrialCustomerRequest();
  request.setLogin(email);
  request.setLanguage(i18n.language);
  request.setProductType(ProductType.ADMINIFY);
  const metadata = { lng: i18n.language };
  return trialServiceClient.createTrialCustomer(request, metadata);
}
