import CollectionHolderRedux from '@/api/collections/CollectionHolderRedux';
import { descriptionsCollectionHolderFunctions } from '@/redux/collections/descriptionsCollection';
import { rolesCollectionHolderFunctions } from '@/redux/collections/rolesCollection';
import { roleUsersCollectionHolderFunctions } from '@/redux/collections/roleUsersCollection';
import { store } from '@/redux/store';
import { loginSelector } from '@/redux/user';
import {
  setAvailableDevicesGroupsId,
  setDeviceGroupSelectMode,
  setUserPermissionList,
} from '@/redux/userPermissions';
import { stringToDeviceGroupsSelectMode } from '@/utils/converters';
import { ApiConsts } from '../ApiConsts';
import { ProtoBuffCollection } from '../collections/ProtoBuffCollection';
import {
  PermissionDescription, PermissionDescriptionsCollection, Role,
  RolesCollection, RoleType, RoleUser, RoleUsersCollection,
} from '../owpb/pbFiles/roles_service_pb';
import { PermissionsFinder } from '../PermissionsFinder';


/** Obsługa wszystkich kolekcji koniecznych dla obsługi uprawnień użytkownika */
class RolesService {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  public descriptionsCollection!: ProtoBuffCollection<PermissionDescription.AsObject>;
  public rolesCollection!: ProtoBuffCollection<Role.AsObject>;
  public roleUsersCollection!: ProtoBuffCollection<RoleUser.AsObject>;
  public permissionsComputed: boolean = false;

  constructor() {
    this.reInitialize();
  }

  public reInitialize(): void {
    this.descriptionsCollection = new ProtoBuffCollection<PermissionDescription.AsObject>(
      ApiConsts.CollectionIDPermissionDescriptions,
      (bytes) => PermissionDescription.deserializeBinary(bytes).toObject(),
      (bytes) => PermissionDescriptionsCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<PermissionDescription.AsObject>(
        ApiConsts.CollectionIDPermissionDescriptions,
        descriptionsCollectionHolderFunctions,
      )
    );

    this.rolesCollection = new ProtoBuffCollection<Role.AsObject>(
      ApiConsts.CollectionIDRoles,
      (bytes) => Role.deserializeBinary(bytes).toObject(),
      (bytes) => RolesCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<Role.AsObject>(
        ApiConsts.CollectionIDRoles,
        rolesCollectionHolderFunctions,
      )
    );

    this.roleUsersCollection = new ProtoBuffCollection<RoleUser.AsObject>(
      ApiConsts.CollectionIDRoleUsers,
      (bytes) => RoleUser.deserializeBinary(bytes).toObject(),
      (bytes) => RoleUsersCollection.deserializeBinary(bytes).toObject().itemsList,
      new CollectionHolderRedux<RoleUser.AsObject>(
        ApiConsts.CollectionIDRoleUsers,
        roleUsersCollectionHolderFunctions,
      )
    );
  }

  public onRolesCollectionChanged() {
    this.computePermissions();
  }

  public onRoleUsersCollectionChanged() {
    this.computePermissions();
  }

  public roles(): Array<Role.AsObject> {
    return this.rolesCollection.items;
  }

  public rolesByType(roleType: RoleType): Array<Role.AsObject> {
    return this.rolesCollection.items.filter(x => x.roleType === roleType);
  }

  public roleUsers(): Array<RoleUser.AsObject> {
    return this.roleUsersCollection.items;
  }

  public roleUsersByType(roleType: RoleType): Array<RoleUser.AsObject> {
    const roles = this.rolesByType(roleType);
    return this.roleUsersCollection.items.filter(x => roles.find(r => r.id === x.roleId));
  }

  public apiUsersRoles(): Array<Role.AsObject> {
    return this.rolesByType(RoleType.API_USERS);
  }

  public apiUsers(): Array<RoleUser.AsObject> {
    return this.roleUsersByType(RoleType.API_USERS);
  }

  public windowsUsersRoles(): Array<Role.AsObject> {
    return this.rolesByType(RoleType.WINDOWS_USERS);
  }

  public windowsUsers(): Array<RoleUser.AsObject> {
    return this.roleUsersByType(RoleType.WINDOWS_USERS);
  }

  private computePermissions() {
    if (this.descriptionsCollection.items.length === 0 && this.rolesCollection.items.length === 0
      && this.roleUsersCollection.items.length === 0) {
      return;
    }

    const login = loginSelector(store.getState());
    if (login) {
      this.permissionsComputed = true;
      const permissionsFinder = new PermissionsFinder(this.rolesCollection.items,
        this.roleUsersCollection.items, this.descriptionsCollection.items);

      const role = permissionsFinder.findRoleForLogin(login);
      if (role) {
        const deviceGroupSelectMode = stringToDeviceGroupsSelectMode(
          permissionsFinder.getPermissionValueForRoleWithDefault(role,
            ApiConsts.PermissionAPIDeviceGroupsSelectMode, '1')
        );
        store.dispatch(setDeviceGroupSelectMode(deviceGroupSelectMode));
        store.dispatch(setUserPermissionList(role.permissionsList));

        const groupsId = permissionsFinder.getPermissionValueForRoleWithDefault(role,
          ApiConsts.PermissionAPIAvailableDeviceGroups, '*');
        store.dispatch(setAvailableDevicesGroupsId(groupsId === '' ? new Array<string>() : groupsId.split(';')));

        // Todo: obsługa kolekcji Rules
      }
    }
  }
}

export default new RolesService();
