import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { urlStructure } from '@/constants/urlStructure';
import { notyContainerClassName } from '@/constants/notyConfig';
import { appHeaderHeight } from '@/constants/common';

// container z-index must be greater than default z-index of material-ui tabs
const Container = styled.div<{isApp: boolean}>`
  position: absolute;
  top: ${({ isApp }) => (isApp ? `${appHeaderHeight + 10}px` : '20px')};
  right: 20px;
  width: 325px;
  z-index: 1101;
`;

const NotyContainer = () => {
  const location = useLocation();
  const isApp = location.pathname.startsWith(urlStructure.app);
  return (
    <Container isApp={isApp} className={notyContainerClassName} />
  );
};

export default NotyContainer;
