import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmUserInvitation } from '@/api/clients/invitation';
import AccountInfo from './AccountInfo';


interface AccountLinkInfoProps {
  invitationId: string,
}

const AccountLinkInfo = ({ invitationId }: AccountLinkInfoProps) => {
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (invitationId) {
      void confirmUserInvitation(invitationId);
    }
  }, [invitationId]);

  return (
    <AccountInfo
      title={t('common:auth.setPassword.accountLink.title')}
      text={t('common:auth.setPassword.accountLink.text')}
    />
  );
};


export default AccountLinkInfo;
