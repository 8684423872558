import React from 'react';
import Button from '@material-ui/core/Button/Button';
import styled from 'styled-components';
import Loader from '@/components/Loader';


const StyledButton = styled(Button)<{ $disabledWithLoader: boolean, fullWidth?: boolean }>`
  padding: 15px 40px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '250px')};
  height: 50px;
`;

const StyledLoader = styled(Loader)`
  height: 10px;
  margin: 0 auto;

  & > div {
    top: 0;
  }
`;

export interface ButtonWithLoaderProps {
  onClick?: () => void,
  isButtonDisabled?: boolean,
  showLoader: boolean,
  label: string,
  variant?: 'contained' | 'outlined' | 'text',
  type?: 'button' | 'submit' | undefined,
  fullWidth?: boolean,
}

const ButtonWithLoader = ({
  onClick,
  showLoader,
  isButtonDisabled = false,
  label,
  variant,
  type,
  fullWidth,
  ...otherProps
}: ButtonWithLoaderProps) => (
  <StyledButton
    color="primary"
    variant={variant}
    onClick={onClick}
    disabled={isButtonDisabled || showLoader}
    $disabledWithLoader={showLoader}
    type={type}
    fullWidth={fullWidth}
    {...otherProps}
  >
    {showLoader ? <StyledLoader /> : label}
  </StyledButton>
);

export default ButtonWithLoader;
