import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import FFTextInput from '@/components/finalForm/FFTextInput';
import { urlCategoriesWithStatusSelector, UrlCategoryWithStatus } from '@/redux/collections/urlCategoriesCollection';
import { createURLCategoryFilter, updateURLCategoryFilter } from '@/api/clients/categories';
import { ContentFilter, ContentFilterType } from '@/api/owpb/pbFiles/filter_service_pb';
import { FFGenericOptionsModal } from '@/components/GenericOptionsModal';


const getTranslations = (type: ContentFilterType, t: TFunction, variant: 'add' | 'edit') => ({
  [ContentFilterType.URL_FILTER]: {
    title: variant === 'add' ? t('common:configuration.addUrl') : t('common:configuration.editUrl'),
    label: t('common:configuration.websiteAddress'),
  },
  [ContentFilterType.PHRASE_FILTER]: {
    title: variant === 'add' ? t('common:configuration.addWord') : t('common:configuration.editWord'),
    label: t('common:configuration.word'),
  },
  [ContentFilterType.URL_REGEX_FILTER]: {
    title: variant === 'add' ? t('common:configuration.addRegex') : t('common:configuration.editRegex'),
    label: t('common:configuration.regularExpresion'),
  },
}[type]);

export interface FilterConfigModalProps {
  open: boolean,
  variant: 'add' | 'edit',
  filterType: ContentFilterType,
  handleClose: () => void,
  initialValue?: ContentFilter.AsObject,
  categoryId?: string,
}

const FilterConfigModal = ({
  open, variant, handleClose, filterType, initialValue, categoryId,
}: FilterConfigModalProps) => {
  const { t } = useTranslation(['common']);
  const categories = useSelector(urlCategoriesWithStatusSelector);
  const translations = getTranslations(filterType, t, variant);

  const handleSave = (values: { name: string, category: string }) => {
    if (variant === 'add') {
      createURLCategoryFilter(filterType, [values.category], values.name);
    } else if (initialValue) {
      updateURLCategoryFilter(
        filterType,
        initialValue.id,
        [values.category],
        values.name
      );
    }
    handleClose();
  };

  return (
    <FFGenericOptionsModal
      isOpen={open}
      onClose={handleClose}
      title={translations.title}
      onSubmit={handleSave}
      confirmButtonText={variant === 'add' ? t('common:common.add') : t('common:common.apply')}
      formProps={{
        initialValues: {
          name: initialValue?.data,
          category: initialValue?.categoriesIdList.find(category => category === categoryId),
        },
      }}
    >
      {() => (
        <>
          <FFTextInput name="name" label={translations.label} />
          <FFTextInput name="category" select label={t('common:configuration.assignCategory')}>
            {categories.map((category: UrlCategoryWithStatus) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </FFTextInput>
        </>
      )}
    </FFGenericOptionsModal>
  );
};

export default FilterConfigModal;

