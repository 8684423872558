import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { urlStructure } from '@/constants/urlStructure';


interface AccountInfoProps {
  title: string,
  text: string,
  linkToLogin?: boolean,
}

const AccountInfo = ({
  title,
  text,
  linkToLogin = false,
}: AccountInfoProps) => {
  const { t } = useTranslation(['common']);
  return (
    <div>
      <Typography variant="h5">
        {title}
      </Typography>
      <Typography>
        {text}
      </Typography>
      {linkToLogin && (
        <Link component={RouterLink} to={urlStructure.login}>
          {t('common:auth.login.logIn')}
        </Link>
      )}
    </div>
  );
};


export default AccountInfo;
