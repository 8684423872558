import React from 'react';


const SvgContentControl = ({ ...otherProps }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.5A10 10 0 0017.083 5 10 10 0 0110 17.5 10 10 0 012.917 5 10 10 0 0010 2.5z"
      fill="#FFE782"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.669 2.126a.5.5 0 01.662 0 9.5 9.5 0 006.73 2.375.5.5 0 01.502.358 10.5 10.5 0 01-7.437 13.125.501.501 0 01-.251 0A10.5 10.5 0 012.437 4.859a.5.5 0 01.503-.358 9.5 9.5 0 006.729-2.375zM3.296 5.51A9.5 9.5 0 0010 16.983 9.5 9.5 0 0016.704 5.51 10.5 10.5 0 0110 3.156 10.5 10.5 0 013.296 5.51z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.694A5.618 5.618 0 0014.01 7.13a5.81 5.81 0 01-.506 4.449A5.675 5.675 0 0110 14.306a5.675 5.675 0 01-3.506-2.727 5.81 5.81 0 01-.506-4.45A5.618 5.618 0 0010 5.695z"
      fill="#FFF1B4"
    />
  </svg>
);

export default SvgContentControl;
