import log from 'loglevel';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { logout } from '@/api';
import { sessionStoreKey } from '@/constants/common';
import { urlStructure } from '@/constants/urlStructure';
import { UserState } from '@/redux/user';
import { sessionGetItem, showNoty } from '@/utils/helpers';
import ForgotPassword from './forgotPassword';
import Login from './login';
import Register from './register';
import SetPassword from './setPassword';


const Auth = () => {
  const { t } = useTranslation(['common']);

  // Wylogowanie jeśli użytkownik zaloguje się do aplikacji i wciśnie wstecz
  useEffect(() => {
    sessionGetItem(sessionStoreKey.user)
      .then((data) => {
        const userState: UserState = JSON.parse(data ?? '{}');
        if (userState?.login) {
          showNoty('error', t('common:auth.login.loggedOut'));
        }
        logout();
      })
      .catch((err) => log.warn('logout error:', err));
  }, [t]);

  return (
    <Switch>
      <Redirect exact from="/" to={urlStructure.login} />
      <Route path={urlStructure.login}>
        <Login />
      </Route>
      <Route path={urlStructure.register}>
        <Register />
      </Route>
      <Route path={urlStructure.setPassword}>
        <SetPassword />
      </Route>
      <Route path={urlStructure.forgotPassword}>
        <ForgotPassword />
      </Route>
    </Switch>
  );
};

export default Auth;

