import * as React from 'react';


const SvgEmail = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 15.375"
    {...otherProps}
  >
    <defs>
      <path id="email_svg__a" d="M0 0h16v15.375H0z" />
      <mask
        id="email_svg__b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v15.375H0z" />
        <use fill="#fff" xlinkHref="#email_svg__a" />
      </mask>
    </defs>
    <use fill="none" xlinkHref="#email_svg__a" />
    <g mask="url(#email_svg__b)">
      <path fill="none" d="M0 0h16v13.843H0V0z" />
      <path
        fill="#2C3E50"
        fillRule="evenodd"
        d="M13.333 2.753H2.667c-.734 0-1.327.619-1.327 1.376l-.007 8.258c0 .757.6 1.376 1.334 1.376h10.666c.734 0 1.334-.619 1.334-1.376V4.13c0-.757-.6-1.376-1.334-1.376zM2.667 4.129L8 7.569l5.333-3.44H2.667zm0 1.376L8 8.946l5.333-3.44v6.881H2.667V5.505z"
      />
    </g>
  </svg>
);

export default SvgEmail;
