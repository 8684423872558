import { useCallback, useState } from 'react';
import {
  useTable as useReactTable,
  TableOptions,
  useSortBy,
  TableInstance,
  useFlexLayout,
  useResizeColumns,
} from 'react-table';


function useHistoryTable<T extends Record<string, unknown>>(
  options: Omit<TableOptions<T>, 'manualSortBy' | 'disableMultiSort'>,
  fetchMoreData: (sortingAndPagination: adminify.TableSortingAndPagination) => void,
  hasMore: boolean = false,
  resetScrollPositionOnTreeItemSelection: boolean = true,
) {
  const tableOptions: TableOptions<T> = {
    ...options as TableOptions<T>,
    manualSortBy: true,
    disableMultiSort: true,
  };
  const tableInstance: TableInstance<T> = useReactTable<T>(
    tableOptions,
    useSortBy,
    useFlexLayout,
    useResizeColumns,
  );

  const fetchMore = useCallback(() => {
    fetchMoreData({
      skip: options.data.length,
      sortedBy: tableInstance.state.sortBy.length > 0
        ? tableInstance.state.sortBy[0]
        : undefined,
    });
  }, [fetchMoreData, options.data.length, tableInstance.state.sortBy]);

  const [
    isColumnSelectionDialogOpen,
    setIsColumnSelectionDialogOpen,
  ] = useState<boolean>(false);

  return {
    openColumnSelectionDialog: () => setIsColumnSelectionDialogOpen(true),
    tableProps: {
      tableInstance,
      infiniteScrollProps: {
        next: fetchMore,
        hasMore,
      },
      resetScrollPositionOnTreeItemSelection,
      isColumnSelectionDialogOpen,
      closeColumnSelectionDialog: () => setIsColumnSelectionDialogOpen(false),
    },
  };
}

export default useHistoryTable;
