import * as React from 'react';


const SvgActiveGroup = ({ ...otherProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...otherProps}
  >
    <defs>
      <circle id="activeGroup_svg__a" cx={3} cy={12} r={3} />
      <mask
        id="activeGroup_svg__b"
        x={0}
        y={0}
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0h16v16H0z" />
        <use fill="#fff" xlinkHref="#activeGroup_svg__a" />
      </mask>
    </defs>
    <g fill="none">
      <path d="M0 0h13.714v13.714H0V0z" />
      <path
        stroke="#2C3E50"
        strokeDasharray="0 0 0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.714 3.286a1 1 0 011-1H11a1 1 0 011 1V4.5H5a1 1 0 00-1 1v3.643H2.714a1 1 0 01-1-1V3.286z"
      />
      <g>
        <path d="M2.286 2.286H16V16H2.286V2.286z" />
        <path
          stroke="#2C3E50"
          strokeDasharray="0 0 0 0"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 4.571h8.286a1 1 0 011 1v4.858a1 1 0 01-1 1H5a1 1 0 01-1-1V5.57a1 1 0 011-1zM6.286 13.714h5.27M7.429 11.429v2.285M10.857 11.429v2.285"
        />
      </g>
    </g>
    <use fill="#72E373" xlinkHref="#activeGroup_svg__a" />
    <use
      fillOpacity={0}
      stroke="#2C3E50"
      strokeWidth={2}
      mask="url(#activeGroup_svg__b)"
      xlinkHref="#activeGroup_svg__a"
    />
  </svg>
);

export default SvgActiveGroup;
