/* eslint-disable @typescript-eslint/lines-between-class-members */
import { Error as GrpcError, Status as GrpcStatus } from 'grpc-web';
import i18n from '@/utils/i18n';
import { Code } from './google/pbFiles/code_pb';
import { Status } from './google/pbFiles/status_pb';
import { ApiError } from './owpb/pbFiles/basic_pb';


export type GrpcErrorOrStatus = GrpcError | GrpcStatus;

const isGrpcError = (variableToCheck: GrpcErrorOrStatus): variableToCheck is GrpcError => (
  (variableToCheck as GrpcError).message !== undefined
);

const isGrpcStatus = (variableToCheck: GrpcErrorOrStatus): variableToCheck is GrpcStatus => (
  (variableToCheck as GrpcStatus).details !== undefined
);

const codeNameMap = Object.entries(Code).reduce<Record<Code, string>>(
  (accumulator, [key, value]) => ({ [value]: key, ...accumulator }),
  {} as Record<Code, string>
);


/**
 * Konwersja stringu na Uint8Array
 * */
function stringToUint8Array(str: string): Uint8Array {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
}


/**
 * Klasa reprezentujący informacje o błędzie GRPC
 */
export class ErrorWithStatus extends Error {
  /** Enum opisujący błąd, może być undefined jeśli przesłany błąd nie zawierał szczegółów */
  readonly apiError: ApiError.AsObject | undefined;
  /** Kod błędu GRPC */
  readonly code: Code;
  /** Nazwa kodu błędu GRPC */
  readonly codeName: string;
  /** Status GRPC */
  readonly status: Status | undefined;

  readonly name = 'ErrorWithStatus';


  constructor(error: GrpcErrorOrStatus) { // eslint-disable-line @typescript-eslint/no-explicit-any
    super('');
    this.code = error.code;
    this.codeName = codeNameMap[error.code as Code];

    if (isGrpcError(error)) {
      this.message = decodeURIComponent(error.message);
    } else if (error.details) {
      this.message = error.details;
    }
    // Gdy brak połączenia z proxy, albo backendem dostajemy:
    // error.Code == 2 i details == 'Http response at 400 or 500 level'
    // Ten sam błąd ma miejsce gdy zamknięty zostanie stream (np. restart backendu)
    // Komunikat 'Http response at' pochodzi z pliku  errorcode.js w google-closure-library
    if (error.code === 2 && this.message === 'Http response at 400 or 500 level') {
      this.message = i18n.t('errors:httpConnectionError');
    }

    // Odczyt grpc-status-details-bin z metadata, i pobranie z niego naszego typu błędu
    if (isGrpcStatus(error) && error.metadata !== undefined) {
      // w wypadku zamknięcia streamu komunikat błędu może znajdować się tylko w metadata
      if (!this.message) {
        this.message = error.metadata['grpc-message'];
      }
      const data = error.metadata['grpc-status-details-bin'];
      if (data) {
        const dataDecoded = atob(data);
        this.status = Status.deserializeBinary(stringToUint8Array(dataDecoded));
        if (this.status) {
          const details = this.status.getDetailsList();
          if (details && details.length > 0) {
            this.apiError = ApiError.deserializeBinary(
              details[0].getValue_asU8()
            ).toObject();
          }
        }
      }
    }

    if (!this.message) {
      this.message = i18n.t('errors:unknownServerError');
    }
  }
}
