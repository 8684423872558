import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { urlStructure } from '@/constants/urlStructure';
import OrderStatus from '@/pages/app/OrderStatus';
import App from './app';
import Auth from './auth';


const MainSwitch = () => (
  <Switch>
    <Route path={urlStructure.app}>
      <App />
    </Route>
    <Route path={urlStructure.orderStatus}>
      <OrderStatus />
    </Route>
    {/* urlStructure.auth powinien być ostatnim routem */}
    <Route path={urlStructure.auth}>
      <Auth />
    </Route>
  </Switch>
);


export default MainSwitch;
