import styled from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';


export type SettingsButtonProps = ButtonProps;

const SettingsButton = styled(Button)`
  text-transform: none;

  & + & {
    margin-left: 20px;
  }
`;

export default SettingsButton;
