import React from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Slider, { SliderProps } from '@material-ui/core/Slider';
import { FFBaseProps } from './types';


const EndsLabels = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-top: -6px;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export interface FFSliderProps extends
  FFBaseProps<number>,
  Omit<SliderProps, 'name' | 'value' | 'onChange'> {
  label?: React.ReactNode,
  endsLabels?: [leftLabel: string, rigthLabel: string],
}

const FFSlider = ({
  name,
  config,
  label,
  className,
  endsLabels,
  ...otherProps
}: FFSliderProps) => {
  const { input: { onChange, ...restInput } } = useField(name, config);

  return (
    <div className={className}>
      {label && (
        <Typography gutterBottom>
          {label}
        </Typography>
      )}
      <Slider
        onChange={(event, value) => onChange(value)}
        {...restInput}
        {...otherProps}
      />
      {endsLabels && (
        <EndsLabels>
          <span>
            {endsLabels[0]}
          </span>
          <span>
            {endsLabels[1]}
          </span>
        </EndsLabels>
      )}
    </div>
  );
};


export default FFSlider;

