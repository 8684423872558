import {
  useTable,
  TableOptions,
  useSortBy,
  useFlexLayout,
  useExpanded,
} from 'react-table';


type Options<T extends Record<string, unknown>> = Omit<TableOptions<T>, 'disableMultiSort'>;

function useConfigTable<T extends Record<string, unknown>>(options: Options<T>) {
  return useTable<T>(
    {
      autoResetExpanded: false,
      ...options as TableOptions<T>,
      disableMultiSort: true,
    },
    useFlexLayout,
    useSortBy,
    useExpanded,
  );
}

export default useConfigTable;
