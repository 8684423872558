import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import log from 'loglevel';
import treeService from '@/TreeService';
import { TreeItem } from '@/TreeService/types';


interface UseTreeItemReturn {
  treeItem: TreeItem | null,
  version: number,
}

export function useTreeItem(itemId: string | null | undefined): UseTreeItemReturn {
  const [treeItem, setTreeItem] = useState<TreeItem | null>(
    itemId ? (treeService.devicesTree.getById(itemId) ?? null) : null
  );
  /* Ponieważ obiekt tree jest instancją złożonej klasy,
  * to useState nie jest wstanie odróżnić starszej wersji od nowszej.
  * To sprawia że nie odpala się rerender komponentu.
  * Wersjonowanie drzewa za pomocą zmiennej `version` naprawia ten problem. */
  const [version, setVersion] = useState<number>(0);

  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);

  if (isFirstRun) {
    if (!treeService.devicesTree) {
      const error = new Error('useTreeItem used before tree initialization');
      Sentry.captureException(error);
      log.error(error);
    }
    setIsFirstRun(false);
  }

  useEffect(() => {
    const observable = treeService.devicesTree.devicesTreeSubject.asObservable();
    const subscription = observable.subscribe((value) => {
      if (treeService.devicesTree) {
        setVersion(value);
        if (itemId == null) {
          setTreeItem(null);
        } else {
          setTreeItem(
            treeService.devicesTree.getById(itemId)
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [itemId]);

  return { treeItem, version };
}
